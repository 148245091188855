export const Constants = {
  MIN_ACCEPTED: 300,
  MAX_ACCEPTED: 2500,
  PROMOTIONCARD_PREFIX: '6015470',
  MIN_CARD_ACCEPTED: 9,
  TELEFONO: '901 10 11 01',
  MIN_CARDCONTROL_ACCEPTED: 1,
  URL_REDIR_NO_LITERS: 'https://form.typeform.com/to/rlnUN8',
  SABADO: 'Sábado',
  PRODUCT_TYPE: {
    ENERGY: 'Energy',
    BI_ENERGY: 'BiEnergy',
    GASOIL_C_EXCELLENT: 'GasoilCExcellent',
    AGRO_DIESEL: 'AgroDiesel',
    GASOIL_B_EXCELLENT: 'GasoilBExcellent'
  },
  REPSOL_DIRECTO: 'repsoldirecto'
}
