<template>
  <section class="products">
    <StaticsHeader
    :title="$t(`message.PRODUCTS_SECTION_TITLE`)"
    :backgroundImg="require(`images/jpg/content_header02_bg.jpg`)"/>
    <div class="transition">
      <div class="products__claim">
          <h2 class="products__claim__title">{{$t('message.PRODUCTS_CLAIM_TITLE')}}</h2>
          <p class="products__claim__content">{{$t('message.PRODUCTS_CLAIM_CONTENT')}}</p>
      </div>
      <div class="products__content">
          <h2 class="products__content__title">{{$t('message.PRODUCTS_CONTENT_TITLE')}}</h2>
          <ProductSchedule
            typology="bi-energy"
          >
            <template #logo>
              <img :src="require(`images/png/logos/BiEnergy-transp.png`)" :alt="$t('message.PRODUCTS_CONTENT_PRODUCT_NAME_01')">
            </template>
            <template #title>
              {{$t('message.PRODUCTS_CONTENT_PRODUCT_NAME_01')}}:
            </template>
            <template #claim>
              {{$t('message.PRODUCTS_CONTENT_PRODUCT_CLAIM_01')}}
            </template>
            <template #body>
              <p>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_01_01')}}</p>
              <p>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_02_01')}}</p>
            </template>
            <template #link>
              <a
              class="link--simple"
              :href="`${$t('message.COUNTRY_LINK')}${$t('message.PRODUCTS_BIENERGY_LINK')}`"
              target="_blank">{{$t('message.PRODUCTS_BIENERGY_LINK_LABEL')}}</a>
            </template>
          </ProductSchedule>
          <ProductSchedule
            typology="energy"
          >
            <template #logo>
              <img :src="require(`images/png/logos/Energy-transp.png`)" :alt="$t('message.PRODUCTS_CONTENT_PRODUCT_NAME_02')">
            </template>
            <template #title>
              {{$t('message.PRODUCTS_CONTENT_PRODUCT_NAME_02')}}:
            </template>
            <template #claim>
              {{$t('message.PRODUCTS_CONTENT_PRODUCT_CLAIM_02')}}
            </template>
            <template #body>
              <p>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_01_02')}}</p>
              <p>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_02_02')}}</p>
            </template>
            <template #link>
              <a
              class="link--simple"
              :href="`${$t('message.COUNTRY_LINK')}${$t('message.PRODUCTS_ENERGY_LINK')}`"
              target="_blank">{{$t('message.PRODUCTS_ENERGY_LINK_LABEL')}}</a>
            </template>
          </ProductSchedule>
      </div>
      <ProductComparative :tableData="tableConfig"></ProductComparative>
      <div class="products__content">
          <h2 class="products__content__title">{{$t('message.PRODUCTS_CONTENT_TITLE2')}}</h2>
          <ProductSchedule
            typology="agrodiesel"
          >
            <template #logo>
              <img :src="require(`images/png/logos/AgroDiesel-transp.png`)" :alt="$t('message.PRODUCTS_CONTENT_PRODUCT_NAME_03')">
            </template>
            <template #title>
              {{$t('message.PRODUCTS_CONTENT_PRODUCT_NAME_03')}}:
            </template>
            <template #claim>
              {{$t('message.PRODUCTS_CONTENT_PRODUCT_CLAIM_03')}}
            </template>
            <template #body>
              <p>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_01_03')}}</p>
              <p>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_02_03')}}</p>
              <!-- <p>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_03_03')}}</p>
              <ul>
                <li>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_04_03')}}</li>
                <li>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_05_03')}}</li>
                <li>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_06_03')}}</li>
                <li>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_07_03')}}</li>
              </ul>
              <p>{{$t('message.PRODUCTS_CONTENT_PRODUCT_CONTENT_08_03')}}</p> -->
            </template>
            <template #link>
              <a
              class="link--simple"
              :href="`${$t('message.COUNTRY_LINK')}${$t('message.PRODUCTS_AGRODIESEL_LINK')}`"
              target="_blank">{{$t('message.PRODUCTS_AGRODIESEL_LINK_LABEL')}}</a>
            </template>
          </ProductSchedule>
      </div>
      <ProductComparative :tableData="tableConfig2"></ProductComparative>
      <CallToAction
            :title="$t('message.STATICS_CTA_TITLE')"
            :subtitle="$t('message.STATICS_CTA_SUBTITLE')"
            :ctaLabel="$t('message.STATICS_CTA_BUTTON_LABEL')"
            @ctaClick="onCtaClick"/>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Products',
  data () {
    return {
      mq: null,
      tableConfig: {
        isSticky: false,
        head: [{
          image: 'truck_BiEnergy',
          productName: this.$t('message.PRODUCTS_TABLE_PRODUCT_NAME_01')
        }, {
          image: 'truck_Energy',
          productName: this.$t('message.PRODUCTS_TABLE_PRODUCT_NAME_02')
        }],
        body: [{
          label: this.$t(`message.${this.translationTagCreator(1)}`),
          product_A: true,
          product_B: false
        }, {
          label: this.$t(`message.${this.translationTagCreator(2)}`),
          product_A: false,
          product_B: true
        }, {
          label: this.$t(`message.${this.translationTagCreator(3)}`),
          product_A: true,
          product_B: true
        }, {
          label: this.$t(`message.${this.translationTagCreator(4)}`),
          product_A: true,
          product_B: true
        }, {
          label: this.$t(`message.${this.translationTagCreator(5)}`),
          product_A: true,
          product_B: false
        }, {
          label: this.$t(`message.${this.translationTagCreator(6)}`),
          product_A: '30%',
          product_B: '2%'
        }, {
          label: this.$t(`message.${this.translationTagCreator(7)}`),
          product_A: true,
          product_B: true
        }]
      },
      tableConfig2: {
        isSticky: false,
        head: [{
          productName: this.$t('message.PRODUCTS_TABLE_PRODUCT_NAME_03')
        }, {
          image: 'truck_AgroDiesel',
          productName: this.$t('message.PRODUCTS_TABLE_PRODUCT_NAME_04')
        }],
        body: [{
          label: this.$t(`message.${this.translationTagCreator(8)}`),
          product_A: false,
          product_B: true
        }, {
          label: this.$t(`message.${this.translationTagCreator(9)}`),
          product_A: true,
          product_B: true
        }, {
          label: this.$t(`message.${this.translationTagCreator(10)}`),
          product_A: false,
          product_B: true
        }, {
          label: this.$t(`message.${this.translationTagCreator(11)}`),
          product_A: true,
          product_B: true
        }, {
          label: this.$t(`message.${this.translationTagCreator(12)}`),
          product_A: false,
          product_B: true
        }]
      }
    }
  },

  methods: {
    checkTableSticky () {
      this.isSticky = this.mq.matches
    },
    translationTagCreator (id) {
      return `PRODUCTS_TABLE_LABEL_${id < 10 ? '0' + id : id}`
    },
    onCtaClick () {
      this.$router.push({ name: 'home' })
    }
  },

  mounted () {
    if (matchMedia) {
      this.mq = window.matchMedia('(max-width: 992px)')
      this.mq.addListener(this.checkTableSticky)
      this.checkTableSticky(this.mq)
    }
  }
}
</script>

<style lang="scss" scoped src="./Products.scss"></style>
