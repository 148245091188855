var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{class:[
    _vm.opened ? 'modal--opened' : false,
    _vm.closed ? 'modal--closed' : false,
    'modal'
  ],attrs:{"role":"dialog"},on:{"click":function($event){return _vm.detectClick($event)}}},[_c('div',{ref:"wrap",staticClass:"modal__wrap",style:([
      this.modal.component.background ? { 'background-color' : `#${this.modal.component.background}` } : null,
      this.modal.component.size ? { 'max-width' : `${this.modal.component.size}px` } : null
    ])},[(!this.modal.component.allowClose)?_c('button',{class:[
        _vm.isReverse ? 'modal__close--isReverse' : null,
        'modal__close'
      ],on:{"click":function($event){return _vm.toClose()}}}):_vm._e(),_c('div',{staticClass:"modal__component"},[_c(this.modal.component.name,_vm._b({tag:"component",on:{"isReverse":_vm.setReverse,"start":_vm.goToStart,"reject":_vm.rejectOffert,"accept":_vm.acceptOffert,"unregister":_vm.userUnregister,"close":_vm.closeModal,"acceptCode":_vm.acceptCode,"repeatOrder":_vm.repeatOrder,"externalURL":_vm.externalURL,"deleteOrder":_vm.deleteOrder}},'component',this.modal.component.props,false),[_vm._l((this.modal.component.slots),function(slot){return _c('template',{slot:slot.name},[_vm._v(" "+_vm._s(slot.content)+" ")])})],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }