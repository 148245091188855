<template>
  <section
    :class="[
      isFocused ? 'input--isFocused' : null,
      isFailed ? 'input--isFailed' : null,
      isDisabled ? 'input--isDisabled' : null,
      hasCustomWidth !== '' ? 'input--hasCustomWidth' : null,
      $slots['icon'] ? 'input--hasIcon' : null,
      'input'
    ]"
    :style="[ hasCustomWidth !== '' ? {'max-width' : hasCustomWidth} : null ]"
  >
    <label
      v-if="$slots['label']"
      class="input__label"
      :for="hasID"
    >
      <!-- @slot label-->
      <slot name="label"/>
    </label>
    <div class="input__wrap">
      <!-- @slot icon -->
      <slot name="icon"/>
      <input
        :id="hasID"
        type="text"
        class="input__field"
        :placeholder="hasPlaceholder"
        :readonly="isDisabled"
        :maxlength="hasMax"
        :value="value"
        v-numericOnly= 'isNumber'
        @focus="focused"
        @blur="blurred"
        @input="sendModel($event)"
      >
    </div>
    <span v-if="$slots['errorMessage']" class="input__failed-message">
      <!-- @slot errorMessage -->
      <slot name="errorMessage"/>
    </span>
    <span v-if="$slots['extratext']" class="input__extratext">
      <!-- @slot extratext -->
      <slot name="extratext"/>
    </span>
  </section>
</template>
<script>
import { numericOnly } from '../../directives/filter'

export default {
  name: 'InputText',

  data() {
    return {
      isFocused: false
    }
  },

  directives: {
    numericOnly
  },

  props: {
    hasID: {
      type: String,
      default: 'input'
    },

    hasPlaceholder: {
      type: String,
      default: ''
    },

    hasCustomWidth: {
      type: String,
      default: ''
    },

    isFailed: {
      type: Boolean,
      default: false
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    hasMax: {
      type: Number,
      default: 250
    },

    isNumber: {
      type: Boolean,
      default: false
    },

    value: {
      type: [String, Number, Object],
      default: null
    }
  },

  methods: {
    sendModel(e) {
      this.$emit('input', e.target.value)
    },

    focused() {
      if (!this.isDisabled) this.isFocused = true
    },

    blurred() {
      if (!this.isDisabled) this.isFocused = false
    }
  }
}
</script>
<style lang="scss" scoped src="./InputText.scss"></style>
