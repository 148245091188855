<template>
  <section class="user-login">
    <transition
      name="spinner"
      appear>
      <Spinner
        v-if="spinner"
        :message="$t(`message.WAITING_MESSAGE`)"/>
    </transition>
    <transition
      name="userForm"
      appear>
      <div v-if="!formDestination" id="login-container"></div>
      <div v-else id="login-container--modal"></div>
    </transition>
  </section>
</template>

<script>
import { GigyaService } from '../../services/gigya'
import { mapActions } from 'vuex'

export default {
  name: 'UserLogin',

  data () {
    return {
      destination: 'login-container',
      spinner: true
    }
  },

  props: {
    hasDestination: {
      type: String,
      default: ''
    }
  },

  computed: {
    formDestination () {
      return this.hasDestination.length > 0
    }
  },

  methods: {
    ...mapActions('analytics', ['pushVirtualPage']),

    async loadContent () {
      await GigyaService.showLogin(this.setDestination()).then(() => {
        this.spinner = false
      })

      this.pushVirtualPage({
        path: this.$route.path,
        pageName: '/es/acceso-clientes/login'
      })
    },

    setDestination () {
      if (this.formDestination) {
        return `${this.destination}--${this.hasDestination}`
      }
      return this.destination
    }
  },

  mounted () {
    this.loadContent()
  }
}
</script>
<style lang="scss" scoped src="./UserLogin.scss"></style>
