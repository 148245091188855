<template>
  <section class="order-entry">
    <slot name="header" />
    <slot name="body" />
    <slot name="footer" />
  </section>
</template>
<script>
export default {
  name: 'OrderEntry'
}
</script>
<style lang="scss" scoped src="./OrderEntry.scss"></style>
