var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[_vm.isHighlight ? 'order-box--isHighlight' : null,
    'order-box']},[(this.$slots['title'])?_c('h2',{staticClass:"order-box__title order-box--isTitle",domProps:{"innerHTML":_vm._s(_vm.getContent('title'))}}):_vm._e(),(!_vm.isVoid)?_c('article',{staticClass:"order-box__wrap"},[(_vm.header)?_c('div',{staticClass:"order-box__header"},[_c(_vm.header.tag || 'dl',{tag:"component",staticClass:"order-box__list"},[(!_vm.getChildExist(_vm.header, 'children'))?[_vm._v(_vm._s(_vm.header.content))]:_vm._l((_vm.header.children),function(elem,index){return [(elem.children)?_c(elem.tag || 'div',{key:`${elem}-${index}`,tag:"component",staticClass:"order-box__cell"},[(!_vm.getChildExist(elem, 'children'))?[_vm._v(_vm._s(elem.content))]:_vm._l((elem.children),function(cell,index){return _c(cell.tag,{key:`${elem}-${index}_${cell}-${index}`,tag:"component",class:[cell.extraClass ? _vm.getExtraClass({classes: cell.extraClass}) : null]},[_vm._v(" "+_vm._s(cell.content)+" ")])})],2):_vm._e()]})],2),(_vm.state)?_c('span',{class:[
            'order-box__state',
            _vm.state.type ? `order-box__state--${_vm.state.type}` : null]},[_vm._v(" "+_vm._s(_vm.state.label)+" ")]):_vm._e()],1):_vm._e(),(_vm.info)?_c('div',{class:[
          _vm.info.extraClass ? !_vm.info.extraClass.includes(null) ? _vm.getExtraClass({classes: _vm.info.extraClass}) : '' : null,
          'order-box__info']},[(_vm.info.image)?_c('div',{staticClass:"order-box__info__image"},[_c('figure',{class:_vm.info.image.extraClass ? _vm.getExtraClass({classes: _vm.info.image.extraClass}) : null},[_c('img',{attrs:{"src":require(`../../assets/images/${_vm.info.image.src}`),"alt":_vm.info.image.alt}})])]):_vm._e(),(_vm.info && _vm.info.content)?_c(_vm.info.content.tag,{tag:"component",staticClass:"order-box__table"},[(_vm.info.content.rows)?[_vm._l((_vm.info.content.rows.content),function(row,index){return _c(_vm.info.content.rows.tag,{key:`${_vm.info.content.rows.tag}-${index}`,tag:"component",class:[
                row.extraClass ? _vm.getExtraClass({classes: row.extraClass}) : null,
                'order-box__table__row']},[_vm._l((row.cells),function(cell,index){return [(cell)?_c(cell.tag,{key:`${cell.tag}-${index}`,tag:"component",class:[
                    index === 0 ? 'order-box__head' : 'order-box__desc',
                    cell.extraClass ? _vm.getExtraClass({classes: cell.extraClass}) : null]},[(!cell.extraComponent)?_c('p',{domProps:{"innerHTML":_vm._s(cell.text)}}):_c(cell.extraComponent.is,_vm._b({tag:"component",nativeOn:{"click":function($event){return _vm.emitClick(cell.extraComponent.action)}}},'component',cell.extraComponent.props,false),[[_vm._v(_vm._s(cell.extraComponent.text))]],2)],1):_vm._e()]})],2)}),(this.$slots['price'] || this.$slots['action'])?_c('tr',{staticClass:"order-box--hasPrice order-box__table__row"},[(this.$slots['price'])?_vm._t("price"):_vm._e(),(this.$slots['action'])?_vm._t("action"):_vm._e()],2):_vm._e()]:_vm._e()],2):_vm._e()],1):_vm._e()]):_c('aside',{staticClass:"order-box--isVoid"},[(this.$slots['isVoidTitle'])?_c('h3',{staticClass:"order-box__void-title",domProps:{"innerHTML":_vm._s(_vm.getContent('isVoidTitle'))}}):_vm._e(),(this.$slots['isVoidMessage'])?_c('div',{staticClass:"order-box__void-message",domProps:{"innerHTML":_vm._s(_vm.getContent('isVoidMessage'))}}):_vm._e(),(this.$slots['isVoidAction'])?_c('div',{staticClass:"order-box__void-action"},[_vm._t("isVoidAction")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }