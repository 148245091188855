PromotionMessageValidation:
<template>
  <aside class="promotional-message-validation">
    <p class="promotional-message-validation__content">
      <IconComponent
        v-if="valid"
        class="validPromotionaCode"
        name="IconStepCheck"
        size="custom"
      />
      <span
        v-if="this.$slots['message']"
        v-html="message">
      </span>
      <LinkButton
        v-if="showInfo"
        class="user-form-order__showInfo"
        @click.native="displayInfo"
        :aria-label="$t(`message.ARIA_LABEL_SHOW_INFO`)">
        <template>
          <IconComponent name="IconInfo" size="xxsmall" />
          </template>
        </LinkButton>
    </p>
  </aside>
</template>
<script>
export default {
  name: 'PromotionMessageValidation',

  props: {
    valid: { type: Boolean },
    showInfo: {
      type: String,
      default: undefined
    }
  },

  computed: {
    message () {
      return this.$slots['message'][0]['text']
    }
  },

  methods: {
    displayInfo () {
      this.$emit('displayInfo')
    }
  }
}
</script>
<style lang="scss" scoped src="./PromotionMessageValidation.scss"></style>
