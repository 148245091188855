import Status from '../../services/http/Status'

const state = {
  isAlive: false
}

const mutations = {
  setStatus: (state, isAlive) => {
    state.isAlive = isAlive
  }
}

const actions = {
  checkStatus: async ({ commit }) => {
    try {
      const response = await Status.check()
      commit('setStatus', response.isAlive)
    } catch (error) {
      console.error(error)
    }
  }
}

export const status = {
  namespaced: true,
  state,
  mutations,
  actions
}
