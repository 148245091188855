import { http } from './index'

const status = '/status'

class Status {
  static check = async () => {
    try {
      const res = await http.get(`${status}`)
      return res.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}

export default Status
