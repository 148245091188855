import { http } from './index'
import axios from 'axios'
import router from '../../router/index'

/**
 * This cancel token lets you cancel request on demand
 * @type {CancelTokenStatic|*}
 */
const CancelToken = axios.CancelToken
let cancelations = []
const user = '/user'

class User {
  static unregister = async payload => {
    try {
      const res = await http.post(
        `${user}/unregister`,
        {
          UID: payload.UID,
          UIDSignature: payload.UIDSignature,
          signatureTimestamp: payload.signatureTimestamp,
          default: payload.default,
          reason: payload.reason
        },
        {
          cancelToken: new CancelToken(function executor (c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      router.push({ name: 'server-error' })
    }
  }
}

export { User }
