
export const numericOnly = {
  bind (el, binding, vnode) {
    const isActive = typeof (binding.value) !== 'undefined' ? binding.value : false

    if (isActive) {
      el.addEventListener('input', () => {
        vnode.context.$root.$nextTick(function() {
          el.value = el.value.replace(/[^\d]/g, '')
        })
      }, false)
    }
  }
}
