<template>
  <aside
    :class="[
      opened ? 'modal--opened' : false,
      closed ? 'modal--closed' : false,
      'modal'
    ]"
    @click="detectClick($event)"
    role="dialog">
    <div
      class="modal__wrap"
      :style="[
        this.modal.component.background ? { 'background-color' : `#${this.modal.component.background}` } : null,
        this.modal.component.size ? { 'max-width' : `${this.modal.component.size}px` } : null
      ]"
      ref="wrap">
      <button
        :class="[
          isReverse ? 'modal__close--isReverse' : null,
          'modal__close'
        ]"
        v-if="!this.modal.component.allowClose"
        @click="toClose()" />
      <div class="modal__component">
        <!-- dynamic component to display in modal -->
        <component
          :is="this.modal.component.name"
          v-bind="this.modal.component.props"
          @isReverse="setReverse"
          @start="goToStart"
          @reject="rejectOffert"
          @accept="acceptOffert"
          @unregister="userUnregister"
          @close="closeModal"
          @acceptCode="acceptCode"
          @repeatOrder="repeatOrder"
          @externalURL="externalURL"
          @deleteOrder="deleteOrder">
          <template
            v-for="slot in this.modal.component.slots"
            :slot="slot.name">
              {{slot.content}}
          </template>
        </component>
        <!-- // -->
      </div>
    </div>
  </aside>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import VueScrollTo from 'vue-scrollto'
import { Errors } from '../../errors'
import { Prices } from '../../helpers/prices'
import { LocalStorage } from '../../services/storage/localStorage'
import { Draft } from '../../helpers/draft'
import { GigyaService } from '../../services/gigya'

export default {
  name: 'ModalDisplay',
  data () {
    return {
      opened: false,
      closed: false,
      hasHeader: false,
      isReverse: false,
      callBack: ''
    }
  },

  computed: {
    ...mapState([
      'modal',
      'orders',
      'user'
    ]),

    voucherValidation () {
      return this.orders.order.voucher.isValid
    },

    modalSize () {
      return `${this.modal.component.size}px`
    },

    unregisterState () {
      return this.user.unregister
    }
  },

  methods: {
    ...mapActions('modal', [
      'setModalDisplay',
      'setModalSize',
      'setModalProps',
      'setModalClose',
      'setModalSubmit'
    ]),

    ...mapActions('orders', [
      'clearOrder',
      'getPrices',
      'availableResponse',
      'setWayletAvailable',
      'setLockState',
      'setVoucherValidation',
      'setVoucherDescription',
      'setButtonState',
      'setVoucher',
      'setVoucherValidation',
      'setVoucherDescriptions',
      'setButtonState',
      'setVoucherUse',
      'setFilterField',
      'setOrderFilter',
      'setButtonState',
      'deleteUserOrder',
      'setDeleteReason'
    ]),
    ...mapActions('user', ['unregister', 'setUnregisterState']),

    ...mapActions('analytics', ['pushAvailabilityEvent']),

    toClose (callBack) {
      this.closed = true

      let callBackFn = this.modal.component.callBack || callBack
      if (typeof callBackFn !== 'undefined' && callBackFn !== null) {
        this.callBack = callBackFn
      } else {
        this.callBack = ''
      }

      const wrap = this.$refs.wrap
      if (typeof wrap !== 'undefined' && wrap !== null) wrap.addEventListener('animationend', (e) => this.detectAnimationEnd(e))
    },

    detectClick (e) {
      const target = e.target
      const comparative = 'modal'
      if (target.classList.contains(comparative)) {
        if (!this.modal.component.allowClose) this.toClose()
      }
    },

    detectAnimationEnd (e) {
      const wrap = this.$refs.wrap
      if (typeof wrap !== 'undefined' && wrap !== null) wrap.removeEventListener('animationend', (e) => this.detectAnimationEnd(e))

      const animationName = e.animationName
      const check = animationName.includes('modal--wrap-out')

      if (check) {
        this.opened = false
        this.setLockState({ state: false })
        this.setModalDisplay({ state: false })

        this.startAnimation = false

        if (this.callBack !== '') {
          this[this.callBack]()
        }
      }
    },

    goToAmountDetail () {
      VueScrollTo.scrollTo('#order-prices', 400, { easing: 'ease-out' })
    },

    checkUserLogged () {
      this.$root.$emit('checkLogged')
    },

    setReverse (value) {
      this.isReverse = value
    },

    getWindowWidth () {
      const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      return w
    },

    closeModal () {
      this.$root.$emit('close')
    },

    goToStart () {
      this.$root.$emit('close')
      this.$router.push({ name: 'home' })
      this.clearOrder()
    },

    async rejectOffert () {
      this.$root.$emit('close')
      const {
        addressInfo: {
          address,
          postalCode
        },
        amountInfo: {
          quantity,
          filter
        }
      } = this.orders.order

      const results = await this.getPrices({
        address: address,
        postalCode: postalCode.value,
        amount: quantity,
        type: filter
      })

      if (results) {
        if (!('code' in results)) {
          if (Prices.filterList(results.offers, quantity).length === 0) {
            this.$store.dispatch(`orders/${Errors.RESULTS_ERROR}`)
          } else {
            this.availableResponse(results.offers)
            this.setWayletAvailable(results.wayletAvailable)
            localStorage.removeItem('wayletAvailable')
            localStorage.setItem('wayletAvailable', results.wayletAvailable.toString())
            this.setButtonState({ type: 'search', isDisabled: false })
          }
        } else {
          this.$store.dispatch(`orders/${results.code}`)
        }
      }

      this.setVoucher({ code: '' })
      this.setVoucherValidation({ isValid: '' })
      this.setVoucherDescription({ description: '' })
      this.setVoucherUse({ use: false })
    },

    acceptOffert () {
      this.$root.$emit('close', 'goToAmountDetail')
      this.setDraft()
    },

    userUnregister () {
      const { props } = this.modal.component
      props[0].isDisabled = true
      props[0].hasSpinner = true
      this.setModalProps({ props: props })

      GigyaService.getAccountInfo()
        .then(eventObject => {
          if (eventObject.errorCode !== 0) {
            this.$root.$emit('close')
            this.$router.push({ name: 'server-error' })
          } else {
            this.showSubmitMessage()
            const {
              UID,
              UIDSignature,
              signatureTimestamp
            } = eventObject

            const {
              defaultChoose,
              reason,
              extraText
            } = this.unregisterState

            this.unregister({
              UID,
              UIDSignature,
              signatureTimestamp,
              default: defaultChoose,
              reason: `${reason} ${extraText}`
            })
          }
        })
    },

    acceptCode () {
      this.acceptOffert()
      this.setVoucher({ code: '' })
      this.setVoucherValidation({ isValid: '' })
      this.setVoucherDescription({ description: '' })
      this.setVoucherUse({ use: false })

      LocalStorage.update('orderPending', {
        voucher: {
          code: '',
          isValid: '',
          description: ''
        }
      })
    },

    setDraft () {
      GigyaService.getAccountInfo()
        .then(eventObject => {
          if (eventObject.errorCode !== 0) {
            this.openLoginModal()
          } else {
            const store = this.$store
            const that = this
            Draft.set(eventObject).then((res) => {
              if (res) {
                if (res.code) {
                  const extraInfo = 'extraInfo' in res ? res.extraInfo : ''
                  store.dispatch(`orders/${res.code}`, extraInfo)
                  return false
                } else {
                  this.setButtonState({ type: 'order', isDisabled: false })
                  if (that.voucherValidation) store.dispatch(`orders/setVoucherUse`, { use: true })
                }
              }
            })
          }
        })
    },

    async repeatOrder (result) {
      const { props } = this.modal.component
      props.hasButton[0].isLoading = true
      this.setModalProps({ props: props })

      const {
        address,
        postalCode,
        amount
      } = result

      const results = await this.getPrices({
        address,
        postalCode,
        amount,
        type: 'LITERS'
      })

      this.setFilterField({ filterField: amount })
      this.setVoucherValidation({ isValid: '' })
      this.setVoucherDescription({ description: '' })
      this.setVoucher({ code: '' })
      this.setOrderFilter({ value: 'LITERS' })

      localStorage.removeItem('orderPending')

      if (!('code' in results)) {
        if (Prices.filterList(results.offers, amount).length === 0) {
          this.$store.dispatch(`orders/${Errors.RESULTS_ERROR}`)
        } else {
          props.hasButton[0].isLoading = false

          this.setModalProps({ props: props })
          this.availableResponse(results.offers)
          this.setWayletAvailable(results.wayletAvailable)
          localStorage.removeItem('wayletAvailable')
          localStorage.setItem('wayletAvailable', results.wayletAvailable.toString())
          LocalStorage.update('orderPending', {
            addressInfo: {
              address,
              postalCode
            },
            amountInfo: {
              quantity: amount,
              filter: 'LITERS',
              filterField: amount
            }
          })

          this.pushAvailabilityEvent({
            action: 'repetir pedido ver disponibilidad y precio',
            label: result.productType,
            category: 'navegacion'
          })
        }
      } else {
        this.$store.dispatch(`orders/${results.code}`)
      }
    },

    externalURL (param) {
      this.closeModal()
      window.open(param.url, '_blank')
    },

    deleteOrder (result) {
      const { id } = result
      GigyaService.getAccountInfo()
        .then(eventObject => {
          if (eventObject.errorCode !== 0) {
            this.openLoginModal()
          } else {
            const {
              UID,
              UIDSignature,
              signatureTimestamp
            } = eventObject

            const { props } = this.modal.component
            props[0].isDisabled = true
            props[0].hasSpinner = true
            this.setModalProps({ props: props })

            this.deleteUserOrder({
              UID,
              UIDSignature,
              signatureTimestamp,
              orderId: id,
              reason: this.orders.order.deletedReason
            }).then((res) => {
              if (res && !res.code) {
                this.showSubmitMessage()
                this.$root.$emit('reloadHistoryOrders')
              }
            })
          }
        })
    },

    openLoginModal () {
      this.setModalDisplay({ state: true })
      this.setModalComponent({
        name: 'UserSession',
        allowClose: false,
        size: 970,
        props: {
          hasDestination: 'modal',
          isComponent: 'UserLogin'
        }
      })
    },

    showSubmitMessage () {
      this.setModalSize({ size: 430 })
      this.setModalSubmit({ submit: true })
      const { props } = this.modal.component
      props[0].submit = true
      props[0].isDisabled = false
      props[0].hasSpinner = false

      this.setModalProps({ props: props })
    }
  },

  mounted () {
    this.opened = true
    this.setLockState({ state: true })
    this.$root.$on('close', (callBack) => { this.toClose(callBack) })
  }
}
</script>
<style lang="scss" scoped src="./ModalContainer.scss"></style>
