<template>
    <section class="business-services">
      <StaticsHeader
        :title="$t(`message.BUSINESSSERVICES_HEADER_TITLE`)"
        :backgroundImg="require(`images/jpg/content_header04_bg.jpg`)"/>
      <div class="transition">
        <div class="business-services__claim">
          <h2 class="business-services__claim__title">{{$t(`message.BUSINESSSERVICES_CLAIM_TITLE`)}}</h2>
          <p class="business-services__claim__content" v-html="$t(`message.BUSINESSSERVICES_CLAIM_CONTENT`)"></p>
        </div>

        <div class="business-services__content business-services__content--features">
          <div class="business-services__content-inner">
            <h2 class="business-services__content__title">{{$t(`message.BUSINESSSERVICES_FEATURES_SECTION_TITLE`)}}</h2>
            <ul class="business-services__content__list">
              <li
                v-for = "(paragr, index) in contents.features.blocks"
                :key="index"
              >
                <BusinessServicesCard>
                  <template #logo><IconComponent :name="paragr.logo" size="custom" /></template>
                  <template #title>{{paragr.title}}</template>
                  <template #content>{{paragr.text}}</template>
                </BusinessServicesCard>
              </li>
            </ul>
          </div>
        </div>

        <div class="business-services__content business-services__content--order-steps">
          <div class="business-services__content-inner">
            <h2 class="business-services__content__title">{{contents.steps.section}}</h2>
            <ul class="business-services__content__list">
              <li
                v-for = "(paragr, index) in contents.steps.blocks"
                :key="index"
              >
                <BusinessServicesCard
                  type="steps"
                  :background="`jpg/${paragr.background}`"
                  :step="parseInt(index) + 1"
                >
                  <template #title>{{paragr.title}}</template>
                </BusinessServicesCard>
              </li>
            </ul>
          </div>
        </div>
        <CallToAction
          :title="$t(`message.STATICS_CTA_TITLE`)"
          :subtitle="$t(`message.STATICS_CTA_SUBTITLE`)"
          :ctaLabel="$t(`message.STATICS_CTA_BUTTON_LABEL`)"
          @ctaClick="onCtaClick"/>
      </div>
    </section>
</template>

<script>
export default {
  name: 'BusinessServices',
  data () {
    return {
      contents: {
        features: {
          blocks: [
            {
              logo: 'IconCheck',
              title: this.$t('message.BUSINESSSERVICES_FEATURES_0_TITLE'),
              text: this.$t('message.BUSINESSSERVICES_FEATURES_0_TEXT')
            },
            {
              logo: 'IconCalendar',
              title: this.$t('message.BUSINESSSERVICES_FEATURES_1_TITLE'),
              text: this.$t('message.BUSINESSSERVICES_FEATURES_1_TEXT')
            },
            {
              logo: 'IconSmartphone',
              title: this.$t('message.BUSINESSSERVICES_FEATURES_2_TITLE'),
              text: this.$t('message.BUSINESSSERVICES_FEATURES_2_TEXT')
            }
          ]
        },
        steps: {
          section: this.$t('message.BUSINESSSERVICES_ORDERSTEPS_SECTION_TITLE'),
          blocks: [
            {
              background: 'business_step00_bg.jpg',
              title: this.$t('message.BUSINESSSERVICES_STEPS_0_TITLE')
            },
            {
              background: 'business_step01_bg.jpg',
              title: this.$t('message.BUSINESSSERVICES_STEPS_1_TITLE')
            },
            {
              background: 'business_step02_bg.jpg',
              title: this.$t('message.BUSINESSSERVICES_STEPS_2_TITLE')
            }
          ]
        }
      }
    }
  },

  methods: {
    onCtaClick () {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
<style lang="scss" scoped src="./BusinessServices.scss"></style>
