<template>
  <section class="error-message">
    <article class="error-message__entry transition">
      <IconComponent :name="icon ? icon.type : 'IconTruck'" :size="icon ? icon.size : 'big'"></IconComponent>
      <h2 class="error-message__title">{{title}}</h2>
      <p v-if="message" class="error-message__message">{{message}}</p>

      <div v-if="titleNotice || messageNotice">
        <h3 v-if="titleNotice" class="error-message__notice-title">{{titleNotice}}</h3>
        <p v-if="messageNotice" class="error-message__notice-message">{{messageNotice}}</p>
      </div>

      <p v-if="queryCode">{{$t(`message.SERVER_CODE_ERROR`, {code: queryCode})}}</p>

      <template v-if="buttons">
        <ButtonComponent
          v-for="button in buttons"
          :key="button.label"
          :label="button.label"
          @click.native="handleAction(button.action)"
        ></ButtonComponent>
      </template>
      <p v-if="extraText" class="error-message__contact" v-html="extraText"></p>
    </article>
  </section>
</template>
<script>
export default {
  name: 'ErrorMessageComponent',

  computed: {
    queryCode() {
      return this.$route && this.$route.query && this.$route.query.code
    }
  },

  props: {
    icon: { type: Object },
    title: { type: String },
    message: { type: String },
    titleNotice: { type: String },
    messageNotice: { type: String },
    extraText: { type: String },
    buttons: { type: Array }
  },

  methods: {
    handleAction(fn) {
      fn.call()
    }
  }
}
</script>
<style lang="scss" scoped src="./ErrorMessage.scss"></style>
