var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[`app__state--${_vm.$route.name}`, 'app']},[_c('div',{staticClass:"app__wrap"},[_c('section',{class:[
      'app__header',
      _vm.isFirstTime ? 'isFirstTime' : null,
      _vm.getUrlPromo
    ]},[_c('transition',{attrs:{"name":"router-vertical-anim"}},[_c('router-view',{attrs:{"name":"header","mode":_vm.$route.name}})],1)],1),_c('transition',{attrs:{"name":"expandHeight"},on:{"before-enter":function($event){return _vm.setExpandHeight(arguments, {
        variable: '--expand-title-height',
        queryElement: '.section-title__item'
      })}}},[(_vm.sectionTitleExist)?_c('aside',{ref:"expandTitle",staticClass:"app__title"},[_c('SectionTitle',{attrs:{"main":_vm.mainTitle,"payoff":_vm.payoff},on:{"startAnimation":_vm.startAnimation}})],1):_vm._e()]),_c('transition',{attrs:{"name":"expandHeight"},on:{"before-enter":function($event){return _vm.setExpandHeight(arguments, {
        variable: '--expand-cognitive-height',
        queryElement: '.cognitive-list'
      })}}},[(_vm.$route.name === 'home')?_c('aside',{staticClass:"app__cognitive"},[_c('div',{staticClass:"cognitive-list"},_vm._l((_vm.cognitiveMessages),function(item,index){return _c('CognitiveInfo',{directives:[{name:"view",rawName:"v-view.once",value:(_vm.viewHandler),expression:"viewHandler",modifiers:{"once":true}}],key:index,attrs:{"data-position":index,"data-computed":item.computed,"data-value":item.value,"data-increase":item.increase},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconComponent',{attrs:{"name":item.icon.type,"size":item.icon.size}})]},proxy:true},{key:"highlight",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`message.COGNITIVEINFOLIST_HIGHLIGHT_TEXT`, { quantity: _vm.getComputed(item), text: item.type }))+" ")]},proxy:true},{key:"message",fn:function(){return [_vm._v(" "+_vm._s(item.message.text)+" ")]},proxy:true}],null,true)})}),1)]):_vm._e()]),_c('transition',{attrs:{"name":"minimized"}},[(_vm.mainComponent)?_c('section',{staticClass:"app__main"},[_c('transition',{attrs:{"name":"router-vertical-anim","appear":""}},[_c('router-view')],1)],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }