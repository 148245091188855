<template>
  <form
    @submit.prevent="validateForm"
    :class="[
      isFailed ? 'survey--isFailed' : null,
      'survey'
    ]"
    autocomplete="off"
  >
    <transition name="minimized">
      <Spinner
        v-if="!isVisible"
        :message="$t(`message.WAITING_MESSAGE`)"/>
    </transition>

    <transition name="translated">
      <div
        v-if="isVisible"
        class="survey__wrap">
        <ProviderData
          :hasPhone="$t(`message.ASSESSMENT_PROVIDER_INFO`, {phone: providerInfo.phone})"
          :hasMail="$t(`message.ASSESSMENT_PROVIDER_MAIL`, {mail: providerInfo.email})"
        >
          <template #title>{{$t(`message.ASSESSMENT_PROVIDER_TITLE`)}}</template>
          <template #image><img :src="require(`images/png/assessment_trader.png`)" alt="trader"></template>
          <template #name>{{providerInfo.name}}</template>
        </ProviderData>
        <SurveyQuestion
          v-for="node in questions"
          :key="node.type"
          :isActive="windowWidth >= 768 ? true : node.active"
          :type="node.type"
          v-model.trim="$v.form[node.type].$model"
        >
          <template #title>{{node.title}}</template>
          <template #note>{{node.answer}}</template>
          <template #votes>
            <StarsVote
              :hasType="node.type"
              :ratings="node.ratings.values"
              :defaultMessage="node.ratings.defaultMessage"
              :icon="{type: 'IconStar', size: 'custom', activeClass: 'active'}"
              :animation="node.ratings.animation"
              @vote="setVote"
            ></StarsVote>
          </template>
        </SurveyQuestion>
        <div class="survey__comments">
          <label
            class="survey__comments__label"
            for="comments"
          >{{$t(`message.ASSESSMENT_COMMENTS_LABEL`)}}</label>
          <textarea
            rows="4"
            id="comments"
            :placeholder="$t(`message.ASSESSMENT_COMMENTS_PLACEHOLDER`)"
            v-model.trim="$v.form.observations.$model"
          ></textarea>
          <transition name="minimized">
            <span
              v-if="isFailed"
              class="survey__failed-message">
              {{$t(`message.ASSESSMENT_COMMENTS_ERROR`)}}
            </span>
          </transition>
        </div>
        <ButtonComponent
          :spinner="isLoading"
          :label="$t(`message.ASSESSMENT_COMMENTS_BUTTON`)"
          type="submit"
        ></ButtonComponent>
      </div>
    </transition>
  </form>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { GigyaService } from '../../services/gigya'
import { Assessment } from '../../services/http/Assessment'
import VueScrollTo from 'vue-scrollto'

export default {
  name: 'SurveyForm',
  data () {
    return {
      isLoading: false,
      isFailed: false,
      isVisible: false,
      provider: '',
      form: {
        amability: '',
        punctuality: '',
        delivery: '',
        observations: ''
      },
      questions: [
        {
          type: 'amability',
          title: this.$t(`message.ASSESSMENT_AMABILITY_TITLE`),
          answer: this.$t(`message.ASSESSMENT_AMABILITY_NOTE`),
          active: true,
          ratings: {
            defaultMessage: this.$t('message.ASSESSMENT_STRIPE_START'),
            values: [
              {
                label: this.$t('message.ASSESSMENT_STRIPE_0'),
                score: '1'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_1'),
                score: '2'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_2'),
                score: '3'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_3'),
                score: '4'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_4'),
                score: '5'
              }
            ]
          }
        },
        {
          type: 'punctuality',
          title: this.$t(`message.ASSESSMENT_PUNCTUALITY_TITLE`),
          answer: this.$t(`message.ASSESSMENT_PUNCTUALITY_NOTE`),
          active: false,
          ratings: {
            values: [
              {
                label: this.$t('message.ASSESSMENT_STRIPE_0'),
                score: '1'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_1'),
                score: '2'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_2'),
                score: '3'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_3'),
                score: '4'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_4'),
                score: '5'
              }
            ]
          }
        },
        {
          type: 'delivery',
          title: this.$t(`message.ASSESSMENT_DELIVERY_TITLE`),
          answer: this.$t(`message.ASSESSMENT_DELIVERY_NOTE`),
          active: false,
          ratings: {
            values: [
              {
                label: this.$t('message.ASSESSMENT_STRIPE_0'),
                score: '1'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_1'),
                score: '2'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_2'),
                score: '3'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_3'),
                score: '4'
              }, {
                label: this.$t('message.ASSESSMENT_STRIPE_4'),
                score: '5'
              }
            ]
          }
        }
      ]
    }
  },

  validations: {
    form: {
      amability: {
        required
      },
      punctuality: {
        required
      },
      delivery: {
        required
      },
      observations: {}
    }
  },

  computed: {
    ...mapState(['assessment', 'user']),

    isValidated () {
      return this.assessment.sended === true
    },

    isLogged () {
      return this.user.isLoggedIn
    },

    providerInfo () {
      return this.provider
    },

    windowWidth () {
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    }
  },

  methods: {
    ...mapActions('modal', ['setModalDisplay', 'setModalComponent']),
    ...mapActions('assessment', ['setAssessmentState']),

    setVote (data) {
      this.form[data.type] = data.value
      this.isFailed = false

      const indexOf = this.questions.indexOf(this.getQuestionNode(data.type))
      const next = this.questions[indexOf + 1]
      if (next) {
        next.active = true
        setTimeout(() => VueScrollTo.scrollTo(`#${next.type}`, 600, { easing: 'ease-out' }), 1000)
      }
    },

    getQuestionNode (type) {
      return this.questions.find(obj => obj.type === type)
    },

    validateForm () {
      this.$v.form.$touch()
      const invalid = this.$v.form.$invalid

      if (!invalid) {
        this.isLoading = true

        this.getAccountInfo((eventObject) => this.sendRating(eventObject))
      } else {
        this.isFailed = true
      }
    },

    async checkLogged (evt) {
      if (evt.status === 'FAIL') {
        this.openModal()
      } else {
        const {
          UID,
          UIDSignature,
          signatureTimestamp
        } = evt

        const rating = await Assessment.getRating({
          UID: UID,
          UIDSignature: UIDSignature,
          signatureTimestamp: signatureTimestamp,
          orderId: this.getURLParam()
        })

        if (rating.data.code) {
          this.$store.dispatch(`assessment/${rating.data.code}`)
        } else {
          this.isVisible = true
          this.provider = rating.data.provider
        }
      }
    },

    async sendRating (evt) {
      const {
        UID,
        UIDSignature,
        signatureTimestamp
      } = evt

      const votes = await Assessment.setRating({
        UID: UID,
        UIDSignature: UIDSignature,
        signatureTimestamp: signatureTimestamp,
        orderId: this.getURLParam(),
        kindness: this.$v.form.amability.$model,
        punctuality: this.$v.form.punctuality.$model,
        delivery: this.$v.form.delivery.$model,
        observations: this.$v.form.observations.$model
      })

      if (votes.data.error) {
        const code = votes.data.error.code
        this.$store.dispatch(`assessment/${code}`)
      } else {
        this.setAssessmentState({
          amability: this.$v.form.amability.$model,
          punctuality: this.$v.form.punctuality.$model,
          delivery: this.$v.form.delivery.$model,
          comments: this.$v.form.observations.$model
        })
        this.$router.push({ name: 'service-assessment-sended' })
      }
    },

    openModal () {
      const payload = {
        state: true,
        name: 'UserSession',
        allowClose: true,
        size: 970,
        props: [
          {
            hasDestination: 'modal',
            isComponent: 'UserLogin'
          }
        ],
        callBack: 'checkUserLogged'
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    getURLParam () {
      const url = window.location.href
      const param = /id=([^&]+)/.exec(url)
      const result = param !== null ? param[1] : '000000000000000000'
      return result
    },

    getAccountInfo (callback) {
      GigyaService.getAccountInfo()
        .then(eventObject => callback(eventObject))
    }
  },

  mounted () {
    for (let node in this.assessment.steps) {
      this.$v.form[node].$touch()
    }

    this.getAccountInfo((eventObject) => this.checkLogged(eventObject))

    this.getURLParam()
    this.$root.$on('checkLogged', () => {
      this.getAccountInfo((eventObject) => this.checkLogged(eventObject))
    })
  }
}
</script>
<style lang="scss" scoped src="./SurveyForm.scss"></style>
