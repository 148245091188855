<template>
  <div :class="[ 'dropdown', showContent ? 'dropdown--open' : '' ]">
    <div :class="[
      'dropdown__title',
      isDisabled ? 'dropdown__title--disabled' : '',
      isAutonomous ? 'dropdown__title--clickable' : '',
      isSpaced ? 'dropdown__title--sbetween' : ''
      ]"
        @click="() => toggleContent()">
      <slot name="title"/>
    </div>
    <div class="dropdown__content" ref="content">
      <slot v-if="showContent" name="content"/>
      <slot v-else name="loader"/>
    </div>
  </div>
</template>

<script>
import anime from 'animejs'

export default {
  name: 'Dropdown',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isAutonomous: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isSpaced: {
      type: Boolean,
      default: false
    },
    reCalculateHeightProp: Boolean,
    idx: [String, Number]
  },

  data() {
    return {
      showContent: false,
      lastHeight: 0,
      internalOpenStatus: false
    }
  },

  methods: {
    animateHeight (lastHeight = 0) {
      const CONTENT_HEIGHT = this.lastHeight = this.getTotalHeight(this.$refs.content.childNodes)
      if (this.$refs.content) this.$refs.content.style.overflow = 'hidden'
      anime({
        targets: this.$refs.content,
        maxHeight: [`${lastHeight}px`, `${CONTENT_HEIGHT}px`],
        easing: 'easeInQuad',
        duration: 300,
      }).finished.then(() => {
        if (this.$refs.content) {
          this.$refs.content.style.overflow = 'inherit'
          this.$refs.content.style.maxHeight = 'none'
        }
      })
    },
    getTotalHeight (list) {
      let total = 0
      if (list) {
        list.forEach((el) => {
          total += el.getBoundingClientRect().height
        })
      }
      return total
    },
    closeAnimation (newHeight = 0) {
      const CONTENT_HEIGHT = this.getTotalHeight(this.$refs.content.childNodes)
      if (this.$refs.content) {
        this.$refs.content.style.overflow = 'hidden'
        this.$refs.content.style.maxHeight = `${CONTENT_HEIGHT}px`
      }
      anime({
        targets: this.$refs.content,
        maxHeight: `${newHeight}px`,
        easing: 'easeInQuad',
        duration: 300
      }).finished.then(() => {
        if (this.$refs.content) this.$refs.content.style.overflow = 'inherit'
        if (newHeight === 0) this.showContent = false
      })
    },
    reCalculateHeight () {
      if (this.isOpen) {
        const CONTENT_HEIGHT = this.$refs.content.childNodes
          ? this.getTotalHeight(this.$refs.content.childNodes)
          : this.$refs.content.offsetHeight
        if (CONTENT_HEIGHT >= this.lastHeight) {
          this.animateHeight(this.lastHeight)
        } else {
          this.closeAnimation(CONTENT_HEIGHT)
        }
      }
    },
    toggleContent () {
      if (this.isAutonomous && !this.isDisabled) {
        this.showContentHandler(!this.showContent, this.showContent)
        this.$emit('toggleDropdown', this.idx)
      }
    },
    showContentHandler (val, oldVal) {
      if (val !== oldVal) {
        if (val === true) {
          this.showContent = val
          this.$nextTick(() => {
            this.animateHeight()
          })
        } else if (oldVal !== undefined) {
          this.$nextTick(() => {
            this.closeAnimation()
          })
        }
      }
    }
  },

  watch: {
    isOpen: {
      handler: function (val, oldVal) {
        this.showContentHandler.bind(this)(val, oldVal)
      },
      immediate: true
    },
    reCalculateHeightProp: {
      handler: function(val, oldVal) {
        if (val !== oldVal) {
          this.$nextTick(() => {
            this.reCalculateHeight()
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped src="./Dropdown.scss"></style>
