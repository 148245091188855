import Vue from 'vue'
import * as moment from 'moment/moment'

const replaceValue = (el) => {
  let value = el.value
  el.value = value.replace(/[^\d]/g, '')

  return el.value
}

Vue.filter('formatDateComplete', function (date) {
  if (!date) return ''
  return moment(date).format('dddd D MMMM')
})

Vue.filter('formatDate', function (date) {
  if (!date) return ''
  return moment(date).format('dddd D')
})

Vue.filter('formatDay', function (date) {
  if (!date) return ''
  return moment(date).format('D')
})

Vue.filter('formatMonth', function (date) {
  if (!date) return ''
  return moment(date).format('MMMM')
})

Vue.filter('formatYear', function (date) {
  if (!date) return ''
  return moment(date).format('YYYY')
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  return value.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
})

Vue.filter('completeCP', function (params) {
  if (!params.value) return ''
  while (params.value.length < (params.size || 2)) { params.value = '0' + params.value }
  return params.value
})

Vue.directive('numericOnly', {
  bind (el, binding/* , vnode */) {
    const value = typeof (binding.value) !== 'undefined' ? binding.value : false

    if (value) {
      el.addEventListener('focus', () => replaceValue(el), false)
      el.addEventListener('input', () => replaceValue(el), false)
      el.addEventListener('blur', () => replaceValue(el), false)
    }
  }
})
