<template>
    <aside class="cognitive-info">
      <ul>
        <li
          v-if="this.$slots['icon']"
          class="cognitive-info__icon">
          <slot name="icon" />
        </li>
        <li
          v-if="this.$slots['highlight']"
          class="cognitive-info__highlight"
          v-html="getContent('highlight')">
        </li>
        <li
          v-if="this.$slots['message']"
          class="cognitive-info__message">
          <p v-html="getContent('message')"></p>
        </li>
      </ul>
    </aside>
</template>

<script>
export default {
  name: 'CognitiveList',

  methods: {
    getContent (name) {
      return this.$slots[name][0]['text']
    }
  }
}
</script>

<style lang="scss" scoped src="./CognitiveInfo.scss"></style>
