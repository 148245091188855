import { http } from './index'
import axios from 'axios'
import store from '../../store'

/**
 * This cancel token lets you cancel request on demand
 * @type {CancelTokenStatic|*}
 */
const CancelToken = axios.CancelToken
let cancelations = []
const getKeys = '/mapKeys'
let subscriptionIndex = 0

class Autocomplete {
  static getKeys = async payload => {
    try {
      const res = await http.get(getKeys)
      if (!('code' in res.data)) store.dispatch(`atlas/setKeys`, res.data.keys)
    } catch (error) {
      return error
    }
  }

  static getResults = async () => {
    try {
      const res = await http.get(
        `https://atlas.microsoft.com/search/address/json?typeahead=true&limit=20&subscription-key=${store.state.atlas.keys[subscriptionIndex]}&api-version=1.0&query=${store.state.atlas.query}`,
        {
          params: {
            countrySet: 'ES',
            language: 'es-ES',
            extendedPostalCodesFor: 'PAD'
          }
        },
        {
          cancelToken: new CancelToken(function executor (c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      const keysSize = store.state.atlas.keys.length
      subscriptionIndex = subscriptionIndex + 1

      if (subscriptionIndex <= keysSize) {
        return Autocomplete.getResults()
      } else {
        return error
      }
    }
  }
}

export { Autocomplete }
