<template>
  <BreadcrumbComponent :steps="steps"></BreadcrumbComponent>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'BreadcrumbContainer',

  computed: {
    ...mapState(['orders', 'user']),

    breadcrumbRoutes () {
      return ['summary', 'personal-data', 'recap', 'completed']
    },

    steps () {
      const currentRouteIndex = this.breadcrumbRoutes.indexOf(this.$route.name)

      return this.breadcrumbRoutes.map((route, index) => ({
        label: this.$t(`message.BREADCRUMB_LABEL_${route.toUpperCase()}`),
        active: index <= currentRouteIndex,
        closed: this.isRouteClosed(route, index, currentRouteIndex)
      }))
    }
  },

  methods: {
    isRouteClosed (routeName, routeIndex, currentRouteIndex) {
      if (routeName === 'personal-data') {
        return this.user.isLoggedIn
      }
      if (routeName === 'completed') {
        return routeIndex === currentRouteIndex
      }
      return routeIndex < currentRouteIndex
    }
  }
}
</script>
