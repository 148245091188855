<template>
  <section class="contact-info">
  <header
    v-if="this.$slots['title']"
    class="contact-info__header">
    <h2 class="contact-info__header__title">
      <slot name="title" />
    </h2>
  </header>
  <article
    v-if="this.$slots['description']"
    class="contact-info__description">
    <slot name="description" />
  </article>
  <ul class="contact-info__mode">
    <li v-if="this.$slots['action']">
      <slot name="action" />
    </li>
    <li v-if="this.$slots['phone']">
      <div class="contact-info__mode-cell contact-info__mode-phone">
        <slot name="phone" />
      </div>
    </li>
    <li v-if="this.$slots['mail']">
      <div class="contact-info__mode-cell contact-info__mode-mail">
        <slot name="mail" />
      </div>
    </li>
  </ul>
  </section>
</template>
<script>
export default {
  name: 'ContactInfo'
}
</script>
<style lang="scss" scoped src="./ContactInfo.scss"></style>
