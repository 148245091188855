import { render, staticRenderFns } from "./ErrorMessage.vue?vue&type=template&id=3a47f64f&scoped=true"
import script from "./ErrorMessage.vue?vue&type=script&lang=js"
export * from "./ErrorMessage.vue?vue&type=script&lang=js"
import style0 from "./ErrorMessage.scss?vue&type=style&index=0&id=3a47f64f&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a47f64f",
  null
  
)

export default component.exports