<template>
  <article
    class="rating-question">
    <div class="rating-question__box">
      <div class="rating-question__info">
        <h3 v-if="ratingTitle" class="rating-question__title">
          {{ ratingTitle }}
        </h3>
        <p v-if="ratingQuestion" class="rating-question__question">
          {{ ratingQuestion }}
        </p>
      </div>
      <StarsSet
        class="rating-question__votes"
        v-on:vote="setVote"
        :valorations="ratingValorations"
        :fieldsetName="ratingTitle"/>
    </div>
  </article>
</template>

<script>
import StarsSet from '../stars-set/StarsSet'

export default {
  name: 'RatingQuestion',
  components: {
    StarsSet
  },
  data () {
    return {
    }
  },
  computed: {
    getWindowSize () {
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    }
  },
  props: {
    ratingTitle: {
      type: String,
      default: 'Servicio'
    },
    ratingQuestion: {
      type: String,
      default: '¿La persona que ha realizado la entrega ha tenido un trato amable y atento contigo?'
    },
    ratingValorations: {
      type: Array
    }
  },

  methods: {
    setVote (value) {
      this.$emit('touch', value)
    }
  }
}
</script>
<style lang="scss" scoped src="./RatingQuestion.scss"></style>
