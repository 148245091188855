<template>
  <div class="hero"
       :style="[typeof backgroundImg !== 'undefined' ? { backgroundImage: `url(${backgroundImg})` } : null]">
    <div class="hero__main">
      <h1 class="hero__title">{{ title }}</h1>
      <p class="mb-xxs hero__txt">{{ text }}</p>
      <div v-if="promotion" class="mb-xxs hero__promotion">
        <p class="hero__promo-text">{{ promotion.text }}</p>
        <a :href="promotion.urlLink" target="_blank" class="hero__link">{{promotion.textLink}}</a>
      </div>
      <template v-if="swipperBullets.length">
        <Swiper :bullets="swipperBullets" :class="swiperWidthClass"/>
      </template>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
import Swiper from '../swiper/Swiper'

export default {
  name: 'Hero',
  components: {
    Swiper
  },
  props: {
    swipperBullets: {
      type: Array,
      default: () => []
    },
    backgroundImg: String,
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    promotion: {
      type: Object,
      default: () => {}
    },
    swiperWidthClass: {
      type: String,
      default: 'swiper--full-width'
    }
  }
}
</script>

<style lang="scss" scoped src="./Hero.scss"></style>
