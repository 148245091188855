<template>
  <footer class="footer">
    <template v-if="!isMinimized">
      <div class="footer__wrap">
        <nav class="footer__navigation">
          <ul class="footer__nodes">
            <li>
              <ul class="footer__main">
                <li
                  v-for="(title, i) in footerTitles"
                  v-bind:key="i"
                >
                  <router-link
                    v-if="title.interlink"
                    :to="{ name: title.interlink }"
                    :rel="title.rel"
                    class="footer__link link--simple link--simple--isLight">
                    {{ title.name }}
                  </router-link>
                  <a
                    v-else
                    :href="title.link"
                    :rel="title.rel"
                    class="footer__link link--simple link--simple--isLight" target="_blank">
                    {{ title.name }}
                  </a>

                </li>
              </ul>
            </li>
            <li>
              <ul class="footer__inline-list">
                <li
                  v-for="socialLink in socialLinks"
                  v-bind:key="socialLink.label"
                >
                  <a :href="socialLink.url" :title="socialLink.label" class="footer__icon" target="_blank">
                    <Icon :name="socialLink.icon" size="xsmall" />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <!-- external links -->
      <div class="footer__wrap">
        <nav class="footer__navigation">
          <ul class="footer__external">
            <li
              v-for="(cln, index) in outerLinks"
              :key="index"

            >
              <h3 class="footer__external-title">{{cln.title}}</h3>
              <ul class="footer__external__links-list">
                <li
                  v-for="(link, index) in cln.links"
                  :key="index"
                >
                  <a
                    class="link--simple link--simple--isLight"
                    :href="link.url"
                    target="_blank"
                  >{{link.label}}</a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </template>
    <div class="footer__copyright">{{ copyRightLabel }}</div>
  </footer>
</template>

<script>
import Icon from '../icons/Icon.vue'

export default {
  name: 'MainFooter',
  props: {
    footerTitles: {
      type: Array,
      default: () => [
        { name: 'Nota legal', link: '#' },
        { name: 'Política de privacidad', link: '#' },
        { name: 'Política de cookies', link: '#' },
        { name: 'Contacto', link: '#' }
      ]
    },
    socialLinks: {
      type: Array,
      default: () => [
        { label: 'facebook', url: '#', icon: 'IconFacebook' },
        { label: 'twitter', url: '#', icon: 'IconTwitter' },
        { label: 'instagram', url: '#', icon: 'IconInstagram' },
        { label: 'linkedin', url: '#', icon: 'IconLinkedin' },
        { label: 'youtube', url: '#', icon: 'IconYoutube' },
        { label: 'flickr', url: '#', icon: 'IconFlickr' }
      ]
    },
    outerLinks: {
      type: Array,
      default: () => [
        {
          title: 'Más sobre gasóleo',
          links: [
            { label: 'Repsol Hogar', url: 'https://www.repsol.es/es/productos-y-servicios/gasoleos-y-fueloleos/gasoleo-calefaccion/repsol-hogar/index.cshtml' },
            { label: 'Gasóleo calefacción', url: 'https://www.repsol.es/es/productos-y-servicios/gasoleos-y-fueloleos/gasoleo-calefaccion/index.cshtml' },
            { label: 'Gasóleo calefacción Energy e+', url: 'https://www.repsol.es/es/productos-y-servicios/gasoleos-y-fueloleos/gasoleo-calefaccion/energy-eplus/index.cshtml' },
            { label: 'Gasóleo calefacción BiEnergy 10', url: 'https://www.repsol.es/es/productos-y-servicios/gasoleos-y-fueloleos/gasoleo-calefaccion/nuevo-gasoleo-calefaccion-bienergye10/index.cshtml' }
          ]
        },
        {
          title: 'Otros productos',
          links: [
            { label: 'Productos y servicios Repsol', url: 'https://www.repsol.es/es/productos-y-servicios/index.cshtml' },
            { label: 'Gasóleos y fuelóleos', url: 'https://www.repsol.es/es/productos-y-servicios/gasoleos-y-fueloleos/index.cshtml' },
            { label: 'Luz y gas natural', url: 'https://www.repsol.es/es/productos-y-servicios/luz-gas-natural/index.cshtml' },
            { label: 'Gas butano y propano', url: 'https://www.repsol.es/es/productos-y-servicios/gas/index.cshtml' }
          ]
        },
        {
          title: 'Más sobre Repsol',
          links: [
            { label: 'Más sobre Repsol', url: 'https://www.repsol.es/es/index.cshtml' },
            { label: 'Qué hacemos', url: 'https://www.repsol.es/es/conocenos/que-hacemos/index.cshtml' },
            { label: 'Sostenibilidad', url: 'https://www.repsol.es/es/sostenibilidad/index.cshtml' },
            { label: 'Sala de prensa', url: 'https://www.repsol.es/es/sala-prensa/index.cshtml' }
          ]
        }
      ]
    },
    copyRightLabel: {
      type: String,
      default: '© Repsol 2000 - 2018'
    },
    isMinimized: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Icon
  }
}
</script>

<style lang="scss" scoped src="./MainFooter.scss"></style>
