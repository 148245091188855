<template>
  <button type="button" class="link-button">
    <span class="link-button__content">
      <slot>
        Características
      </slot>
    </span>
  </button>
</template>

<script>

export default {
  name: 'LinkButton'
}
</script>
<style lang="scss" scoped src="./LinkButton.scss"></style>
