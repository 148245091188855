import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.VUE_APP_APP_INSIGHTS_INSTRUMENTATION_KEY
  }
})

appInsights.loadAppInsights()
appInsights.trackPageView()

export default appInsights
