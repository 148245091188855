<template>
  <article class="product-schedule">
    <div
      :class="[
        `product-schedule__logo--${typology}`,
        'product-schedule__logo'
      ]
      ">
        <div
          v-if="this.$slots['logo']"
          class="product-schedule__logo__img">
            <slot name="logo" />
        </div>
    </div>
    <div class="product-schedule__content">
        <h1
          v-if="this.$slots['title']"
          class="product-schedule__title">
          <slot name="title" />
        </h1>
        <h2
          v-if="this.$slots['claim']"
          class="product-schedule__subtitle">
          <slot name="claim" />
        </h2>
        <div
          v-if="this.$slots['body']"
          class="product-schedule__body">
          <slot name="body" />
        </div>
        <template
          v-if="this.$slots['link']"
        >
          <slot name="link" />
        </template>
    </div>
  </article>
</template>
<script>
export default {
  name: 'ProductSchedule',

  props: {
    typology: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped src="./ProductSchedule.scss"></style>
