import { Errors } from '../../errors'
import router from '../../router/index'

const serviceError = () => {
  router.push({ name: 'server-error' })
}

export const assessment = {
  namespaced: true,

  state: {
    steps: {
      amability: '',
      punctuality: '',
      delivery: ''
    },
    comments: '',
    sent: false
  },

  mutations: {
    setAssessmentState: (state, payload) => {
      state.steps.amability = payload.amability
      state.steps.punctuality = payload.punctuality
      state.steps.delivery = payload.delivery
      state.comments = payload.comments
      state.sent = true
    }
  },

  actions: {
    setAssessmentState: ({ commit }, payload) => {
      commit('setAssessmentState', payload)
    },

    [Errors.SERVER_ERROR]: () => {
      serviceError()
    },

    [Errors.INVALID_USER_SIGNATURE]: () => {
      router.push({ name: 'server-error', query: { code: Errors.INVALID_USER_SIGNATURE } })
    },

    [Errors.INVALID_RATING_INPUT]: () => {
      router.push({ name: 'server-error', query: { code: Errors.INVALID_RATING_INPUT } })
    },

    [Errors.INVALID_ORDER_OWNER]: () => {
      router.push({ name: 'service-assessment-rejected' })
    },

    [Errors.ALREADY_RATED_ORDER]: () => {
      router.push({ name: 'service-assessment-already-rated' })
    }
  }
}
