<template>
  <article
    :class="[
      this.$slots['recommended'] ? 'result-detail--isRecommended' : null,
      'result-detail']">
    <button
      v-if="this.$slots['recommended']"
      class="result-detail__card--isRecommended"
      @click="showRecommendedInfo"
      data-cy="recommendedOffer">
      <slot name="recommended" />
    </button>
    <div
      class="result-detail__logo"
      :data-type="hasType"
      @click="showInfo(hasType)">
      <figure>
        <img :src="require(`images/png/logos/${hasType}.png`)" :alt="hasType">
      </figure>
    </div>
    <div class="result-detail__info">
      <ul class="result-detail__area">
        <li>
          <ul class="result-detail__prices">
            <li class="result-detail__unit_price">
              <span class="result-detail__liters-by-euros"><slot name="euros" /></span>
              <slot name="unitPrice" />
            </li>
            <li class="result-detail__total_price" v-html="totalPrice"></li>
          </ul>
        </li>
        <li
          v-if="this.$slots['action']"
          class="result-detail__submit">
          <slot name="action" />
        </li>
      </ul>
    </div>
  </article>
</template>
<script>
export default {
  name: 'ResultDetail',

  props: {
    hasType: {
      type: String,
      default: ''
    },

    totalPrice: {
      type: String,
      default: ''
    }
  },

  methods: {
    showInfo (type) {
      this.$emit('showInfo', type)
    },

    showRecommendedInfo () {
      this.$emit('showRecommendedInfo')
    }
  }
}
</script>
<style lang="scss" scoped src="./ResultDetail.scss"></style>
