<template>
 <div class="user-data">
    <h4
     v-if="title"
     class="user-data__title"
    >
     {{title}}
    </h4>
    <ul class="user-data__table">
      <slot
        v-for="(index, row) in $slots"
        :name="row" />
    </ul>
  </div>
</template>
<script>
export default {
  name: 'UserData',
  props: {
    title: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped src="./UserData.scss"></style>
