<template>
  <section class="order-completed hello">
    <div class="order-completed__recap">
      <h3 class="order-completed__ID">{{$t(`message.RESUME_STORE_ID`)}}<span>{{orderID}}</span></h3>
      <!-- START: USER DECALRATION -->
      <OrderDeclaration>
        <template #title>{{$t(`message.ORDER_DOWNLOAD_TITLE`)}}</template>
        <template #alert>
          <AlertsComponent>
            <template #icon><IconComponent name="IconAlerts" size="xsmall" /></template>
            <template #text>{{isDistribuidorRepsolDirectoAlert}}</template>
          </AlertsComponent>
        </template>
        <template #info>
        {{isDistribuidorRepsolDirectoModule}}
        </template>
        <template #links>
          <LinkButton
            @click.native="displayInfo([
                { 'name': 'title', 'content': `<h2 class='modal-basic__title'>${$t(`message.ORDER_DOWNLOAD_MODAL_TITLE_1`)}</h2>` },
                { 'name': 'description', 'content': `<div class='modal-basic__text'>${$t(`message.ORDER_DOWNLOAD_MODAL_DESC_1`)}</div>` },
                { 'name': 'title2', 'content': `<h2 class='modal-basic__title'>${$t(`message.ORDER_DOWNLOAD_MODAL_TITLE_2`)}</h2>` },
                { 'name': 'description2', 'content': `<div class='modal-basic__text'>${$t(`message.ORDER_DOWNLOAD_MODAL_DESC_2`, {directo: isDistribuidorRepsolDirectoModal})}</div>` }
            ])">
            <template>
              <a class="link--simple">{{$t(`message.ORDER_DOWNLOAD_MODAL_LINK_MODULE`)}}</a>
            </template>
          </LinkButton>
        </template>
        <template #action>
          <ButtonComponent
            :label="$t(`message.ORDER_DOWNLOAD_MODULE`)"
            v-on:click.native="download()"
          ></ButtonComponent>
        </template>
      </OrderDeclaration>
      <!-- END: USER DECALRATION -->

      <!-- START: USER MGM PROMOTION -->

      <!-- END: USER MGM PROMOTION -->

      <!-- START: PROVIDER INFO -->
      <ProviderData
        :hasPhone="$t(`message.ASSESSMENT_PROVIDER_INFO`, {phone: orders.order.provider.phone})"
        :hasMail="$t(`message.ASSESSMENT_PROVIDER_MAIL`, {mail: orders.order.provider.email})">
        <template #title>{{$t(`message.ASSESSMENT_PROVIDER_TITLE`)}}</template>
        <template #image><img :src="require(`images/png/assessment_trader.png`)" alt="trader"></template>
        <template #name>{{orders.order.provider.name}}</template>
      </ProviderData>
      <OrderPricesInfo :totalPrice="formatPrice">
        <template #title>{{$t(`message.RESUME_TITLE_AMOUNTINFO`)}}</template>
        <template #labelType>{{$t(`message.RESUME_STORE_TYPE`)}}</template>
        <template #imageType v-if="orders.order.amountInfo.type"><img :src="require(`images/png/logos/${orders.order.amountInfo.type}.png`)" :alt="orders.order.amountInfo.type"></template>
        <template #labelQuantity>{{$t(`message.RESUME_STORE_QUANTITY`)}}</template>
        <template #amountQuantity>{{$t(`message.RESUME_STORE_QUANTITY_TYPE`, {ltr: orders.order.amountInfo.quantity})}}</template>
        <template #labelTotalPrice>{{$t(`message.RESUME_STORE_TOTALPRICE`)}}</template>
        <template #amountUnitPrice>{{$t(`message.RESUME_STORE_UNITPRICE`, {chr: unitPrice})}}</template>
        <template #labelIva>{{$t(`message.RESULT_IVA_LABEL`)}}</template>
        <template
          #promotionalCode
          v-if="voucherUse">
          <PromotionMessageValidation
            :showInfo = "promoLegalBasisUrl"
            @displayInfo = "showPromoInfo"
            valid >
            <template #message>{{$t(`message.PROMOTIONAL_CODE_VALID_NO_CODE`)}} {{voucherDescription}}</template>
          </PromotionMessageValidation>
        </template>
      </OrderPricesInfo>
      <!-- END: PRICES INFO + PROMOTIONAL CODE VALIDATION -->

      <!-- START: CUSTOMER INFO -->
      <UserData :title="$t(`message.RESUME_USER_TABLE_RECAP`)">
        <template
          v-for="(index, row) in userOrder"
          :slot="row">
          <li
            :key="row"
            v-if="index.content">
            <span class="user-data__cell user-data__label">{{index.label}}</span>
            <span class="user-data__cell">{{index.content}}</span>
            <PaymentCard
              v-if="index.type"
              :cards="index.type.mode !== 'CASH' && index.type.mode !== 'WAYLET' ? ['visa', 'master'] : []">
              <template
                v-if="index.type.mode !== 'CASH' && index.type.mode !== 'WAYLET'"
                #text>{{$t(`message.ORDER_SUMMARY_PAYMENT_AVAILABLE_CREDIT_CARD`)}}</template>
              <template
                v-if="index.type.mode !== 'CASH' && index.type.mode !== 'WAYLET'"
                #extra>
                <CognitiveMessage
                  :message="$t(`message.COGNITIVE_PAYMENT_MESSAGE`)"
                  display="secondary"
                />
              </template>
            </PaymentCard>
          </li>
        </template>
      </UserData>
      <!-- END: CUSTOMER INFO -->

      <ButtonComponent
        @click.native="goToStart"
        :label="$t(`message.BUTTON_BACK`)"
      ></ButtonComponent>
    </div>
    <!-- START: CROSS SELLING BANNER -->
    <div
      :class="[
        'banner__list',
        banners.length > 1 ? 'banner__list--hasMultiple' : null
      ]">
      <Banner
        v-for="banner in banners"
        :key="banner.title"
        :background="banner.background"
        :title="banner.title"
        :description="banner.description"
        :isDark="banner.isDark"
        :hasButton="banner.hasButton"
        :action="banner.action"
        @bannerClick="banner.action.emit"/>
    </div>
    <!-- END: CROSS_SELLING BANNER -->
  </section>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { GigyaService } from '../../services/gigya'
import { Constants } from '../../constants'
export default {
  name: 'OrderCompleted',
  data () {
    return {
      recap: {},
      ID: '',
      banners: [
        {
          isDark: true,
          background: 'banner1_590x260_2020-03-20.jpg',
          title: this.$t(`message.CROSS_SELLING_BANNER_TITLE_1`),
          description: this.$t(`message.CROSS_SELLING_BANNER_DESCRIPTION_1`),
          action: {
            isButton: true,
            label: this.$t(`message.CROSS_SELLING_BANNER_ACTION_LABEL_1`),
            emit: () => this.crossSellingExternalPage('https://www.repsol.es/es/productos-y-servicios/luz-gas-natural/index.cshtml')
          }
        }
      ]
    }
  },

  computed: {
    ...mapState(['orders']),

    unitPrice () {
      return parseFloat(this.orders.order.amountInfo.unitPrice).toLocaleString()
    },

    isDistribuidorRepsolDirecto() {
      return this.orders.order.provider.name.replace(/\s/g, '').toLowerCase().includes(Constants.REPSOL_DIRECTO)
    },

    isDistribuidorRepsolDirectoAlert() {
      return this.isDistribuidorRepsolDirecto
        ? this.$t(`message.ORDER_DOWNLOAD_ALERT_REPSOLDIRECTO`)
        : this.$t(`message.ORDER_DOWNLOAD_ALERT`)
    },

    isDistribuidorRepsolDirectoModule() {
      let descModule = this.$t(`message.ORDER_DOWNLOAD_DESCRIPTION_MODULE`)
      let descModule2 = this.isDistribuidorRepsolDirecto
        ? this.$t(`message.ORDER_DOWNLOAD_DESCRIPTION_MODULE_REPSOLDIRECTO`)
        : this.$t(`message.ORDER_DOWNLOAD_DESCRIPTION_MODULE`)

      return `${descModule} ${descModule2}`
    },

    isDistribuidorRepsolDirectoModal() {
      return this.isDistribuidorRepsolDirecto ? this.$t(`message.ORDER_DOWNLOAD_MODAL_DESC_2_APPEND_RD`) : ''
    },

    userOrder () {
      return this.recap
    },

    orderID () {
      return this.ID
    },

    voucherValidation () {
      return this.orders.order.voucher.isValid
    },

    voucherDescription () {
      return this.orders.order.voucher.description
    },

    voucherUse () {
      return this.orders.order.voucher.canUseIt
    },

    totalPrice () {
      return this.orders.order.amountInfo.totalPrice
    },

    promoLegalBasisUrl () {
      return this.orders.order.voucher.legalBasisUrl !== '' ? this.orders.order.voucher.legalBasisUrl : undefined
    },

    formatPrice() {
      const total = typeof this.totalPrice === 'string' ? this.totalPrice : this.totalPrice.toFixed(2)
      const res = total.split('.')
      const integer = parseFloat(res[0]).toLocaleString()
      const decimal = res[1]
      return `${integer}<span>,${decimal}€</span>`
    }
  },

  methods: {
    ...mapActions('orders', ['clearOrder']),
    ...mapActions('modal', ['setModalDisplay', 'setModalComponent']),
    ...mapActions('analytics', ['pushTransaction']),

    download () {
      if (this.orders.order.amountInfo.type === Constants.PRODUCT_TYPE.AGRO_DIESEL) {
        window.open(`${process.env.BASE_URL}download/MODELO_DE_DCF_AGRO.pdf`)
      } else {
        window.open(`${process.env.BASE_URL}download/DECLARACION_CONSUMIDOR_FINAL.XLSM`)
      }
    },

    goToStart () {
      this.clearOrder()
      this.$router.push({ name: 'home' })
    },

    setRecapData (user) {
      this.ID = this.orders.order.ID
      this.recap = [
        {
          label: this.$t(`message.RESUME_STORE_NAME`),
          content: user.profile.firstName
        },
        {
          label: this.$t(`message.RESUME_STORE_LASTNAME`),
          content: user.profile.lastName
        },
        {
          label: this.$t(`message.RESUME_STORE_EMAIL`),
          content: user.profile.email
        },
        {
          label: this.$t(`message.RESUME_STORE_USER_DNI`),
          content: user.data.DocID.Pers
        },
        {
          label: this.$t(`message.RESUME_STORE_USER_PHONE`),
          content: user.profile.phones.default
        },
        {
          label: this.$t(`message.RESUME_STORE_DATE`),
          content: this.orders.order.timeInfo.date
        },
        {
          label: this.$t(`message.RESUME_STORE_TIME`),
          content: this.orders.order.timeInfo.time === 'MORNING' ? this.$t('message.ORDER_SUMMARY_DELIVERY_MORNING') : this.$t('message.ORDER_SUMMARY_DELIVERY_AFTERNOON')
        },
        {
          label: this.$t(`message.RESUME_STORE_ADDRESS`),
          content: this.orders.order.addressInfo.address
        },
        {
          label: this.$t(`message.RESUME_STORE_POSTALCODE`),
          content: this.orders.order.addressInfo.postalCode.value
        },
        {
          label: this.$t(`message.RESUME_STORE_EXTRAADDRESS`),
          content: this.orders.order.addressInfo.extraAddress
        },
        {
          label: this.$t(`message.RESUME_STORE_ADDITIONALINFO`),
          content: this.orders.order.additionalInfo.info
        },
        {
          label: this.$t(`message.RESUME_STORE_PAYMENTCHOISE`),
          content: this.orders.order.paymentInfo.payment === 'CASH' ? this.$t('message.ORDER_SUMMARY_PAYMENT_CASH') : this.orders.order.paymentInfo.payment === 'WAYLET' ? this.$t('message.ORDER_SUMMARY_PAYMENT_WAYLET') : this.$t('message.ORDER_SUMMARY_PAYMENT_CREDIT_CARD'),
          type: {
            mode: this.orders.order.paymentInfo.payment
          }
        },
        {
          label: this.$t(`message.RESUME_STORE_CARDNUMBER`),
          content: this.orders.order.card.number
        }
      ]

      this.pushTransaction({ orderId: this.orders.order.ID, providerName: this.orders.order.provider.name })
    },

    openBasicModal (values = { props: {}, slots: [] }) {
      const payload = {
        state: true,
        name: 'ModalBasic',
        allowClose: false,
        size: 490,
        props: values.props,
        slots: values.slots
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    displayInfo (slots) {
      const values = {
        slots: slots
      }
      this.openBasicModal(values)
    },

    crossSellingExternalPage (url) {
      window.open(url, '_blank')
    },

    showPromoInfo () {
      const values = {
        slots: [
          { 'name': 'descriptionLiters', 'content': `<div class='modal-basic__text'>${this.$t(`message.PROMO_LEGAL_BASIS`, { url: `${this.promoLegalBasisUrl}` })}</div>` }
        ]
      }
      this.openBasicModal(values)
    }
  },

  mounted () {
    GigyaService.getAccountInfo().then(this.setRecapData)
  }
}
</script>
<style lang="scss" scoped src="./OrderCompleted.scss"></style>
