import { Constants } from '../constants'

export default {
  // - LANGUAGE
  COUNTRY_ES: 'España',
  COUNTRY_LOCATION: 'es',
  COUNTRY_LINK: 'https://www.repsol.es',
  PROJECT_ES: 'Pide tu gasóleo',
  LANGUAGE: 'Idioma',
  // - UIKIT
  INPUT_PLACEHOLDER: 'Ingresar texto',
  INPUT_DISABLED_PLACEHOLDER: 'Lo siento, estoy deshabilitado',
  INPUT_VALIDATE_PLACEHOLDER: 'Introduce al menos 4 caracteres por favor',
  SELECT_PLACEHOLDER: 'Seleccione una opción',
  BUTTON_HOVER: 'Botón',
  BUTTON_CLICK: 'Hacer click',
  BUTTON_DISABLED: 'Deshabilitado',
  BUTTON_BACK: 'Volver al inicio',
  LINK_HOVER: 'Simple link',
  RADIO_LABEL: 'Soy un radiobutton',
  CHECKBOX_LABEL: 'Soy un checkbox',
  REQUIRED_LABEL: 'Campo requerido',
  MINIMUM_CHARACTERS_REQUIRED: 'Necesitamos los {chr} de tu código postal',
  LANDING_INPUT_ADDRESS_PLACEHOLDER: 'Calle Embajadores, 28 Madrid',
  LANDING_INPUT_POSTALCODE_PLACEHOLDER: '28036',
  LANDING_INPUT_LITERS_PLACEHOLDER: '500',
  SIGN_OFF: 'Cerrar sesión',
  UNREGISTER: 'Darme de baja',
  UNREGISTER_ALERT: 'Al darte de baja, la cuenta se cerrará. No podrás volver a acceder a tu perfil.',
  KEEP_PROFILE: 'Mantener mi cuenta',
  BUTTON_BACK_2: 'Ir al inicio',
  LANDING_INPUT_EXTRAADDRESS_PLACEHOLDER: 'Bloque 3, Urbanización Los Álamos, Local 3...',
  LANDING_INPUT_EXTRAINFO_PLACEHOLDER: 'Ubicación del depósito, altura del depósito,...',
  LANDING_INPUT_EUROS_PLACEHOLDER: `${Constants.MIN_ACCEPTED}`,

  // - COOKIES
  COOKIES_TEXT: `
    Usamos cookies propias y de terceros para optimizar su visita y analizar hábitos de navegación.
    Si cierra este mensaje o continúa navegando en esta web estará aceptando la`,
  COOKIES_POLICY_LINK: 'Política de cookies',
  COOKIES_POLICY_ACCEPT: 'Aceptar',

  // - NAVIGATION
  NAVIGATION_INDEX_LABEL: 'Inicio',
  NAVIGATION_FAQ_LABEL: 'Preguntas frecuentes',
  NAVIGATION_PRODUCTS_LABEL: 'Tipos de gasóleo',
  NAVIGATION_USERAREA_LABEL: 'Mi perfil',
  NAVIGATION_LOGOUT_LABEL: 'Cerrar sesión',
  NAVIGATION_BUSINESSSERVICES_LABEL: 'Conoce el servicio',

  // - ERRORS
  INPUT_TEST_ERROR: 'mínimo 4 caracteres requeridos',
  SELECT_TEST_ERROR: 'se requiere selección',
  PAGE_NOT_FOUND_ERROR_TITLE: 'Lo sentimos. No encontramos la página que buscas,',
  PAGE_NOT_FOUND_ERROR_MESSAGE: `
    ¿Necesitas ayuda? Llámanos al <i>900150151</i> o mándanos un correo electrónico
    a <a href="mailto:sacportal@repsol.com">sacportal@repsol.com</a>.
    Daremos respuesta a todas tus dudas de <i>08:00</i> a <i>22:00</i> de lunes a viernes y de <i>08:00</i> a <i>14:00</i> los sábados`,
  PROVIDER_ERROR_CONTACT: `
    Para cualquier consulta, llámanos al <i>900150151</i> o mándanos un correo electrónico
    a <a href="mailto:sacportal@repsol.com">sacportal@repsol.com</a>.`,
  UNAVAILABLE_ERROR_TITLE: 'No hay litros disponibles para los próximos 3 días',
  UNAVAILABLE_ERROR_MESSAGE:
    'Sentimos las molestias, pero no está disponible la cantidad de litros solicitada. Modifica la cantidad o inténtalo durante los próximos días.',
  UNAVAILABLE_ERROR_NOTICE_TITLE: '¿Quieres que te avisemos cuando haya litros disponibles en tu zona?',
  UNAVAILABLE_ERROR_NOTICE_MESSAGE: 'Déjanos tu email y te avisaremos cuando haya gasóleo disponible en tu zona.',
  UNAVAILABLE_ERROR_NOTICE_BUTTON: 'Recibir aviso',
  SERVER_ERROR_TITLE: 'Lo sentimos, se ha producido un error interno de la página',
  SERVER_ERROR_MESSAGE: `
    Si el error continúa, llámanos al <i>900150151</i> o mándanos un correo electrónico
    a <a href="mailto:sacportal@repsol.com">sacportal@repsol.com</a>.
    Daremos respuesta a todas tus dudas de <i>08:00</i> a <i>22:00</i>
    de lunes a viernes y de <i>08:00</i> a <i>14:00</i> los sábados.`,
  DIRECTION_NEED: 'Necesitamos la dirección de la vivienda',
  GENERIC_ERROR_CONTACT: `
    Para cualquier consulta, llámanos al <i>901101101</i> o mándanos un correo electrónico
    a <a href="mailto:sacrcpp@repsol.com">sacrcpp@repsol.com</a>.
    Daremos respuesta a todas tus dudas de <i>08:00</i> a <i>22:00</i>
    de lunes a viernes y de <i>08:00</i> a <i>14:00</i> los sábados.`,

  // - MODAL INFO
  MODAL_INFO_OPTIONS_BODY: `Los pedidos de gasoleo calefacción Bienergy e+10 y de gasóleo agrícola Agrodiesel e+10 tendrán el descuento de 20 céntimos/litro del Real Decreto 6/2022. Los pedidos de Energy e+ no se beneficiarán de dicho descuento`,
  MODAL_INFO_BUTTON_CLOSE: 'Cerrar',
  // - ORDERSº
  ORDER_SEARCH_TITLE_START: 'Pedir ahora',
  ORDER_SEARCH_TITLE_RESULT: 'Datos de tu pedido',
  ORDER_SEARCH_CTA: 'Buscar',
  ORDER_SEARCH_ADDRESS_LABEL: '¿A qué dirección te lo enviamos?',
  ORDER_SEARCH_POSTAL_CODE_LABEL: '¿Cuál es tu código postal?',
  ORDER_SEARCH_LITERS_LABEL: '¿Cuánto gasóleo necesitas?',
  ORDER_SEARCH_LITERS_EXTRA: 'Cómo indicar los litros',
  ORDER_SEARCH_LITERS_CHOOSED: 'litros',
  ORDER_SEARCH_EXTRA_FIELD_LABEL: '¿Quieres añadir más detalles a la dirección?',
  ORDER_SEARCH_CHANGE_CRITERIA_LABEL: 'Modificar búsqueda',
  ORDER_SEARCH_BUTTON_LABEL: 'Pedir gasóleo',
  ORDER_POSTALCODE_ERROR_REQUIRED: 'Necesitamos el código postal de la vivienda',
  ORDER_INVALID_POSTALCODE_ERROR: 'Por favor, revisa que el Código Postal es correcto',
  ORDER_LITERS_ERROR_REQUIRED: `Necesitamos saber el número de litros que quieres. Tienen que ser múltiplos de 10, un mínimo ${Constants.MIN_ACCEPTED} y un máximo ${Constants.MAX_ACCEPTED}. Si necesitas más de ${Constants.MAX_ACCEPTED} litros, llámanos al ${Constants.TELEFONO} y te daremos una atención personalizada`,
  ORDER_LITERS_ERROR_FORMAT: `Pedido mínimo: ${Constants.MIN_ACCEPTED}. Máximo: ${Constants.MAX_ACCEPTED}. La cantidad de litros tiene que ser múltiplo de 10. Si necesitas más de ${Constants.MAX_ACCEPTED} litros, llámanos al ${Constants.TELEFONO} y te daremos una atención personalizada`,
  ORDER_PAYOFF_TITLE: 'Pedido online de gasóleo calefacción y agrícola',
  ORDER_PAYOFF_SUBTITLE:
    'Ya activo en Asturias, Granada y Segovia. Estamos trabajando para ampliar las zonas de servicio.',
  ORDER_BUTTON_MESSAGE: 'Te mostramos el precio y disponibilidad de litros para los próximos 3 días hábiles',
  ORDER_COMPLETED_TITLE: 'Pedido realizado',
  ORDER_COMPLETED_PAYOFF:
    'Hemos enviado una copia de tu pedido al distribuidor para que te lo entregue en la fecha y hora elegida.',
  ORDER_ERROR_PRICECHANGED_TITLE: 'El precio del gasóleo calefacción ha cambiado',
  ORDER_ERROR_PRICECHANGED_TEXT:
    'Debido a la cotización, el precio del gasóleo calefacción ha cambiado. El importe actual de tu pedido es {extraInfo}€ (IVA incluido)',
  ORDER_ERROR_PRICECHANGED_BUTTON_ACCEPT: 'Continuar pedido',
  ORDER_ERROR_PRICECHANGED_BUTTON_REJECT: 'Cancelar pedido',
  ORDER_ERROR_OVERSIZE_TITLE: 'No hay litros suficientes para este pedido',
  ORDER_ERROR_OVERSIZE_TEXT:
    'La disponibilidad de gasóleo ha cambiado durante las últimas horas. Te recomendamos realizar un nuevo pedido y pedir menos cantidad de litros.',
  ORDER_ERROR_OVERSIZE_BUTTON_REJECT: 'Hacer pedido nuevo',
  ORDER_ERROR_UNPUBLISHED_TITLE: 'La oferta no exixte',
  ORDER_ERROR_UNPUBLISHED_TEXT: 'texto',
  ORDER_ERROR_UNPUBLISHED_BUTTON_ACCEPT: 'Recuperar datos de mi pedido',
  ORDER_ERROR_UNPUBLISHED_BUTTON_REJECT: 'Volver a inicio',
  ORDER_DOWNLOAD_TITLE: 'Declaración de Consumidor Final',
  ORDER_DOWNLOAD_ALERT:
    'Debes tener impreso y firmado este documento cuando llegue el distribuidor con tu pedido junto con una fotocopia de tu DNI.',
  ORDER_DOWNLOAD_ALERT_REPSOLDIRECTO:
    'Si es tu primer pedido, debes imprimir, rellenar y firmar este documento y enviarlo por email al Distribuidor antes de que se pueda realizar el suministro.',
  ORDER_DOWNLOAD_MODULE: 'Descargar documento',
  ORDER_DOWNLOAD_DESCRIPTION_MODULE:
    'Descarga, cumplimenta en el ordenador (excepto la firma), imprime, fírma y envíalo escaneado',
  ORDER_DOWNLOAD_DESCRIPTION_MODULE_REPSOL: 'Cuando recibas el pedido, entregáselo al distibuidor.',
  ORDER_DOWNLOAD_DESCRIPTION_MODULE_REPSOLDIRECTO:
    'El Distribuidor debe tenerla antes de poder realizar el suministro.',
  ORDER_DOWNLOAD_MODAL_LINK_MODULE: '¿Qué es la Declaración Consumidor Final?',
  ORDER_DOWNLOAD_MODAL_TITLE_1: '¿Qué es la Declaración Consumidor Final?',
  ORDER_DOWNLOAD_MODAL_DESC_1:
    'Es el documento con el que justificas que eres consumidor final. Con este documento puedes beneficiarte de las bonificaciones del gasóleo.',
  ORDER_DOWNLOAD_MODAL_TITLE_2: '¿Cuándo tengo que rellenarlo?',
  ORDER_DOWNLOAD_MODAL_DESC_2:
    'Tienes que rellenarlo y entregárselo una sola vez a tu distribuidor habitual {directo}. En caso de que cambies de distribuidor, tendrás que rellenarlo de nuevo y entregárselo.',
  ORDER_DOWNLOAD_MODAL_DESC_2_APPEND_RD: ', antes del primer suministro',
  ORDER_BULLET_1: 'Tú eliges. Te entregamos en el día y el horario que mejor te venga.',
  ORDER_BULLET_2: 'Sin llamadas ni horarios. Pídelo a cualquier hora y cualquier día de la semana.',
  ORDER_BULLET_3: 'Desde donde quieras. Solo necesitas tu móvil o tu ordenador para hacer el pedido.',
  ORDER_EXTRATEXT:
    'Si tienes algún requisito concreto para el acceso al domicilio o sobre el suministro, indícalo aquí. Te informamos que esto puede implicar algún coste adicional.',
  ORDER_DELIVERY_EXTRATEXT:
    'Las características del suministro (tipo de depósito, acceso ...) pueden causar alguna variación del precio',
  ORDER_SEARCH_EXTRAADDRESS_LABEL: 'Añadir detalles a la dirección',
  ORDER_SEARCH_PROMOTIONALCODE_LABEL: 'Código de descuento',
  ORDER_PROMOTIONAL_ERROR_INCORRECT: 'El código introducido no es válido',
  ORDER_SEARCH_PROMOTIONALCODE_MOBILE_LABEL: 'Código promocional',
  ORDER_SEARCH_AGAIN_BUTTON_LABEL: 'Repetir búsqueda',
  ORDER_EUROS_ERROR_REQUIRED:
    'Necesitamos que nos indiques el importe en euros de tu pedido para calcular los litros de gasóleo que te enviaremos.',
  ORDER_EUROS_ERROR_FORMAT: `Importe mínimo: ${Constants.MIN_ACCEPTED}€. La cantidad de euros tiene que ser múltiplo de 10`,
  ORDER_DROPDOWN_TYPE_LABEL_LITERS: 'litros',
  ORDER_DROPDOWN_TYPE_LABEL_EUROS: 'euros',

  MEMBER_GET_MEMBER_TITLE: '¡Consigue beneficios para ti y tus amigos!',
  MEMBER_GET_MEMBER_MESSAGE: `
    Comparte tu código con todos los amigos que quieras y, con la entrega del primer pedido que realicen, recibiréis un email con un cheque de descuento en carburante.
    <br/><br/>
    Podréis canjear este cheque al pagar el repostaje en estaciones de servicio Repsol, Campsa o Petronor.
    <br/><br/><br/>
    Tu código promocional:
  `,
  MEMBER_GET_MEMBER_ACTIONS_TITLE: '¡Compártelo!',

  // - RESULTS
  RESULT_UNIT_PRICE_LABEL: '{price} €/litro',
  RESULT_BUTTON_LABEL: 'Realizar pedido',
  RESULT_RECOMMENDED_LABEL: 'Mejor relación calidad precio',
  RESULT_UNAVAILABLE_LABEL: 'Lo sentimos, no hay disponibilidad de suministro para este día.',
  RESULT_LIST_LABEL_TITLE: 'Opciones de pedido para los próximos 3 días hábiles',
  RESULT_LIST_LABEL_DESCRIPTION:
    'El precio que te mostramos es el más reciente.  El precio y la disponibilidad del gasóleo están sujetos a cambios según el mercado',
  RESULT_IVA_LABEL: 'IVA incluido',
  RESULT_TOTAL_PRICE: '{totalPrice} <span class="iva_label">(IVA incluido)</span>',

  // - FOOTER
  FOOTER_LEGAL_NOTE: 'Nota legal',
  FOOTER_PRIVACY_POLICIES: 'Política de privacidad',
  FOOTER_PRIVACY_COOKIES: 'Política de cookies',
  FOOTER_CONTACT: 'Contacto',
  FOOTER_COPY: '© Repsol 2000 - 2021',
  FOOTER_EXTERNAL_CLM01_TITLE: 'Más sobre gasóleo',
  FOOTER_EXTERNAL_CLM01_LINK01_LABEL: 'Repsol Hogar',
  FOOTER_EXTERNAL_CLM01_LINK01_URL: '/es/productos-y-servicios/gasoleo/servicios/hogar/mantenimiento/index.cshtml',
  FOOTER_EXTERNAL_CLM01_LINK02_LABEL: 'Gasóleo calefacción',
  FOOTER_EXTERNAL_CLM01_LINK02_URL: '/es/productos-y-servicios/gasoleo/productos/gasoleo-calefaccion/index.cshtml',
  FOOTER_EXTERNAL_CLM01_LINK03_LABEL: 'Servicio de cambio de caldera',
  FOOTER_EXTERNAL_CLM01_LINK03_URL: '/es/productos-y-servicios/gasoleo/servicios/hogar/cambio-de-caldera/index.cshtml',
  FOOTER_EXTERNAL_CLM01_LINK04_LABEL: 'Servicio de financiación',
  FOOTER_EXTERNAL_CLM01_LINK04_URL: '/es/productos-y-servicios/gasoleo/servicios/hogar/financiacion/index.cshtml',

  FOOTER_EXTERNAL_CLM02_TITLE: 'Otros productos',
  FOOTER_EXTERNAL_CLM02_LINK01_LABEL: 'Productos y servicios Repsol',
  FOOTER_EXTERNAL_CLM02_LINK01_URL: '/es/productos-y-servicios/index.cshtml',
  FOOTER_EXTERNAL_CLM02_LINK02_LABEL: 'Gasóleos',
  FOOTER_EXTERNAL_CLM02_LINK02_URL: '/es/productos-y-servicios/gasoleo/index.cshtml',
  FOOTER_EXTERNAL_CLM02_LINK03_LABEL: 'Luz y gas natural',
  FOOTER_EXTERNAL_CLM02_LINK03_URL: '/es/productos-y-servicios/luz-gas-natural/index.cshtml',
  FOOTER_EXTERNAL_CLM02_LINK04_LABEL: 'Gas butano y propano',
  FOOTER_EXTERNAL_CLM02_LINK04_URL: '/es/productos-y-servicios/gas-butano-propano/index.cshtml',

  FOOTER_EXTERNAL_CLM03_TITLE: 'Más sobre Repsol',
  FOOTER_EXTERNAL_CLM03_LINK01_LABEL: 'Más sobre Repsol',
  FOOTER_EXTERNAL_CLM03_LINK01_URL: '/es/index.cshtml',
  FOOTER_EXTERNAL_CLM03_LINK02_LABEL: 'Qué hacemos',
  FOOTER_EXTERNAL_CLM03_LINK02_URL: '/es/conocenos/que-hacemos/index.cshtml',
  FOOTER_EXTERNAL_CLM03_LINK03_LABEL: 'Sostenibilidad',
  FOOTER_EXTERNAL_CLM03_LINK03_URL: '/es/sostenibilidad/index.cshtml',
  FOOTER_EXTERNAL_CLM03_LINK04_LABEL: 'Sala de prensa',
  FOOTER_EXTERNAL_CLM03_LINK04_URL: '/es/sala-prensa/index.cshtml',

  // - ORDER SUMMARY
  ORDER_SUMMARY_DELIVERY_TITLE: '¿Cuándo te lo entregamos?',
  ORDER_SUMMARY_DELIVERY_MORNING: 'Mañana (de 08:00 a 13:00)',
  ORDER_SUMMARY_DELIVERY_AFTERNOON: 'Tarde (de 13:00 a 19:00)',
  ORDER_SUMMARY_PAYMENT_TITLE: '¿Cómo quieres pagar?',
  ORDER_SUMMARY_PAYMENT_INFO: 'El distribuidor llevará preparado el método de cobro correspondiente.',
  ORDER_SUMMARY_PAYMENT_CASH: 'Efectivo',
  ORDER_SUMMARY_PAYMENT_CREDIT_CARD: 'Tarjeta (débito/crédito)',
  ORDER_SUMMARY_PAYMENT_WAYLET: 'Waylet',
  ORDER_SUMMARY_PAYMENT_AVAILABLE_CREDIT_CARD: 'Tarjetas admitidas:',
  ORDER_SUMMARY_REPSOL_PLUS_INFO1: 'Ventajas de pertenecer al club',
  ORDER_SUMMARY_REPSOL_PLUS_INFO2: `
    Disfruta de ofertas personalizadas con descuentos en carburante o en nuestras tiendas,
    consigue más Puntos Travel Club y accede a sorteos de miles de lavados gratis cada mes.`,
  ORDER_SUMMARY_REPSOL_PLUS_INFO3: 'Soy cliente de Repsol Más',

  // - BUTTONS & LINKS
  BUTTON_GO_ON: 'Continuar',
  BUTTON_CONTINUE_ORDER: 'Continuar pedido',
  BUTTON_GO_BACK: 'Volver',

  // - BREADCRUMB
  BREADCRUMB_LABEL_SUMMARY: 'Entrega y forma de pago',
  'BREADCRUMB_LABEL_PERSONAL-DATA': 'Datos personales',
  BREADCRUMB_LABEL_RECAP: 'Confirmación del pedido',
  BREADCRUMB_LABEL_COMPLETED: 'Pedido realizado',

  // - MODALS
  MODAL_ORDER_OPTIONS_TITLE: '¿Qué opciones de pedido tengo?',
  MODAL_ORDER_OPTIONS_TITLE2: '¿Qué opción me recomendáis?',
  MODAL_ORDER_OPTIONS_TEXT: `
    <p>Tienes disponibles varias opciones de pedido para los próximos 3 días hábiles. En estas opciones te mostramos el precio por litro y el importe total de los litros seleccionados.</p>
    <p>El precio del litro de gasóleo calefacción puede variar de un día a otro según cotización y disponibilidad en el mercado.</p>
  `,
  MODAL_PRODUCT_DESCONTO: `Los pedidos de gasóleo calefacción BiEnergy e+10 tendrán el descuento de 20 céntimos/litro del Real Decreto 6/2022. Los pedidos de Energy e+ no se beneficiarán de dicho descuento.`,
  MODAL_ORDER_OPTIONS_TEXT2: `
    <p>Nuestra opción recomendada para ti es BiEnergy e+10.<br>Te aconsejamos pedir este
    tipo de gasóleo porque conseguirás un mayor ahorro energético y cuidarás del medio
    ambiente con sus menores emisiones. Por ejemplo, en calderas de condensación este ahorro puede llegar hasta el 30%.</p>
    <p>Además, te indicamos el día con mejor precio para que puedas ahorrar más.</p>
  `,

  MODAL_PRODUCT_BIENERGY_TITLE: '¿Qué es Repsol BiEnergy e+10?',
  MODAL_PRODUCT_BIENERGY_TEXT: `
    <p>Es un gasóleo calefacción de última generación apto para todo tipo de calderas,
    tanto de viviendas unifamiliares como comunidades de vecinos. Ofrece mejoras en el
    rendimiento de caldera, ahorrando así dinero y cuidando el medio ambiente.</p>
    <ul>
      <li class="list__title">Repsol BiEnergy e+10 te proporciona:</li>
      <li>Mayor eficiencia energética. Hasta un 30% de ahorro en calderas de condensación.</li>
      <li>Cuidado del medio ambiente. Minimiza las emisiones de óxido de azufre
      y reduce las emisiones de óxido de nitrógeno.
      Por el menor consumo de producto, las emisiones de CO2 se reducen hasta un 30%.</li>
      <li>Garantía de calidad. Es un gasóleo recomendado por los principales fabricantes
      de calderas de condensación por su bajo contenido en azufre.
      Mantiene tu caldera como recién comprada más tiempo.</li>
    </ul>`,
  MODAL_PRODUCT_ENERGY_TITLE: '¿Qué es Repsol Energy e+?',
  MODAL_PRODUCT_ENERGY_TEXT: `
    <p>Es un gasóleo calefacción apto para calderas de viviendas unifamiliares y comunidades de vecinos.</p>
    <ul>
      <li class="list__title">La fórmula única y exclusiva Repsol Energy e+ te proporciona:</li>
      <li>Calidad. Protege los sistemas de alimentación e inyección.</li>
      <li>Estabilidad. Se mantiene almacenado y sin perder propiedades originales durante más tiempo que otros gasóleos, evitando problemas durante el periodo estival.
      </li>
      <li>Seguridad. Es uno de los combustibles más seguros del mercado.
      Posee un punto de inflamación situado por encima de los 60º C,
      lo que hace que sea muy difícil alcanzarlo. </li>
    </ul>`,
  MODAL_PRODUCT_GASOILCEXCELLENT_TITLE: '¿Qué es Gasoil C Excel·lent?',
  MODAL_PRODUCT_GASOILCEXCELLENT_TEXT: `
  <p>Está pensado para todos aquellos clientes que quieren ahorrar, tanto en el producto como en la conservación de la caldera.</p>
  <ul>
    <li>Sus altas prestaciones hacen que sea una excelente opción de ahorro de energía y mantenimiento de la caldera.</li>
    <li>Óptimo para uso doméstico y profesional.</li>
  </ul>
  <ul>
    <li class="list__title">El gasoil Excel·lent ha sido certificado con la Clasificación AAA por los laboratorios Chemipol, por sus características y prestaciones:</li>
    <li>A en protección y limpieza de la caldera: Gracias a los aditivos de última generación, inhibidores de la corrosión y antioxidantes, que ayudan a mantener tu caldera en óptimas condiciones.</li>
    <li>A en mejora de la combustión y disminución del consumo: El dispersante polimérico que contiene, evita la formación de lodos y mejora la estabilidad y la fluidez. Esto mejora la combustión y reduce el consumo.</li>
    <li>A en reducción de emisiones contaminantes.</li>
  </ul>`,
  MODAL_PRODUCT_GASOILBEXCELLENT_TITLE: '¿Qué es Gasoil B Excel·lent?',
  MODAL_PRODUCT_GASOILBEXCELLENT_TEXT: `
  <p>Está pensado para todos aquellos clientes que quieren ahorrar, tanto en el producto como en el mantenimiento de su maquinaria.</p>
  <ul>
    <li>Sus altas prestaciones hacen que sea una excelente opción de ahorro de energía y en los costes de mantenimiento reduciendo la obstrucción de los filtros.</li>
    <li>Óptimo para uso profesional.</li>
    </ul>
    <ul>
    <li class="list__title">El gasoil Excel·lent ha sido certificado con la Clasificación AAA por los laboratorios Chemipol, por sus características y prestaciones:</li>
    <li>A en protección y limpieza de la caldera: Gracias a los aditivos de última generación, inhibidores de la corrosión y antioxidantes, que ayudan a mantener tu caldera en óptimas condiciones.</li>
    <li>A en mejora de la combustión y disminución del consumo: El dispersante polimérico que contiene, evita la formación de lodos y mejora la estabilidad y la fluidez. Esto mejora la combustión y reduce el consumo.</li>
    <li>A en reducción de emisiones contaminantes.</li>
  </ul>`,
  MODAL_PRODUCT_AGRODIESEL_TITLE: '¿Qué es AgroDiésel e+ 10?',
  MODAL_PRODUCT_AGRODIESEL_TEXT: `
  <p>Alarga la vida útil de tu maquinaria y cuenta con la garantía y la seguridad de disfrutar de un gasóleo premium.</p>
  <p>El gasóleo agrícola de calidad que tu empresa agropecuaria necesita.</p>
  <ul>
    <li class="list__title">El <b>gasoil B mejorado</b> creado a partir de una <b>fórmula avanzada</b> que da como resultado un producto que:</li>
    <li><b>Alarga la vida útil de tu maquinaria</b> ya que protege el motor de la corrosión y conserva en condiciones adecuadas el sistema de alimentación e inyección del gasóleo agrícola.</li>
    <li><b>Minimiza los costes de mantenimiento</b> reduciendo la obstrucción de los filtros.</li>
    <li>Ofrece una <b>combustión idónea</b> conservando la potencia, reduciendo las emisiones y el consumo como el primer día. </li>
    <li><b>Respeta el medioambiente</b> a través de su fórmula de composición sin azufre.</li>
  </ul>
  <p><b>Innovación, rendimiento y fiabilidad</b> se dan la mano para ofrecerte el mejor gasóleo para tu explotación.</p>`,

  MODAL_USER_PROFILE_QUIT_TITLE: 'Darme de baja ',
  MODAL_USER_PROFILE_QUIT_HEADER: '¿Nos cuentas el motivo? Tu experiencia nos ayudará a mejorar.',

  // - FEATURES
  FEATURES_LIST_CHECK: 'Tú eliges.Te ofrecemos diferentes días y horarios de entrega.',
  FEATURES_LIST_CALENDAR: 'Sin llamadas ni horarios. Pídelo a cualquiera hora y cualquier día de la semana.',
  FEATURES_LIST_PHONE: 'Desde donde quieras. Solo necesitas tu móvil o tu ordenador para hacer el pedido.',

  // - PERSONAL DATA
  PERSONALDATA_LABEL_REGISTRATION: 'Registrarme',
  PERSONALDATA_LABEL_LOGIN: 'Iniciar Sesión',

  VERIFICATION_PENDING_TITLE: 'Confirma tu registro',
  VERIFICATION_PENDING_SUBTITLE: 'Verifica tu cuenta',

  // - LOGIN
  LOGIN_WELCOME: 'Hola, ',
  RESUME_TITLE_ADDRESSINFO: 'Dirección de entrega',
  RESUME_TITLE_TIMEINFO: 'Día y hora de entrega',
  RESUME_TITLE_PAYMENTINFO: 'Método de pago',
  RESUME_TITLE_AMOUNTINFO: 'Importe del pedido',
  RESUME_TITLE_ADDITIONALINFO: 'Datos adicionales de entrega',
  RESUME_STORE_NAME: 'Nombre',
  RESUME_STORE_LASTNAME: 'Apellido',
  RESUME_STORE_EMAIL: 'Correo electrónico',
  RESUME_STORE_DATE: 'Día de la entrega',
  RESUME_STORE_TIME: 'Franja horaria',
  RESUME_STORE_TIME_MORNING: 'Mañana (de 08:00 a 13:00)',
  RESUME_STORE_TIME_AFTERNOON: 'Tarde (de 13:00 a 19:00) ',
  RESUME_STORE_ADDRESS: 'Dirección',
  RESUME_STORE_POSTALCODE: 'Código postal',
  RESUME_STORE_ADDITIONALINFO: 'Detalles instalación',
  RESUME_STORE_EXTRAADDRESS: 'Detalles añadidos',
  RESUME_STORE_EXTRAINFO: 'Detalles añadidos',
  RESUME_STORE_PAYMENT: 'Método elegido',
  RESUME_STORE_PAYMENTCHOISE: 'Método de pago',
  RESUME_STORE_PAYMENT_CARD: 'Tarjeta (débito/crédito)',
  RESUME_STORE_PAYMENT_CASH: 'Efectivo',
  RESUME_STORE_PAYMENT_WAYLET: 'Waylet',
  RESUME_STORE_CARD_ADMITTED: 'Tarjetas admitidas:',
  RESUME_STORE_TYPE: 'Tipo de combustible',
  RESUME_STORE_QUANTITY: 'Número de litros',
  RESUME_STORE_QUANTITY_TYPE: '{ltr} Litros',
  RESUME_STORE_UNITPRICE: '{chr} €/litro',
  RESUME_STORE_TOTALPRICE: 'Precio total',
  RESUME_STORE_CARDNUMBER: 'Número de tarjeta',
  RESUME_TERMS: 'He leído y acepto las',
  RESUME_TERMS_LINK: 'condiciones del servicio',
  RESUME_BUTTON_LABEL: 'Finalizar pedido',
  RESUME_BACK_LABEL: 'Modificar entrega y pago',
  RESUME_DATE_ARTICLE: 'de',
  RESUME_ALERT:
    'Al finalizar ahora el pedido, aseguras el precio y disponibilidad del gasóleo (estos están sujetos a cambios según el mercado).',
  RESUME_USER_TABLE_RECAP: 'Datos de entrega y pago',
  RECAP_CONFIRMACION_PEDIDO:
    'El pago por el servicio se realiza después del repostaje, no te cobraremos ninguna cantidad hasta que lo hayas recibido',
  RECAP_CONFIRMACION_DESCONTO: '',

  RESUME_STORE_ID: 'Tu número de pedido es el',
  RESUME_STORE_USER_DNI: 'DNI/NIE',
  RESUME_STORE_USER_PHONE: 'Teléfono',

  // - LITER INFO
  LITER_INFO_TITLE: '¿Cómo indico los litros que necesito?',
  LITER_INFO_TEXT: `Tu pedido tiene que ser de mínimo ${Constants.MIN_ACCEPTED} litros y máximo ${Constants.MAX_ACCEPTED}.`,
  LITER_INFO_TEXT2: 'La cantidad de litros tiene que ser múltiplo de 10. Por ejemplo, 310 - 320 - 330 - 340...',

  // - EUROS INFO
  EUROS_INFO_TITLE: '¿Cómo indico el importe en euros que necesito?',
  EUROS_INFO_TEXT: `Introduce el importe en euros de gasóleo que necesitas. Revisa los resultados que te mostramos teniendo en cuenta que el coste puede variar debido a que la cantidad de litros mínima para un pedido es de ${Constants.MIN_ACCEPTED} y la máxima es de ${Constants.MAX_ACCEPTED}. Si necesitas más de ${Constants.MAX_ACCEPTED} litros, llámanos al ${Constants.TELEFONO} y te daremos una atención personalizada.`,
  EUROS_INFO_TEXT2: `Ej: Si introduces 50€, el precio se ajustará para llegar a un pedido de ${Constants.MIN_ACCEPTED}l o si pides 5.000€ el precio se reducirá hasta llegar a un pedido de ${Constants.MAX_ACCEPTED}l.`,

  // - STATICS
  STATICS_SECTIONS: 'Secciones',

  // - CTA
  STATICS_CTA_TITLE: '¿Necesitas gasoil?',
  STATICS_CTA_SUBTITLE: 'Haz tu pedido online ahora.',
  STATICS_CTA_BUTTON_LABEL: 'Pedir gasóleo',

  // - FAQ
  FAQS_HEADER_TITLE: 'Tenemos respuesta para todas tus dudas',
  FAQS_ORDER_LINK: 'El proceso de pedido',
  FAQS_ORDER_TITLE: 'Sobre el proceso de pedido',
  FAQS_ORDER_QUESTION_01: '¿Cuántos litros de gasoil puedo pedir?',
  FAQS_ORDER_ANSWER_01: `Puedes pedir un mínimo de ${Constants.MIN_ACCEPTED} litros y un máximo de ${Constants.MAX_ACCEPTED}. El total de litros siempre tiene que ser múltiplo de 10 (Por ejemplo: 310, 320, 330, 340…).`,
  FAQS_ORDER_QUESTION_02: '¿Cómo se calcula el importe en euros de gasóleo?',
  FAQS_ORDER_ANSWER_02: `Indícanos el importe en euros de gasóleo que necesitas y te mostraremos los
    litros que puedes adquirir por dicho importe y el coste final.
    <div class="faq-section__dropdown-content__list">
      El importe puede variar debido al precio actual del gasóleo y a que el pedido mínimo de
      litros de gasóleo es de ${Constants.MIN_ACCEPTED} y el máximo es de ${Constants.MAX_ACCEPTED}.
    </div>
    <div class="faq-section__dropdown-content__list">
      Por ejemplo: Si introduces un importe de 50€ el precio se ajustará para llegar a un pedido
      de ${Constants.MIN_ACCEPTED}l o si pides 5.000€ el precio se reducirá hasta llegar a un pedido de ${Constants.MAX_ACCEPTED}l.
    </div>
  `,
  FAQS_ORDER_QUESTION_03: '¿El precio del gasoil y la disponibilidad puede cambiar?',
  FAQS_ORDER_ANSWER_03: `
    Sí, están sujetos a cambios según el mercado.
    Cuando vayas a hacer tu pedido, siempre te mostraremos la disponibilidad y
    el precio (con IVA incluido) en vigor en ese momento.`,
  FAQS_ORDER_QUESTION_04: 'Durante el pedido ¿puede cambiar el precio y disponibilidad del gasoil?',
  FAQS_ORDER_ANSWER_04: `
    El precio y disponibilidad del gasoil pueden cambiar a lo largo del día según la cotización.
    Te recomendamos que finalices el proceso de pedido en el momento para evitar posibles cambios.`,
  FAQS_ORDER_QUESTION_05: '¿El servicio de pedido online de gasóleo está disponible en toda España?',
  FAQS_ORDER_ANSWER_05: `
  Sí, el pedido online de gasóleo está disponible en toda la península e Islas Baleares.`,
  FAQS_ORDER_QUESTION_06: '¿Cuáles son los horarios de entrega?',
  FAQS_ORDER_ANSWER_06: `
  Los horarios de entrega son dos: por la mañana (08:00-13:00) y
  por la tarde (13:00-19:00).<br>Los horarios y días de entrega disponibles
  te aparecerán después de rellenar en la página de Inicio los datos
  de tu dirección y la cantidad de gasóleo que necesitas.
  <div class="faq-section__dropdown-content__list">
    <h5>Ten en cuenta que:</h5>
    <ul>
      <li>Si haces el pedido antes de las 18h te mostraremos los horarios disponibles a partir del día siguiente.</li>
      <li>Si haces el pedido después de las 18h te mostraremos los horarios disponibles a partir de pasado mañana.</li>
      <li>Los domingos y festivos no se reparten.</li>
    </ul>
  </div>`,
  FAQS_ORDER_QUESTION_07: '¿Qué ocurre si viene el distribuidor y no estoy en casa?',
  FAQS_ORDER_ANSWER_07: 'El distribuidor te llamará para acordar un nuevo horario de entrega.',
  FAQS_ORDER_QUESTION_08: '¿Puedo cambiar el horario de entrega una vez que he finalizado el pedido?',
  FAQS_ORDER_ANSWER_08:
    'Una vez finalizado el pedido, para cambiar el horario de entrega, contacta con el distribuidor que tiene asignado tu pedido.',
  FAQS_ORDER_QUESTION_09: '¿Puedo cambiar la dirección de entrega una vez que he finalizado el pedido?',
  FAQS_ORDER_ANSWER_09:
    'Una vez finalizado el pedido, para cambiar la dirección de entrega, contacta con el distribuidor que tiene asignado tu pedido.',
  FAQS_ORDER_QUESTION_10: '¿Cómo puedo cancelar el pedido?',
  FAQS_ORDER_ANSWER_10: 'Para cancelar el pedido, contacta con el distribuidor que tiene asignado tu pedido.',
  FAQS_ORDER_QUESTION_11: 'Para hacer el pago en efectivo, ¿tengo que tener el importe exacto?',
  FAQS_ORDER_ANSWER_11: 'No, no hace falta. El distribuidor llevará cambio para que puedas realizar el pago.',
  FAQS_ORDER_QUESTION_12: 'He pedido más litros de los que caben en mi depósito, ¿tengo que pagarlos?',
  FAQS_ORDER_ANSWER_12: `
    No, solo tendrás que pagar los litros que se te entreguen.
    Los camiones están equipados con contadores que nos permitirán
    calcular el importe final del pedido.`,
  FAQS_ORDER_QUESTION_13: '¿Cómo se realiza el pago con tarjeta?',
  FAQS_ORDER_ANSWER_13: `
  Los camiones de Repsol van equipados con datafonos para poder pagar en el momento de la descarga por los litros suministrados.
  En ningún caso Repsol le solicitará información sobre sus tarjetas ni por teléfono ni por email`,
  FAQS_ORDER_QUESTION_14: '¿Qué es el pago por Waylet?',
  FAQS_ORDER_ANSWER_14: `
  Waylet es la app gratuita de Repsol para pagar sin contacto de forma rápida y segura, al mismo tiempo que ahorras y acumulas descuentos.
  Al pagar en la red Waylet, en función de promociones vigentes, podrás acumular saldo en tu monedero digital para descontarlo en futuras compras, y conseguir descuentos en tus marcas y comercios favoritos.
  Utilízala para pagar tus repostajes y recargas eléctricas en las estaciones de servicio y puntos de recarga del Grupo Repsol, y ahorra tiempo al pagar tus repostajes directamente desde el surtidor sin pasar por caja.
  Además, podrás gestionar tu estacionamiento en las zonas con Servicio de Estacionamiento Regulado (SER) de las principales localidades de España, y comprar en más de 7.500 comercios adheridos a la red Waylet como las tiendas de las Estaciones de Servicio, todos los Centros de El Corte Inglés, Hipercor, Supercor, Bricor y Sfera; tiendas Scalpers, talleres First Stop, La Casa de las Carcasas, Federópticos, ¡y muchos más!
  También puedes comprar una amplia variedad de productos de la tienda de tu estación de servicio a través de la Tienda Online Repsol y pasar a recogerlo o realizar tu pedido a domicilio.
  Y compensar las emisiones de CO2 de tus repostajes, colaborando en proyectos forestales.`,
  FAQS_ORDER_QUESTION_15: '¿Por qué no me aparece la opción para elegir Waylet en la pantalla de forma de pago?',
  FAQS_ORDER_ANSWER_15: `
  No en todas las poblaciones podemos aceptar como sistema de pago la aplicación WAYLET, si la opción no está disponible cuando aparece la pantalla de “Elija su forma de pago” es que todavía no está disponible en su localidad; lo sentimos, por favor elija otra forma de pago (Efectivo o Tarjeta)`,
  FAQS_ORDER_QUESTION_16: 'No he pedido litros suficientes para rellenar mi depósito, ¿tengo que hacer otro pedido?',
  FAQS_ORDER_ANSWER_16: `
    Si el distribuidor tiene litros disponibles en el momento de la entrega,
    te suministrará la cantidad que necesites y calculará el importe final del pedido.`,
  FAQS_ORDER_QUESTION_17: '¿Hacéis algún tipo de recomendación al hacer mi pedido?',
  FAQS_ORDER_ANSWER_17:
    'Siempre te recomendaremos BiEnergy e+10, nuestro producto con más calidad. Y te destacaremos la opción de pedido en la que esté al mejor precio.',
  FAQS_ORDER_QUESTION_18: '¿Puedo estar seguro/a de que recibiré los litros que pedí?',
  FAQS_ORDER_ANSWER_18: `
    Tu tranquilidad y confianza es lo más importante para nosotros.
    Por eso, solo trabajamos con distribuidores que ofrezcan todas las garantías y seguridad en el suministro.`,
  FAQS_ORDER_QUESTION_19: '¿Cuándo recibo la factura de mi pedido?',
  FAQS_ORDER_ANSWER_19: 'El distribuidor te dará la factura en el mismo momento que te entrega el pedido.',
  FAQS_ORDER_QUESTION_20:
    '¿Cómo puedo contactar con vosotros si tengo alguna duda o incidencia con el servicio ‘Pide tu gasóleo’?',
  FAQS_ORDER_ANSWER_20: `Ponemos a tu disposición los siguientes canales de ayuda:
  <div class="faq-section__dropdown-content__list">
    <ul>
      <li>Correo electrónico: <a href="mailto:pidetugasoleo@repsol.com" class="link--simple">pidetugasoleo@repsol.com</a></li>
    </ul>
  </div>
  Para consultas sobre un pedido realizado, por favor contacta con tu distribuidor directamente.`,

  FAQS_DISTRIBUTOR_LINK: 'El distribuidor',
  FAQS_DISTRIBUTOR_TITLE: 'Sobre el distribuidor',
  FAQS_DISTRIBUTOR_QUESTION_01: '¿Puedo elegir el distribuidor que yo quiera?',
  FAQS_DISTRIBUTOR_ANSWER_01: `
    Para ofrecer un servicio y entrega de calidad, tenemos
    los distribuidores por códigos postales.
    A la hora de realizar el pedido, según tu código postal,
    se te asignará automáticamente el distribuidor correspondiente.`,
  FAQS_DISTRIBUTOR_QUESTION_02:
    '¿Tengo que entregar algún tipo de documentación al distribuidor cuando me entregue el pedido?',
  FAQS_DISTRIBUTOR_ANSWER_02: `
    Si es la primera vez que el distribuidor te suministra el pedido,
    tienes que entregarle el documento de declaración de consumidor final.
    <p>- Documento para gasóleo calefacción descargar <a href="${process.env.BASE_URL}download/DECLARACION_CONSUMIDOR_FINAL.XLSM" class="link--simple" target="_blank">aquí</a>.</p>
    <p>- Documento para gasóleo agrícola descargar <a href="${process.env.BASE_URL}download/MODELO_DE_DCF_AGRO.pdf" class="link--simple" target="_blank">aquí</a>.</p>`,
  FAQS_DISTRIBUTOR_QUESTION_03: '¿Para qué sirve la valoración del distribuidor?',
  FAQS_DISTRIBUTOR_ANSWER_03: 'Gracias a tu valoración podremos mejorar y adaptar el servicio a tus necesidades.',
  FAQS_PRODUCT_LINK: 'Nuestros productos',
  FAQS_PRODUCT_TITLE: 'Sobre nuestros productos',
  FAQS_PRODUCT_QUESTION_01: '¿Qué diferencia existe entre el gasóleo BiEnergy e+10 y el gasóleo Energy e+?',
  FAQS_PRODUCT_ANSWER_01: `
    Te recomendamos visitar la página de <a href="${process.env.BASE_URL}tipos-de-gasoleos" class="link--simple">productos</a>
    para consultar toda la información sobre los dos tipos de gasóleo
    y sus diferencias para elegir el que mejor se adapte a tus necesidades.`,
  FAQS_PRODUCT_QUESTION_02: 'Si tengo una caldera de condensación, ¿qué tipo de gasoil necesito?',
  FAQS_PRODUCT_ANSWER_02: `
    Para las calderas de Condensación,
    solo recomendamos BiEnergy e+10, es el único producto
    en el mercado desarrollado específicamente para este tipo
    de calderas y que recomiendan la mayoría de los fabricantes.
    Puedes consultar más información sobre este gasoil <a href="${process.env.BASE_URL}tipos-de-gasoleos" class="link--simple">aquí</a>`,
  FAQS_PRODUCT_QUESTION_03:
    'Si tengo una caldera de combustión y resto de calderas (calderas estándar, calderas convencionales, calderas antiguas ...), ¿qué tipo de gasoil necesito?',
  FAQS_PRODUCT_ANSWER_03: `
    Aunque puedes utilizar los dos tipos de gasoil disponibles,
    te recomendamos utilizar BiEnergy e+10 porque mejorarás el rendimiento y
    la vida útil de tu caldera. Además conseguirás un ahorro energético en el consumo.
    Puedes consultar toda la información sobre estos gasoil <a href="${process.env.BASE_URL}tipos-de-gasoleos" class="link--simple">aquí</a>`,
  FAQS_PRODUCT_QUESTION_04: '¿Afecta a mi caldera cambiar de tipo de gasoil?',
  FAQS_PRODUCT_ANSWER_04: `
    Cambiar de tipo de gasoil no afecta a tu caldera,
    pero puede cambiar su rendimiento y vida útil.
    Por eso, te recomendamos utilizar BiEnergy e+10, que mejora dicho rendimiento y la vida útil de la caldera.`,
  FAQS_PRODUCT_QUESTION_05: '¿Qué es el Agrodiesel e+10?',
  FAQS_PRODUCT_ANSWER_05: `
    Es un gasoil especialmente diseñado para la maquinaria
    agrícola, tanto por su formulación exclusiva como por su
    bajo contenido en azufre.`,
  FAQS_PRODUCT_QUESTION_06: '¿En qué se diferencia el Agrodiesel e+10 de otros gasóleos?',
  FAQS_PRODUCT_ANSWER_06: `
    Su formulación especial está diseñada para evitar el
    ensuciamiento de los inyectores de los motores modernos
    tipo “Common Rail”; gracias a ello mejora el rendimiento
    de estos motores, reduciendo el consumo y disminuyendo las averías`,
  FAQS_PRODUCT_QUESTION_07: '¿Hay fabricantes que recomiendan este producto?',
  FAQS_PRODUCT_ANSWER_07: `
    Los principales fabricantes de
    maquinaria Agrícola recomiendan
    Repsol AgroDiesel e +10, por su
    alto rendimiento y para proteger la maquinaría de la corrosión.`,
  FAQS_SAC_LINK: 'Mi perfil',
  FAQS_SAC_TITLE: 'Sobre Mi perfil',
  FAQS_SAC_QUESTION_01: '¿Para qué sirve registrarme con mis perfiles de redes sociales?',
  FAQS_SAC_ANSWER_01: `
    Registrarte con tu perfil de Facebook, Twitter, Linkedin
    te permite crear tu cuenta en el servicio de pedido online
    de gasóleo de manera fácil y rápida.
    Nosotros no publicaremos nada en tus redes sociales
    y tus datos en estas redes seguirán siendo confidenciales y privados.`,
  FAQS_SAC_QUESTION_02: '¿Cómo puedo darme de baja?',
  FAQS_SAC_ANSWER_02: 'Entra en "Mi perfil" y selecciona la opción ‘Darme de baja’.',
  FAQS_SAC_QUESTION_03: '¿Cómo puedo dejar de recibir comunicaciones comerciales?',
  FAQS_SAC_ANSWER_03: 'Entra en "Mi perfil" y modifica el apartado de ‘Preferencias’.',
  FAQS_SAC_QUESTION_04: '¿Puedo recuperar mi cuenta una vez que me he dado de baja?',
  FAQS_SAC_ANSWER_04: `
    Una vez que te has dado de baja no es posible recuperar tu cuenta.
    Para poder tener una nueva cuenta y realizar tus pedidos de gasoil tienes que volver a registrarte.`,
  FAQS_SAC_QUESTION_05: '¿Puedo modificar mis datos personales?',
  FAQS_SAC_ANSWER_05:
    'Entra en "Mi perfil". En la sección ‘Datos Personales’ podrás modificarlos las veces que necesites.',
  FAQS_SAC_QUESTION_06: '¿Puedo realizar un pedido igual que el último realizado?',
  FAQS_SAC_ANSWER_06:
    'Sí, siempre puedes volver a realizar un pedido igual que el último realizado. En la sección Mis pedidos de tu área personal tendrás disponible esta opción en primer lugar.',
  FAQS_SAC_QUESTION_07: '¿Dónde puedo encontrar la información de mi último pedido realizado?',
  FAQS_SAC_ANSWER_07:
    "La información relativa a tu último pedido realizado siempre la encontrarás colocada en primera posición dentro de 'Mis pedidos' en tu área privada.",
  FAQS_SAC_QUESTION_08: '¿Puedo encontrar en algún lugar un histórico con los pedidos que he ido realizando?',
  FAQS_SAC_ANSWER_08:
    "Sí, siempre encontrarás un histórico de todos tus pedidos en la sección 'Mis pedidos' dentro de tu área privada. Estos pedidos estarán catalogados por: Pedido en curso, Pedido entregado y Pedido cancelado.",

  // - ASSESSMENT
  ASSESSMENT_PROVIDER_TITLE: 'Distribuidor asignado',
  ASSESSMENT_PROVIDER_INFO: '<span>Teléfono</span> <span class="bold">{phone}</span>',
  ASSESSMENT_PROVIDER_MAIL: '<span>Email</span> <span class="bold">{mail}</span>',
  ASSESSMENT_COMMENTS_LABEL: '¿Tienes algún comentario? (opcional)',
  ASSESSMENT_COMMENTS_PLACEHOLDER: 'Deja tu comentario',
  ASSESSMENT_COMMENTS_ERROR: 'Debes valorar los tres apartados antes de continuar',
  ASSESSMENT_COMMENTS_BUTTON: 'Enviar valoración',
  ASSESSMENT_AMABILITY_TITLE: 'Amabilidad',
  ASSESSMENT_AMABILITY_NOTE: 'El distribuidor ha tenido un trato agradable y atento conmigo.',
  ASSESSMENT_PUNCTUALITY_TITLE: 'Puntualidad',
  ASSESSMENT_PUNCTUALITY_NOTE: 'El pedido fue entregado en el día y franja horaria que elegí.',
  ASSESSMENT_DELIVERY_TITLE: 'Entrega',
  ASSESSMENT_DELIVERY_NOTE:
    'El distribuidor tomó todas las medidas de seguridad y limpieza necesarias para la entrega.',
  ASSESSMENT_STRIPE_START: 'Haz tu valoración',
  ASSESSMENT_STRIPE_0: 'Deficiente',
  ASSESSMENT_STRIPE_1: 'Mejorable',
  ASSESSMENT_STRIPE_2: 'Bien',
  ASSESSMENT_STRIPE_3: 'Muy bien',
  ASSESSMENT_STRIPE_4: 'Excelente',
  ASSESSMENTSENDED_BUTTON: 'Volver a Pide tu gasóleo',
  ASSESSMENTREJECTED_BUTTON: 'Ir a Pide tu gasóleo',

  // - PRODUCTS
  PRODUCTS_SECTION_TITLE: 'El gasoil de calefacción y agrícola que necesitas cuando lo necesitas',
  PRODUCTS_CLAIM_TITLE: 'Ahorro y eficiencia',
  PRODUCTS_CLAIM_CONTENT: `
    Investigamos día a día para desarrollar productos que consigan la mayor eficiencia
    energética y  el mejor rendimiento, reduciendo su impacto medioambiental.
    Trabajamos para llevarte el mejor gasoil.`,

  PRODUCTS_CONTENT_TITLE: 'Nuestros gasóleos calefacción',
  PRODUCTS_CONTENT_TITLE2: 'Gasóleo Agrícola',
  PRODUCTS_CONTENT_PRODUCT_NAME_01: 'BiEnergy e+10',
  PRODUCTS_CONTENT_PRODUCT_CLAIM_01: 'Consigue un mayor ahorro energético',
  PRODUCTS_CONTENT_PRODUCT_CONTENT_01_01: `
    BiEnergy e+10 es el gasoil calefacción de última generación con el
    que conseguir la máxima eficiencia energética en tu caldera de condensación
    y un ahorro de hasta el 30% en comparación con las calderas convencionales.
    Con este gasoil también consigues un mayor ahorro en el mantenimiento de tu
    caldera porque sus componentes mejorados proporcionan una mayor estabilidad
    del producto y optimiza el funcionamiento de la caldera.`,
  PRODUCTS_CONTENT_PRODUCT_CONTENT_02_01:
    'BIEnergy e+10 es el producto recomendado por los principales fabricantes de calderas en sus equipos de última generación.',

  PRODUCTS_CONTENT_PRODUCT_NAME_02: 'Energy e+',
  PRODUCTS_CONTENT_PRODUCT_CLAIM_02: 'Disfruta de la seguridad en tu hogar',
  PRODUCTS_CONTENT_PRODUCT_CONTENT_01_02: `
    Sus componentes mejorados  y exclusivos ofrecen una
    calidad superior que  proporciona a tu hogar  el confort,
    tranquilidad y seguridad que buscas.`,
  PRODUCTS_CONTENT_PRODUCT_CONTENT_02_02: `
    Energy e+ es el gasoil calefacción cuya fórmula única
    cumple con las más altas exigencias de los fabricantes de calderas,
    protege los sistemas de alimentación e inyección,
    y te permite mantener tu caldera como recién estrenada.`,

  PRODUCTS_CONTENT_PRODUCT_NAME_03: 'AgroDiésel e+ 10',
  PRODUCTS_CONTENT_PRODUCT_CLAIM_03: 'Un gasóleo agrícola todoterreno',
  PRODUCTS_CONTENT_PRODUCT_CONTENT_01_03: `
    Alarga la vida útil de tu maquinaria y cuenta
    con la garantía y la seguridad de disfrutar de
    un gasóleo premium.`,
  PRODUCTS_CONTENT_PRODUCT_CONTENT_02_03: `El gasóleo agrícola de calidad que tu empresa agropecuaria necesita`,
  // PRODUCTS_CONTENT_PRODUCT_CONTENT_03_03: `El gasoil B mejorado creado a partir de una fórmula avanzada que da como resultado un producto que:`,
  // PRODUCTS_CONTENT_PRODUCT_CONTENT_04_03: `- Alarga la vida útil de tu maquinaria ya que protege el motor de la corrosión y conserva en condiciones adecuadas el sistema de alimentación e inyección del gasóleo agrícola.`,
  // PRODUCTS_CONTENT_PRODUCT_CONTENT_05_03: `- Minimiza los costes de mantenimiento reduciendo la obstrucción de los filtros.`,
  // PRODUCTS_CONTENT_PRODUCT_CONTENT_06_03: `- Ofrece una combustión idónea conservando la potencia, reduciendo las emisiones y el consumo como el primer día.`,
  // PRODUCTS_CONTENT_PRODUCT_CONTENT_07_03: `- Respeta el medioambiente a través de su fórmula de composición sin azufre.`,
  // PRODUCTS_CONTENT_PRODUCT_CONTENT_08_03: `Innovación, rendimiento y fiabilidad se dan la mano para ofrecerte el mejor gasóleo para tu explotación.`,

  PRODUCTS_TABLE_PRODUCT_NAME_01: 'Repsol BiEnergy e+10',
  PRODUCTS_TABLE_PRODUCT_NAME_02: 'Repsol Energy e+',
  PRODUCTS_TABLE_PRODUCT_NAME_03: 'Gasóleo B',
  PRODUCTS_TABLE_PRODUCT_NAME_04: 'Repsol Agrodiésel e+10',
  PRODUCTS_TABLE_LABEL_01: 'Para caldera de condensación',
  PRODUCTS_TABLE_LABEL_02:
    'Para caldera de combustión y resto de calderas (calderas estándar, calderas convencionales, calderas antiguas ...)',
  PRODUCTS_TABLE_LABEL_03: 'Para viviendas unifamiliares',
  PRODUCTS_TABLE_LABEL_04: 'Para comunidades de vecinos',
  PRODUCTS_TABLE_LABEL_05: 'Reduce la contaminación medioambiental',
  PRODUCTS_TABLE_LABEL_06: 'Mejora la eficiencia energética',
  PRODUCTS_TABLE_LABEL_07: 'Asegura la estabilidad del gasóleo en el depósito de la caldera',
  PRODUCTS_TABLE_LABEL_08: 'Optimizado para motores Common Rail',
  PRODUCTS_TABLE_LABEL_09: 'Respetuoso con el medio ambiente por su formula bajo en azufre',
  PRODUCTS_TABLE_LABEL_10: 'Protege de la corrosión por su aditivación específica',
  PRODUCTS_TABLE_LABEL_11: 'Cumple especificaciones BOE',
  PRODUCTS_TABLE_LABEL_12: 'Minimiza costes de mantenimiento, conservando limpios los inyectores',
  PRODUCTS_BIENERGY_LINK_LABEL: 'Saber más sobre Bi Energy e+10',
  PRODUCTS_BIENERGY_LINK: '/es/productos-y-servicios/gasoleo/productos/gasoleo-calefaccion/index.cshtml',
  PRODUCTS_ENERGY_LINK_LABEL: 'Saber más sobre Energy e+',
  PRODUCTS_ENERGY_LINK: '/es/productos-y-servicios/gasoleo/productos/gasoleo-calefaccion/index.cshtml',
  PRODUCTS_AGRODIESEL_LINK_LABEL: 'Saber más sobre AgroDiésel e+ 10',
  PRODUCTS_AGRODIESEL_LINK: '/es/productos-y-servicios/gasoleo/productos/agrodiesel/index.cshtml',

  // - CONTACTS
  CONTACT_HEADER_TITLE: 'Cuéntanos en qué te podemos ayudar',
  CONTACT_SECTION_TITLE: '¿Tienes alguna duda o incidencia con el servicio?',
  CONTACT_SECTION_INFO_TITLE: 'Ponemos a tu disposición los siguientes canales de ayuda:',
  CONTACT_SECTION_INFO_ADDITIONAL:
    'Para consultas sobre un pedido realizado, por favor contacta con tu distribuidor directamente.',
  CONTACT_SECTION_PHONE: 'Teléfono <span>{phone}</span>',
  CONTACT_SECTION_MAIL: 'Correo electrónico <span>{mail}</span>',
  CONTACT_SECTION_EXTRAINFO_TITLE: '¿Necesitas cambiar algún dato de tu pedido o cancelarlo?',
  CONTACT_SECTION_EXTRAINFO_ADDITIONAL: `
    Para cualquier modificación o cancelación del pedido, ponte en contacto con tu distribuidor asignado.
    Tienes disponible su teléfono en el correo electrónico que recibiste al finalizar el pedido.`,

  // - SERVICE CONDITIONS
  SERVICE_CODNITIONS_1_TEXT: `<p>
      Las presentes Condiciones Particulares (en adelante, las “Condiciones”)
      regulan los términos y condiciones en los que Repsol Comercial de Productos Petrolíferos S.A.,
      entidad con CIF A-80298839, inscrita en el Registro Mercantil de Madrid. Tomo 2.530, general,
      folio 1, hoja M-44.194, inscripción 1ª y con domicilio en 28045 Madrid, c/ Méndez Álvaro, 44 (“Repsol Comercial”),
      prestará a través de la plataforma online denominada Canal de Distribución de Gasóleos de Repsol ("Sitio Web")
      el servicio de intermediación entre clientes finales y empresas distribuidoras de gasóleos y revendedoras del
      producto adquirido a Repsol Comercial, para facilitar la compraventa de dichos productos entre
      el cliente y el distribuidor.
    </p>
    <p>
      Estas Condiciones del Servicio Online son complementarias
      al <a href="https://www.repsol.com/es/pie-de-pagina/nota-legal/index.cshtml" class="link--simple" target="_blank">“Aviso Legal”</a>,
      <a href="https://www.repsol.com/es/pie-de-pagina/politica-de-privacidad/index.cshtml" class="link--simple" target="_blank">“Política de Privacidad”</a>
      y <a href="https://www.repsol.es/particulares/politica-de-cookies/" class="link--simple" target="_blank">“Política de Cookies”</a>
      aplicables al Sitio Web.
    </p>
    <p>
      Recomendamos que leas atentamente las presentes Condiciones antes
      de hacer uso del Servicio Online. Al hacer uso del Servicio Online
      declaras conocer las presentes Condiciones, las aceptas y consientes
      quedar vinculado por las mismas y ello equivaldrá a todos los efectos
      a tu firma manuscrita. En consecuencia, si no estás de acuerdo con
      el contenido íntegro de las Condiciones no debes registrarte,
      ni hacer uso del Servicio Online.
    </p>
    <p>
      Estas Condiciones, así como los servicios, las modalidades de acceso,
      y en general, cualquier elemento que integra el Servicio Online podrá
      ser modificado en cualquier momento y sin previo aviso, siendo aplicables
      aquellas Condiciones vigentes en el momento de uso del Servicio Online,
      por lo que se recomienda al Cliente que revise regularmente las mismas.
      En su caso, dichas modificaciones serán efectivas desde su publicación en el Sitio web.
      Si alguna de las presentes Condiciones fuera declarada inválida,
      nula o por cualquier causa ineficaz, dicha condición se entenderá excluida
      sin que dicha declaración pueda afectar a la validez ni a la exigibilidad del resto de condiciones.
    </p>`,
  SERVICE_CODNITIONS_2_TITLE: 'SERVICIO ONLINE',
  SERVICE_CODNITIONS_2_TEXT: `<p>
      A través del Sitio Web los clientes de gasóleo marca Repsol (“Clientes”)
      pueden realizar solicitudes de compra de entre un mínimo de ${Constants.MIN_ACCEPTED} litros y
      un máximo de ${Constants.MAX_ACCEPTED} litros de gasóleo (“Pedido”) dentro de Península o Baleares,
      quedando por tanto expresamente excluidos los Pedidos para instalaciones situadas
      en cualquier otro territorio. La solicitud se dirigirá a través del Servicio Online
      al distribuidor correspondiente (“Distribuidor”) para su confirmación y ejecución, en su caso.
    </p>
    <p>
      El contrato de compraventa se establece, exclusivamente,
      entre el Cliente y el Distribuidor, siendo Repsol Comercial ajena al mismo
      y no asumiendo ninguna obligación o responsabilidad derivada de la compraventa del producto.
    </p>
    <p>
      El contrato de compraventa se regirá por las condiciones del Distribuidor y, en su defecto, por las presentes Condiciones.
    </p>`,

  SERVICE_CODNITIONS_3_TITLE: 'QUIENES PUEDEN REALIZAR PEDIDOSSERVICIO ONLINE',
  SERVICE_CODNITIONS_3_TEXT: `<p>
      Podrán realizar Pedidos los Clientes, personas físicas o jurídicas, que cumplan con los siguientes requisitos:
    </p>
    <ol>
      <li>En el caso de personas físicas ser mayor de dieciocho (18) años, y no tener limitada o restringida la capacidad de obrar. </li>
      <li>
        En el caso de personas jurídicas,
        estar legalmente constituidas y no estar afectado por alguna prohibición de contratar
        realizando el Pedido una persona física que actúe en su nombre y representación y disponga
        de facultades suficientes para acceder al Servicio Online y obligar
        a la empresa a lo que aquí se compromete.</li>
      <li>
        Ser cliente final de los productos, es decir, realizar la compra para consumo propio, y con destino exclusivo a la instalación identificada en el propio Pedido.
      </li>
      <li>
        Tener Tu Cuenta como Cliente Único de Repsol
        (ver más detalle en el apartado “Tratamiento de datos de carácter personal” más adelante)
        y estar por tanto registrado en el Servicio Online a través del Sitio Web.
      </li>
      <li>
        Aceptar las presentes Condiciones antes de confirmar el Pedido realizado.
      </li>
    </ol>
    <p>
      Al confirmar el Pedido el Cliente declara cumplir con los requisitos que se citan anteriormente.
    </p>`,

  SERVICE_CODNITIONS_4_TITLE: 'DISTRIBUIDORES',
  SERVICE_CODNITIONS_4_TEXT: `<p>
      Repsol Comercial colabora exclusivamente con Distribuidores de gasóleos marca Repsol para consumo propio en instalaciones fijas en Península y Baleares.
    <p>
    <p>
      La compraventa se concierta entre el Cliente y el Distribuidor,
      siendo una relación ajena a Repsol Comercial. Repsol Comercial no será responsable
      de la compraventa del Producto y, sin carácter limitativo (i) de la calidad,
      cantidad o características del Producto distribuido; (ii) de los daños y perjuicios
      que puedan derivarse al Cliente o a cualquier tercero, directa o indirectamente,
      de la compraventa y suministro del Producto; (iii) de cualesquiera pérdidas o daños sufridos
      por los Productos; y (iv) de cualesquiera retrasos, defectos o errores en el suministro.
    <p>
    <p>
      Los Productos objeto de Pedidos estarán garantizados conforme a las garantías
      que cada Distribuidor ofrezca en cada caso, sin perjuicio de aquellas garantías comerciales
      contempladas en la normativa en vigor en cada momento. Repsol Comercial no será responsable
      del incumplimiento de ninguna obligación del Distribuidor o de cualquier otro tercero,
      así como de aquellas previstas en las garantías ofrecidas por el Distribuidor o por terceros,
      o de cualquier otro tipo.
    </p>`,

  SERVICE_CODNITIONS_5_TITLE: 'CÓMO REALIZAR UN PEDIDO',
  SERVICE_CODNITIONS_5_TEXT: `<p>
      Los Pedidos deberán incluir para su tramitación, al menos, la siguiente información:
    </p>
    <ol>
      <li> Nº de Pedido </li>
      <li> Datos identificativos y de contacto del Cliente </li>
      <li> Datos identificativos y de contacto del Distribuidor </li>
      <li> Identificación del Producto </li>
      <li> Cantidad de Producto </li>
      <li> Dirección de suministro </li>
      <li> Fecha de suministro </li>
      <li> Datos de facturación </li>
      <li> Precio del Producto (incluye el correspondiente desglose) </li>
    </ol>
    <p>
      El Cliente recibirá un email con los datos de su Pedido,
      pudiendo incluir cambios en el mismo o anularlo, en cualquier momento,
      y siempre que exista un acuerdo bilateral entre el distribuidor y el usuario.
    </p>
    <p>
      El Distribuidor se reserva el derecho de cancelar o rechazar cualquier Pedido por alguno de los siguientes motivos:
    </p>
    <ul>
      <li>Cuando se detecten indicios de que el Pedido puede ser fraudulento o exista riesgo para la seguridad de los sistemas, o de cualquier otro tipo.</li>
      <li>Por causas de fuerza mayor.</li>
      <li>Por incumplimiento de las condiciones que rigen la compraventa</li>
    </ul>
    <p>El Cliente puede consultar el estado del Pedido en todo momento a través de su propia cuenta en el apartado “Mis Pedidos”.</p>`,

  SERVICE_CODNITIONS_6_TITLE: 'ENTREGA DEL PRODUCTO Y DERECHO DE DESISTIMIENTO',
  SERVICE_CODNITIONS_6_TEXT: `<p>
      El Pedido incluye la solicitud de entrega del Producto en una fecha determinada.
      En cualquier caso, la cantidad y fecha de suministro seleccionadas por el Cliente son orientativas,
      quedando sujeto a la disponibilidad del producto y del Distribuidor. El Cliente recibirá confirmación
      de la fecha exacta en la que será entregado el Pedido antes de que ésta se produzca.
    </p>
    <p>
      No se realizarán entregas en domingos o festivos. Las entregas en sábados quedarán sujetas a la disponibilidad del Distribuidor.
    </p>
    <p>
      En el momento de la entrega el Cliente deberá firmar el correspondiente albarán.
    </p>
    <p>
      El derecho de desistimiento no será aplicable una vez efectuado el suministro de acuerdo
      a lo recogido en el artículo 103 letra f) de la Ley General para la Defensa de Consumidores y Usuarios<sup>1</sup>,
      por tratarse de un producto que una vez entregado en las instalaciones del Cliente,
      dada su propia naturaleza y la imposibilidad de limitar el uso del depósito se mezcla de forma indisociable con otros bienes.
    </p>
    <span class="note">
      1) Artículo 103 letra f) del Real Decreto Legislativo 1/2007, de 16 de Noviembre,
      por el que se aprueba el texto refundido de la Ley General para la Defensa de Consumidores
      y Usuarios</span>`,

  SERVICE_CODNITIONS_7_TITLE: 'PRECIO Y PAGO',
  SERVICE_CODNITIONS_7_TEXT: `<p>
      El Cliente dispondrá de la información sobre el precio de los productos en el momento de realización del Pedido.
      Estos precios son siempre precios finales, e incluyen los impuestos correspondientes y los costes
      correspondientes al transporte y entrega.
    </p>
    <p>
      Los precios se muestran en Euros.
    </p>
    <p>
      El pago del precio se realizará directamente al Distribuidor, por los medios que éste admita y de acuerdo a sus condiciones de venta.
    </p>`,

  SERVICE_CODNITIONS_8_TITLE: 'TRATAMIENTO DE DATOS DE CARÁCTER PERSONAL',
  SERVICE_CODNITIONS_8_TEXT: `<p>
      Repsol Comercial garantiza en todo momento la confidencialidad de la información que nos facilites
      durante el proceso de alta en el Servicio Online así como de tramitación y gestión del Pedido.
      Recuerda que eres el único responsable de la custodia de tu nombre de usuario y contraseña vinculados
      al registro en el Servicio Online, y de impedir el acceso por terceros a dichos datos, siendo estos datos
      para tu uso personal y no transferible a otras personas.
    </p>
    <p>
      En el caso de que quien se registra actúe en nombre y representación de una persona jurídica,
      declara que dispone de facultades suficientes para acceder al Servicio Online y obligar a
      la empresa a cuanto dispuesto en las presentes Condiciones.
    </p>
    <p>
      Para darte de alta en el Servicio Online, es preciso  cumplimentar el correspondiente formulario de registro.
      Si no dispones de Tu Cuenta Repsol, al registrarte te crearemos una, cuyas credenciales te permitirán
      darte de alta y gestionar tu actividad en los diferentes Servicios Online que ofrece Repsol.
    </p>
    <p>
      Un Servicio Online es cualquier servicio electrónico a disposición de clientes y usuarios del grupo Repsol,
      accesibles a través de páginas web, aplicaciones móviles o similares que exigen de un registro
      mediante credenciales de acceso para su utilización.
    </p>
    <p>
      Para la creación de Tu Cuenta y para el registro y prestación del Servicio Online
      es imprescindible el tratamiento de los datos personales. Porque buscamos la máxima transparencia contigo,
      a continuación mostramos la información básica sobre como tratamos los datos personales:
    </p>
    <h4>Entidades responsables del tratamiento</h4>
    <p>Repsol Comercial de Productos Petrolíferos, S.A.</p>
    <p>Repsol, S.A., en calidad de corresponsable del tratamiento, conforme se explica en el apartado relativo a las finalidades de tratamiento.</p>
    <p>Ambas sociedades domiciliadas en 28045, Madrid, Méndez Álvaro, 44</p>
    <h4>Delegado de protección de datos</h4>
    <p>Puedes contactar con él en <a href="mailto:protecciondedatos@repsol.com" class="link--simple">protecciondedatos@repsol.com.</a></p>
    <h4>Datos objeto del tratamiento </h4>
    <p>
      Repsol Comercial de Productos Petrolíferos, S.A.: Todos los datos que nos proporciones
      en el registro y durante la prestación de los servicios, aquellos asociados a Tu Cuenta,
      datos sobre incidencias, quejas, sugerencias o consultas y aquellos que nos comuniquen las
      demás empresas del grupo Repsol* sí así lo hubieses autorizado.</p>
    <p>
      Repsol, S.A.: datos de registro y otros datos asociados a Tu Cuenta de usuario
      como por ejemplo productos y/o servicios de los que eres cliente o usuario y datos sobre incidencias,
      quejas, sugerencias o consultas.</p>
    <table cellpadding="0" cellspacing="0">
      <tr>
        <th></th>
        <th style="width: 30%">Finalidad</th>
        <th style="width: 60%">Finalidad detallada </th>
      </tr>
      <tr>
        <td>(i)</td>
        <td>Acceso a los servicios online del grupo Repsol por Repsol, S.A.</td>
        <td>
          Gestionar Tu Cuenta, incluida su creación si no dispones de una,
          así como el alta en el Servicio Online que solicites.
          Tu cuenta nos permite el control de acceso a todos los Servicios Online en los que estés registrado,
          gestión de incidencias, sugerencias, quejas y consultas, permitiendo a Repsol,
          S.A. conocer la totalidad de Servicios Online en los que te has registrado, pero no la actividad que realices
          en cada uno de ellos, salvo que lo consientas.</td>
      </tr>
      <tr>
        <td>(ii)</td>
        <td>Prestación del servicio por Repsol Comercial de Productos Petrolíferos, S.A.</td>
        <td>
          Gestionar tu acceso a los servicios ofrecidos por
          Repsol Comercial de Productos Petrolíferos, S.A., gestionar la relación contractual,
          que incluye la remisión de información intrínsecamente relacionada con el Servicio Online,
          gestión de incidencias, sugerencias, quejas y consultas.</td>
      </tr>
      <tr>
        <td>(iii)</td>
        <td>Cumplimiento legal</td>
        <td>Cumplir con nuestras obligaciones legales</td>
      </tr>
      <tr>
        <td>(iv)</td>
        <td>Medición de la calidad del servicio</td>
        <td>Analizar la información con el fin de realizar estudios estadísticos y de satisfacción y poder mejorar nuestros productos y/o servicios</td>
      </tr>
      <tr>
        <td>(v)</td>
        <td>Marketing de productos propios</td>
        <td>
          Remitirte información comercial personalizada únicamente conforme
          a tu perfil de cliente de Repsol Comercial de Productos Petrolíferos,
          S.A. sobre nuestros productos, esto es, carburantes y combustibles.</td>
      </tr>
      <tr>
        <td>(vi)</td>
        <td>Realizar perfiles enriqueciendo la información disponible con información del grupo Repsol</td>
        <td>
          Enriquecer tu perfil cliente del grupo Repsol,
          obteniendo para ello información de la que son titulares otras empresas del grupo
          Repsol* con el fin de elaborar un perfil completo de cliente y poder remitirte información
          comercial personalizada sobre los productos y servicios identificados más abajo** y
          analizar patrones de comportamiento con fines de internos de big data</td>
      </tr>
      <tr>
        <td>(vii)</td>
        <td>Cesión al grupo Repsol*</td>
        <td>
          Comunicar tus datos a otras empresas del grupo Repsol* para
          que estas puedan enriquecer sus bases de datos y remitirte información comercial
          personalizada sobre los productos y servicios identificados más abajo**</td>
      </tr>
      <tr>
        <td>(viii)</td>
        <td>Marketing de productos de terceros</td>
        <td>
          Remitirte información comercial sobre los productos y servicios
          de terceros identificados más abajo**. Todos los envíos de información comercial
          podrán realizarse por cualesquiera medios, incluidos electrónicos</td>
      </tr>
    </table>
    <span class="note">
      (**) Productos y servicios sobre los que se enviará información comercial:
      invitaciones a eventos informativos, deportivos, recreativos o lúdicos,
      promociones, concursos, sorteos e información comercial sobre sectores de soluciones energéticas,
      transporte, movilidad, ayuda a la automoción, seguros, finanzas, ocio, viajes, hogar, deporte, gastronomía,
      programas de fidelización, medios y servicios de pago, o telecomunicaciones.
    </span>
    <h4>Legitimación de los tratamientos</h4>
    <p>
      Tratamientos de datos de Repsol Comercial de Productos Petrolíferos, S.A.:
      Los tratamientos descritos en el apartado (ii) y (iii) estarán legitimados
      en la existencia de una relación contractual con el usuario y el cumplimiento de obligaciones legales,
      respectivamente. Los tratamientos descritos en los apartados (iv) y (v) estarán legitimados por
      la existencia de un interés legítimo empresarial. Los restantes tratamientos
      requieren el consentimiento expreso del cliente.
    </p>
    <p>
      Tratamientos de datos de Repsol, S.A.: estarán legitimados en la existencia
      de una relación contractual con el usuario y un interés legítimo empresarial en buscar
      la eficiencia y la usabilidad del usuario en el acceso a los Servicios Online del grupo Repsol.
    </p>
    <h4>Cesiones a terceros</h4>
    <p>
      Con carácter general no cedemos tus datos a terceros,
      salvo en los siguientes supuestos: (i) los que resulten necesarios conforme a ley;
      (ii) los proveedores que nos presten servicios en su condición de encargado del tratamiento;
      (iii) el distribuidor indicado en tu pedido para la prestación del servicio de suministro del
      producto solicitado a través del Servicio Online; (iv) terceros titulares de servicios o
      productos que el usuario voluntariamente solicite (p.e., cuando el usuario quiera beneficiarse
      una oferta de otra empresa del grupo Repsol o de un partner o solicitar financiación);
      (v) el resto de empresas del grupo Repsol con fines de enriquecimiento y perfilado conforme se describe
      en el apartado de finalidades de tratamiento.
    </p>
    <h4>Conservación de datos</h4>
    <p>
      Trataremos tus datos mientras dure la relación contractual y no te des de baja en el Servicio Online.
      No obstante, si no has utilizado el Servicio Online y solo accedes como usuario, conservaremos tus
      datos hasta que canceles Tu Cuenta. Una vez termine la relación contractual, te des de baja en el
      Servicio Online o cancelada Tu Cuenta, según el caso, tus datos serán conservados a los únicos efectos
      de atender posibles responsabilidades jurídicas de todo orden.
    </p>
    <p>
      No obstante, si detectamos que durante un periodo de dos (2) años no has utilizado o interactuado en
      Tu Cuenta o con ninguno de nuestros Servicios Online procederemos a la cancelación de Tu Cuenta,
      a la baja en cualquier Servicio Online en el que estés inscrito y tus datos serán conservados a
      los únicos efectos de atender posibles responsabilidades jurídicas de todo orden.
    </p>
    <h4>Derechos de los interesados</h4>
    <p>
      Acceso, rectificación, supresión, limitación al tratamiento, portabilidad y derecho a no verse sometido a decisiones basadas únicamente en tratamientos automatizados. El usuario tiene, en particular, derecho a oponerse en cualquier momento a los tratamientos basados en la existencia de un interés legítimo empresarial o en su consentimiento. Asimismo, el consentimiento prestado en cada momento será revocable. Se podrán ejercitar estos derechos a través de una comunicación al domicilio de la entidad que ostente condición de responsable del tratamiento o a través de sacportal@repsol.com. Asimismo, podrás formular, en cualquier momento, una reclamación ante la autoridad de control.
    </p>
    <h4>Transferencias internacionales</h4>
    <p>
      Las que resulten de nuestra relación con proveedores de servicios con las garantías dispuestas
      en la Política de privacidad disponible en
      <a href="http://www.repsol.com/privacidad" target:"_blank" class="link--simple">www.repsol.com/privacidad</a></p>
    <h4>Información ampliada</h4>
    <p>
      Puedes consultar información ampliada sobre cómo tratamos los datos en la Política de privacidad
      disponible en <a href="http://www.repsol.com/privacidad" target:"_blank" class="link--simple">www.repsol.com/privacidad</a>
    </p>
    <h4>Solicitud de consentimiento</h4>
    <p>Necesitamos tu consentimiento para poder hacerte llegar ofertas personalizadas que te permitan ahorrar y disfrutar todas las ventajas que el grupo Repsol te ofrece.</p>
    <ul class="check">
      <li>
        Autorizo a que se realice un perfilado sobre mis datos y que dicho perfil sea enriquecido
        con información que otras empresas del grupo Repsol tengan de mí, con fines de marketing y big data interno</li>
      <li>Autorizo a que Repsol Comercial de Productos Petrolíferos, S.A. me remita información comercial por cualesquiera medios sobre los productos y servicios descritos**</li>
      <li>Autorizo a que mi perfil de cliente sea cedido a otras empresas del grupo Repsol* para que estas lo enriquezcan y perfilen con fines de marketing y big data interno</li>
    </ul>
    <span class="note">
      *Empresas del grupo Repsol: identificadas en
      <a href="http://www.informeanual.repsol.com" target="_blank" class="link--simple">www.informeanual.repsol.com</span>`,

  SERVICE_CODNITIONS_9_TITLE: 'DURACIÓN',
  SERVICE_CODNITIONS_9_TEXT: `<p>
      El Servicio Online estará accesible en cualquier momento, mientras esté activo.
    </p>
    <p>
      Repsol Comercial se reserva el derecho de cancelar el Servicio Online en cualquier momento
      sin que lo anterior genere derecho de indemnización, remuneración o compensación alguna.
    </p>`,

  SERVICE_CODNITIONS_10_TITLE: 'ATENCIÓN AL CLIENTE',
  SERVICE_CODNITIONS_10_TEXT: `<p>
      Recibimos de buen grado comentarios y sugerencias.
      Repsol Comercial pone a tu disposición los siguientes medios gratuitos de Atención al Cliente
      para la comunicación de sugerencias, dudas, o cualquier tipo de incidencia, queja y/o reclamación
      relacionada con el Servicio Online en horario 08:00 a 22:00
      de lunes a viernes y de 08:00 a 14:00 los sábados:
    </p>
    <p>Repsol Comercial de Productos Petrolíferos, S.A. C/ Méndez Álvaro, 44, 28045, Madrid.</p>
    <ol>
      <li>Email: <a href="mailto:sacportal@repsol.com" class="link--simple">sacportal@repsol.com</a></li>
      <li><a href="/contacto" class="link--simple">Apartado de Contacto del Sitio web</a></li>
    </ol>
    <p>
      Cualquier sugerencias, dudas, o cualquier tipo de incidencia, queja y/o reclamación relacionada
      con el suministro de los productos deberá dirigirse al Distribuidor correspondiente a través de
      los canales que éste tenga habilitados.
    </p>`,

  SERVICE_CODNITIONS_11_TITLE: 'LEY APLICABLE Y FUERO',
  SERVICE_CODNITIONS_11_TEXT: `<p>
      Las presentes Condiciones Particulares se regirán por la legislación española.
    </p>
    <p>
      Con renuncia a cualquier otro fuero que pudiera corresponderles,
      te sometes a la jurisdicción de los Tribunales de tu domicilio en España para
      la resolución de cualquier controversia que pudiera surgir en relación con el registro
      y uso del Servicio Online. Si no dispusieses de domicilio en España, serán competentes
      los Juzgados y Tribunales de Madrid capital.
    </p>`,

  HOME_SEO_TITLE: 'Pide gasoil a domicilio con Repsol desde casa | Repsol',
  HOME_SEO_DESCRIPTION:
    'Te ofrecemos nuestro mejor servicio de gasoil a domicilio, online y desde dónde elijas, para que siempre puedas acceder al mejor gasóleo calefacción.',
  FAQS_SEO_TITLE: '¿Tienes dudas del servicio de gasóleo a domicilio? | Repsol',
  FAQS_SEO_DESCRIPTION:
    'Resuelve aquí tus dudas sobre cómo pedir gasóleo a domicilio con Repsol y empieza a utilizar el servicio hoy mismo.',
  PROFILE_SEO_TITLE: 'Accede a tu perfil | Repsol',
  PROFILE_SEO_DESCRIPTION: 'Accede a tu perfil | Repsol',
  CONTACT_SEO_TITLE: 'Contacto de gasóleo a domicilio | Repsol',
  CONTACT_SEO_DESCRIPTION:
    '¿Tienes dudas y no encuentras respuesta en las preguntas frecuentes? Contacta con nosotros y te ayudaremos.',
  PRODUCTS_SEO_TITLE: 'Nuestros gasóleos calefacción | Repsol',
  PRODUCTS_SEO_DESCRIPTION:
    'Consigue un mayor ahorro energético y reduce costes de mantenimiento. Descubre nuestros gasóelos calefacción para tu hogar.',
  BUSINESS_SEO_TITLE: 'Descubre cómo pedir tu gasóleo a domicilio online  | Repsol ',
  BUSINESS_SEO_DESCRIPTION:
    'Nuestro objetivo es ofrecerte siempre el mejor gasóleo calefacción a domicilio. Un servicio rápido, eficiente y de calidad.',

  BUSINESSSERVICES_HEADER_TITLE: 'La energía para tu hogar y tu negocio agrícola es cómoda y sencilla. Es online',
  BUSINESSSERVICES_CLAIM_TITLE: 'Queremos estar más cerca de ti',
  BUSINESSSERVICES_CLAIM_CONTENT: `
    Estamos trabajando para ampliar la cobertura y hacer tu día a día un poco más fácil
    con un servicio de gasoil rápido, eficiente y de calidad.`,
  BUSINESSSERVICES_FEATURES_SECTION_TITLE: 'Tu pedido, a tu medida',
  BUSINESSSERVICES_FEATURES_0_TITLE: 'Diferentes opciones de entrega',
  BUSINESSSERVICES_FEATURES_0_TEXT: `
    Tu tiempo y comodidad es lo más importante para nosotros.
    Por eso, ponemos a tu disposición dos franjas horarias de entrega: mañana o tarde.
    En cada pedido, podrás elegir la que más te convenga.`,
  BUSINESSSERVICES_FEATURES_1_TITLE: 'Con pedido 24 horas, 365 días al año',
  BUSINESSSERVICES_FEATURES_1_TEXT: `
    Olvídate del reloj y el calendario para hacer tu pedido.
    Con el servicio online puedes hacerlo en cualquier momento del día.
    Nosotros nos encargaremos de que lo recibas el día que tú lo necesitas.`,
  BUSINESSSERVICES_FEATURES_2_TITLE: 'Y desde donde quieras',
  BUSINESSSERVICES_FEATURES_2_TEXT: `
    Solo necesitarás 5 minutos y tu móvil u ordenador.
    Hemos diseñado un proceso de pedido tan sencillo y rápido que puedes
    realizarlo en cualquier momento libre que tengas.`,
  BUSINESSSERVICES_ORDERSTEPS_SECTION_TITLE: '¿Cómo realizar un pedido?',
  BUSINESSSERVICES_STEPS_0_TITLE: 'Eliges tu horario de entrega y forma de pago',
  BUSINESSSERVICES_STEPS_1_TITLE: 'Completas tus datos personales',
  BUSINESSSERVICES_STEPS_2_TITLE: 'Revisas y confirmas los datos del pedido',

  // REQUEST LIMIT
  REQUEST_LIMIT_TITLE: 'Servicio bloqueado',
  REQUEST_LIMIT_DESCRIPTION:
    'Has superado el máximo de búsquedas para realizar tu pedido. Podrás volver a realizar una nueva búsqueda el {extraInfo}',
  REQUEST_LIMIT_BUTTON_LABEL: 'Entendido',
  SECTION_MAIN_SUMMARY: 'Entrega y forma de pago',
  SECTION_PAYOFF_SUMMARY: 'Personaliza el pedido según tus preferencias',
  'SECTION_MAIN_PERSONAL-DATA': 'Identifícate',
  'SECTION_PAYOFF_PERSONAL-DATA': 'Regístrate o inicia sesión para continuar tu pedido',
  SECTION_MAIN_RECAP: 'Confirma tu pedido',
  SECTION_PAYOFF_RECAP: 'Comprueba que todos los datos son correctos',
  SECTION_MAIN_COMPLETED: 'Pedido realizado',
  SECTION_PAYOFF_COMPLETED:
    'Hemos enviado una copia de tu pedido al distribuidor para que te lo entregue en la fecha y hora elegida.',
  'SECTION_MAIN_SERVICE-ASSESSMENT': 'Valora a tu distribuidor',
  'SECTION_PAYOFF_SERVICE-ASSESSMENT': 'Ayúdanos a mejorar la calidad de nuestro servicio',
  'SECTION_MAIN_SERVICE-ASSESSMENT-SENDED': 'Gracias por tu valoración',
  'SECTION_PAYOFF_SERVICE-ASSESSMENT-SENDED':
    'Queremos llevarte a casa el gasóleo con el mejor servicio. Con tu ayuda, lo haremos posible.',
  'SECTION_MAIN_SERVICE-ASSESSMENT-REJECTED': 'El usuario introducido no puede realizar la valoración',
  'SECTION_PAYOFF_SERVICE-ASSESSMENT-REJECTED': 'Para valorar, inicia sesión con el usuario que realizaste el pedido.',
  'SECTION_MAIN_SERVICE-ASSESSMENT-ALREADY-RATED': 'Ya has hecho la valoración',
  'SECTION_PAYOFF_SERVICE-ASSESSMENT-ALREADY-RATED':
    'Podrás volver a valorar al distribuidor con la entrega de tu próximo pedido.',
  'SECTION_MAIN_SERVICE-CONDITION': 'CLIENTES',
  'SECTION_PAYOFF_SERVICE-CONDITION':
    'CONDICIONES PARTICULARES DEL SERVICIO ONLINE DE INTERMEDIACIÓN A TRAVÉS DEL CANAL DE DISTRIBUCIÓN DE GASÓLEOS DE REPSOL',
  REQUEST_LIMIT_DAY_DESCRIPTION:
    'Has superado el máximo de búsquedas por día. Podrás volver a realizar una nueva búsqueda el {extraInfo}',

  ARIA_LABEL_DIRECTION: 'Indica tu dirección',
  ARIA_LABEL_POSTALCODE: 'Indica tu código postal',
  ARIA_LABEL_QUANTITY: 'Indica cuanto gasoleo necesitas',
  ARIA_LABEL_PROFILE: 'Mi perfil',
  ARIA_LABEL_SHOW_MENU: 'Abre el menú',
  ARIA_LABEL_HIDE_MENU: 'Cierra el menú',
  ARIA_LABEL_SHOW_INFO: 'Muestra las informaciones',
  ARIA_LABEL_EXTRAADDRESS: 'Indica detalles de dirección',
  ARIA_LABEL_PROMOTIONALCODE: 'Indica tu código promocional',

  IE_USER_TITLE: 'Para poder iniciar el proceso de pedido online de gasóleo, te recomendamos que:',
  IE_USER_MESSAGE: '- Actualices tu navegador a la última versión de Internet Explorer 11.',
  IE_USER_MESSAGE_2:
    '- Instales uno de los navegadores compatibles con el pedido online: Google Chrome, Firefox, Safari, Edge.',

  ORDER_LIMIT_TITLE: 'Has superado el límite de pedidos mensuales',
  ORDER_LIMIT_DESCRIPTION:
    'A través del servicio de pedido online puedes realizar un máximo de 5 pedidos al mes. El día {extraInfo} podrás realizar un nuevo pedido.',
  ORDER_LIMIT_BUTTON_LABEL: 'Entendido',

  MODIFIED_PROFILE_TITLE: 'Datos personales modificados',
  MODIFIED_PROFILE_DESCRIPTION: 'Estos son los datos que utilizaremos para tus próximos pedidos.',

  // - COGNITIVER MESSAGE
  COGNITIVE_MESSAGE_RESULTS: 'El precio puede aumentar, no esperes para realizar tu pedido',
  COGNITIVE_MESSAGE_RESUME: 'Pide ahora y garantiza el precio de tu pedido',

  // - PROMOTIONALCODE
  PROMOTIONALCODE_INFO_TEXT:
    'Un código promocional es una combinación de números y letras que supone un beneficio para ti.',
  PROMOTIONALCODE_INFO_TEXT2:
    'Si tienes un código promocional introdúcelo en el campo que encontrarás en el formulario de pedido de la página principal y, si es válido, obtendrás tu oferta especial.',
  PROMOTIONALCODE_INFO_TEXT3:
    'En caso de error al introducirlo en la casilla correspondiente, puedes corregirlo actualizando tu pedido.',
  PROMOTIONAL_CODE_VALID: 'Tu código promocional <strong>{promotionalCode}</strong> se ha aplicado correctamente.',
  PROMOTIONAL_CODE_VALID_NO_CODE: 'Tu código promocional se ha aplicado correctamente.',
  PROMOTIONAL_CODE_EXIST: 'El código promocional es valido.',
  PROMOTIONAL_CODE_ERROR_MODAL_TITLE_INVALID_VOUCHER: 'Ya has utilizado este código promocional',
  PROMOTIONAL_CODE_ERROR_MODAL_TEXT_INVALID_VOUCHER: 'Cada código se puede utilizar una sola vez.',
  PROMOTIONAL_CODE_ERROR_MODAL_HELP_INVALID_VOUCHER: `Si no has utilizado el código y el error continúa, llámanos a <strong>900150151</strong> o mándanos un correo electrónico
    a <a href="mailto:sacportal@repsol.com">sacportal@repsol.com</a>.
    Daremos respuesta a todas tus dudas de <strong>08:00</strong> a <strong>22:00</strong> de lunes a viernes y de <strong>08:00</strong> a <strong>14:00</strong> los sábados`,
  PROMOTIONAL_CODE_ERROR_BUTTON_ACCEPT: 'Continuar sin código',
  PROMOTIONAL_CODE_ERROR_BUTTON_REJECT: 'Volver al pedido',
  PROMOTIONAL_CODE_ERROR_MODAL_TITLE_NO_VOUCHER: 'El código promocional introducido no es válido',
  PROMOTIONAL_CODE_ERROR_MODAL_TEXT_NO_VOUCHER: 'Comprueba que has introducido el código correctamente.',
  PROMOTIONAL_CODE_ERROR_MODAL_HELP_NO_VOUCHER: `Si el error continúa, llámanos al <strong>900150151</strong> o mándanos un correo electrónico
    a <a href="mailto:sacportal@repsol.com">sacportal@repsol.com</a>.
    Daremos respuesta a todas tus dudas de <strong>08:00</strong> a <strong>22:00</strong> de lunes a viernes y de <strong>08:00</strong> a <strong>14:00</strong> los sábados`,

  // - CROSS_SELLING_BANNER
  CROSS_SELLING_BANNER_TITLE_1: 'Toda la energía que tu hogar necesita, en un mismo lugar',
  CROSS_SELLING_BANNER_DESCRIPTION_1:
    'Contrata ahora la luz y/o gas de tu hogar con Repsol y <strong>consigue hasta 9cts. de descuento</strong> en cada litro de carburante.',
  CROSS_SELLING_BANNER_ACTION_LABEL_1: 'Contratar',

  CROSS_SELLING_BANNER_TITLE_2: 'Servicio de mantenimiento Repsol Hogar',
  CROSS_SELLING_BANNER_DESCRIPTION_2: 'Nos encargamos del buen funcionamiento de tu caldera para tu tranquilidad.',
  CROSS_SELLING_BANNER_ACTION_LABEL_2: 'Contratar',

  AUTOCOMPLETED_UNAVAILABLE_RESULTS: 'No se ha encontrado tu dirección. Escríbela para continuar con el proceso.',

  // - COGNITIVE INFO LIST
  COGNITIVEINFOLIST_HIGHLIGHT_TEXT: '<span class="more">Más de</span><span>{quantity}</span> {text}',
  COGNITIVEINFOLIST_CLIENT_INFO_HEADER: 'clientes',
  COGNITIVEINFOLIST_CLIENT_INFO_TEXT: 'ya se benefician de la comodidad y facilidad del pedido online de gasóleo.',
  COGNITIVEINFOLIST_LITERS_INFO_HEADER: 'Millones de litros',
  COGNITIVEINFOLIST_LITERS_INFO_TEXT: 'entregados en España cada año',
  COGNITIVEINFOLIST_DEALERS_INFO_HEADER: 'distribuidores',
  COGNITIVEINFOLIST_DEALERS_INFO_TEXT: 'repartidos en 48 provincias para que siempre tengas disponible tu gasóleo.',
  COGNITIVE_LITERS_MESSAGE: '¡Más litros, más ahorro!',
  COGNITIVE_PAYMENT_MESSAGE:
    'Paga en el momento de la entrega a tu distribuidor con tu tarjeta sin cargos ni costes adicionales.',

  // - PROMOTION
  PROMOTION_TITLE: 'Hola', // `<span>Cheque Regalo de <strong>{quantity}€</strong></span>`,
  PROMOTION_SUBTITLE: `
    <p class="instructions-title">
      Válido de lunes a domingo.
    </p>
    <p class="instructions-description">
      Cheque canjeable por {quantity} € de regalo en carburante, compras en tiendas o lavado en las Estaciones de Servicio Repsol, Campsa y Petronor.
      Descuento aplicable en una única operación.
      Válido solo un cheque por operación.
      El empleado deberá pasar el cheque por el terminal Solred. Elemento promocional.
      Prohibida su venta al público. No se admitirán cheques deteriorados o en mal estado.
    </p>
    <p class="instructions-footer">
      Fecha de caducidad {endDate}
    </p>
  `,
  PROMOTION_WAYLET_TITLE: `Tu cheque de carburante <span>disponible en tu móvil</span>`,
  PROMOTION_WAYLET_SUBTITLE: `
    <h3>Canjéalo al pagar con <strong>Waylet</strong></h3>
    <p>Activa el cheque en la app introduciendo el código en el apartado Mis ofertas > Cupones.
    De esta forma, <strong>dispondrás del descuento directo la próxima vez que pagues con Repsol Waylet.</strong></p>
  `,
  PROMOTION_PASSBOOK: 'También puedes <strong>descargarlo</sgtrong>',

  // - EURO ALERT
  EURO_SEARCH_ALERT: 'Revisa los resultados, el importe final a pagar puede haber variado.',
  EURO_SEARCH_ALERT_LINK: 'Quiero saber más',

  // - DATE RESULT
  RESULT_DATE_LABEL: 'Fecha de entrega',

  // - PROMOCION MIN ERROR
  ORDER_PROMOTIONAL_ERROR_MINIMUM: 'Promoción válida para pedido de más de {quantity}',
  ORDER_SEARCH_TYPE_LITERS: 'litros',
  ORDER_SEARCH_TYPE_EUROS: 'euros',
  PROMOTIONAL_CODE_ERROR_MODAL_TITLE_INVALID_QUANTITY_VOUCHER:
    'El código promocional es válido a partir de una cantidad',
  PROMOTIONAL_CODE_ERROR_MODAL_TEXT_INVALID_QUANTITY_VOUCHER:
    'El código de un solo uso para pedidos a partir de 500 litros o 450 euros.',
  PROMOTIONAL_CODE_ERROR_MODAL_HELP_INVALID_QUANTITY_VOUCHER: `Si no has utilizado el código y el error continúa, llámanos a <strong>900150151</strong> o mándanos un correo electrónico
    a <a href="mailto:sacportal@repsol.com">sacportal@repsol.com</a>.
    Daremos respuesta a todas tus dudas de <strong>08:00</strong> a <strong>22:00</strong> de lunes a viernes y de <strong>08:00</strong> a <strong>14:00</strong> los sábados`,

  // - PRIVATE AREA
  USER_ACCOUNT_LABEL: 'Mi cuenta',
  USER_ORDERS_LABEL: 'Mis pedidos',

  // - ORDER HISTORY
  ORDER_HISTORY_HEADER_TITLE: 'Tus pedidos realizados',
  ORDER_HISTORY_HEADER_INFO: 'Más información sobre mis pedidos',
  ORDER_HISTORY_EXPECTED_DATE: 'Fecha prevista',
  ORDER_HISTORY_ORDER_NUMBER: 'nº pedido',
  ORDER_HISTORY_ORDER_STATE_INPROGRESS: 'Pedido en curso',
  ORDER_HISTORY_ORDER_STATE_DELIVERED: 'Pedido entregado',
  ORDER_HISTORY_ORDER_STATE_DELETED: 'Pedido cancelado',
  ORDER_HISTORY_ORDER_LABEL_PRODUCT: 'Producto',
  ORDER_HISTORY_ORDER_LABEL_AMOUNT: 'Cantidad',
  ORDER_HISTORY_ORDER_LABEL_DELIVERY_ADDRESS: 'Dirección de entrega',
  ORDER_HISTORY_ORDER_LABEL_POSTAL_CODE: 'Código postal',
  ORDER_HISTORY_ORDER_DISTRIBUTOR: 'Distribuidor de zona',
  ORDER_HISTORY_ORDER_DATA_AMOUNT: '{amount} l.',
  ORDER_HISTORY_ORDER_MODAL_DISTRIBUTOR_ASSIGNED: 'Distribuidor asignado a este pedido',
  ORDER_HISTORY_ORDER_MODAL_MAIL_LABEL: 'Email',
  ORDER_HISTORY_VOID_TITLE: 'Aún no has realizado ningún pedido',
  ORDER_HISTORY_VOID_MESSAGE:
    '<p>Realiza tu pedido de gasóleo ahora.</p><p>Podrás ver la información de tu pedido en esta página.</p>',
  ORDER_HISTORY_ACTION_REPEAT_ORDER: 'Vuelve a realizar este pedido',
  ORDER_HISTORY_ACTION_DELETE_ORDER: 'Cancelar pedido',
  ORDER_HISTORY_MODAL_TITLE: 'Información sobre mis pedidos',
  ORDER_HISTORY_MODAL_DESCRIPTION:
    'En esta sección tienes toda la información relativa a tus pedidos y el histórico de los mismos: entregados, en curso y realizados.',
  ORDER_HISTORY_HEADER_HIGHLIGHT_TITLE: 'Consulta tu último pedido',
  ORDER_HISTORY_HEADER_HIGHLIGHT_BUTTON_LABEL: 'Repetir pedido',
  ORDER_HISTORY_HEADER_HIGHLIGHT_BUTTON_LABEL_HOME: 'Repetir último pedido',
  ORDER_HISTORY_HEADER_HIGHLIGHT_SUBTITLE:
    'Busca de nuevo esta cantidad de gasóleo para tu dirección y consulta la oferta disponible',
  ORDER_HISTORY_FILTER_LABEL: 'Filtrar por',
  ORDER_HISTORY_FILTER_ALL: 'Todos los pedidos',
  ORDER_HISTORY_FILTER_ACTIVATED: 'Pedidos en curso',
  ORDER_HISTORY_FILTER_DELIVERED: 'Pedidos entregados',
  ORDER_HISTORY_FILTER_CANCELLED: 'Pedidos cancelados',
  ORDER_HISTORY_VOID_TITLE_BYFILTER: 'Aún no tienes ningún pedido con estas características',
  ORDER_HISTORY_VOID_MESSAGE_BYFILTER:
    '<p>Prueba a modificar los filtros seleccionados o realiza un nuevo pedido ahora.</p>',

  // - WAITING MESSAGE
  WAITING_MESSAGE: 'Espere por favor...',

  // - REPEAT ORDER
  ORDER_HISTORY_REPEAT_TITLE: '¿Quieres volver a hacer este pedido?',
  ORDER_HISTORY_REPEAT_DESCRIPTION:
    'Buscaremos disponibilidad y precio para la cantidad de gasóleo y dirección de entrega indicada.',
  ORDER_HISTORY_REPEAT_DIRECTION_TITLE: 'Dirección de entrega',
  ORDER_HISTORY_REPEAT_AMOUNT_TITLE: 'Cantidad de gasóleo',
  ORDER_HISTORY_REPEAT_AMOUNT_LABEL: 'Litros',
  ORDER_HISTORY_REPEAT_ACTION: 'Ver disponibilidad y precio',

  // USER UNREGISTER FORM
  USER_UNREGISTER_FORM_LABEL_TOOMUCHPUBLICITY:
    '<h4>Recibo demasiada publicidad en mi correo electrónico.</h4><p>Para no recibir más publicidad, promociones y ofertas, edita tus preferencias en tu perfil.</p>',
  USER_UNREGISTER_FORM_LABEL_NOTUSESERVICE:
    '<h4>No quiero utilizar más este servicio.</h4><p>¿Hay algo que no te ha gustado del servicio de pedido online? Por favor, cuéntanoslo para que podamos solucionarlo. Llámanos al 900150151 o mándanos un correo electrónico a sacportal@repsol.com</p>',
  USER_UNREGISTER_FORM_LABEL_OTHERREASON:
    '<h4>Quiero darme de baja por otros motivos.</h4><p>Tus motivos para darte de baja son importantes para nosotros. ¿Nos los cuentas?</p>',
  USER_UNREGISTER_FORM_REASON_TOOMUCHPUBLICITY: 'Recibo demasiada publicidad en mi correo electrónico.',
  USER_UNREGISTER_FORM_REASON_NOTUSESERVICE: 'No quiero utilizar más este servicio.',
  USER_UNREGISTER_FORM_REASON_OTHERREASON: 'Quiero darme de baja por otros motivos.',
  USER_UNREGISTER_FREETEXT_INFO: 'Máximo 500 caracteres',
  USER_UNREGISTER_FREETEXT_PLACEHOLDER: 'Escribe las razones de la baja (campo opcional)',
  USER_UNREGISTER_STATE_MESSAGE: `
      <h3 class="modal-userForm__title">Hemos dado de baja tu cuenta</h3>
      <p class="modal-userForm__description">Y tus datos personales han sido borrados de nuestra base de datos. Para realizar un pedido, tendrás que registrarte de nuevo.</p>`,

  // - LEGAS BASIS
  LEGAL_BASIS_LABEL: 'Consulta bases legales de la promoción',

  // SERVER CODE ERROR
  SERVER_CODE_ERROR: `Error: {code}`,

  // - RECOMMENDED OPTION
  RECOMMENDED_OPTION_TITLE: '¿Por qué te ofrecemos esta opción',
  RECOMMENDED_OPTION_DESCRIPTION:
    'Siempre te destacaremos la opción con las mejores características en relación a la suma de la mayor calidad del producto, la fecha de disponibilidad más cercana y el precio más bajo',

  // - PROMO LEGAL BASIS
  PROMO_LEGAL_BASIS:
    'Consulta las <a href="{url}" class="link--simple" target="_blank">bases notariales de la promoción</a>',

  // DELETE ORDER
  ORDER_HISTORY_DELETE_TITLE: 'Estás a punto de cancelar un pedido',
  ORDER_HISTORY_DELETE_DESCRIPTION:
    'Si cancelas este pedido lo notificaremos al distribuidor asignado a tu zona para que no realice la entrega a domicilio.',
  ORDER_HISTORY_DELETE_BUTTON_LABEL: 'Cancelar pedido',
  ORDER_HISTORY_DELETE_LINK_LABEL: 'Volver y mantener esta solicitud',
  ORDER_HISTORY_DELETE_ALERT: 'Al cancelar este pedido…..',
  ORDER_HISTORY_FREETEXT_PLACEHOLDER: 'Escribe las razones de la cancelación (campo obligatorio)',
  ORDER_HISTORY_DELETE_FREETEXT_INFO: 'Máximo 234 caracteres',
  ORDER_HISTORY_DELETE_STATE_MESSAGE: `
      <h3 class="modal-userForm__title">Tu pedido ha sido cancelado correctamente</h3>`,
  ORDER_HISTORY_FORM_FAILED_MESSAGE: `Has superado el número máximo de caracteres`,
  ORDER_HISTORY_DELETE_STATE_FAILED: `
      <h3 class="modal-userForm__title">Tu pedido no se ha podido cancelar</h3>`,
  ORDER_HISTORY_FORM_FAILED_REQUIRED_MESSAGE: 'El campo es obligatorio',

  // - TRAVEL CARD
  ORDER_SUMMARY_TRAVEL_CARD_TITLE: 'Nº Tarjeta Travel Club',
  ORDER_SUMMARY_PROMOTIONALCARD_ERROR: 'Introduce los siguientes 9 dígitos de tu tarjeta en este campo',
  ORDER_SUMMARY_PROMOTIONALCARDCONTROL_ERROR: 'Introduce el último dígito de tu tarjeta en este campo',

  // - PROMOTION SHARE
  SOCIAL_SHARE_BODY:
    '¿Quieres llevarte un cheque carburante por valor de 50€? Utiliza el código {code} al hacer tu pedido de gasóleo en',
  SOCIAL_SHARE_LINK: 'https://pidetugasoleo.repsol.es',
  MAILTO_SHARE_SUBJECT: '50€ en carburante te están esperando',
  MAILTO_SHARE_BODY:
    '¿Quieres llevarte un cheque carburante por valor de 50€? ¡Muy fácil! Tan solo tienes que entrar en https://pidetugasoleo.repsol.es y realizar tu pedido de gasóleo con el código {code}.',

  // - COOKIEBOT
  COOKIEBOT_SAVE_LABEL: 'GUARDAR',

  // - CAMPANA
  CAMPANA_TITLE: 'Pide gasoil a domicilio con Repsol desde casa | Repsol',
  CAMPANA_DESCRIPTION:
    'Te ofrecemos nuestro mejor servicio de gasoil a domicilio, online y desde dónde elijas, para que siempre puedas acceder al mejor gasóleo calefacción.',

  // - MANTEINANCE
  MAINTENANCE_TITLE:
    'Algunos de nuestros servicios no están funcionando correctamente ahora mismo. Por favor, inténtalo de nuevo más tarde.'
}
