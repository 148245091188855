<template>
  <component :is="tagName" :class="[
  'checkbox',
  isDisabled ? 'checkbox--disabled' : '',
  hasError ? 'checkbox__with-error' : '']">
    <input type="checkbox"
           :checked="isChecked"
           :id="id"
           :name="id"
           class="checkbox__input"
           @change="getValue($event.target.checked)"
    >
    <label class="checkbox__label" :for="id">
      <i :class="['checkbox__box', isChecked ? 'checkbox__box--checked' : '']"/>
      <!-- @slot Slot for label element-->
      <slot name="label">
        {{label}}
      </slot>
    </label>
    <span v-if="hasError" class="checkbox__error-message mt-xxs">
      <!-- @slot Slot for error message element-->
      <slot name="errorMessage"/>
    </span>
  </component>
</template>
<script>
export default {
  name: 'CheckBox',

  data() {
    return {
      values: []
    }
  },

  props: {
    tagName: {
      type: String,
      default: 'div'
    },
    id: {
      type: String,
      default: 'checkbox_id'
    },
    label: {
      type: String,
      default: ''
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getValue(e) {
      if (!this.isDisabled) {
        this.$emit('input', e)
      }
    }
  }
}
</script>
<style lang="scss" scoped src="./CheckBox.scss"></style>
