var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"user-session"},[_c('div',{staticClass:"user-session__wrap"},[_c('ul',{staticClass:"user-session__column"},[_c('li',{staticClass:"user-session__route"},[_c('TabsSwitch',{attrs:{"isComponent":_vm.component,"hasDestination":_vm.hasDestination,"hasTabs":[
            {
              'label': _vm.$t(`message.PERSONALDATA_LABEL_REGISTRATION`),
              'content': 'UserRegistration'
            },{
              'label': _vm.$t(`message.PERSONALDATA_LABEL_LOGIN`),
              'content': 'UserLogin'
            }
          ]},on:{"change":_vm.switchComponent}})],1),_c('li',{staticClass:"user-session__features"},[_c('FeatureList',{scopedSlots:_vm._u([{key:"check",fn:function(){return [_c('span',{staticClass:"features__icon"},[_c('IconComponent',{attrs:{"name":"IconCheck","size":"xsmall"}})],1),_c('p',[_vm._v(_vm._s(_vm.$t(`message.FEATURES_LIST_CHECK`)))])]},proxy:true},{key:"calendar",fn:function(){return [_c('span',{staticClass:"features__icon"},[_c('IconComponent',{attrs:{"name":"IconCalendar","size":"xsmall"}})],1),_c('p',[_vm._v(_vm._s(_vm.$t(`message.FEATURES_LIST_CALENDAR`)))])]},proxy:true},{key:"phone",fn:function(){return [_c('span',{staticClass:"features__icon"},[_c('IconComponent',{attrs:{"name":"IconSmartphone","size":"xsmall"}})],1),_c('p',[_vm._v(_vm._s(_vm.$t(`message.FEATURES_LIST_PHONE`)))])]},proxy:true}])})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }