<template>
  <section class="contact">
    <StaticsHeader
      :title="$t(`message.CONTACT_HEADER_TITLE`)"
      :backgroundImg="require(`images/jpg/content_header03_bg.jpg`)"/>
    <div class="transition">
      <div class="contact__content">
        <ContactInfo>
          <template #title>{{$t('message.CONTACT_SECTION_TITLE')}}</template>
          <template #description>
            <h3>{{$t('message.CONTACT_SECTION_INFO_TITLE')}}</h3>
            <p>{{$t('message.CONTACT_SECTION_INFO_ADDITIONAL')}}</p>
          </template>
          <template #action>
            <ButtonComponent
              :label="$t(`message.NAVIGATION_FAQ_LABEL`)"
              @click.native="gotoRoute()"
            ></ButtonComponent>
          </template>
          <template #mail>
            <a href="mailto:pidetugasoleo@repsol.com">
              <IconComponent name="IconContactMail" size="medium"/>
              <span v-html="$t(`message.CONTACT_SECTION_MAIL`, {mail: 'pidetugasoleo@repsol.com'})"></span>
            </a>
          </template>
        </ContactInfo>
      </div>

      <aside class="contact__extraInfo">
        <p class="contact__info__text">{{$t(`message.CONTACT_SECTION_EXTRAINFO_TITLE`)}}</p>
        <p class="contact__info__text-additional">{{$t(`message.CONTACT_SECTION_EXTRAINFO_ADDITIONAL`)}}</p>
      </aside>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Contact',
  methods: {
    gotoRoute () {
      this.$router.push({ name: 'faq' })
    }
  }
}
</script>
<style lang="scss" scoped src="./Contact.scss"></style>
