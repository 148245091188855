export const Errors = {
  PROVIDER_ERROR: 1001,
  RESULTS_ERROR: 1002,
  SERVER_ERROR: 1003,
  INVALID_POSTAL_CODE: 2002,
  INVALID_ORDER_INPUT: 2004,
  OFFER_NO_LONGER_EXISTS: 2006,
  OFFER_PRICE_CHANGED: 2007,
  NOT_ENOUGH_LITERS_IN_OFFER: 2008,
  INVALID_USER_SIGNATURE: 2009,
  NON_ACTIVE_USER: 2010,
  INVALID_RATING_INPUT: 2011,
  INVALID_ORDER_OWNER: 2012,
  ALREADY_RATED_ORDER: 2013,
  MAX_REQUESTS_LIMIT: 2014,
  DAILY_REQUESTS_LIMIT: 2015,
  INVALID_DRAFT_INPUT: 2017,
  NO_PENDING_ORDERS: 2018,
  NO_CURRENT_DRAFT: 2019,
  INVALID_OFFER_ID: 3001,
  INPUT_REQUIRED: 2001,
  MAX_CREATION_LIMIT: 2020,
  NO_VOUCHER: 2021,
  INVALID_VOUCHER_USED_BY_USER: 2022,
  INVALID_VOUCHER_MINIMUM_REQUIRED: 2023,
  CANCELLATION_REASON_MAX_LENGTH: 2024,
  CANCELLATION_REJECTED: 1005,
  ACTIVE_USER: 2025
}
