<template>
  <div id="wrap">
    <!-- START: TOP HEADER/MAIN MENU BLOCK -->
    <HeaderComponent
      :isMinimized="isMinimizedHeader"
      :isLogged="isLogged"
      :labelCookiesMessageBody="$t('message.COOKIES_TEXT')"
      :labelCookiesMessageLink="$t('message.COOKIES_POLICY_LINK')"
      urlCookiesMessageLink="https://www.repsol.es/particulares/politica-de-cookies/"
      :labelActiveProject="$t('message.PROJECT_ES')"
      :labelLoggedUserArea="`${$t('message.LOGIN_WELCOME')} ${this.user.firstName}`"
      :labelCloseSession="$t('message.NAVIGATION_LOGOUT_LABEL')"
      :labelUserArea="$t('message.NAVIGATION_USERAREA_LABEL')"
      :navigationLinks="[
        {text: this.$t('message.NAVIGATION_INDEX_LABEL'), url: {'name': 'home'}},
        {text: this.$t('message.NAVIGATION_BUSINESSSERVICES_LABEL'), url: {'name':'business-services'}},
        {text: this.$t('message.NAVIGATION_PRODUCTS_LABEL'), url: {'name':'products'}},
        {text: this.$t('message.NAVIGATION_FAQ_LABEL'), url: {'name':'faq'}}
      ]"
      :urlUserArea="`${this.profilePage}`"
      v-on:userLogin="userModalLogin()"
      v-on:closeSession="closeSession()">
      <template #logoImg>
        <img src="~@/assets/images/logo_repsol.png" alt="Repsol logo">
      </template>
    </HeaderComponent>
    <!-- END: TOP HEADER/MAIN MENU BLOCK -->

    <!-- START: APP MAIN CONTENT BLOCK -->
    <main class="main-wrap__view" ref="routerWrap">
      <ModalComponent v-if="modalDisplay"/>
      <transition name="router-vertical-anim">
        <router-view/>
      </transition>
    </main>
    <!-- END: APP MAIN CONTENT BLOCK -->

    <!-- START: FOOTER/EXTERNAL LINKS BLOCK -->
    <FooterComponent
      :footerTitles="footerTitles"
      :socialLinks="socialLinks"
      :outerLinks="outerLinks"
      :copyRightLabel="$t(`message.FOOTER_COPY`)"
      :isMinimized="isMinimizedFooter"
    />
    <!-- END: FOOTER/EXTERNAL LINKS BLOCK -->
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'root',
  data () {
    return {
      footerTitles: [
        { id: 1, name: this.$t(`message.FOOTER_LEGAL_NOTE`), link: 'https://www.repsol.com/es/pie-de-pagina/nota-legal/index.cshtml' },
        { id: 2, name: this.$t(`message.FOOTER_PRIVACY_POLICIES`), link: 'https://www.repsol.com/es/pie-de-pagina/politica-de-privacidad/index.cshtml' },
        { id: 3, name: this.$t(`message.FOOTER_PRIVACY_COOKIES`), link: 'https://www.repsol.es/particulares/politica-de-cookies/' },
        { id: 4, name: this.$t(`message.FOOTER_CONTACT`), interlink: 'contact' }
      ],
      socialLinks: [
        { label: 'facebook', url: 'https://www.facebook.com/repsol', icon: 'IconFacebook' },
        { label: 'twitter', url: 'https://twitter.com/repsol', icon: 'IconTwitter' },
        { label: 'instagram', url: 'https://www.instagram.com/repsol/', icon: 'IconInstagram' },
        { label: 'linkedin', url: 'https://www.linkedin.com/company/repsol/', icon: 'IconLinkedin' },
        { label: 'youtube', url: 'https://www.youtube.com/repsol', icon: 'IconYoutube' },
        { label: 'flickr', url: 'https://www.flickr.com/photos/repsol', icon: 'IconFlickr' }
      ],
      outerLinks: [
        {
          title: this.$t(`message.FOOTER_EXTERNAL_CLM01_TITLE`),
          links: [
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM01_LINK01_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM01_LINK01_URL`)}`
            },
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM01_LINK02_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM01_LINK02_URL`)}`
            },
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM01_LINK03_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM01_LINK03_URL`)}`
            },
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM01_LINK04_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM01_LINK04_URL`)}`
            }
          ]
        },
        {
          title: this.$t(`message.FOOTER_EXTERNAL_CLM02_TITLE`),
          links: [
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM02_LINK01_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM02_LINK01_URL`)}`
            },
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM02_LINK02_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM02_LINK02_URL`)}`
            },
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM02_LINK03_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM02_LINK03_URL`)}`
            },
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM02_LINK04_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM02_LINK04_URL`)}`
            }
          ]
        },
        {
          title: this.$t(`message.FOOTER_EXTERNAL_CLM03_TITLE`),
          links: [
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM03_LINK01_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM03_LINK01_URL`)}`
            },
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM03_LINK02_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM03_LINK02_URL`)}`
            },
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM03_LINK03_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM03_LINK03_URL`)}`
            },
            {
              label: this.$t(`message.FOOTER_EXTERNAL_CLM03_LINK04_LABEL`),
              url: `${this.$t(`message.COUNTRY_LINK`)}${this.$t(`message.FOOTER_EXTERNAL_CLM03_LINK04_URL`)}`
            }
          ]
        }
      ],
      minimizedArrayHeader: ['summary', 'personal-data', 'recap', 'completed', 'service-assessment', 'service-assessment-sended', 'service-assessment-rejected', 'service-assessment-already-rated'],
      minimizedArrayFooter: ['summary', 'personal-data', 'recap', 'completed']
    }
  },

  watch: {
    $route (to, from) {
      if (from.name !== 'user-account') this.$root.$emit('close')
    }
  },

  computed: {
    ...mapState(['modal', 'user']),
    ...mapState('cookies', ['cookiesPolicyAccepted']),

    isLogged () {
      return !window.Cypress ? this.user.isLoggedIn : true
    },

    profilePage () {
      return this.$router.resolve({ name: 'user-orders', query: { filterBy: 'ALL' } }).href
    },

    isMinimizedHeader () {
      return this.minimizedArrayHeader.includes(this.$route.name)
    },

    isMinimizedFooter () {
      return this.minimizedArrayFooter.includes(this.$route.name)
    },

    modalDisplay () {
      return this.modal.status
    }
  },

  methods: {
    ...mapActions('modal', ['setModalDisplay', 'setModalComponent']),
    ...mapActions('user', ['logout']),
    ...mapActions('cookies', ['acceptCookiesPolicy']),

    setCookie () {
      this.acceptCookiesPolicy()
    },

    userModalLogin () {
      const payload = {
        state: true,
        name: 'UserSession',
        size: 970,
        props: [
          { 'hasDestination': 'modal' }
        ]
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    closeSession () {
      this.logout()
    },

    getIEBrowser () {
      const userAgent = window.navigator.userAgent
      const msie = userAgent.indexOf('MSIE ')

      // get IE below 11
      return msie > 0
    },

    setBrowserMessage () {
      const payload = {
        state: true,
        name: 'ModalBasic',
        allowClose: true,
        size: 490,
        'slots': [
          { 'name': 'title', 'content': `<h2 class='modal-basic__title'>${this.$t(`message.IE_USER_TITLE`)}</h2>` },
          { 'name': 'description', 'content': `<div class='modal-basic__text'>${this.$t(`message.IE_USER_MESSAGE`)}</div>` },
          { 'name': 'description2', 'content': `<div class='modal-basic__text'>${this.$t(`message.IE_USER_MESSAGE_2`)}</div>` }
        ]
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    }
  },

  mounted () {
    if (this.getIEBrowser()) this.setBrowserMessage()
  }
}
</script>
<style lang="scss" scoped src="./MainWrap.scss"></style>
