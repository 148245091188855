<template>
  <section class="survey-result">
    <div class="survey-result__entry">
      <IconComponent name="IconCheck" size="big"></IconComponent>
      <h2 class="survey-result__title">{{text}}</h2>
      <ButtonComponent
        v-if="label"
        :label="label"
        @click.native="goto"
      ></ButtonComponent>
      <p
        v-if="contacts"
        class="survey-result__contact" v-html="contacts"></p>
    </div>
  </section>
</template>
<script>
export default {
  name: 'SurveyResult',
  data () {
    return {
      route: ''
    }
  },

  props: {
    text: {
      type: String,
      default: ''
    },

    label: {
      type: String
    },

    contacts: {
      type: String
    }
  },

  methods: {
    goto () {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
<style lang="scss" scoped src="./SurveyResult.scss"></style>
