import store from '../store/'
import { Constants } from '../constants'

const literForEuros = () => {
  const actualLiters = Math.floor(store.state.orders.order.amountInfo.filterField / store.state.orders.order.amountInfo.unitPrice)
  if (actualLiters > Constants.MAX_ACCEPTED) { return Constants.MAX_ACCEPTED } else
  if (actualLiters < Constants.MIN_ACCEPTED) { return Constants.MIN_ACCEPTED } else { return actualLiters }
}

export class Draft {
  static async set ({ UID, UIDSignature, signatureTimestamp }) {
    const {
      timeInfo: { time },
      paymentInfo: { payment },
      amountInfo: { ID, unitPrice, quantity, filter },
      addressInfo: { address, postalCode, extraAddress },
      additionalInfo: { info },
      voucher: { code, description, type: Type },
      card: { type, number, control }
    } = store.state.orders.order

    const voucherCode = (code !== '') ? code : undefined
    const voucherDescription = (description !== '') ? description : undefined
    const voucherType = (type !== '') ? Type : undefined
    const card = (number && number !== '') ? { type, number: `${Constants.PROMOTIONCARD_PREFIX}${number}${control}` } : undefined
    const additionalInfo = info || undefined

    const payload = {
      offerId: ID,
      address: address,
      additionalAddress: extraAddress,
      postalCode: postalCode.value,
      unitPrice: unitPrice,
      amount: filter === 'EUROS' ? literForEuros().toString() : quantity,
      filter: 'LITERS',
      timeSlot: time.toUpperCase(),
      paymentOption: payment.toUpperCase(),
      additionalInfo,
      voucher: {
        code: voucherCode,
        description: voucherDescription,
        type: voucherType
      },
      card
    }

    return store.dispatch('orders/setOrderDraft', { UID, UIDSignature, signatureTimestamp, ...payload })
  }
}
