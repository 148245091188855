// - path resolver by alias in vue.config.js
import Vue from 'vue'
import App from 'app/App.vue'
import 'app/components.js'
import router from 'app/router/index'
import store from 'app/store/index'

import appInsights from './appInsights'
import Vuelidate from 'vuelidate'
import vClickOutside from 'v-click-outside'
import VueScrollTo from 'vue-scrollto'
import Sticky from 'vue-sticky-directive'
import IdleVue from 'idle-vue'

import i18n from 'app/i18n'

import 'app/filters'
import Vuebar from 'vuebar'

import { SESSION_INACTIVE_TIMEOUT } from './services/gigya/constants.js'
import checkView from 'vue-check-view'

// Vue use
Vue.use(Vuelidate)
Vue.use(vClickOutside)
Vue.use(Vuebar)
Vue.use(VueScrollTo)
Vue.use(Sticky)
Vue.use(IdleVue, { store, idleTime: SESSION_INACTIVE_TIMEOUT, startAtIdle: false })
Vue.use(checkView)

Vue.config.productionTip = false

const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
  mounted() {
    this.$router.afterEach((to, from) => {
      appInsights.trackPageView({ name: to.name || to.path })
    })
  }
}).$mount('#app')

appInsights.trackPageView()

if (window.Cypress) {
  window.app = app
}
