<template>
  <section
    :class="[
      'user-orders',
      historyFiltered(queryURL) && !Array.isArray(historyFiltered(queryURL)) && historyFiltered(queryURL).code ? 'user-orders--isVoid' : null]">
    <!-- GET: HIGHTLIGHT: first element of delivered order -->
    <template v-if="
      historyFiltered(queryURL)
      && !historyFiltered(queryURL).code">
      <section
        v-if="getHighlight.length > 0"
        class="user-orders__section">
        <header class="user-orders__header">
          <h2 class="user-orders__header__title">
            {{$t(`message.ORDER_HISTORY_HEADER_HIGHLIGHT_TITLE`)}}
          </h2>
        </header>
        <ul
          class="user-orders__list"
          data-cy="highlight">
          <transition
            @before-enter="setMaxHeight(arguments)"
            @enter="setEnterDelay(arguments)"
            @leave="setLeaveDelay(arguments)"
            name="showResponse"
            appear>
            <li data-index="0">
               <OrderBox
                isHighlight
                :info="{
                  image: {
                    src: `png/logos/${getHighlight[0].order.productType[0].toUpperCase()}${getHighlight[0].order.productType.slice(1)}.png`,
                    alt: getHighlight[0].order.productType,
                    extraClass: ['--isPTG', `--is${getHighlight[0].order.productType[0].toUpperCase()}${getHighlight[0].order.productType.slice(1)}`]
                  },
                  content: {
                    tag: 'table',
                    rows: {
                      tag: 'tr',
                      content: [{
                            cells: [
                              { tag: 'th', text: 'Esto es lo que pediste la última vez:', extraClass: ['--isTitle', '--isFullWidth'] }
                            ]
                          },{
                            cells: [
                              { tag: 'th', text: $t(`message.ORDER_HISTORY_ORDER_LABEL_PRODUCT`) },
                              { tag: 'td', text: products[getHighlight[0].order.productType].fullName }
                            ]
                          },{
                            cells: [
                              { tag: 'th', text: $t(`message.ORDER_HISTORY_ORDER_LABEL_AMOUNT`) },
                              { tag: 'td', text: `${$t(`message.ORDER_HISTORY_ORDER_DATA_AMOUNT`, {amount: getHighlight[0].order.amount})}`, extraClass: ['--isAmount'] }
                            ]
                          },{
                            cells: [
                              { tag: 'th', text: $t(`message.ORDER_HISTORY_ORDER_LABEL_DELIVERY_ADDRESS`) },
                              { tag: 'td', text: getHighlight[0].order.address }
                            ]
                          },{
                            cells: [
                              { tag: 'th', text: $t(`message.ORDER_HISTORY_ORDER_LABEL_POSTAL_CODE`)},
                              { tag: 'td', text: getHighlight[0].order.postalCode }
                            ]
                          }
                        ]
                      }
                    }
                  }">

                  <!-- ACTION SLOT -->
                  <template #action>
                    <td class="order-box--hasUserAction">
                      <ButtonComponent
                        @click.native="repeatOrder(getHighlight[0].order)"
                        :label="$t('message.ORDER_HISTORY_HEADER_HIGHLIGHT_BUTTON_LABEL')"
                        data-cy="repeatOrder"/>
                    </td>
                  </template>
                </OrderBox>
              </li>
          </transition>
        </ul>
      </section>
    </template>
    <!-- GET: RESPONSE -->
    <section class="user-orders__section">
      <header class="user-orders__header">
        <h2 class="user-orders__header__title">
          {{$t(`message.ORDER_HISTORY_HEADER_TITLE`)}}
        </h2>
        <LinkButton
          @click.native="displayInfo({
            component: 'ModalBasic',
            size: 490,
            slots: [
              { name: 'title', content: `<h2 class='modal-basic__title'>${$t(`message.ORDER_HISTORY_MODAL_TITLE`)}</h2>` },
              { name: 'description', content: `<div class='modal-basic__text'>${$t(`message.ORDER_HISTORY_MODAL_DESCRIPTION`)}</div>` }
            ],
            props: {
              hasButton: [
                {
                  action: 'close',
                  label: $t(`message.REQUEST_LIMIT_BUTTON_LABEL`),
                  type: 'button'
                }
              ]
            }
          })"
          class="order-box__link"
          data-cy="history-modal-info">
          <template>{{$t(`message.ORDER_HISTORY_HEADER_INFO`)}}</template>
        </LinkButton>
      </header>

      <!-- FILTER BY:
        1. all
        2. in progress
        3. delivered
        4. deleted -->
      <template v-if="
        historyFiltered(queryURL)
        && Array.isArray(historyFiltered(queryURL))
        && !historyFiltered(queryURL).code">
        <div class="user-orders__filter">
          <form class="user-orders__filter__form">
            <label
              class="user-orders__filter__label"
              for="userOrdersFilter">{{$t(`message.ORDER_HISTORY_FILTER_LABEL`)}}</label>
            <div class="user-orders__filter__options">
              <select
                id="userOrdersFilter"
                @change="changeFilter($event)"
                data-cy="filters">
                <option
                  v-for="key in historyFilters"
                  :key="key.value"
                  :value="key.value"
                  :selected="key.value === queryURL">
                  {{$t(`message.ORDER_HISTORY_FILTER_${key.translate}`).toUpperCase()}}
                </option>
              </select>
            </div>
          </form>
        </div>
      </template>

      <!-- LOAD SPINNER -->
      <transition
        name="spinner"
        appear>
        <Spinner
          v-if="historyResults.length === 0"
          :message="$t(`message.WAITING_MESSAGE`)"/>
      </transition>
      <!-- END: LOAD SPINNER -->

      <!-- START: DISPLAY RESPONSE -->
      <transition-group
        @before-enter="setMaxHeight(arguments)"
        @enter="setEnterDelay(arguments)"
        @leave="setLeaveDelay(arguments)"
        tag="ul"
        class="user-orders__list"
        name="showResponse"
        appear>

        <!-- CASE > HAS RESULTS:
          1. historyFiltered is Array
          2. historyFiltered has length > 0 -->
        <template
          v-if="
            historyFiltered(queryURL)
            && Array.isArray(historyFiltered(queryURL))
            && historyFiltered(queryURL).length > 0">
            <template v-for="(result, index) in historyFiltered(queryURL)">
            <li
              :data-index="index"
              :key="`${result.order.id}`">
              <OrderBox
                :header="{
                  tag: 'dl',
                  children: [
                    {
                      tag: 'div',
                      children: getCellDate(result.order)
                    },
                    {
                      tag: 'div',
                      children: [
                        {
                          tag: 'dt',
                          content: $t(`message.ORDER_HISTORY_ORDER_NUMBER`)
                        },
                        {
                          tag: 'dd',
                          content: result.order.id,
                          extraClass: ['__desc']
                        }
                      ]
                    }
                  ]
                }"
                :info="{
                  extraClass: [result.order.status === 'CANCELLED' ? '--isDisabled' : null],
                  image: {
                    src: `png/logos/${result.order.productType[0].toUpperCase()}${result.order.productType.slice(1)}.png`,
                    alt: result.order.productType,
                    extraClass: ['--isPTG', `--is${result.order.productType[0].toUpperCase()}${result.order.productType.slice(1)}`]
                  },
                  content: {
                    tag: 'table',
                    rows: {
                      tag: 'tr',
                      content: [
                        {
                          cells: [
                            { tag: 'th', text: $t(`message.ORDER_HISTORY_ORDER_LABEL_PRODUCT`) },
                            { tag: 'td', text: products[result.order.productType].fullName }
                          ]
                        },{
                          cells: [
                            { tag: 'th', text: $t(`message.ORDER_HISTORY_ORDER_LABEL_AMOUNT`) },
                            { tag: 'td', text: `${$t(`message.ORDER_HISTORY_ORDER_DATA_AMOUNT`, {amount: result.order.amount})} <span class='unitPrice'>${$t(`message.RESULT_UNIT_PRICE_LABEL`, {price: result.order.unitPrice.toString().replace('.', ',')})}</span>`, extraClass: ['--isAmount'] }
                          ]
                        },{
                          cells: [
                            { tag: 'th', text: $t(`message.ORDER_HISTORY_ORDER_LABEL_DELIVERY_ADDRESS`) },
                            { tag: 'td', text: result.order.address }
                          ]
                        },{
                          cells: [
                            { tag: 'th', text: $t(`message.ORDER_HISTORY_ORDER_LABEL_POSTAL_CODE`)},
                            { tag: 'td', text: result.order.postalCode }
                          ]
                        },{
                          cells: getCellDistributor(result)
                        }
                      ]
                    }
                  }
                }"
                :state="{
                  type: result.order.status ? state[result.order.status].type : null,
                  label: result.order.status ? state[result.order.status].label : null
                }"
                @showModal="displayInfo({
                  component: 'ModalDistributor',
                  size: 680,
                  slots: [ { name: 'title', content: `${$t(`message.ORDER_HISTORY_ORDER_MODAL_DISTRIBUTOR_ASSIGNED`)}` }],
                  props: {
                    image: {
                      src: 'png/truck_Energy.png',
                      alt: result.provider.name
                    },
                    provider: {
                      name: result.provider.name,
                      details: [
                        {
                          label: $t(`message.RESUME_STORE_USER_PHONE`),
                          data: result.provider.phone || null
                        },
                        {
                          label: $t(`message.ORDER_HISTORY_ORDER_MODAL_MAIL_LABEL`),
                          data: result.provider.email ? `<a class='link--simple' href='mailto:${result.provider.email}'>${result.provider.email}</a>` : null
                        }
                      ]
                    },
                    hasButton: [
                      {
                        action: 'close',
                        label: $t(`message.REQUEST_LIMIT_BUTTON_LABEL`),
                        type: 'button'
                      }
                    ]
                  }
                })">

                <!-- PRICE SLOS -->
                <template
                  v-if="result.order"
                  #price>
                    <td
                      class="order-box--hasTotalPrice"
                      v-html="$t(`message.RESULT_TOTAL_PRICE`, {totalPrice: getTotalPrice(result.order.total.toFixed(2))})"></td>
                </template>

                <!-- ACTION SLOT -->
                <template
                  v-if="
                    state[result.order.status]
                    && state[result.order.status].action
                    && state[result.order.status].action.emit"
                  #action>
                  <td class="order-box--hasUserAction">
                      <LinkButton
                        @click.native="handleAction(state[result.order.status].action.emit, result.order)"
                        data-cy="order-user-action">
                        <template>{{ state[result.order.status].action.text }}</template>
                      </LinkButton>
                  </td>
                </template>
              </OrderBox>
            </li>
          </template>
        </template>

        <!-- CASE > NO HAS RESULTS:
          1. historyFiltered is Array
          2. historyFiltered has length === 0 -->
        <template>
          <li
            key="void"
            :data-index="0"
            v-if="
              firstLoad
              && historyFiltered(queryURL)
              && Array.isArray(historyFiltered(queryURL))
              && historyFiltered(queryURL).length === 0">
            <OrderBox
              isVoid>
              <template #isVoidTitle>
                {{queryURL === 'ALL' ? $t(`message.ORDER_HISTORY_VOID_TITLE`): $t(`message.ORDER_HISTORY_VOID_TITLE_BYFILTER`)}}
              </template>
              <template #isVoidMessage>
                {{queryURL === 'ALL' ? $t(`message.ORDER_HISTORY_VOID_MESSAGE`): $t(`message.ORDER_HISTORY_VOID_MESSAGE_BYFILTER`)}}
              </template>
              <template #isVoidAction>
                <ButtonComponent
                  :label="$t(`message.ORDER_SEARCH_TITLE_START`)"
                  @click.native="$router.push({ name: 'home' })"/>
              </template>
            </OrderBox>
          </li>
        </template>

        <!-- CASE > ERROR:
          1. historyFiltered is Object
          2. historyFiltered contain code key -->
        <template v-if="
          historyFiltered(queryURL)
          && !Array.isArray(historyFiltered(queryURL))
          && historyResults.code">
          <li
            key="error"
            :data-index="0">
            <OrderBox
              isVoid>
              <template #isVoidTitle>
                {{$t(`message.ORDER_HISTORY_VOID_TITLE`)}}
              </template>
              <template #isVoidMessage>
                {{$t(`message.ORDER_HISTORY_VOID_MESSAGE`)}}
              </template>
              <template #isVoidAction>
                <ButtonComponent
                  :label="$t(`message.ORDER_SEARCH_TITLE_START`)"
                  @click.native="$router.push({ name: 'home' })"/>
              </template>
            </OrderBox>
          </li>
        </template>
      </transition-group>
    </section>
    <!-- END: RESPONSE ELEMENTS -->
  </section>
</template>
<script>
import Vue from 'vue'
import { GigyaService } from '../../services/gigya'
import i18n from '../../i18n'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'UserOrders',

  data: () => {
    return {
      delay: 0.4,
      firstLoad: false,
      state: {
        DELIVERED: {
          type: 'delivered',
          label: i18n.t(`message.ORDER_HISTORY_ORDER_STATE_DELIVERED`),
          action: {
            text: i18n.t(`message.ORDER_HISTORY_ACTION_REPEAT_ORDER`),
            emit: 'repeatOrder'
          }
        },
        ACTIVATED: {
          type: 'inProgress',
          label: i18n.t(`message.ORDER_HISTORY_ORDER_STATE_INPROGRESS`),
          action: {
            text: i18n.t(`message.ORDER_HISTORY_ACTION_DELETE_ORDER`),
            emit: 'deleteOrder'
          }
        },
        CANCELLED: {
          type: 'deleted',
          label: i18n.t(`message.ORDER_HISTORY_ORDER_STATE_DELETED`)
        }
      },

      products: {
        Energy: {
          fullName: 'Energy e+'
        },
        BiEnergy: {
          fullName: 'Bi Energy 10 e+'
        },
        GasoilCExcellent: {
          fullName: 'Gasoil C Excel·lent'
        },
        GasoilBExcellent: {
          fullName: 'Gasoil B Excel·lent'
        },
        AgroDiesel: {
          fullName: 'AgroDiésel e+ 10'
        }
      },

      filter: {
        action: 'ALL'
      }
    }
  },

  watch: {
    actionFilter () {
      this.updateURL(this.actionFilter)
    },

    deleteReason () {
      const { props } = this.modal.component
      props[0].isDisabled = this.deleteReason === ''
    }
  },

  computed: {
    ...mapState([
      'orders',
      'modal'
    ]),

    ...mapGetters({
      historyFiltered: 'orders/getHistoryFiltered'
    }),

    historyFilters () {
      return this.orders.history.filters
    },

    historyResults () {
      return this.orders.history.response
    },

    actionFilter () {
      return this.filter.action
    },

    queryURL () {
      return this.$route.query.filterBy
    },

    getHighlight () {
      return this.historyFiltered('DELIVERED') || []
    },

    deleteReason () {
      return this.orders.order.deletedReason
    }
  },

  methods: {
    ...mapActions('modal', [
      'setModalDisplay',
      'setModalComponent',
      'setModalProps'
    ]),

    ...mapActions('orders', [
      'getPrices',
      'getOrderHistory',
      'setHistory',
      'setDeleteReason',
      'setFilterField',
      'setLockState',
      'availableResponse',
      'setVoucherValidation',
      'setVoucherDescription',
      'setVoucher',
      'setOrderFilter'
    ]),
    ...mapActions('analytics', ['pushAvailabilityEvent']),

    getDate (date) {
      return `${Vue.filter('capitalize')(Vue.filter('formatDay')(date))} ${this.$t(`message.RESUME_DATE_ARTICLE`)} ${Vue.filter('capitalize')(Vue.filter('formatMonth')(date))} ${this.$t(`message.RESUME_DATE_ARTICLE`)} ${Vue.filter('capitalize')(Vue.filter('formatYear')(date))}`
    },

    getTotalPrice (price) {
      const split = price.split('.')
      return `${split[0]},<span class="decimals">${split[1]}€</span>`
    },

    displayInfo (toDisplay = {
      name: 'ModalBasic',
      size: 490,
      slots: [],
      props: {}
    }) {
      const payload = {
        state: true,
        name: toDisplay.component,
        allowClose: false,
        size: toDisplay.size,
        slots: toDisplay.slots,
        props: toDisplay.props
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    setLoader () {
      const payload = {
        state: true,
        name: 'ModalLoader',
        background: 'none',
        allowClose: true,
        props: {
          hasSpinner: {
            name: 'Spinner',
            props: {
              message: this.$t(`message.WAITING_MESSAGE`),
              color: 'fff'
            }
          }
        }
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    setMaxHeight (args) {
      if (args[0]) {
        this.$nextTick(() => {
          let componentStyles = args[0].style
          componentStyles.setProperty('--max-height', `${args[0].children[0].offsetHeight}px`)
        })
      }
    },

    setEnterDelay (args) {
      if (args[0]) {
        this.$nextTick(() => {
          let childrenStyle = args[0].style
          childrenStyle.setProperty('--animation-delay', `${this.delay * args[0].dataset.index + 1}s`)
        })
      }
    },

    setLeaveDelay (args) {
      if (args[0]) {
        this.$nextTick(() => {
          let childrenStyle = args[0].style
          childrenStyle.setProperty('--animation-delay', `${this.delay * args[0].dataset.index / 2}s`)
        })
      }
    },

    async getOrderHistoryRequest ({ UID, UIDSignature, signatureTimestamp }) {
      const orders = await this.getOrderHistory({ UID, UIDSignature, signatureTimestamp })

      this.setHistory(orders)
      this.firstLoad = true
    },

    getCellDate (order) {
      const check = order && order.status !== 'CANCELLED'
      if (check) return [{ tag: 'dt', content: this.$t(`message.ORDER_HISTORY_EXPECTED_DATE`) }, { tag: 'dd', content: this.getDate(order.deliveryDate), extraClass: ['__desc'] }]
    },

    getCellDistributor (result) {
      const check = result.order && result.order.status !== 'CANCELLED'
      if (check) return [{ tag: 'th', text: this.$t(`message.ORDER_HISTORY_ORDER_DISTRIBUTOR`), extraClass: ['__head'] }, { tag: 'td', extraClass: ['__desc'], extraComponent: { is: 'LinkButton', text: result.provider.name, action: 'showModal' } }]
    },

    updateURL (filter) {
      if (this.$route.query.filterBy) this.$router.push({ name: 'user-orders', query: { filterBy: filter } })
    },

    repeatOrder (result) {
      this.pushAvailabilityEvent({
        action: 'repetir pedido',
        label: result.productType,
        category: 'navegacion'
      })

      const payload = {
        state: true,
        name: 'ModalOrderRepeat',
        allowClose: false,
        size: 630,
        slots: [
          { name: 'title', content: `<h2 class='modal-order-repeat__title'>${this.$t(`message.ORDER_HISTORY_REPEAT_TITLE`)}</h2>` },
          { name: 'description', content: `<div class='modal-order-repeat__description'>${this.$t(`message.ORDER_HISTORY_REPEAT_DESCRIPTION`)}</div>` }
        ],
        props: {
          hasResume: [
            {
              title: this.$t('message.ORDER_HISTORY_REPEAT_DIRECTION_TITLE'),
              table: [
                {
                  label: this.$t('message.RESUME_STORE_ADDRESS'),
                  value: result.address
                },
                {
                  label: this.$t('message.RESUME_STORE_POSTALCODE'),
                  value: result.postalCode
                }
              ]
            },
            {
              title: this.$t('message.ORDER_HISTORY_REPEAT_AMOUNT_TITLE'),
              table: [
                {
                  label: this.$t('message.ORDER_HISTORY_REPEAT_AMOUNT_LABEL'),
                  value: this.$t('message.RESUME_STORE_QUANTITY_TYPE', { ltr: result.amount })
                }
              ]
            }
          ],
          hasButton: [
            {
              action: 'repeatOrder',
              label: i18n.t(`message.ORDER_HISTORY_REPEAT_ACTION`),
              type: 'button',
              params: result,
              isLoading: false
            }
          ]
        }
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    handleAction (fn, params) {
      return this[fn](params)
    },

    deleteOrder (result) {
      this.setDeleteReason({ extraText: '' })
      const payload = {
        component: 'ModalUserForm',
        size: 770,
        slots: [
          { name: 'title', content: this.$t('message.ORDER_HISTORY_DELETE_TITLE') },
          { name: 'description', content: this.$t('message.ORDER_HISTORY_DELETE_DESCRIPTION') }
        ],
        props: [
          {
            submit: false,
            isDisabled: true,
            submitMessage: this.$t('message.ORDER_HISTORY_DELETE_STATE_MESSAGE'),

            hasForm: {
              freeText: {
                state: true,
                label: this.$t('message.ORDER_HISTORY_DELETE_FREETEXT_INFO'),
                placeholder: this.$t('message.ORDER_HISTORY_FREETEXT_PLACEHOLDER'),
                validationError: false,
                isEditable: true,
                maxlength: 234
              }
            },

            hasButton: [
              {
                action: 'deleteOrder',
                label: i18n.t(`message.ORDER_HISTORY_DELETE_BUTTON_LABEL`),
                type: 'button',
                params: result
              },

              {
                action: 'close',
                label: i18n.t(`message.ORDER_HISTORY_DELETE_LINK_LABEL`),
                type: 'link'
              }
            ]
          }
        ]
      }

      this.displayInfo(payload)
    },

    changeExtraText (res) {
      const { props } = this.modal.component
      props[0].hasForm.freeText.validationError = false
      this.setDeleteReason({ extraText: res })
      this.setModalProps({ props: props })
    },

    getUserAccountOrders () {
      GigyaService.getAccountInfo().then(event => {
        this.getOrderHistoryRequest(event)
      })
    },

    changeFilter (payload) {
      this.filter.action = payload.target.value
    }
  },

  mounted () {
    if (!this.$route.query.filterBy) this.$router.push({ name: 'user-orders', query: { filterBy: this.filter.action } })
    this.getUserAccountOrders()
    this.$root.$on('changeExtraText', this.changeExtraText)
    this.$root.$on('reloadHistoryOrders', this.getUserAccountOrders)
  }
}
</script>
<style lang="scss" scoped src="./UserOrders.scss"></style>
