export const session = store => {
  store.subscribe((_mutation, state) => {
    const {
      idleVue: { isIdle },
      user: { isLoggedIn }
    } = state

    if (isLoggedIn && isIdle) {
      store.dispatch('user/logout')
    }
  })
}
