<template>
  <section class="service-condition">
    <SectionTitle
      :main="$t(`message.SECTION_MAIN_SERVICE-CONDITION`)"
      :payoff="$t(`message.SECTION_PAYOFF_SERVICE-CONDITION`)"
    ></SectionTitle>
    <Terms
      :articles="articles"
      class="transition"
    ></Terms>

  </section>
</template>
<script>
export default {
  name: 'serviceCondition',

  data () {
    return {
      articles: [
        {
          text: this.$t(`message.SERVICE_CODNITIONS_1_TEXT`)
        }, {
          title: this.$t(`message.SERVICE_CODNITIONS_2_TITLE`),
          text: this.$t(`message.SERVICE_CODNITIONS_2_TEXT`)
        }, {
          title: this.$t(`message.SERVICE_CODNITIONS_3_TITLE`),
          text: this.$t(`message.SERVICE_CODNITIONS_3_TEXT`)
        }, {
          title: this.$t(`message.SERVICE_CODNITIONS_4_TITLE`),
          text: this.$t(`message.SERVICE_CODNITIONS_4_TEXT`)
        }, {
          title: this.$t(`message.SERVICE_CODNITIONS_5_TITLE`),
          text: this.$t(`message.SERVICE_CODNITIONS_5_TEXT`)
        }, {
          title: this.$t(`message.SERVICE_CODNITIONS_6_TITLE`),
          text: this.$t(`message.SERVICE_CODNITIONS_6_TEXT`)
        }, {
          title: this.$t(`message.SERVICE_CODNITIONS_7_TITLE`),
          text: this.$t(`message.SERVICE_CODNITIONS_7_TEXT`)
        }, {
          title: this.$t(`message.SERVICE_CODNITIONS_8_TITLE`),
          text: this.$t(`message.SERVICE_CODNITIONS_8_TEXT`)
        }, {
          title: this.$t(`message.SERVICE_CODNITIONS_9_TITLE`),
          text: this.$t(`message.SERVICE_CODNITIONS__TEXT`)
        }, {
          title: this.$t(`message.SERVICE_CODNITIONS_10_TITLE`),
          text: this.$t(`message.SERVICE_CODNITIONS_10_TEXT`)
        }, {
          title: this.$t(`message.SERVICE_CODNITIONS_11_TITLE`),
          text: this.$t(`message.SERVICE_CODNITIONS_11_TEXT`)
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped src="./ServiceCondition.scss"></style>
