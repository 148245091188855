<template>
  <aside
    class="spinner">
    <img :src="require(`images/png/spinner-50.png`)">
    <span
      class="spinner__label"
      :style="[this.color ? {'color' : `#${this.color}`} : null]"
      >{{message}}</span>
  </aside>
</template>
<script>
export default {
  name: 'Spinner',

  props: {
    message: {
      type: String,
      default: 'Wait please...'
    },

    color: { type: String }
  }
}
</script>
<style lang="scss" scoped src="./Spinner.scss"></style>
