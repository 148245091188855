import axios from 'axios'

/**
 * HTTP Instance. Create as many instances
 * as you wish.
 * Also set here all your axios instance options.
 */
export const http = axios.create({
  baseURL: `${process.env.BASE_URL}${process.env.VUE_APP_SMARTCAL_API_URL_PATH}`,
  timeout: 30000
})
