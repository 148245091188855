<template>
  <section class="password-reset">
    <div class="password-reset__wrap">
      <div id="password-reset-container"></div>
      <LinkButton @click.native="goToHome">
        <template>{{ $t(`message.BUTTON_BACK_2`) }}</template>
      </LinkButton>
    </div>
  </section>
</template>

<script>
import { GigyaService } from '../../services/gigya'

export default {
  name: 'PasswordReset',

  methods: {
    loadContent () {
      GigyaService.showResetPassword('password-reset-container')
    },

    goToHome () {
      this.$router.push({ name: 'home' })
    }
  },

  mounted () {
    this.loadContent()
  }
}
</script>

<style lang="scss" scoped src="./PasswordReset.scss"></style>
