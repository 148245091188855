<template>
  <section class="faq">
    <StaticsHeader
      :title="$t(`message.FAQS_HEADER_TITLE`)"
      :backgroundImg="require(`images/jpg/content_header03_bg.jpg`)"/>
      <div class="faq__content transition">
          <nav class="faq__navigation">
              <div class="faq__navigation__title">{{$t(`message.STATICS_SECTIONS`)}}</div>
              <ul class="faq__navigation__list">
                  <li v-for="(section, idx) in sectionConfigs"
                      :key="`faq_section_${idx}`"
                      :class="[
                        'faq__navigation__element',
                        activeSection === idx ? 'faq__navigation__element--active' : ''
                        ]"
                      @click="() => setActiveSection(idx)"
                  >{{$t(`message.FAQS_${section.translationTagCode}_LINK`)}}</li>
              </ul>
          </nav>
          <div class="faq__section__container">
              <transition-group name="faq__section">
                  <template v-for="(section, idx) in sectionConfigs">
                      <FaqSection
                              v-if="activeSection === idx"
                              :key="'faq__section' + idx"
                              :translationTagCode="section.translationTagCode"
                              :quantity="section.quantity">
                          <template slot="title" slot-scope="{translationTag}">{{$t(`message.${translationTag}`)}}</template>
                          <template slot="question" slot-scope="{translationTag}">{{$t(`message.${translationTag}`)}}</template>
                          <template slot="answer" slot-scope="{translationTag}"><div v-html="$t(`message.${translationTag}`)"></div></template>
                      </FaqSection>
                  </template>
              </transition-group>
          </div>
      </div>
  </section>
</template>

<script>
export default {
  name: 'Faq',
  data () {
    return {
      activeSection: 0,
      sectionConfigs: [
        {
          translationTagCode: 'ORDER',
          quantity: 20
        }, {
          translationTagCode: 'DISTRIBUTOR',
          quantity: 3
        }, {
          translationTagCode: 'PRODUCT',
          quantity: 7
        }, {
          translationTagCode: 'SAC',
          quantity: 8
        }
      ]
    }
  },
  methods: {
    setActiveSection (section) {
      this.activeSection = section
    }
  }
}
</script>
<style lang="scss" scoped src="./Faq.scss"></style>
