<template>
  <section
    :class="
      [isHighlight ? 'order-box--isHighlight' : null,
      'order-box']">
    <h2
      v-if="this.$slots['title']"
      v-html="getContent('title')"
      class="order-box__title order-box--isTitle"></h2>

    <!-- **** HAS CONTENT ***** -->
    <article
      v-if="!isVoid"
      class="order-box__wrap">
        <!-- HEADER:
        custom cells -->
        <div
          v-if="header"
          class="order-box__header">
          <component
            :is="header.tag || 'dl'"
            class="order-box__list">
            <!-- DEFAULT HEADER TEXT: no childrens exist -->
            <template v-if="!getChildExist(header, 'children')">{{header.content}}</template>

            <!-- HEADER NODES: childrens exist -->
            <template
              v-for="(elem, index) in header.children"
              v-else>
              <component
                :is="elem.tag || 'div'"
                :key="`${elem}-${index}`"
                v-if="elem.children"
                class="order-box__cell">

                <!-- DEFAULT CONTENT TEXT: no childrens exist -->
                <template v-if="!getChildExist(elem, 'children')">{{elem.content}}</template>

                <!-- CONTENT NODES: childrens exist -->
                <component
                  :is="cell.tag"
                  v-for="(cell, index) in elem.children"
                  v-else
                  :key="`${elem}-${index}_${cell}-${index}`"
                  :class="[cell.extraClass ? getExtraClass({classes: cell.extraClass}) : null]">
                  {{cell.content}}
                </component>
              </component>
            </template>
          </component>
          <!-- STATE LABEL
            1. order in progress
            2. order delivered
          -->
          <span
            v-if="state"
            :class="[
              'order-box__state',
              state.type ? `order-box__state--${state.type}` : null]">
            {{state.label}}
          </span>
        </div>

        <div
          v-if="info"
          :class="[
            info.extraClass ? !info.extraClass.includes(null) ? getExtraClass({classes: info.extraClass}) : '' : null,
            'order-box__info']">

          <!-- IMAGE
            1. PTG = logo
            2. BOMBONA = bombona
          -->
          <div
            v-if="info.image"
            class="order-box__info__image">
            <figure :class="info.image.extraClass ? getExtraClass({classes: info.image.extraClass}) : null">
              <img
                :src="require(`../../assets/images/${info.image.src}`)"
                :alt="info.image.alt" />
            </figure>
          </div>

          <!--  TABLE INFO:
           1. loop on row
           2. loop on cells -->
          <component
            v-if="info && info.content"
            :is="info.content.tag"
            class="order-box__table">
            <template v-if="info.content.rows">
              <component
                :is="info.content.rows.tag"
                :key="`${info.content.rows.tag}-${index}`"
                v-for="(row, index) in info.content.rows.content"
                :class="[
                  row.extraClass ? getExtraClass({classes: row.extraClass}) : null,
                  'order-box__table__row']">
                <template
                  v-for="(cell, index) in row.cells">
                  <component
                    v-if="cell"
                    :is="cell.tag"
                    :class="[
                      index === 0 ? 'order-box__head' : 'order-box__desc',
                      cell.extraClass ? getExtraClass({classes: cell.extraClass}) : null]"
                    :key="`${cell.tag}-${index}`">
                    <!-- SIMPLE LABEL TEXT -->
                    <p
                      v-if="!cell.extraComponent"
                      v-html="cell.text"></p>

                    <!-- COMPLEX CONTENT TYPE
                      1. Anchor link
                      2. Button link
                    -->
                    <component
                      v-else
                      :is="cell.extraComponent.is"
                      v-bind="cell.extraComponent.props"
                      @click.native="emitClick(cell.extraComponent.action)">
                      <template>{{ cell.extraComponent.text }}</template>
                    </component>
                  </component>
                </template>
              </component>

              <!-- PRICE SLOT:
               1. custom html price
               2. custom html action -->
               <tr
                v-if="this.$slots['price'] || this.$slots['action']"
                class="order-box--hasPrice order-box__table__row">
                <slot
                  v-if="this.$slots['price']"
                  name="price" />
                <slot
                  v-if="this.$slots['action']"
                  name="action" />
               </tr>
            </template>
          </component>
        </div>
    </article>

    <!-- **** WITHOUT CONTENT **** -->
    <aside
      class="order-box--isVoid"
      v-else>
      <h3
        class="order-box__void-title"
        v-if="this.$slots['isVoidTitle']"
        v-html="getContent('isVoidTitle')">
      </h3>
      <div
        class="order-box__void-message"
        v-if="this.$slots['isVoidMessage']"
        v-html="getContent('isVoidMessage')">
      </div>
      <div
        class="order-box__void-action"
        v-if="this.$slots['isVoidAction']">
        <slot name="isVoidAction" />
      </div>
    </aside>
  </section>
</template>
<script>
export default {
  name: 'OrderBox',

  props: {
    header: {
      type: Object,
      default: () => {}
    },

    info: {
      type: Object,
      default: () => {}
    },

    // ORDER STATE:
    // 1. inProgress
    // 2. delivered
    state: {
      type: Object,
      default: () => {}
    },

    isVoid: {
      type: Boolean,
      default: false
    },

    isHighlight: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getChildExist (obj, prop) {
      return obj.hasOwnProperty(prop)
    },

    getExtraClass (props) {
      let addedClasses = ''
      let prefix = props.prefix || 'order-box'
      if (props.classes) {
        for (let extraClass of props.classes) {
          addedClasses = `${addedClasses} ${prefix}${extraClass}`
        }
      }
      return addedClasses.trim() || null
    },

    emitClick (action) {
      this.$emit(action)
    },

    getContent (name) {
      return this.$slots[name][0]['text']
    }
  }
}
</script>
<style lang="scss" scoped src="./OrderBox.scss"></style>
