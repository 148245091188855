export class Prices {
  static filterList (res, amount) {
    if (res) {
      return res
        .map(node => {
          const products = node.products
          node.products = products.filter(product =>
            product.liters >= Number(amount)
          )
          return node
        })
        .filter(node => {
          return node.products && node.products.length !== 0
        })
    }
  }
}
