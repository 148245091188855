<template>
    <section class="terms">
        <article
                v-for= "(article, idx) in articles"
                :key="`terms-${idx}`"
                class = "terms__article"
        >
            <h3 v-if="!!article.title" class="terms__title">{{article.title}}</h3>
            <div v-html="article.text"
                 class="terms__text"
            ></div>
        </article>
    </section>
</template>

<script>
export default {
  name: 'Terms',
  props: {
    articles: {
      type: Array,
      default: [{
        title: 'Hey! I\'m a title',
        text: 'Hey! I\'m a text',
      }, {
        title: 'Hey! I\'m other title',
        text: 'Hey! I\'m other text',
      }]
    }
  }
}
</script>

<style lang="scss" scoped src="./Terms.scss"></style>
