export const GTM_ID = process.env.VUE_APP_GTM_ID

const Environments = {
  PRO: 'prod',
  PRE: 'pre',
  DEV: 'dev'
}

export const ENV = Environments[process.env.VUE_APP_GTM_ENV] || Environments.DEV

export const Events = {
  EVENT: 'eventoGA',
  GIGYA: 'evento Gigya',
  VIRTUAL_PAGE: 'virtualPage',
  ENHANCED_ECOMMERCE_VIEW: 'eec.checkout',
  ENHANCED_ECOMMERCE_OPTION: 'eec.checkout_option',
  ENHANCED_ECOMMERCE_PURCHASE: 'eec.purchase'
}

export const StepNames = {
  1: 'Entrega y Forma de Pago',
  2: 'Vista datos Personales',
  3: 'Vista confirmación pedido'
}
