<template>
  <article class="order-info">
    <h2 class="order-info__title"><slot name="title" /></h2>
    <div class="order-info__nodes">
      <slot name="content" />
      <slot name="extraDeliveryInfo" />
      <slot name="paymentCard" />
    </div>
  </article>
</template>
<script>
export default {
  name: 'OrderResumeInfo'
}
</script>
<style lang="scss" scoped src="./OrderResumeInfo.scss"></style>
