import { render, staticRenderFns } from "./ModalUserForm.vue?vue&type=template&id=8be12e26&scoped=true"
import script from "./ModalUserForm.vue?vue&type=script&lang=js"
export * from "./ModalUserForm.vue?vue&type=script&lang=js"
import style0 from "./ModalUserForm.scss?vue&type=style&index=0&id=8be12e26&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8be12e26",
  null
  
)

export default component.exports