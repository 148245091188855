<template>
  <div class="header-notification" v-if="isVisible">
    <!-- @slot Slot for notification content html -->
    <slot/>
    <button class="link--simple hide-notification"
            @click="acceptCookies"
    >{{acceptCookiesLabel}}</button>
  </div>
</template>
<script>

export default {
  name: 'HeaderNotification',
  methods: {
    acceptCookies: function() {
      this.$emit('acceptCookies')
    }
  },
  props: {
    isVisible: {
      type: Boolean,
      default: true
    },
    acceptCookiesLabel: {
      type: String,
      default: 'Aceptar'
    }
  }
}
</script>
<style lang="scss" scoped src="./HeaderNotification.scss"></style>
