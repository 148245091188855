<template>
  <a
    class="action-share"
    :href="action"
    :target="isMail ? '_self' : '_blank'"
    :title="title">
    <slot name="content" />
  </a>
</template>
<script>
export default {
  name: 'PromotionBox',
  props: {
    action: { type: String },
    backgroundColor: { type: String },
    customColor: { type: String },
    title: {
      type: String,
      default: 'Share'
    },
    isMail: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    let style = this.$el.style
    this.$nextTick(() => {
      if (this.backgroundColor) style.setProperty('--background-color', this.backgroundColor)
      if (this.customColor) style.setProperty('--icon-color', this.customColor)
    })
  }
}
</script>
<style lang="scss" scoped src="./ActionShare.scss"></style>
