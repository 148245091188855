<template>
  <aside class="promotion-box">
    <h5 class="promotion-box__title" v-if="$slots['title']">
      <slot name="title" />
    </h5>

    <p v-if="$slots['message']" class="promotion-box__message">
      <slot name="message" />
    </p>

    <div v-if="$slots['code']" class="promotion-box__code">
      <slot name="code" />
    </div>

    <h6 class="promotion-box__actions-title" v-if="$slots['actions-share']">
      <slot name="actions-title" />
    </h6>
    <slot v-if="$slots['actions-share']" name="actions-share" />
  </aside>
</template>
<script>
export default {
  name: 'PromotionBox'
}
</script>
<style lang="scss" scoped src="./PromotionBox.scss"></style>
