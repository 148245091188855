<template>
<table class="product-comparative">
  <thead>
      <tr class="product-comparative__row"
        v-sticky="tableData.isSticky"
        sticky-side="top">
          <th class="product-comparative__cell"></th>
          <th
            v-for="(heading, key) in tableData.head"
            :key="`product-comparative__${key}`"
            class="product-comparative__cell"
          >
              <img v-if="heading.image" :src="require(`images/png/${heading.image}.png`)" :alt="heading.productName">
              <span class="product-comparative__cell__product-name">{{heading.productName}}</span>
          </th>
      </tr>
  </thead>
  <tbody>
      <tr
        v-for="(content, key) in tableData.body"
        :key="key"
        class="product-comparative__row"
      >
          <td class="product-comparative__cell">{{content.label}}</td>
          <td class="product-comparative__cell">
            <IconComponent
              v-if="typeof content.product_A === 'boolean' && content.product_A"
              name="IconStepCheck"
              size="xsmall"
              class="product-comparative__cell__check"/>
            <span v-if="typeof content.product_A === 'string'"
                  class="product-comparative__cell__value">{{content.product_A}}</span>
          </td>
          <td class="product-comparative__cell">
              <IconComponent
                v-if="typeof content.product_B === 'boolean' && content.product_B"
                name="IconStepCheck"
                size="xsmall"
                class="product-comparative__cell__check"/>
              <span v-if="typeof content.product_B === 'string'"
                    class="product-comparative__cell__value">{{content.product_B}}</span>
          </td>
      </tr>
  </tbody>
</table>
</template>
<script>
export default {
  name: 'ProductComparative',

  props: {
    tableData: {
      type: Object
    }
  }
}
</script>
<style lang="scss" scoped src="./ProductComparative.scss"></style>
