<template>
  <section :class="[`app__state--${$route.name}`, 'app']">
    <div class="app__wrap">
      <!-- START: HEADER BLOCK -->
      <section :class="[
        'app__header',
        isFirstTime ? 'isFirstTime' : null,
        getUrlPromo
      ]">
        <transition name="router-vertical-anim">
          <router-view name="header" :mode="$route.name"></router-view>
        </transition>
      </section>
      <!-- END: HEADER BLOCK -->

      <!-- START: FUNNEL TITLE BLOCK -->
      <transition @before-enter="
        setExpandHeight(arguments, {
          variable: '--expand-title-height',
          queryElement: '.section-title__item'
        })
        " name="expandHeight">
        <aside ref="expandTitle" class="app__title" v-if="sectionTitleExist">
          <SectionTitle :main="mainTitle" :payoff="payoff" @startAnimation="startAnimation"></SectionTitle>
        </aside>
      </transition>
      <!-- END: FUNNEL TITLE BLOCK -->

      <!-- START: COGNITIVE INFO LIST -->
      <transition v-on:before-enter="
        setExpandHeight(arguments, {
          variable: '--expand-cognitive-height',
          queryElement: '.cognitive-list'
        })
        " name="expandHeight">
        <aside class="app__cognitive" v-if="$route.name === 'home'">
          <div class="cognitive-list">
            <CognitiveInfo v-for="(item, index) in cognitiveMessages" :key="index" :data-position="index"
              :data-computed="item.computed" :data-value="item.value" :data-increase="item.increase"
              v-view.once="viewHandler">
              <template #icon>
                <IconComponent :name="item.icon.type" :size="item.icon.size" />
              </template>
              <template #highlight>
                {{
                  $t(`message.COGNITIVEINFOLIST_HIGHLIGHT_TEXT`, {
                    quantity: getComputed(item),
                    text: item.type
                  })
                }}
              </template>
              <template #message>
                {{ item.message.text }}
              </template>
            </CognitiveInfo>
          </div>
        </aside>
      </transition>
      <!-- END: COGNITIVE INFO LIST -->

      <!-- START: APP MAIN CONTAIN BLOCK -->
      <transition name="minimized">
        <section class="app__main" v-if="mainComponent">
          <transition name="router-vertical-anim" appear>
            <router-view></router-view>
          </transition>
        </section>
      </transition>
      <!-- END: APP MAIN CONTAIN BLOCK -->
    </div>
  </section>
</template>
<script>
import anime from 'animejs'
import { setTimeout } from 'timers'
import { Utilities } from '../../helpers/utilities'

export default {
  name: 'Campana',
  data() {
    return {
      route: '',
      isFirstTime: true,
      showModalConfirmations: true,
      modal: {
        header: '',
        body: this.$t(`message.MODAL_INFO_OPTIONS_BODY`),
        footer: ''
      },
      sectionTitles: [
        {
          route: 'summary',
          main: this.$t(`message.SECTION_MAIN_SUMMARY`),
          payoff: this.$t(`message.SECTION_PAYOFF_SUMMARY`)
        },
        {
          route: 'personal-data',
          main: this.$t(`message.SECTION_MAIN_PERSONAL-DATA`),
          payoff: this.$t(`message.SECTION_PAYOFF_PERSONAL-DATA`)
        },
        {
          route: 'recap',
          main: this.$t(`message.SECTION_MAIN_RECAP`),
          payoff: this.$t(`message.SECTION_PAYOFF_RECAP`)
        },
        {
          route: 'completed',
          main: this.$t(`message.SECTION_MAIN_COMPLETED`),
          payoff: this.$t(`message.SECTION_PAYOFF_COMPLETED`)
        },
        {
          route: 'service-assessment',
          main: this.$t(`message.SECTION_MAIN_SERVICE-ASSESSMENT`),
          payoff: this.$t(`message.SECTION_PAYOFF_SERVICE-ASSESSMENT`)
        },
        {
          route: 'service-assessment-sended',
          main: this.$t(`message.SECTION_MAIN_SERVICE-ASSESSMENT-SENDED`)
        },
        {
          route: 'service-assessment-rejected',
          main: this.$t(`message.SECTION_MAIN_SERVICE-ASSESSMENT-REJECTED`)
        },
        {
          route: 'service-assessment-already-rated',
          main: this.$t(`message.SECTION_MAIN_SERVICE-ASSESSMENT-ALREADY-RATED`)
        }
      ],
      cognitiveMessages: [
        {
          computed: 'clients',
          value: 10000,
          increase: 100,
          type: this.$t(`message.COGNITIVEINFOLIST_CLIENT_INFO_HEADER`),
          icon: {
            type: 'IconCustomer',
            size: 'medium'
          },
          message: {
            text: this.$t(`message.COGNITIVEINFOLIST_CLIENT_INFO_TEXT`)
          }
        },
        {
          computed: 'liters',
          value: 2.3,
          type: this.$t(`message.COGNITIVEINFOLIST_LITERS_INFO_HEADER`),
          increase: 1,
          icon: {
            type: 'IconMonitor',
            size: 'medium'
          },
          message: {
            text: this.$t(`message.COGNITIVEINFOLIST_LITERS_INFO_TEXT`)
          }
        },
        {
          computed: 'dealers',
          value: 180,
          type: this.$t(`message.COGNITIVEINFOLIST_DEALERS_INFO_HEADER`),
          increase: 10,
          icon: {
            type: 'IconTruck',
            size: 'medium'
          },
          message: {
            text: this.$t(`message.COGNITIVEINFOLIST_DEALERS_INFO_TEXT`)
          }
        }
      ],
      clients: 0,
      liters: 0,
      dealers: 0
    }
  },

  watch: {
    $route(to, from) {
      this.isFirstTime = false
    }
  },

  computed: {
    components() {
      const matched = this.$route.matched
      const currentComponents = matched[matched.length - 1]
      return currentComponents.components
    },

    mainComponent() {
      return this.components.default
    },

    sectionTitleExist() {
      return this.sectionTitles.find(obj => obj.route === this.$route.name)
    },

    mainTitle() {
      return this.sectionTitleExist ? this.sectionTitleExist.main : ''
    },

    payoff() {
      return this.sectionTitleExist ? this.sectionTitleExist.payoff : ''
    },

    intClients() {
      return Math.trunc(this.clients).toLocaleString()
    },

    intLiters() {
      return Math.trunc(this.liters).toLocaleString()
    },

    intDealers() {
      return Math.trunc(this.dealers).toLocaleString()
    },

    cognitiveDelay() {
      if (window.innerWidth < 768) {
        return 0
      } else {
        return 250
      }
    },

    getUrlPromo() {
      if (this.$route.query.promo) {
        switch (this.$route.query.promo.toUpperCase()) {
          case 'TRAVELCLUB':
            return 'travelclub'
          case 'AGRODIESEL':
            return 'agrodiesel'
          default:
        }
      }
    }
  },

  methods: {
    setExpandHeight(args, props) {
      let bodyStyles = document.body.style
      if (args[0]) {
        this.$nextTick(() => {
          bodyStyles.setProperty(
            props.variable,
            getComputedStyle(args[0].querySelector(props.queryElement)).height
          )
        })
      }
    },

    startAnimation() {
      this.setExpandHeight([this.$refs.expandTitle], {
        variable: '--expand-title-height',
        queryElement: '.section-title__item'
      })
    },

    visibilityChanged(isVisible, entry, obj) {
      if (isVisible || !Utilities.isIE11()) {
        anime({
          targets: this.$data,
          [obj.variable]: obj.value,
          round: obj.increase,
          delay: 400,
          easing: 'easeInCubic',

          complete: function (anim) {
            entry.target.classList.add('complete')
          }
        })
      }
    },

    getComputed(item) {
      if (Utilities.isIE11()) {
        return item.value
      } else {
        if (item.computed === 'liters') {
          return item.value
        }

        return this[`int${item.computed.charAt(0).toUpperCase()}${item.computed.slice(1)}`]
      }
    },

    viewHandler(e) {
      const index = e.target.element.dataset.position
      const element = e.target.element
      const computed = e.target.element.dataset.computed
      const value = e.target.element.dataset.value
      const increase = e.target.element.dataset.increase
      let inview = e.type === 'enter'

      if (inview) {
        setTimeout(() => {
          anime({
            targets: this.$data,
            [computed]: value,
            round: increase,
            easing: 'easeInCubic',

            complete: function (anim) {
              element.classList.add('complete')
            }
          })
        }, this.cognitiveDelay * Number(index))
      }
    },

    goToURL(url) {
      var newTab = window.open(url, '_blank')
      newTab.focus()
    },
    closeQuestionModal() {
      this.showModalConfirmations = false
    }
  },

  mounted() {
    this.$nextTick(() => this.startAnimation())
  }
}
</script>
<style lang="scss" scoped src="./Campana.scss"></style>
