<template>
  <aside class="modal-loader">
    <template v-if="hasSpinner">
      <component
        :is="hasSpinner.name"
        v-bind="hasSpinner.props" />
    </template>
  </aside>
</template>
<script>
export default {
  name: 'ModalLoader',
  props: {
    hasSpinner: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="scss" scoped src="./ModalLoader.scss"></style>
