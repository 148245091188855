import Vue from 'vue'
import Vuex from 'vuex'

// - Modules
// - path resolver by alias in vue.config.js
import { user } from 'modules/user'
import { orders } from 'modules/orders'
import { modal } from 'modules/modal'
import { assessment } from 'modules/assessment'
import { cookies } from 'modules/cookies'
import { analytics } from 'modules/analytics'
import { i18n } from 'modules/i18n'
import { atlas } from 'modules/atlas'
import { status } from 'modules/status'

import { session } from 'plugins/session'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    orders,
    modal,
    assessment,
    cookies,
    analytics,
    i18n,
    atlas,
    status
  },
  plugins: [session]
})
