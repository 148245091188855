<template>
  <header class="provider-data">
    <h4
      class="provider-data__title"
      v-if="$slots['title']"
    ><slot name="title" /></h4>
    <figure class="provider-data__image">
      <slot
        v-if="$slots['image']"
        name="image" />
      <figcaption>
        <h3
          class="provider-data__name"
          v-if="$slots['name']"
        ><slot name="name" /></h3>
        <span
          class="provider-data__info"
          v-if="hasPhone"
          v-html="hasPhone"></span>
        <span
          class="provider-data__info"
          v-if="hasMail"
          v-html="hasMail"></span>
      </figcaption>
    </figure>
  </header>
</template>
<script>
export default {
  name: 'providerData',

  props: {
    hasPhone: {
      type: String
    },

    hasMail: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped src="./ProviderData.scss"></style>
