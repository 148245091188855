import VueI18n from 'app/i18n'
import * as moment from 'moment/moment'

export const i18n = {
  namespaced: true,

  state: {
    locale: localStorage.getItem('lang') || 'es',
    languages: [
      { id: 'es', text: 'Español' },
      { id: 'en', text: 'English' }
    ]
  },

  mutations: {
    setLocale: (state, locale) => {
      state.locale = locale
    }
  },
  getters: {
    languages (state) {
      return state.languages
    }
  },
  actions: {
    changeLocale: ({ commit }, locale) => {
      moment.locale(locale)
      VueI18n.locale = locale
      commit('setLocale', locale)
      localStorage.setItem('lang', locale)
    }
  }
}
