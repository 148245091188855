import { ENV, Events, StepNames } from './constants'

const dataLayer = window.dataLayer || []
const GTM_ID = process.env.VUE_APP_GTM_ID

const DIMENSIONS_TO_FLUSH = {
  disponibilidad: undefined,
  cp: undefined,
  producto: undefined,
  tipo_busqueda: undefined,
  franjaHoraria: undefined,
  section1: undefined,
  section2: undefined,
  section3: undefined,
  section4: undefined,
  section5: undefined,
  language: undefined,
  pageName: undefined,
  url_real: undefined,
  hostname_real: undefined,
  userId: undefined,
  service: undefined,
  login: undefined
}

export class GtmService {
  static init () {
    window.dataLayer = dataLayer
    dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    })
    const gtm = document.createElement('script')
    const gtmScript = document.createTextNode(
      "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
        "new Date().getTime(),event:'gtm.js'});var\n" +
        'f=d.getElementsByTagName(s)[0],\n' +
        "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.setAttributeNode(d.createAttribute('data-ot-ignore'));j.async=true;j.src=\n" +
        "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
        `})(window,document,'script','dataLayer','${GTM_ID}');`
    )
    gtm.appendChild(gtmScript)
    document.head.appendChild(gtm)
  }

  static pushVirtualPage ({
    path,
    pageName,
    userId = '',
    gigyaServices = [],
    isPrivateArea = false,
    language
  }) {
    const splitPath = path.split('/').filter((e) => e !== '')
    GtmService.push({
      environment: `${ENV}`,

      // These dimensions must be hardcoded and also
      // be the same for all the virtual page events.
      section1: 'productos-y-servicios',
      section2: 'gasoleo',
      section3: 'ptg',
      section4: splitPath[0],
      section5: splitPath[1],
      section6: splitPath[2],

      userId,
      login: GtmService.getLogin(userId),
      content_type: !isPrivateArea ? 'abierto' : 'privado',

      language,

      event: Events.VIRTUAL_PAGE,
      pageName: pageName || `/es/productos-y-servicios/gasoleo/ptg${path}`,
      url_real: window.location.href,
      service: userId ? gigyaServices.join(',') : '',
      hostname_real: window.location.origin
    })
  }

  static pushEnhancedEcommerceView ({ userId, stepNumber, product }) {
    GtmService.push({
      userId,
      login: GtmService.getLogin(userId),
      event: Events.ENHANCED_ECOMMERCE_VIEW,
      eventCat: 'ecommerce',
      eventAct: `checkout-${stepNumber}`,
      eventLbl: StepNames[stepNumber],
      ecommerce: {
        currencyCode: 'EUR',
        checkout: {
          actionField: { step: stepNumber },
          products: [product]
        }
      }
    })
  }

  static pushEnhancedEcommerceOption ({ userId, stepNumber, option, extraParams }) {
    GtmService.push({
      userId,
      login: GtmService.getLogin(userId),
      event: Events.ENHANCED_ECOMMERCE_OPTION,
      eventCat: 'ecommerce',
      eventAct: `checkout-${stepNumber}-option`,
      eventLbl: option,
      ecommerce: {
        checkout_option: {
          actionField: {
            step: stepNumber,
            option
          }
        }
      },
      ...extraParams
    })
  }

  static pushTransaction ({
    userId,
    orderId,
    totalPrice,
    timeSlot,
    providerName,
    product,
    searchType
  }) {
    GtmService.push({
      userId,
      login: GtmService.getLogin(userId),
      event: Events.ENHANCED_ECOMMERCE_PURCHASE,
      eventCat: 'ecommerce',
      eventAct: 'purchase',
      eventLbl: orderId,
      eventVal: totalPrice,
      franjaHoraria: timeSlot,
      tipo_busqueda: searchType,
      ecommerce: {
        purchase: {
          actionField: {
            id: orderId,
            affiliation: providerName,
            revenue: totalPrice
          },
          products: [product]
        }
      }
    })
  }

  static pushEvent ({ userId, category, action, label, extraParams }) {
    GtmService.push({
      userId,
      login: GtmService.getLogin(userId),
      event: Events.EVENT,
      eventCat: category,
      eventAct: action,
      eventLbl: label,
      ...extraParams
    })
  }

  static pushGigyaEvent ({ userId, category, action, label, extraParams }) {
    GtmService.push({
      userId,
      login: GtmService.getLogin(userId),
      event: Events.GIGYA,
      eventCat: category,
      eventAct: action,
      eventLbl: label,
      ...extraParams
    })
  }

  static push (params) {
    dataLayer.push({
      // Flush custom dimensions
      ...DIMENSIONS_TO_FLUSH,
      ...params
    })
  }

  static getLogin (userId) {
    return userId ? 'logged' : 'notlogged'
  }
}
