var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[
    'user-orders',
    _vm.historyFiltered(_vm.queryURL) && !Array.isArray(_vm.historyFiltered(_vm.queryURL)) && _vm.historyFiltered(_vm.queryURL).code ? 'user-orders--isVoid' : null]},[(
    _vm.historyFiltered(_vm.queryURL)
    && !_vm.historyFiltered(_vm.queryURL).code)?[(_vm.getHighlight.length > 0)?_c('section',{staticClass:"user-orders__section"},[_c('header',{staticClass:"user-orders__header"},[_c('h2',{staticClass:"user-orders__header__title"},[_vm._v(" "+_vm._s(_vm.$t(`message.ORDER_HISTORY_HEADER_HIGHLIGHT_TITLE`))+" ")])]),_c('ul',{staticClass:"user-orders__list",attrs:{"data-cy":"highlight"}},[_c('transition',{attrs:{"name":"showResponse","appear":""},on:{"before-enter":function($event){return _vm.setMaxHeight(arguments)},"enter":function($event){return _vm.setEnterDelay(arguments)},"leave":function($event){return _vm.setLeaveDelay(arguments)}}},[_c('li',{attrs:{"data-index":"0"}},[_c('OrderBox',{attrs:{"isHighlight":"","info":{
                image: {
                  src: `png/logos/${_vm.getHighlight[0].order.productType[0].toUpperCase()}${_vm.getHighlight[0].order.productType.slice(1)}.png`,
                  alt: _vm.getHighlight[0].order.productType,
                  extraClass: ['--isPTG', `--is${_vm.getHighlight[0].order.productType[0].toUpperCase()}${_vm.getHighlight[0].order.productType.slice(1)}`]
                },
                content: {
                  tag: 'table',
                  rows: {
                    tag: 'tr',
                    content: [{
                          cells: [
                            { tag: 'th', text: 'Esto es lo que pediste la última vez:', extraClass: ['--isTitle', '--isFullWidth'] }
                          ]
                        },{
                          cells: [
                            { tag: 'th', text: _vm.$t(`message.ORDER_HISTORY_ORDER_LABEL_PRODUCT`) },
                            { tag: 'td', text: _vm.products[_vm.getHighlight[0].order.productType].fullName }
                          ]
                        },{
                          cells: [
                            { tag: 'th', text: _vm.$t(`message.ORDER_HISTORY_ORDER_LABEL_AMOUNT`) },
                            { tag: 'td', text: `${_vm.$t(`message.ORDER_HISTORY_ORDER_DATA_AMOUNT`, {amount: _vm.getHighlight[0].order.amount})}`, extraClass: ['--isAmount'] }
                          ]
                        },{
                          cells: [
                            { tag: 'th', text: _vm.$t(`message.ORDER_HISTORY_ORDER_LABEL_DELIVERY_ADDRESS`) },
                            { tag: 'td', text: _vm.getHighlight[0].order.address }
                          ]
                        },{
                          cells: [
                            { tag: 'th', text: _vm.$t(`message.ORDER_HISTORY_ORDER_LABEL_POSTAL_CODE`)},
                            { tag: 'td', text: _vm.getHighlight[0].order.postalCode }
                          ]
                        }
                      ]
                    }
                  }
                }},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('td',{staticClass:"order-box--hasUserAction"},[_c('ButtonComponent',{attrs:{"label":_vm.$t('message.ORDER_HISTORY_HEADER_HIGHLIGHT_BUTTON_LABEL'),"data-cy":"repeatOrder"},nativeOn:{"click":function($event){return _vm.repeatOrder(_vm.getHighlight[0].order)}}})],1)]},proxy:true}],null,false,2272244161)})],1)])],1)]):_vm._e()]:_vm._e(),_c('section',{staticClass:"user-orders__section"},[_c('header',{staticClass:"user-orders__header"},[_c('h2',{staticClass:"user-orders__header__title"},[_vm._v(" "+_vm._s(_vm.$t(`message.ORDER_HISTORY_HEADER_TITLE`))+" ")]),_c('LinkButton',{staticClass:"order-box__link",attrs:{"data-cy":"history-modal-info"},nativeOn:{"click":function($event){_vm.displayInfo({
          component: 'ModalBasic',
          size: 490,
          slots: [
            { name: 'title', content: `<h2 class='modal-basic__title'>${_vm.$t(`message.ORDER_HISTORY_MODAL_TITLE`)}</h2>` },
            { name: 'description', content: `<div class='modal-basic__text'>${_vm.$t(`message.ORDER_HISTORY_MODAL_DESCRIPTION`)}</div>` }
          ],
          props: {
            hasButton: [
              {
                action: 'close',
                label: _vm.$t(`message.REQUEST_LIMIT_BUTTON_LABEL`),
                type: 'button'
              }
            ]
          }
        })}}},[[_vm._v(_vm._s(_vm.$t(`message.ORDER_HISTORY_HEADER_INFO`)))]],2)],1),(
      _vm.historyFiltered(_vm.queryURL)
      && Array.isArray(_vm.historyFiltered(_vm.queryURL))
      && !_vm.historyFiltered(_vm.queryURL).code)?[_c('div',{staticClass:"user-orders__filter"},[_c('form',{staticClass:"user-orders__filter__form"},[_c('label',{staticClass:"user-orders__filter__label",attrs:{"for":"userOrdersFilter"}},[_vm._v(_vm._s(_vm.$t(`message.ORDER_HISTORY_FILTER_LABEL`)))]),_c('div',{staticClass:"user-orders__filter__options"},[_c('select',{attrs:{"id":"userOrdersFilter","data-cy":"filters"},on:{"change":function($event){return _vm.changeFilter($event)}}},_vm._l((_vm.historyFilters),function(key){return _c('option',{key:key.value,domProps:{"value":key.value,"selected":key.value === _vm.queryURL}},[_vm._v(" "+_vm._s(_vm.$t(`message.ORDER_HISTORY_FILTER_${key.translate}`).toUpperCase())+" ")])}),0)])])])]:_vm._e(),_c('transition',{attrs:{"name":"spinner","appear":""}},[(_vm.historyResults.length === 0)?_c('Spinner',{attrs:{"message":_vm.$t(`message.WAITING_MESSAGE`)}}):_vm._e()],1),_c('transition-group',{staticClass:"user-orders__list",attrs:{"tag":"ul","name":"showResponse","appear":""},on:{"before-enter":function($event){return _vm.setMaxHeight(arguments)},"enter":function($event){return _vm.setEnterDelay(arguments)},"leave":function($event){return _vm.setLeaveDelay(arguments)}}},[(
          _vm.historyFiltered(_vm.queryURL)
          && Array.isArray(_vm.historyFiltered(_vm.queryURL))
          && _vm.historyFiltered(_vm.queryURL).length > 0)?[_vm._l((_vm.historyFiltered(_vm.queryURL)),function(result,index){return [_c('li',{key:`${result.order.id}`,attrs:{"data-index":index}},[_c('OrderBox',{attrs:{"header":{
                tag: 'dl',
                children: [
                  {
                    tag: 'div',
                    children: _vm.getCellDate(result.order)
                  },
                  {
                    tag: 'div',
                    children: [
                      {
                        tag: 'dt',
                        content: _vm.$t(`message.ORDER_HISTORY_ORDER_NUMBER`)
                      },
                      {
                        tag: 'dd',
                        content: result.order.id,
                        extraClass: ['__desc']
                      }
                    ]
                  }
                ]
              },"info":{
                extraClass: [result.order.status === 'CANCELLED' ? '--isDisabled' : null],
                image: {
                  src: `png/logos/${result.order.productType[0].toUpperCase()}${result.order.productType.slice(1)}.png`,
                  alt: result.order.productType,
                  extraClass: ['--isPTG', `--is${result.order.productType[0].toUpperCase()}${result.order.productType.slice(1)}`]
                },
                content: {
                  tag: 'table',
                  rows: {
                    tag: 'tr',
                    content: [
                      {
                        cells: [
                          { tag: 'th', text: _vm.$t(`message.ORDER_HISTORY_ORDER_LABEL_PRODUCT`) },
                          { tag: 'td', text: _vm.products[result.order.productType].fullName }
                        ]
                      },{
                        cells: [
                          { tag: 'th', text: _vm.$t(`message.ORDER_HISTORY_ORDER_LABEL_AMOUNT`) },
                          { tag: 'td', text: `${_vm.$t(`message.ORDER_HISTORY_ORDER_DATA_AMOUNT`, {amount: result.order.amount})} <span class='unitPrice'>${_vm.$t(`message.RESULT_UNIT_PRICE_LABEL`, {price: result.order.unitPrice.toString().replace('.', ',')})}</span>`, extraClass: ['--isAmount'] }
                        ]
                      },{
                        cells: [
                          { tag: 'th', text: _vm.$t(`message.ORDER_HISTORY_ORDER_LABEL_DELIVERY_ADDRESS`) },
                          { tag: 'td', text: result.order.address }
                        ]
                      },{
                        cells: [
                          { tag: 'th', text: _vm.$t(`message.ORDER_HISTORY_ORDER_LABEL_POSTAL_CODE`)},
                          { tag: 'td', text: result.order.postalCode }
                        ]
                      },{
                        cells: _vm.getCellDistributor(result)
                      }
                    ]
                  }
                }
              },"state":{
                type: result.order.status ? _vm.state[result.order.status].type : null,
                label: result.order.status ? _vm.state[result.order.status].label : null
              }},on:{"showModal":function($event){_vm.displayInfo({
                component: 'ModalDistributor',
                size: 680,
                slots: [ { name: 'title', content: `${_vm.$t(`message.ORDER_HISTORY_ORDER_MODAL_DISTRIBUTOR_ASSIGNED`)}` }],
                props: {
                  image: {
                    src: 'png/truck_Energy.png',
                    alt: result.provider.name
                  },
                  provider: {
                    name: result.provider.name,
                    details: [
                      {
                        label: _vm.$t(`message.RESUME_STORE_USER_PHONE`),
                        data: result.provider.phone || null
                      },
                      {
                        label: _vm.$t(`message.ORDER_HISTORY_ORDER_MODAL_MAIL_LABEL`),
                        data: result.provider.email ? `<a class='link--simple' href='mailto:${result.provider.email}'>${result.provider.email}</a>` : null
                      }
                    ]
                  },
                  hasButton: [
                    {
                      action: 'close',
                      label: _vm.$t(`message.REQUEST_LIMIT_BUTTON_LABEL`),
                      type: 'button'
                    }
                  ]
                }
              })}},scopedSlots:_vm._u([(result.order)?{key:"price",fn:function(){return [_c('td',{staticClass:"order-box--hasTotalPrice",domProps:{"innerHTML":_vm._s(_vm.$t(`message.RESULT_TOTAL_PRICE`, {totalPrice: _vm.getTotalPrice(result.order.total.toFixed(2))}))}})]},proxy:true}:null,(
                  _vm.state[result.order.status]
                  && _vm.state[result.order.status].action
                  && _vm.state[result.order.status].action.emit)?{key:"action",fn:function(){return [_c('td',{staticClass:"order-box--hasUserAction"},[_c('LinkButton',{attrs:{"data-cy":"order-user-action"},nativeOn:{"click":function($event){return _vm.handleAction(_vm.state[result.order.status].action.emit, result.order)}}},[[_vm._v(_vm._s(_vm.state[result.order.status].action.text))]],2)],1)]},proxy:true}:null],null,true)})],1)]})]:_vm._e(),[(
            _vm.firstLoad
            && _vm.historyFiltered(_vm.queryURL)
            && Array.isArray(_vm.historyFiltered(_vm.queryURL))
            && _vm.historyFiltered(_vm.queryURL).length === 0)?_c('li',{key:"void",attrs:{"data-index":0}},[_c('OrderBox',{attrs:{"isVoid":""},scopedSlots:_vm._u([{key:"isVoidTitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.queryURL === 'ALL' ? _vm.$t(`message.ORDER_HISTORY_VOID_TITLE`): _vm.$t(`message.ORDER_HISTORY_VOID_TITLE_BYFILTER`))+" ")]},proxy:true},{key:"isVoidMessage",fn:function(){return [_vm._v(" "+_vm._s(_vm.queryURL === 'ALL' ? _vm.$t(`message.ORDER_HISTORY_VOID_MESSAGE`): _vm.$t(`message.ORDER_HISTORY_VOID_MESSAGE_BYFILTER`))+" ")]},proxy:true},{key:"isVoidAction",fn:function(){return [_c('ButtonComponent',{attrs:{"label":_vm.$t(`message.ORDER_SEARCH_TITLE_START`)},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'home' })}}})]},proxy:true}],null,false,144753666)})],1):_vm._e()],(
        _vm.historyFiltered(_vm.queryURL)
        && !Array.isArray(_vm.historyFiltered(_vm.queryURL))
        && _vm.historyResults.code)?[_c('li',{key:"error",attrs:{"data-index":0}},[_c('OrderBox',{attrs:{"isVoid":""},scopedSlots:_vm._u([{key:"isVoidTitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`message.ORDER_HISTORY_VOID_TITLE`))+" ")]},proxy:true},{key:"isVoidMessage",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(`message.ORDER_HISTORY_VOID_MESSAGE`))+" ")]},proxy:true},{key:"isVoidAction",fn:function(){return [_c('ButtonComponent',{attrs:{"label":_vm.$t(`message.ORDER_SEARCH_TITLE_START`)},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'home' })}}})]},proxy:true}],null,false,3800975241)})],1)]:_vm._e()],2)],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }