<template>
  <article class="section-title">
    <div class="section-title__list">
      <header class="section-title__items">
        <transition
          mode="out-in"
          name="change-title"
          v-on:enter="startAnimation">
          <div
            :key="main"
            class="section-title__item">
            <h2 class="section-title__main">{{main}}</h2>
            <h3
              v-if="payoff"
              class="section-title__payoff">{{payoff}}
            </h3>
          </div>
        </transition>
      </header>
    </div>
  </article>
</template>
<script>
export default {
  name: 'SectionTitle',

  props: {
    main: {
      type: String,
      default: 'Title'
    },

    payoff: {
      type: String
    }
  },

  methods: {
    startAnimation () {
      this.$emit('startAnimation')
    }
  }
}
</script>
<style lang="scss" scoped src="./SectionTitle.scss"></style>
