<template>
  <article
    :class="[
      !isActive ? 'survey-question--inactive' : null,
      'survey-question'
    ]"
    :id="type"
  >
    <div class="survey-question__wrap">
      <header class="survey-question__info">
        <h3
          v-if="$slots['title']"
          class="survey-question__title"
        >
          <slot name="title" />
        </h3>
        <p
          v-if="$slots['note']"
          class="survey-question__note"
        >
          <slot name="note" />
        </p>
      </header>
      <aside
        v-if="$slots['note']"
        class="survey-question__vote">
        <slot name="votes" />
      </aside>
    </div>
  </article>
</template>
<script>
export default {
  name: 'SurveyQuestion',
  data () {
    return {
    }
  },

  props: {
    isActive: {
      type: Boolean,
      default: false
    },

    type: { type: String }
  }
}
</script>
<style lang="scss" scoped src="./SurveyQuestion.scss"></style>
