<template>
  <fieldset
    :class="[
      isDisabled ? 'fieldset--disabled' : null,
      'fieldset'
    ]"
    :disabled="isDisabled"
    @change="getValue($event)"
  >
    <ul :class="[
        `fieldset__wrap--${hasDirection}`,
        'fieldset__wrap'
      ]">
      <li v-for="(node, index) in this.$slots" :key="index">
        <label class="radio__fake">
          <input type="radio"
                 :value="index"
                 :id="hasName"
                 :name="hasName"
                 class="input">
          <i class="radio__fake__replace"></i>
          <div
            class="radio__label"
            v-html="getContent(index)">
          </div>
        </label>
      </li>
    </ul>
  </fieldset>
</template>
<script>
export default {
  name: 'RadioButton',

  data() {
    return {}
  },

  props: {
    hasDirection: {
      type: String,
      default: 'row'
    },

    hasName: {
      type: String,
      default: ''
    },

    isChecked: {
      type: String,
      default: ''
    },

    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getValue(e) {
      // -  emit value to validation
      this.$emit('input', e.target.value)
    },

    filterChecked() {
      const component = this.$el
      const radios = component.querySelectorAll('input')

      for (const radio of radios) {
        if (radio.value === this.isChecked) radio.checked = true
      }
    },

    getContent (name) {
      return this.$slots[name][0]['text']
    }
  },

  mounted() {
    if (this.isChecked !== '') this.filterChecked()
  }
}
</script>
<style lang="scss" scoped src="./RadioButton.scss"></style>
