import Vue from 'vue'

import Breadcrumb from 'components/breadcrumb/Breadcrumb.vue'
import {
  // MainHeader as Header,

  MainFooter as Footer,
  CheckBox,
  Alerts,
  LinkButton,
  Icon,
  CorpButton as Button,
  RadioButton,
  Dropdown,
  StaticsHeader,
  FaqSection,
  FormField,
  Hero,
  Terms
} from 'repsol-uikit'

import NavigationMenu from 'repsol-uikit/src/components/navigation-menu/NavigationMenu.vue'

/* Remove main header from REPSOL UIKIT and reuse it.. this so we can use the localeSwitcher
*/
import MainHeader from 'components/main-header/MainHeader.vue'
import ResultRecord from 'components/result-record/ResultRecord.vue'
import ResultDetail from 'components/result-detail/ResultDetail.vue'

// - Modals components
import Modal from 'containers/modal-container/ModalContainer.vue'
import ModalBasic from 'components/modal-basic/ModalBasic.vue'
import ModalOrderError from 'components/modal-order-error/ModalOrderError.vue'
import ModalUserForm from 'components/modal-user-form/ModalUserForm.vue'

// - Personal data
import UserSession from 'containers/user-session/UserSession.vue'
import FeatureList from 'components/features-list/FeaturesList.vue'
import TabsSwitch from 'components/tabs-switch/TabsSwitch.vue'
import UserRegistration from 'containers/user-registration/UserRegistration.vue'
import UserLogin from 'containers/user-login/UserLogin.vue'

// i18n
import LocaleSwitcher from 'components/locale-switcher/locale-switcher.vue'

// - Other components
import OrderEntry from 'components/order-entry/OrderEntry.vue'
import OrderResumeInfo from 'components/order-resume/OrderResumeInfo.vue'
import OrderPricesInfo from 'components/order-prices/OrderPricesInfo.vue'
import OrderDeclaration from 'components/order-declaration/OrderDeclaration.vue'
import PromotionBox from 'components/promotion-box/PromotionBox.vue'
import SurveyQuestion from 'components/survey-question/SurveyQuestion.vue'
import StarsVote from 'components/stars-vote/StarsVote.vue'

import BusinessServicesCard from 'components/business-service-card/BusinessServicesCard.vue'
import ProviderData from 'components/provider-data/ProviderData.vue'
import UserData from 'components/user-data/UserData.vue'
import SectionTitle from 'components/section-title/SectionTitle.vue'
import CallToAction from 'components/call-to-action/CallToAction.vue'
import ContactInfo from 'components/contact-info/ContactInfo.vue'
import ProductSchedule from 'components/product-schedule/ProductSchedule.vue'
import ProductComparative from 'components/product-comparative/ProductComparative.vue'
import MobileOrderInfo from 'components/mobile-order-info/MobileOrderInfo.vue'
import Banner from 'components/banner/Banner.vue'
import CognitiveMessage from 'components/cognitive-message/CognitiveMessage.vue'
import PromotionMessageValidation from 'components/promotion-message-validation/PromotionMessageValidation.vue'
import Spinner from 'components/spinner/Spinner.vue'
import UserFormOrder from 'containers/user-form-order/UserFormOrder.vue'
import CognitiveInfo from 'components/cognitive-info/CognitiveInfo.vue'
import PaymentCard from 'components/payment-card/PaymentCard.vue'
import ActionShare from 'components/action-share/ActionShare.vue'
import OrderBox from 'components/order-box/OrderBox.vue'
import ModalDistributor from 'components/modal-distributor/ModalDistributor.vue'
import ModalLoader from 'components/modal-loader/ModalLoader.vue'
import ModalOrderRepeat from 'components/modal-order-repeat/ModalOrderRepeat.vue'

// - components - registration
Vue.component('ButtonComponent', Button)
Vue.component('RadioButtonComponent', RadioButton)
Vue.component('CheckBoxComponent', CheckBox)
Vue.component('IconComponent', Icon)
Vue.component('FooterComponent', Footer)
Vue.component('NavigationMenu', NavigationMenu)
Vue.component('HeaderComponent', MainHeader)
Vue.component('ResultRecord', ResultRecord)
Vue.component('ResultDetail', ResultDetail)
Vue.component('BreadcrumbComponent', Breadcrumb)
Vue.component('ModalComponent', Modal)
Vue.component('ModalBasic', ModalBasic)
Vue.component('ModalUserForm', ModalUserForm)
Vue.component('UserSession', UserSession)
Vue.component('FeatureList', FeatureList)
Vue.component('TabsSwitch', TabsSwitch)
Vue.component('UserRegistration', UserRegistration)
Vue.component('UserLogin', UserLogin)
Vue.component('LocaleSwitcher', LocaleSwitcher)
Vue.component('AlertsComponent', Alerts)
Vue.component('ModalOrderError', ModalOrderError)
Vue.component('LinkButton', LinkButton)
Vue.component('Dropdown', Dropdown)
Vue.component('SurveyQuestion', SurveyQuestion)
Vue.component('StarsVote', StarsVote)
Vue.component('FaqSection', FaqSection)
Vue.component('StaticsHeader', StaticsHeader)
Vue.component('BusinessServicesCard', BusinessServicesCard)
Vue.component('FormField', FormField)
Vue.component('OrderEntry', OrderEntry)
Vue.component('OrderResumeInfo', OrderResumeInfo)
Vue.component('OrderPricesInfo', OrderPricesInfo)
Vue.component('ProviderData', ProviderData)
Vue.component('UserData', UserData)
Vue.component('SectionTitle', SectionTitle)
Vue.component('OrderDeclaration', OrderDeclaration)
Vue.component('PromotionBox', PromotionBox)
Vue.component('Hero', Hero)
Vue.component('CallToAction', CallToAction)
Vue.component('ContactInfo', ContactInfo)
Vue.component('ProductSchedule', ProductSchedule)
Vue.component('ProductComparative', ProductComparative)
Vue.component('MobileOrderInfo', MobileOrderInfo)
Vue.component('Terms', Terms)
Vue.component('Banner', Banner)
Vue.component('CognitiveMessage', CognitiveMessage)
Vue.component('PromotionMessageValidation', PromotionMessageValidation)
Vue.component('Spinner', Spinner)
Vue.component('UserFormOrder', UserFormOrder)
Vue.component('CognitiveInfo', CognitiveInfo)
Vue.component('PaymentCard', PaymentCard)
Vue.component('ActionShare', ActionShare)
Vue.component('OrderBox', OrderBox)
Vue.component('ModalDistributor', ModalDistributor)
Vue.component('ModalLoader', ModalLoader)
Vue.component('ModalOrderRepeat', ModalOrderRepeat)
