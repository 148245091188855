<template>
  <article
    :class="[
      hasProduct.length !== 0 ? 'modal-basic--hasHeader' : null,
      'modal-basic'
    ]">
    <header
      v-if="hasProduct.length !== 0"
      :class="[
        hasProduct !== '' ? `modal-basic__header__${hasProduct[0].type}` : null,
        'modal-basic__header'
      ]">
      <figure>
        <img
          :src="require(`images/${hasProduct[0].logo}`)"
          alt="logo">
      </figure>
    </header>
    <template v-for="(index,slot) in this.$slots">
      <div
        :key="slot"
        v-html="getContent(slot)"
        class="modal-basic__slot-container">
      </div>
    </template>
    <ul
      v-if="hasButton"
      class="modal-basic__buttons">
      <li
        v-for="(node, index) in hasButton"
        :key="index">
        <template v-if="node.type === 'button'">
          <ButtonComponent
            hasFullWidth
            @click.native="runMethod(node.action, node.params ? node.params : null)"
            :label="node.label"
            :key="index"
            :isSecondary=(node.isSecondary)
            v-if="!isIE11" />
          <a
            class="link--simple"
            @click="runMethod(node.action, node.params ? node.params : null)"
            :key="index"
            v-else>{{node.label}}</a>
        </template>
        <template v-if="node.type === 'link'">
          <a
            class="link--simple"
            @click="runMethod(node.action, node.params ? node.params : null)"
            :key="index"
          >{{node.label}}</a>
        </template>
      </li>
    </ul>
  </article>
</template>
<script>
import { Utilities } from '../../helpers/utilities'
export default {
  name: 'ModalBasic',
  data () {
    return {}
  },

  props: {
    hasProduct: {
      type: Array,
      default: () => []
    },

    hasButton: { type: Array }
  },

  computed: {
    isIE11 () {
      return Utilities.isIE11()
    }
  },

  methods: {
    getContent (name) {
      return this.$slots[name][0]['text']
    },

    close () {
      this.$emit('close')
    },

    externalURL (url) {
      this.$emit('externalURL', url)
    },

    runMethod (method, params) {
      this[method](params)
    }
  },

  mounted () {
    if (this.hasProduct.length !== 0) this.$emit('isReverse', true)
  }
}
</script>
<style lang="scss" scoped src="./ModalBasic.scss"></style>
