<template>
  <article
    :class="[
      type !== '' ? `business-card--${type}` : null,
      'business-card'
    ]"
    :style="[ background !== '' ? {backgroundImage: `url(${require('images/' + background + '')})`} : null ]"
  >
    <div class="business-card__content">
      <div
        class="business-card__logo"
        v-if="this.$slots.logo"
      >
        <slot name="logo" />
      </div>
      <span
        class="business-card__step"
        v-if="step !== 0"
      >{{step}}</span>
      <h3
        class="business-card__title"
        v-if="this.$slots.title"
      >
        <slot name="title" />
      </h3>
      <p
        class="business-card__content"
        v-if="this.$slots.content"
      >
        <slot name="content" />
      </p>
    </div>
  </article>
</template>
<script>
export default {
  name: 'BusinessServicesCard',

  props: {
    type: {
      type: String,
      default: ''
    },

    background: {
      type: String,
      default: ''
    },

    step: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="scss" scoped src="./BusinessServicesCard.scss"></style>
