import * as Cookies from 'js-cookie'

import {
  SESSION_COOKIE,
  LOGIN_SCREENSET,
  LOGIN_START_SCREEN,
  REGISTER_START_SCREEN,
  RESET_START_SCREEN,
  UPDATE_START_SCREEN,
  UPDATE_SCREENSET,
  UNREGISTER_DATA,
  BASE_URL,
  SESSION_CHECK_INTERVAL
} from './constants'

import store from 'app/store'
import { SizmekService } from '../sizmek'

const isScreenSetAlreadyLoaded = containerID => {
  const container = document.getElementById(containerID)
  return container && container.childElementCount > 0
}

const isLogged = event =>
  event.errorCode === 0 && event.data.Service.VVDDGasoleoCalefaccion

let sdk = {
  notLoaded: true,
  then: () =>
    Promise.reject(
      new Error(
        'Gigya SDK not initialized. Call GigyaService.init before calling any method'
      )
    )
}

export class GigyaService {
  static SSOTimeoutPointer = null
  static cachedLoggedIn = null
  static logoutEventHandler = null
  static loginEventHandler = null

  static init({ onLogin, onLogout }) {
    GigyaService.loginEventHandler = user => onLogin && onLogin(user)
    GigyaService.logoutEventHandler = () => onLogout && onLogout()

    GigyaService.loadSDK()
    GigyaService.subscribeToSSO()
  }

  static loadSDK() {
    if (!sdk.notLoaded) {
      return
    }

    sdk = new Promise((resolve, reject) => {
      const gigya = document.createElement('script')
      gigya.setAttribute('src', BASE_URL)
      gigya.async = true

      gigya.addEventListener('load', () => {
        resolve(window.gigya)
      })
      gigya.addEventListener('error', error => {
        reject(error)
      })

      document.head.appendChild(gigya)
    })
  }

  static async finalizeRegistration(regToken) {
    const gigya = await sdk
    return new Promise(resolve =>
      gigya.accounts.finalizeRegistration({ regToken, callback: resolve })
    )
  }

  static isLoggedIn() {
    return !!Cookies.get(SESSION_COOKIE)
  }

  static async logout() {
    const gigya = await sdk
    return new Promise(resolve => gigya.accounts.logout({ callback: resolve }))
  }

  static async getAccountInfo() {
    const additionalInfo = 'phones'
    const gigya = await sdk
    return new Promise(resolve =>
      gigya.accounts.getAccountInfo({
        extraProfileFields: additionalInfo,
        callback: resolve
      })
    )
  }

  static async updateUserDocID({ docID }) {
    const gigya = await sdk
    return new Promise(resolve => {
      const documentSelector = {
        'NIF': 'Pers',
        'NIE': 'Pers',
        'CIF': 'Empr',
        'PASAPORTE': 'Passport'
      }

      docID.Tipo = docID.Tipo.toUpperCase()
      const selector = documentSelector[docID.Tipo]

      gigya.accounts.setAccountInfo({
        data: {
          'DocID.VVDDGasoleoCalefaccion': docID[selector],
          'DocID.TipoVVDDGasoleoCalefaccion': docID.Tipo
        },
        callback: resolve
      })
    })
  }

  static async unregisterAccount() {
    const gigya = await sdk
    return new Promise(resolve =>
      gigya.accounts.setAccountInfo({
        ...UNREGISTER_DATA,
        callback: e => {
          SizmekService.tagPage({ page: 'unregister' })
          resolve(e)
        }
      })
    )
  }

  static async showScreenSet({ screenSet, containerID, startScreen, ...rest }) {
    const gigya = await sdk
    return new Promise(resolve => {
      if (isScreenSetAlreadyLoaded(containerID)) {
        return
      }

      gigya.accounts.showScreenSet({
        lang: localStorage.getItem('lang') || 'es',
        screenSet,
        containerID,
        startScreen,
        onAfterScreenLoad: e => {
          GigyaService.bindEventRegisterForms()
          resolve(e)
        },
        ...rest
      })
    })
  }

  static async showResetPassword(containerID) {
    return GigyaService.showScreenSet({
      screenSet: LOGIN_SCREENSET,
      startScreen: RESET_START_SCREEN,
      lang: localStorage.getItem('lang') || 'es',
      containerID
    })
  }

  static async showLogin(containerID) {
    return GigyaService.showScreenSet({
      screenSet: LOGIN_SCREENSET,
      startScreen: LOGIN_START_SCREEN,
      lang: localStorage.getItem('lang') || 'es',
      containerID,
      onAfterSubmit: async event => {
        // Social registration triggered at login
        if (event.response.userInfo) {
          const {
            response: {
              userInfo: { UID, identities }
            }
          } = event
          store.dispatch('analytics/pushGigyaEvent', {
            category: 'gigya_registro',
            action: identities[0].provider,
            label: window.location.href,
            extraParams: {
              userId: UID,
              login: 'notlogged'
            }
          })
        }

        // Site or social login
        if (event.response.user) {
          let gigyaUser = await GigyaService.getAccountInfo()
          const tmpData = gigyaUser.data
          if (tmpData.DocID && tmpData.DocID.Tipo && !tmpData.DocID.VVDDGasoleoCalefaccion) {
            await GigyaService.updateUserDocID({ docID: tmpData.DocID })
            gigyaUser = await GigyaService.getAccountInfo()
          }
          const {
            response: {
              user: { UID, loginProvider },
              data: { Service }
            }
          } = event
          store.dispatch('analytics/pushGigyaEvent', {
            category: 'gigya_login',
            action: loginProvider,
            label: window.location.href,
            extraParams: {
              userId: UID,
              login: 'logged',
              service: Service && Object.keys(Service).join(',')
            }
          })
        }

        GigyaService.bindEventRegisterForms()
      }
    })
  }

  static async showRegistration(containerID) {
    return GigyaService.showScreenSet({
      screenSet: LOGIN_SCREENSET,
      startScreen: REGISTER_START_SCREEN,
      lang: localStorage.getItem('lang') || 'es',
      containerID,
      onAfterSubmit: event => {
        // Site or social registration
        if (event.response.userInfo) {
          const {
            response: {
              userInfo: { UID, identities }
            }
          } = event
          store.dispatch('analytics/pushGigyaEvent', {
            category: 'gigya_registro',
            action: identities[0].provider,
            label: window.location.href,
            extraParams: {
              userId: UID,
              login: 'notlogged'
            }
          })
        }
        // Social login triggered after registration
        if (event.response.user) {
          const {
            response: {
              user: { UID, loginProvider },
              data: { Service }
            }
          } = event
          store.dispatch('analytics/pushGigyaEvent', {
            category: 'gigya_login',
            action: loginProvider,
            label: window.location.href,
            extraParams: {
              userId: UID,
              login: 'logged',
              service: Service && Object.keys(Service).join(',')
            }
          })
        }

        // save user order data
        if (store.state.orders.response.length) {
          const {
            UID,
            UIDSignature,
            signatureTimestamp
          } = event.response

          const {
            timeInfo: { time },
            paymentInfo: { payment },
            amountInfo: {
              ID,
              unitPrice,
              quantity
            },
            addressInfo: {
              address,
              postalCode
            },
            additionalInfo: { info },
            voucher: {
              code,
              description,
              type
            }
          } = store.state.orders.order

          const payload = {
            UID: UID,
            UIDSignature: UIDSignature,
            signatureTimestamp: signatureTimestamp,
            offerId: ID,
            address: address,
            additionalAddress: info,
            postalCode: postalCode.value,
            unitPrice: unitPrice,
            amount: quantity,
            filter: 'LITERS',
            timeSlot: time.toUpperCase(),
            paymentOption: payment.toUpperCase(),
            voucher: (code !== '') ? { code, description, type } : null
          }

          const draft = store.dispatch('orders/setOrderDraft', payload)
          draft.then(res => {
            if (res.error) {
              store.dispatch(`orders/${res.error.code}`)
            }
          })
        }
      }
    })
  }

  static async showProfileUpdate(containerID, { onAfterSubmit }) {
    return GigyaService.showScreenSet({
      screenSet: UPDATE_SCREENSET,
      startScreen: UPDATE_START_SCREEN,
      lang: localStorage.getItem('lang') || 'es',
      containerID,
      onAfterSubmit,
      onBeforeValidation: _ =>
        new Promise(resolve => {
          GigyaService.getAccountInfo({
            include: 'preferences, data, profile',
            extraProfileFields: 'phones'
          }).then(event => {
            if (isLogged(event)) {
              resolve()
            } else {
              GigyaService.logoutEventHandler()
              resolve({ form: 'User logged out' })
            }
          })
        })
    })
  }

  static subscribeToSSO() {
    const SSOListener = () => {
      const loggedIn = GigyaService.isLoggedIn()
      if (GigyaService.cachedLoggedIn !== loggedIn) {
        GigyaService.cachedLoggedIn = loggedIn

        GigyaService.getAccountInfo().then(event => {
          if (isLogged(event)) {
            GigyaService.loginEventHandler({
              id: event.UID,
              firstName: event.profile.firstName,
              loginProvider: event.loginProvider || 'site',
              gigyaServices:
                event.data &&
                event.data.Service &&
                Object.keys(event.data.Service)
            })
          } else {
            GigyaService.logoutEventHandler()
          }
        })
      }

      GigyaService.SSOTimeoutPointer = setTimeout(
        SSOListener,
        SESSION_CHECK_INTERVAL
      )
    }

    sdk.then(() => {
      // If the method is called more than once, this removes the pending timeout
      clearTimeout(GigyaService.SSOTimeoutPointer)
      SSOListener()
    })
  }

  static bindEventRegisterForms() {
    const register = document.getElementById('register-social-login') || document.getElementById('gigya-profile-form')
    if (register) {
      const checkAll = register.querySelectorAll('.consent-checkall')
      const consentRegistration = register.querySelectorAll('.consent-registration')

      for (let consent of consentRegistration) {
        let input = consent.querySelector('input')
        input.onclick = function () {
          for (let check of checkAll) {
            let input = check.querySelector('input')
            const checkedAll = GigyaService.getInputsValue(consentRegistration).every((node) => node === true)
            if (!this.checked && !checkedAll) input.checked = false
            if (checkedAll) input.checked = true
          }
        }
      }

      for (let check of checkAll) {
        let input = check.querySelector('input')
        input.onclick = function () {
          for (let consent of consentRegistration) {
            let input = consent.querySelector('input')
            this.checked ? input.checked = true : input.checked = false
          }
        }
      }
    }
  }

  static getInputsValue(nodes) {
    let inputsValue = []

    for (let node of nodes) {
      inputsValue = [...inputsValue, node.querySelector('input').checked]
    }
    return inputsValue
  }
}
