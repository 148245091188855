<template>
  <section
    :class="[
      isFocused ? 'select--isFocused' : null,
      isDisabled ? 'select--isDisabled' : null,
      'select'
    ]"
    @mousedown="focused"
    @focus="focused"
    @blur="blur"
    v-click-outside="blur"
  >
    <!-- hidden select -->
    <input type="hidden" ref="hiddenSelect" :value="isSelected" tabindex="1">

    <!-- selected fake option -->
    <div class="select__wrap">
      <div class="select__selected">{{isSelectedLabel}}</div>
    </div>

    <!-- fake option list -->
    <aside
      class="select__options"
      :style="[ !isFocused ? {'height' : 0} : {'height' : `${wrapHeight}px`}]"
    >
      <ul class="select__options__list" ref="list">
        <li
          v-for="(node, pos) in elements"
          @click="setSelectedLabel($event.target)"
          :class="[pos === index ? 'isFocused' : '']"
          :data-value="node.value"
          :key="pos"
        >{{node.label}}</li>
      </ul>
    </aside>
  </section>
</template>
<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'Select',

  data() {
    return {
      index: 0,
      isFocused: false,
      isSelected: 0,
      prevEvent: '',
      isSelectedLabel: '',
      wrapHeight: 0
    }
  },

  directives: {
    ClickOutside
  },

  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },

    isFailed: {
      type: Boolean,
      default: false
    },

    hasStartOption: {
      type: Number,
      default: 0
    },

    elements: {
      type: Array,
      default: [],
      required: true
    }
  },

  watch: {
    isFocused() {
      this.wrapHeight = this.$refs['list'].clientHeight
    }
  },

  methods: {
    focused(_e) {
      if (!this.isDisabled) {
        this.isFocused = true
      }
    },

    blur() {
      this.isFocused = false
    },

    setSelectedLabel(t) {
      this.isSelected = t.dataset.value
      this.isSelectedLabel = t.textContent
      this.isFocused = false

      // -  emit value to validation
      this.$emit('input', this.isSelected)
      this.getSelectedIndex(t)
    },

    getSelectedIndex(target) {
      const parent = target.parentNode
      this.index = Array.prototype.indexOf.call(parent.children, target)
      const hiddenSelect = this.$refs.hiddenSelect

      hiddenSelect.value = true
    },

    selectElementByEnter() {
      const nodes = this.$el.querySelectorAll('.select__options__list li')
      this.setSelectedLabel(nodes[this.index])
      this.isFocused = false
    },

    moveSelectedNode(e) {
      const max = this.elements.length
      const keyCode = e.which || e.keyCode

      // - closed return false
      if (!this.isFocused) return false

      // - key press !== up, down or enter
      if (keyCode !== 38 && keyCode !== 40 && keyCode !== 13) return false

      // - move
      switch (keyCode) {
        case 38: // - up
          this.index > 0 ? (this.index -= 1) : (this.index = max)
          break
        case 40: // - down
          this.index <= max - 1 ? (this.index += 1) : (this.index = 0)
          break
        case 13: // - enter
          this.selectElementByEnter()
          break
      }
    }
  },

  mounted() {
    this.wrapHeight = this.$refs['list'].clientHeight
    window.addEventListener('keydown', e => {
      this.moveSelectedNode(e)
    })
    this.setSelectedLabel(this.$el.querySelectorAll('.select__options__list li')[0])

    // - has start option
    if (this.hasStartOption !== 0) {
      this.index = this.hasStartOption
      this.selectElementByEnter()
    }
  }
}
</script>
<style lang="scss" scoped src="./Select.scss"></style>
