export const modal = {
  namespaced: true,

  state: {
    status: false,
    component: {
      background: 'fff',
      allowClose: false,
      name: '',
      size: '',
      props: [],
      slots: [],
      callBack: ''
    },
    submit: false
  },

  mutations: {
    setModalDisplay: (state, payload) => {
      state.status = payload.state
    },

    setModalComponent: (state, payload) => {
      state.component.name = payload.name
      state.component.background = payload.background || 'fff'
      state.component.allowClose = payload.allowClose || false
      state.component.size = payload.size || ''
      state.component.props = payload.props || []
      state.component.slots = payload.slots || []
      state.component.callBack = payload.callBack || ''
    },

    setModalSize: (state, payload) => {
      state.component.size = payload.size
    },

    setModalProps: (state, payload) => {
      state.component.props = payload.props
    },

    setModalClose: (state, payload) => {
      state.component.allowClose = payload.allowClose
    },

    setModalSubmit: (state, payload) => {
      state.submit = payload.submit
    }
  },

  actions: {
    setModalDisplay: ({ commit }, payload) => {
      commit('setModalDisplay', payload)
    },

    setModalComponent: ({ commit }, payload) => {
      commit('setModalComponent', payload)
    },

    setModalSize: ({ commit }, payload) => {
      commit('setModalSize', payload)
    },

    setModalProps: ({ commit }, payload) => {
      commit('setModalProps', payload)
    },

    setModalClose: ({ commit }, payload) => {
      commit('setModalClose', payload)
    },

    setModalSubmit: ({ commit }, payload) => {
      commit('setModalSubmit', payload)
    }
  }
}
