<template>
  <div class="stars-set">
      <transition name="appear" mode="out-in">
        <p class="mb-xs stars-set__label" v-bind:key="item">
          {{ valorations[item] }}
        </p>
      </transition>

      <fieldset :id="fieldsetName">
        <div
          v-for="n in (valorations.length -1)"
          :key="n"
          class="stars-set__item">
          <Icon
            @mouseover.native="setClass(n)"
            @mouseout.native="removeClass()"
            @click.native="vote(n)"
            name="IconStar"
            size="custom"
            :class="[
              n <= item ? 'stars-set__icon stars-set__icon--active' : 'stars-set__icon'
            ]"
          />
          <input
            type="radio"
            :value="n"
            :name="fieldsetName"
            class="stars-set__input" />
        </div>
      </fieldset>
  </div>
</template>

<script>
import Icon from '../icons/Icon'

export default {
  name: 'StarsSet',
  components: {
    Icon
  },
  data () {
    return {
      item: 0,
      voted: 0,
      position: 0,
      ratingState: this.valorations[this.item]
    }
  },

  computed: {
    isDesktopWidth () {
      return window.matchMedia(this.mediaQueries.tablet)
    }
  },

  methods: {
    setClass (item) {
      if (this.isDesktopWidth.matches) this.item = item
    },
    removeClass () {
      if (this.isDesktopWidth.matches) {
        if (this.voted === 0) {
          this.item = 0
        } else {
          this.item = this.voted
        }
      }
    },
    vote (n) {
      this.voted = n
      this.item = n
      this.ratingState = this.valorations[this.item]
      this.$emit('vote', n)
    }
  },

  props: {
    fieldsetName: {
      type: String,
      default: 'fieldset'
    },
    valorations: {
      type: Array,
      default: function () { return ['Haz tu valoración', 'Deficiente', 'Mejorable', 'Bien', 'Muy bien', 'Excelente'] }
    },
    mediaQueries: {
      type: Object,
      default: function () {
        return {
          tablet: '(min-width: 1023px)',
          mobile: '(max-width: 991px)'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped src="./StarsSet.scss"></style>
