import { http } from './index'
import axios from 'axios'
import router from '../../router/index'
import store from '../../store'
/**
 * This cancel token lets you cancel request on demand
 * @type {CancelTokenStatic|*}
 */
const CancelToken = axios.CancelToken
let cancelations = []
const order = '/order'
const prices = '/prices'

class Orders {
  static getPrices = async payload => {
    try {
      const res = await http.get(
        `${prices}`,
        {
          params: {
            postalCode: payload.postalCode,
            amount: payload.amount,
            type: payload.type,
            voucher: payload.voucher
          },
          headers: {
            UID: payload.UID
          }
        },
        {
          cancelToken: new CancelToken(function executor(c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      const errorCode = error.response.data.code
      if (errorCode) {
        store.dispatch(`orders/${errorCode}`, error.response.data.extraInfo)
      } else {
        router.push({ name: 'server-error' })
      }

      return error
    }
  }

  static setOrder = async payload => {
    try {
      const res = await http.post(
        `${order}`, payload,
        {
          cancelToken: new CancelToken(function executor (c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      if (error.response && error.response.status === 500) {
        router.push({ name: 'server-error' })
      }
      return error.response
    }
  }

  static setOrderDraft = async payload => {
    try {
      const res = await http.post(
        `${order}/draft`, payload,
        {
          cancelToken: new CancelToken(function executor (c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  static getOrderDraft = async payload => {
    try {
      const res = await http.get(
        `${order}/pending`,
        {
          params: payload
        },
        {
          cancelToken: new CancelToken(function executor (c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  static getOrderHistory = async payload => {
    try {
      const res = await http.get(
        `${order}/history`,
        {
          params: payload
        },
        {
          cancelToken: new CancelToken(function executor (c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  static getOrderHistoryHome = async (payload, filter) => {
    try {
      const res = await http.get(
        `${order}/history/${filter}`,
        {
          params: payload
        },
        {
          cancelToken: new CancelToken(function executor (c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  static deleteOrder = async payload => {
    try {
      const res = await http.post(
        `${order}/cancel`, payload,
        {
          cancelToken: new CancelToken(function executor (c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      const errorCode = error.response.data.code
      if (errorCode) {
        errorCode === 2001 ? store.dispatch(`orders/CANCELLATION_REQUIRE_INPUT`) : store.dispatch(`orders/${errorCode}`)
      } else {
        router.push({ name: 'server-error' })
      }
      return error
    }
  }
}

export { Orders }
