let cookiebot = {
  notLoaded: true,
  then: () =>
    Promise.reject(
      new Error(
        'Cookiebot not initialized.'
      )
    )
}

let cookiedeclaration = {
  notLoaded: true,
  then: () =>
    Promise.reject(
      new Error(
        'Cookiedeclaration not initialized.'
      )
    )
}

export const CookieBotBanner = (params) => ({
  methods: {
    loadCookiebot() {
      const {
        id,
        url,
        mode = 'none',
        culture = 'ES'
      } = params

      if (!cookiebot.notLoaded) { return }

      cookiebot = new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.setAttribute('src', `${url}uc.js`)
        script.dataset.cbid = id
        script.dataset.blockingmode = mode
        script.dataset.culture = culture
        script.addEventListener('error', error => {
          reject(error)
        })
        document.head.appendChild(script)
      })
    },

    printSaveButton () {
      const {
        show,
        label
      } = params.saveButton

      if (show) {
        const checks = {
          NuevasPref: 'CybotCookiebotDialogBodyLevelButtonPreferences',
          NuevasEst: 'CybotCookiebotDialogBodyLevelButtonStatistics',
          NuevasMark: 'CybotCookiebotDialogBodyLevelButtonMarketing'
        }
        let checksState = []

        const a = document.createElement('BUTTON')
        a.setAttribute('id', 'CybotCookiebotNuevoBotonGuardar')
        a.setAttribute('tabIndex', '0')
        a.innerText = label || 'SAVE'
        a.classList.add('CybotCookiebotDialogBodyButton')
        document.getElementById('CybotCookiebotDialogDetailBodyContentCookieContainerTypes').appendChild(a)

        a.addEventListener('click', function () {
          for (let check in checks) {
            const el = document.querySelector(`#${checks[check]}`)
            checksState = [el.checked, ...checksState]
          }

          // eslint-disable-next-line no-undef
          Cookiebot.submitCustomConsent(...checksState)

          setTimeout(function () {
            const dialog = document.querySelector('#CybotCookiebotDialog')
            dialog.parentNode.removeChild(dialog)
          }, 200)
        })
      }
    }
  },

  mounted() {
    if (!window.Cypress) {
      this.loadCookiebot()

      window.addEventListener('CookiebotOnDialogDisplay', () => {
        this.printSaveButton()
      })

      window.showCookiebotLegalWarning = () => {
        const {
          policyURL = 'https://www.repsol.es/particulares/politica-de-cookies/'
        } = params

        window.open(`${policyURL}`, '_blank')
      }
    }
  }
})

export const CookieBotDeclaration = (params) => ({
  methods: {
    loadCookieDeclaration() {
      const {
        id,
        url
      } = params

      if (!cookiedeclaration.notLoaded) { return }

      cookiedeclaration = new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.setAttribute('src', `${url}${id}/cd.js`)

        script.addEventListener('error', error => {
          reject(error)
        })

        document.head.appendChild(script)
      })
    }
  },

  mounted() {
    if (!window.Cypress) {
      this.loadCookieDeclaration()
    }
  }
})
