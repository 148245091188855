<template>
  <section class="private-area">
    <ul class="private-area__wrap">
      <li class="private-area__navigation">
        <nav>
          <router-link
            :to="{ name: 'user-orders', query: { filterBy: 'ALL' }}"
            class="private-area__navigation__item"
            data-cy="user-orders"
            replace> {{ $t(`message.USER_ORDERS_LABEL`) }} </router-link>
          <router-link
            :to="{ name: 'user-account' }"
            class="private-area__navigation__item"
            data-cy="user-account"
            replace> {{ $t(`message.USER_ACCOUNT_LABEL`) }} </router-link>
        </nav>
      </li>
      <li class="private-area__content">
        <transition
          name="router-vertical-anim"
          appear>
          <router-view name="areaContent"></router-view>
        </transition>
      </li>
    </ul>
  </section>
</template>
<script>
export default {
  name: 'PrivateArea'
}
</script>
<style lang="scss" scoped src="./PrivateArea.scss"></style>
