<template>
    <header
      class="statics-header"
      :style="[typeof backgroundImg !== 'undefined'
        ? bgImgGradient
          ? { background: `${bgImgGradient}, url(${backgroundImg}) ${bgExtra}` }
          : { backgroundImage: `url(${backgroundImg})` }
        : null]"
    ><h1 class="statics-header__title">{{title}}</h1>
    </header>
</template>

<script>
export default {
  name: 'StaticsHeader',
  props: {
    title: String,
    backgroundImg: String,
    bgImgGradient: String,
    bgExtra: {
      type: String,
      default: 'center center/cover no-repeat'
    }
  }
}
</script>

<style lang="scss" scoped src="./StaticsHeader.scss"></style>
