<template>
  <aside
    v-if="cards.length > 0"
    class="payment-card">
    <div class="payment-card__wrap">
      <p v-if="this.$slots['text']">
        <slot name="text" />
      </p>
      <ul class="payment-card__list">
        <li
          v-for="item in cards"
          :key="item"
          :class="[
            'card',
            `card--${item}`
          ]"
        ></li>
      </ul>
      <div
        v-if="this.$slots['extra']"
        class="payment-card__extra">
        <slot name="extra" />
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  name: 'PaymentCard',
  props: {
    cards: { type: Array }
  }
}
</script>
<style lang="scss" scoped src="./PaymentCard.scss"></style>
