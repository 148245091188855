<template>
  <header class="header" :class="{ 'with-mask': showMask, 'header--fixed': fixed }">
    <div class="header__top">
      <ul>
        <li>
          <!-- @slot logoImg-->
          <slot name="logoImg" />
        </li>
        <li class="project">
          <div class="project__labels">
            {{ labelActiveProject }}
            <span v-if="labelActiveCountry" class="country">{{
              labelActiveCountry
            }}</span>
          </div>
        </li>
        <li></li>
        <div class="user-area">
          <LocaleSwitcher></LocaleSwitcher>
          <li class="user-area" v-if="!hideUserAreaLink">
            <router-link
              v-if="isLogged"
              :to="urlUserArea"
              class="user-area__link"
              rel="index, follow"
            >
              <IconComponent name="IconUser" size="xxsmall"></IconComponent>
              <Icon name="IconUser" size="xxsmall" />
              <span class="user-logged">{{ labelLoggedUserArea }}</span>
            </router-link>
            <button v-else type="button" class="user-area__link" @click="onUserLogin">
              <IconComponent name="IconUser" size="xxsmall"></IconComponent
              >{{ labelUserArea }}
            </button>
          </li>
        </div>
      </ul>
    </div>
    <transition name="minimized">
      <div class="header__bottom" v-if="!isMinimized">
        <NavigationMenu
          :navigationLinks="navigationLinks"
          :headerHeight="headerHeight"
          :userIsLogged="isLogged"
          :labelCloseSession="labelCloseSession"
          :labelUserArea="labelUserArea"
          :urlUserArea="urlUserArea"
          :userWelcomeMessage="labelLoggedUserArea"
          v-on:toggleMobileMenu="toggleOpacityMask"
          v-on:userLogin="onUserLogin"
          v-on:closeSession="onCloseSession"
        />
      </div>
    </transition>
  </header>
</template>
<script>
export default {
  name: 'MainHeader',
  components: {},
  props: {
    isMinimized: {
      type: Boolean,
      default: false
    },
    scrollDirection: {
      type: Object,
      default: function () {
        return { up: false, position: 0 }
      }
    },
    navigationLinks: {
      type: Array,
      default: () => [
        { text: 'Inicio', url: '/' },
        { text: 'Qué es', url: '' },
        { text: 'Productos', url: '' },
        { text: 'Preguntas frecuentes', url: '' }
      ]
    },
    labelActiveProject: {
      type: String,
      default: 'Pide tu Gasóleo'
    },
    labelActiveCountry: {
      type: String
    },
    labelUserArea: {
      type: String,
      default: 'Área cliente'
    },
    labelLoggedUserArea: {
      type: String,
      default: 'Hola'
    },
    labelCloseSession: {
      type: String,
      default: 'Cerrar Sesión'
    },
    urlUserArea: {
      type: [String, Object],
      default: '#'
    },
    isLogged: {
      type: Boolean,
      default: false
    },
    hideUserAreaLink: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      fixed: false,
      headerHeight: 0,
      mq: null,
      showMask: false
    }
  },
  methods: {
    matchHeight: function () {
      this.headerHeight = this.$el.clientHeight
    },
    toggleOpacityMask: function (isVisible) {
      this.showMask = typeof isVisible === 'boolean' ? isVisible : false
    },
    onUserLogin: function () {
      this.$emit('userLogin')
    },
    onCloseSession: function () {
      this.$emit('closeSession')
    }
  },
  mounted() {
    this.matchHeight()
    if (matchMedia) {
      this.mq = window.matchMedia('(min-width: 768px)')
      this.mq.addListener(this.toggleOpacityMask)
      this.toggleOpacityMask(false)
    }
  },
  watch: {
    scrollDirection: {
      handler(newVal) {
        if (newVal.up) {
          this.fixed = newVal.position > this.headerHeight
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped src="./MainHeader.scss"></style>
