<template>
  <aside class="result-record">
    <div class="result-record__wrap">
      <div class="result-record__date">
        <ul class="result-record__date-result">
          <slot name="date" />
        </ul>
      </div>
      <div class="result-record__products">
        <div class="result-record__cards">
          <div v-if="this.$slots.BiEnergy" class="result-record__line"><slot name="BiEnergy" /></div>
          <div v-if="this.$slots.Energy" class="result-record__line"><slot name="Energy" /></div>
          <div v-if="this.$slots.GasoilCExcellent" class="result-record__line"><slot name="GasoilCExcellent" /></div>
          <div v-if="this.$slots.GasoilBExcellent" class="result-record__line"><slot name="GasoilBExcellent" /></div>
          <div v-if="this.$slots.AgroDiesel" class="result-record__line"><slot name="AgroDiesel" /></div>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>

export default {
  name: 'ResultRecord'
}
</script>
<style lang="scss" scoped src="./ResultRecord.scss"></style>
