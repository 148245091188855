<template>
  <article class="modal-error">
    <template v-if="iconExist">
      <IconComponent
        :name=hasIcon.name
        :size=hasIcon.size />
    </template>
    <h2
      v-if="this.$slots['title']"
      class="modal-error__title"
      v-html="getContent('title')">
    </h2>
    <div
      v-if="this.$slots['description']"
      class="modal-error__text"
      v-html="getContent('description')">
    </div>
    <ul class="modal-error__buttons">
      <li
        v-for="(node, index) in hasButton"
        :key="index">
        <template v-if="node.type === 'button'">
          <ButtonComponent
            hasFullWidth
            @click.native="runMethod(node.action)"
            :label="node.label"
            :key="index"
            :isSecondary=(node.isSecondary)
            v-if="!isIE11" />
          <a
            class="link--simple"
            @click="runMethod(node.action)"
            :key="index"
            v-else>{{node.label}}</a>
        </template>
        <template v-if="node.type === 'link'">
          <a
            class="link--simple"
            @click="runMethod(node.action)"
            :key="index"
          >{{node.label}}</a>
        </template>
      </li>
    </ul>
    <div
      v-if="this.$slots['help']"
      class="modal-error__help"
      v-html="getContent('help')">>
    </div>
  </article>
</template>
<script>
import { Utilities } from '../../helpers/utilities'
export default {
  name: 'ModalOrderError',

  props: {
    isPriceChanged: {
      type: Boolean,
      default: false
    },

    isOversizeLiter: {
      type: Boolean,
      default: false
    },

    hasButton: {
      type: Array,
      default: () => []
    },

    hasIcon: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    iconExist () {
      if (this.hasIcon) return Object.keys(this.hasIcon).length > 0
    },

    isIE11 () {
      return Utilities.isIE11()
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    start () {
      this.$emit('start')
    },

    reject () {
      this.$emit('reject')
    },

    accept () {
      this.$emit('accept')
    },

    acceptCode () {
      this.$emit('acceptCode')
    },

    runMethod (method) {
      this[method]()
    },

    getContent (name) {
      return this.$slots[name][0]['text']
    }
  }
}
</script>
<style lang="scss" scoped src="./ModalOrderError.scss"></style>
