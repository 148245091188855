<template>
  <section id="app" sticky-container>
    <router-view></router-view>
  </section>
</template>
<script>
import { GigyaService } from './services/gigya'
import { GtmService } from './services/gtm'
import { mapState } from 'vuex'
// import { CookieBot } from './services/cookiebot'

export default {
  name: 'app',
  data() {
    return {
      lock: false
    }
  },

  watch: {
    lockState(value) {
      const that = document.querySelector('body')
      value ? that.classList.add('lock') : that.classList.remove('lock')
    }
  },

  computed: {
    ...mapState(['orders']),

    lockState() {
      return this.orders.order.lockState
    }
  },
  methods: {
    loadCookies() {
      window.addEventListener('CookiebotOnDialogDisplay', e => {
        document.querySelector('#CybotCookiebotDialogBodyButtonAccept').addEventListener('click', () => {
          setTimeout(() => {
            // eslint-disable-next-line no-undef
            Cookiebot.submitCustomConsent(true, true, true)
          }, 500)
        })
      })

      const cookiebotRepsol = document.createElement('script')
      cookiebotRepsol.setAttribute(
        'src',
        'https://www.repsol.com/es/scripts/scripts/cookiebot/cookiebotrepsoldomain.js'
      )
      cookiebotRepsol.setAttribute('data-culture', localStorage.getItem('lang') || 'es')
      cookiebotRepsol.setAttribute('default-styles', 'true')
      cookiebotRepsol.setAttribute('data-cbid', 'a8f3b66a-46d8-4b23-bbdf-3aa642458c13') // modal
      cookiebotRepsol.setAttribute('type', 'text/javascript')
      cookiebotRepsol.setAttribute('async', 'false')

      document.head.appendChild(cookiebotRepsol)
    }
  },
  created () {
    this.$store.dispatch('status/checkStatus')
    const onLogin = user => {
      this.$root.$emit('close')

      if (this.$route.name === 'personal-data') {
        this.$store.dispatch('analytics/pushEcommerceOption', {
          stepNumber: 2,
          option: `login - ${user.loginProvider}`
        })
        this.$router.push({ name: 'recap' })
      }
      this.$store.dispatch('user/updateUser', user)
    }
    const onLogout = () => this.$store.dispatch('user/logout')

    GigyaService.init({ onLogin, onLogout })
    GtmService.init()
    // this.loadCookies()
    // if (process.env.NODE_ENV === 'production') {
    //   CookieBot.init()
    // }
  }
}
</script>
<style lang="scss" src="./styles/index.scss"></style>
