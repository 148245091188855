<template>
  <section class="user-session">
    <div class="user-session__wrap">
      <ul class="user-session__column">
         <li class="user-session__route">
          <TabsSwitch
            :isComponent="component"
            :hasDestination="hasDestination"
            @change= "switchComponent"
            :hasTabs="[
              {
                'label': $t(`message.PERSONALDATA_LABEL_REGISTRATION`),
                'content': 'UserRegistration'
              },{
                'label': $t(`message.PERSONALDATA_LABEL_LOGIN`),
                'content': 'UserLogin'
              }
            ]"
          >
          </TabsSwitch>
        </li>
        <li class="user-session__features">
          <FeatureList>
            <template #check>
              <span class="features__icon"><IconComponent name="IconCheck" size="xsmall"></IconComponent></span>
              <p>{{$t(`message.FEATURES_LIST_CHECK`)}}</p>
            </template>
            <template #calendar>
              <span class="features__icon"><IconComponent name="IconCalendar" size="xsmall"></IconComponent></span>
              <p>{{$t(`message.FEATURES_LIST_CALENDAR`)}}</p>
            </template>
            <template #phone>
              <span class="features__icon"><IconComponent name="IconSmartphone" size="xsmall"></IconComponent></span>
              <p>{{$t(`message.FEATURES_LIST_PHONE`)}}</p>
            </template>
          </FeatureList>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
export default {
  name: 'UserSession',

  data () {
    return {
      componentName: this.isComponent
    }
  },

  props: {
    hasDestination: {
      type: String,
      default: ''
    },

    isComponent: {
      type: String,
      default: 'UserLogin'
    }
  },

  computed: {
    component () {
      return this.componentName
    }
  },

  methods: {
    switchComponent (newComponent) {
      this.componentName = newComponent
    }
  }
}
</script>
<style lang="scss" scoped src="./UserSession.scss"></style>
