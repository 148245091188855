var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{class:[
    _vm.isDisabled ? 'fieldset--disabled' : null,
    'fieldset'
  ],attrs:{"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.getValue($event)}}},[_c('ul',{class:[
      `fieldset__wrap--${_vm.hasDirection}`,
      'fieldset__wrap'
    ]},_vm._l((this.$slots),function(node,index){return _c('li',{key:index},[_c('label',{staticClass:"radio__fake"},[_c('input',{staticClass:"input",attrs:{"type":"radio","id":_vm.hasName,"name":_vm.hasName},domProps:{"value":index}}),_c('i',{staticClass:"radio__fake__replace"}),_c('div',{staticClass:"radio__label",domProps:{"innerHTML":_vm._s(_vm.getContent(index))}})])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }