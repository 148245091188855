var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"profile"},[_c('div',{staticClass:"profile__wrap transition"},[_c('div',{attrs:{"id":"profile-update-container"}}),_c('ButtonComponent',{attrs:{"label":_vm.$t(`message.SIGN_OFF`),"isSecondary":""},nativeOn:{"click":function($event){return _vm.userLogout.apply(null, arguments)}}}),_c('LinkButton',{nativeOn:{"click":function($event){_vm.openModal({
      type:'ModalUserForm',
      size: 770,
      slots: [
        { name: 'title', content: 'Darme de baja '},
        { name: 'description', content: '¿Nos cuentas el motivo? Tu experiencia nos ayudará a mejorar.'}
      ],
      props: [
        {
          submit: false,
          isDisabled: _vm.reasonID === '',
          submitMessage: _vm.$t('message.USER_UNREGISTER_STATE_MESSAGE'),
          hasAlert: {
            icon: 'IconAlerts',
            text: _vm.$t(`message.UNREGISTER_ALERT`)
          },

          hasForm: {
            freeText: {
              state: true,
              label: _vm.$t('message.USER_UNREGISTER_FREETEXT_INFO'),
              placeholder: _vm.$t('message.USER_UNREGISTER_FREETEXT_PLACEHOLDER'),
              isEditable: false,
              hasValue: _vm.unregisterExtraText
            },
            radioButtons: [
              {
                id: 'tooMuchPublicity',
                label: _vm.$t('message.USER_UNREGISTER_FORM_LABEL_TOOMUCHPUBLICITY')
              },
              {
                id: 'notUseService',
                label: _vm.$t('message.USER_UNREGISTER_FORM_LABEL_NOTUSESERVICE')
              },
              {
                id: 'otherReason',
                label: _vm.$t('message.USER_UNREGISTER_FORM_LABEL_OTHERREASON')
              }
            ]
          },

          hasButton: [
            {
              action: 'userUnregister',
              label: _vm.$t(`message.UNREGISTER`),
              type: 'button'
            },

            {
              action: 'close',
              label: _vm.$t(`message.KEEP_PROFILE`),
              type: 'link'
            }
          ]
        }
      ]
    })}}},[[_vm._v(_vm._s(_vm.$t(`message.UNREGISTER`)))]],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }