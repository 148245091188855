import Vue from 'vue'
import Router from 'vue-router'

import VueScrollTo from 'vue-scrollto'
import store from '../store'
import { routes } from './routes'
import { GigyaService } from '../services/gigya'

Vue.use(Router)

const setTitle = to => {
  const fromChildrenToRoot = to.matched.slice().reverse()
  const firstRouteWithTitle = fromChildrenToRoot.find(
    e => e.meta && e.meta.title
  )
  if (firstRouteWithTitle) {
    document.title = firstRouteWithTitle.meta.title
  }
}

const setDescription = to => {
  const fromChildrenToRoot = to.matched.slice().reverse()
  const firstRouteWithDescription = fromChildrenToRoot.find(
    e => e.meta && e.meta.description
  )
  if (firstRouteWithDescription) {
    document.querySelector('meta[name=description]').content =
      firstRouteWithDescription.meta.description
  }
}

const autoAcceptCookies = from => {
  const isFirstPage = !from.name && from.path === '/'
  if (!isFirstPage) {
    store.dispatch('cookies/acceptCookiesPolicy')
  }
}

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  setTitle(to)
  setDescription(to)
  autoAcceptCookies(from)

  if (to.meta && to.meta.isPrivateArea && !GigyaService.isLoggedIn()) {
    return next({ name: 'home' })
  }

  const scroll = window.pageYOffset
  if (scroll !== 0 && Object.keys(to.query).length === 0) {
    if (from.name !== 'summary' || to.name !== 'results') {
      VueScrollTo.scrollTo('#app', 400, {
        easing: 'ease-out',
        cancelable: true,
        onDone: () => {
          next()
        }
      })
    } else {
      document.documentElement.scrollTop = 0
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to, _from) => {
  store.dispatch('analytics/pushVirtualPage', {
    path: to.path,
    pageName: to.meta && to.meta.pageName,
    isPrivateArea: to.meta && to.meta.isPrivateArea
  })
  store.dispatch('analytics/tagPage', {
    page: to.name
  })
})

export default router
