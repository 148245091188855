<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :type="!to ? type : false"
    :to="to"
    :disabled="isDisabled"
    :class="[
      isSecondary ? 'corp-button--isSecondary' : null,
      hasFullWidth ? 'corp-button--hasFullWidth' : null,
      spinner ? 'corp-button--hasLoader' : null,
      'corp-button'
    ]">
    <!-- spinner loader -->
    <span v-if="spinner" class="corp-button__loader"><span class="spinner"></span></span>
    <template v-else>
      <slot name="label">
        {{label}}
      </slot>
    </template>
  </component>
</template>
<script>
export default {
  name: 'CorpButton',
  props: {
    spinner: {
      type: Boolean,
      default: false
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    isSecondary: {
      type: Boolean,
      default: false
    },

    hasFullWidth: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: null
    },

    to: {
      type: [String, Object],
      required: false
    },

    type: {
      type: String,
      default: 'button',
      required: false
    }
  }
}
</script>
<style lang="scss" scoped src="./CorpButton.scss"></style>
