<template>
    <section class="faq-section">
        <header class="faq-section__header">
            <h2 class="faq-section__title"><slot name="title" :translationTag="`FAQS_${translationTagCode}_TITLE`"/></h2>
        </header>
        <template v-for="idx in quantity">
            <Dropdown
              class="faq-section__dropdown"
              :isOpen="idx === 1"
              :isAutonomous="true"
              :key="`faq-section-${idx}`">
                <template slot="title">
                    <div class="faq-section__dropdown-header">
                      <h3 class="faq-section__dropdown-header__title"><slot name="question" :translationTag="translationTagCreator(translationTagCode, 'QUESTION', idx)"/></h3>
                      <Icon name="IconCaretBottom" size="xsmall" class="faq-section__icon"/>
                    </div>
                </template>
                <template slot="content">
                    <div class="faq-section__dropdown-content"><slot name="answer" :translationTag="translationTagCreator(translationTagCode, 'ANSWER', idx)"/></div>
                </template>
            </Dropdown>
        </template>
    </section>
</template>

<script>
import Dropdown from '../dropdown/Dropdown'
import Icon from '../icons/Icon'

export default {
  name: 'FaqSection',
  components: {
    Dropdown,
    Icon
  },
  props: {
    translationTagCode: {
      type: String,
      default: 'ORDER'
    },
    quantity: {
      type: Number,
      default: 2
    }
  },
  methods: {
    translationTagCreator (tagCode, type, id) {
      return `FAQS_${tagCode}_${type}_${id < 10 ? '0' + id : id}`
    }
  }
}
</script>

<style lang="scss" scoped src="./FaqSection.scss"></style>
