<template>
  <section class="user-registration">
    <transition
      name="spinner"
      appear>
      <Spinner
        v-if="spinner"
        :message="$t(`message.WAITING_MESSAGE`)"/>
    </transition>
    <transition
      name="userForm"
      appear>
       <div v-if="!formDestination" id="register-container"></div>
       <div v-else id="register-container--modal"></div>
    </transition>
  </section>
</template>

<script>
import { GigyaService } from '../../services/gigya'
import { mapActions } from 'vuex'

export default {
  name: 'UserRegistration',

  data () {
    return {
      destination: 'register-container',
      spinner: true
    }
  },

  props: {
    hasDestination: {
      type: String,
      default: ''
    }
  },

  computed: {
    formDestination () {
      return this.hasDestination.length > 0
    }
  },

  methods: {
    ...mapActions('analytics', ['pushVirtualPage']),

    loadContent () {
      GigyaService.showRegistration(this.setDestination()).then(() => {
        this.spinner = false
      })

      this.pushVirtualPage({
        path: this.$route.path,
        pageName: '/es/acceso-clientes/registro/gasoleo'
      })
    },

    setDestination () {
      if (this.formDestination) {
        return `${this.destination}--${this.hasDestination}`
      }
      return this.destination
    }
  },

  mounted () {
    this.loadContent()
  }
}
</script>
<style lang="scss" scoped src="./UserRegistration.scss"></style>
