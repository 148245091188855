import MainWrap from 'containers/main-wrap/MainWrap.vue'

import AppContainer from 'containers/app-container/AppContainer.vue'
import SearchForm from 'containers/search-form/SearchForm.vue'
import ResultsList from 'containers/results-list/ResultsList.vue'
import UserSession from 'containers/user-session/UserSession.vue'
import OrderSummary from 'containers/order-summary/OrderSummary.vue'
import OrderRecap from 'containers/order-recap/OrderRecap.vue'
import OrderCompleted from 'containers/order-completed/OrderCompleted.vue'

import Breadcrumb from 'containers/breadcrumb/Breadcrumb.vue'
import ErrorMessage from 'components/error-message/ErrorMessage.vue'

import ServiceCondition from 'containers/service-condition/ServiceCondition.vue'
import PasswordReset from 'containers/password-reset/PasswordReset.vue'
import Faq from 'containers/static-faq/Faq.vue'
import Contact from 'containers/static-contact/Contact.vue'
import Products from 'containers/static-products/Products.vue'
import Profile from 'containers/user-profile/UserProfile.vue'
import ConfirmRegistration from 'containers/confirm-registration/ConfirmRegistration.vue'
import BusinessServices from 'containers/static-business-services/BusinessServices.vue'

import SurveyForm from 'containers/survey-form/SurveyForm.vue'
import SurveyResult from 'components/survey-result/SurveyResult.vue'
// import QRGenerator from 'components/QR-generator/QRGenerator.vue'
import PrivateArea from 'containers/private-area/PrivateArea.vue'
import UserOrders from 'containers/user-orders/UserOrders.vue'

import Campana from 'containers/campana/Campana.vue'

import { GigyaService } from '../services/gigya'

import store from '../store'
import i18n from '../i18n'
import router from '../router/index'
import { Constants } from '../constants'

const PAGE_NOT_FOUND = 'page-not-found'
export const routes = [
  {
    path: '',
    component: MainWrap,
    meta: {
      title: i18n.t('message.HOME_SEO_TITLE'),
      description: i18n.t('message.HOME_SEO_DESCRIPTION')
    },
    children: [
      {
        path: '',
        component: AppContainer,
        children: [
          {
            path: '',
            name: 'home',
            components: {
              default: '',
              header: SearchForm
            },
            beforeEnter: (_to, _from, next) => {
              store.dispatch('orders/setButtonState', { type: 'search', isDisabled: false })
              localStorage.setItem('orderPending', JSON.stringify({}))
              next()
            }
          },
          {
            path: 'results',
            name: 'results',
            components: {
              header: SearchForm,
              default: ResultsList
            },
            beforeEnter: (_to, _from, next) => {
              if (!store.state.orders.response.length) {
                next({ name: 'home' })
              } else {
                next()
              }
            }
          },
          {
            path: 'error',
            name: 'results-error',
            components: {
              header: SearchForm,
              default: ErrorMessage
            },
            props: {
              default: {
                icon: {
                  type: 'IconTruck',
                  size: 'big'
                },
                title: i18n.t('message.UNAVAILABLE_ERROR_TITLE'),
                message: i18n.t('message.UNAVAILABLE_ERROR_MESSAGE'),
                titleNotice: i18n.t('message.UNAVAILABLE_ERROR_NOTICE_TITLE'),
                messageNotice: i18n.t('message.UNAVAILABLE_ERROR_NOTICE_MESSAGE'),
                buttons: [
                  {
                    label: i18n.t('message.UNAVAILABLE_ERROR_NOTICE_BUTTON'),
                    action: function () {
                      window.open(Constants.URL_REDIR_NO_LITERS, '_blank')
                    }
                  }
                ]
              }
            },
            beforeEnter: (_to, _from, next) => {
              if (_from.name === null) {
                next({ name: 'home' })
              } else {
                next()
              }
            }
          },
          {
            path: 'summary',
            name: 'summary',
            components: {
              default: OrderSummary,
              header: Breadcrumb
            },
            beforeEnter: (_to, _from, next) => {
              if (!localStorage.getItem('orderPending')) {
                next({ name: 'home' })
              } else {
                next()
              }
            }
          },
          {
            path: 'personal-data',
            name: 'personal-data',
            components: {
              default: UserSession,
              header: Breadcrumb
            },
            props: {
              default: true
            },
            beforeEnter: (_to, _from, next) => {
              if (!store.state.orders.order.paymentInfo.payment) {
                next({ name: 'home' })
              } else {
                next()
              }
            }
          },
          {
            path: 'recap',
            name: 'recap',
            components: {
              default: OrderRecap,
              header: Breadcrumb
            },
            beforeEnter: (_to, _from, next) => {
              if (window.Cypress) {
                next()
              } else {
                const storageLength = Object.keys(JSON.parse(localStorage.getItem('orderPending'))).length > 0
                if (!storageLength || !localStorage.getItem('orderPending') || !GigyaService.isLoggedIn()) {
                  next({ name: 'home' })
                } else {
                  next()
                }
              }
            }
          },
          {
            path: 'completed',
            name: 'completed',
            components: {
              default: OrderCompleted,
              header: Breadcrumb
            },
            beforeEnter: (_to, from, next) => {
              localStorage.removeItem('orderPending')
              if (!from.name) {
                next({ name: 'home' })
              } else {
                next()
              }
            }
          },
          {
            path: 'valoracion-del-servicio',
            name: 'service-assessment',
            component: SurveyForm,
            beforeEnter: (_to, from, next) => {
              const shouldRedirect = ['service-assessment-sended', 'service-assessment-rejected'].includes(from.name)

              if (shouldRedirect) {
                window.location.href = i18n.t(`message.COUNTRY_LINK`)
              } else {
                next()
              }
            }
          },
          {
            path: 'encuesta-enviada',
            name: 'service-assessment-sended',
            component: SurveyResult,
            props: {
              text: i18n.t('message.SECTION_PAYOFF_SERVICE-ASSESSMENT-SENDED'),
              label: i18n.t('message.ASSESSMENTSENDED_BUTTON'),
              contacts: i18n.t(`message.PROVIDER_ERROR_CONTACT`)
            }
          },
          {
            path: 'encuesta-no-permitida',
            name: 'service-assessment-rejected',
            component: SurveyResult,
            props: {
              text: i18n.t('message.SECTION_PAYOFF_SERVICE-ASSESSMENT-REJECTED'),
              label: i18n.t('message.ASSESSMENTREJECTED_BUTTON'),
              contacts: i18n.t(`message.PROVIDER_ERROR_CONTACT`)
            }
          },
          {
            path: 'encuesta-ya-evaluada',
            name: 'service-assessment-already-rated',
            component: SurveyResult,
            props: {
              text: i18n.t('message.SECTION_PAYOFF_SERVICE-ASSESSMENT-ALREADY-RATED'),
              label: i18n.t('message.ASSESSMENTSENDED_BUTTON'),
              contacts: i18n.t(`message.PROVIDER_ERROR_CONTACT`)
            }
          }
        ]
      },
      {
        path: 'conoce-el-servicio',
        name: 'business-services',
        component: BusinessServices,
        meta: {
          title: i18n.t('message.BUSINESS_SEO_TITLE'),
          description: i18n.t('message.BUSINESS_SEO_DESCRIPTION')
        }
      },
      {
        path: 'tipos-de-gasoleos',
        name: 'products',
        component: Products,
        meta: {
          title: i18n.t('message.PRODUCTS_SEO_TITLE'),
          description: i18n.t('message.PRODUCTS_SEO_DESCRIPTION')
        }
      },
      {
        path: 'preguntas-frecuentes',
        name: 'faq',
        component: Faq,
        meta: {
          title: i18n.t('message.FAQS_SEO_TITLE'),
          description: i18n.t('message.FAQS_SEO_DESCRIPTION')
        }
      },
      {
        path: 'contacto',
        name: 'contact',
        component: Contact,
        meta: {
          title: i18n.t('message.CONTACT_SEO_TITLE'),
          description: i18n.t('message.CONTACT_SEO_DESCRIPTION')
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: PrivateArea,
        redirect: { name: 'user-orders' },
        meta: {
          title: i18n.t('message.PROFILE_SEO_TITLE'),
          description: i18n.t('message.PROFILE_SEO_DESCRIPTION'),
          isPrivateArea: true,
          pageName: '/es/acceso-clientes/perfil'
        },
        children: [
          {
            path: '/cuenta-de-usuario',
            name: 'user-account',
            components: {
              areaContent: Profile
            },
            beforeEnter: (_to, _from, next) => {
              if (!window.Cypress) {
                if (!store.state.user.isLoggedIn) {
                  next({ name: 'home' })
                } else {
                  next()
                }
              } else {
                next()
              }
            }
          },
          {
            path: '/historico-de-pedidos/:filterBy?',
            name: 'user-orders',
            components: {
              areaContent: UserOrders
            },
            beforeEnter: (_to, _from, next) => {
              if (!window.Cypress) {
                if (!store.state.user.isLoggedIn) {
                  next({ name: 'home' })
                } else {
                  next()
                }
              } else {
                next()
              }
            }
          }
        ]
      },
      {
        path: 'pedidos',
        name: 'orders',
        component: PrivateArea,
        meta: {
          title: i18n.t('message.PROFILE_SEO_TITLE'),
          description: i18n.t('message.PROFILE_SEO_DESCRIPTION'),
          isPrivateArea: true,
          pageName: '/es/acceso-clientes/perfil'
        }
      },
      {
        path: 'confirm-registration',
        name: 'confirm-registration',
        component: ConfirmRegistration
      },
      {
        path: 'password-reset',
        name: 'password-reset',
        component: PasswordReset
      },
      {
        path: PAGE_NOT_FOUND,
        name: PAGE_NOT_FOUND,
        component: ErrorMessage,
        props: {
          icon: {
            type: 'IconTruck',
            size: 'big'
          },
          title: i18n.t('message.PAGE_NOT_FOUND_ERROR_TITLE'),
          buttons: [
            {
              label: i18n.t('message.BUTTON_BACK'),
              action: function () {
                router.push({ name: 'home' })
              }
            }
          ],
          extraText: i18n.t(`message.PAGE_NOT_FOUND_ERROR_MESSAGE`)
        }
      },
      {
        path: 'server-error/:code?',
        name: 'server-error',
        component: ErrorMessage,
        props: {
          icon: {
            type: 'IconTruck',
            size: 'big'
          },
          title: i18n.t('message.SERVER_ERROR_TITLE'),
          buttons: [
            {
              label: i18n.t('message.BUTTON_BACK'),
              action: function () {
                router.push({ name: 'home' })
              }
            }
          ],
          extraText: i18n.t(`message.SERVER_ERROR_MESSAGE`)
        },
        beforeEnter: (_to, _from, next) => {
          if (_from.name === null) {
            next({ name: 'home' })
          } else {
            next()
          }
        }
      },
      {
        path: 'condiciones-de-servicio',
        name: 'service-condition',
        component: ServiceCondition
      },
      // {
      //   path: 'promocion',
      //   name: 'promotion',
      //   component: QRGenerator,
      //   props: route => ({
      //     title: i18n.t(`message.PROMOTION_TITLE`, { quantity: route.query.quantity.replace('.', ',') }),
      //     instructions: i18n.t(`message.PROMOTION_SUBTITLE`, {
      //       quantity: route.query.quantity.replace('.', ','),
      //       endDate: route.query.endDate
      //     }),
      //     wayletTitle: i18n.t('message.PROMOTION_WAYLET_TITLE'),
      //     wayletSubtitle: i18n.t('message.PROMOTION_WAYLET_SUBTITLE'),
      //     passbookInstructions: i18n.t('message.PROMOTION_PASSBOOK')
      //   })
      // },
      {
        path: 'campana',
        name: 'campana',
        component: Campana,
        meta: {
          title: i18n.t('message.CAMPANA_TITLE'),
          description: i18n.t('message.CAMPANA_DESCRIPTION')
        },
        children: [
          {
            path: '',
            name: 'home',
            components: {
              default: '',
              header: SearchForm
            },
            beforeEnter: (_to, _from, next) => {
              store.dispatch('orders/setButtonState', { type: 'search', isDisabled: false })
              localStorage.setItem('orderPending', JSON.stringify({}))
              next()
            }
          },
          {
            path: 'results',
            name: 'results',
            components: {
              header: SearchForm,
              default: ResultsList
            },
            beforeEnter: (_to, _from, next) => {
              if (!store.state.orders.response.length) {
                next({ name: 'home' })
              } else {
                next()
              }
            }
          },
          {
            path: 'error',
            name: 'results-error',
            components: {
              header: SearchForm,
              default: ErrorMessage
            },
            props: {
              default: {
                icon: {
                  type: 'IconTruck',
                  size: 'big'
                },
                title: i18n.t('message.UNAVAILABLE_ERROR_TITLE'),
                message: i18n.t('message.UNAVAILABLE_ERROR_MESSAGE'),
                titleNotice: i18n.t('message.UNAVAILABLE_ERROR_NOTICE_TITLE'),
                messageNotice: i18n.t('message.UNAVAILABLE_ERROR_NOTICE_MESSAGE'),
                buttons: [
                  {
                    label: i18n.t('message.UNAVAILABLE_ERROR_NOTICE_BUTTON'),
                    action: function () {
                      window.open(Constants.URL_REDIR_NO_LITERS, '_blank')
                    }
                  }
                ]
              }
            },
            beforeEnter: (_to, _from, next) => {
              if (_from.name === null) {
                next({ name: 'home' })
              } else {
                next()
              }
            }
          },
          {
            path: 'summary',
            name: 'summary',
            components: {
              default: OrderSummary,
              header: Breadcrumb
            },
            beforeEnter: (_to, _from, next) => {
              if (!localStorage.getItem('orderPending')) {
                next({ name: 'home' })
              } else {
                next()
              }
            }
          },
          {
            path: 'personal-data',
            name: 'personal-data',
            components: {
              default: UserSession,
              header: Breadcrumb
            },
            props: {
              default: true
            },
            beforeEnter: (_to, _from, next) => {
              if (!store.state.orders.order.paymentInfo.payment) {
                next({ name: 'home' })
              } else {
                next()
              }
            }
          },
          {
            path: 'recap',
            name: 'recap',
            components: {
              default: OrderRecap,
              header: Breadcrumb
            },
            beforeEnter: (_to, _from, next) => {
              if (window.Cypress) {
                next()
              } else {
                const storageLength = Object.keys(JSON.parse(localStorage.getItem('orderPending'))).length > 0
                if (!storageLength || !localStorage.getItem('orderPending') || !GigyaService.isLoggedIn()) {
                  next({ name: 'home' })
                } else {
                  next()
                }
              }
            }
          },
          {
            path: 'completed',
            name: 'completed',
            components: {
              default: OrderCompleted,
              header: Breadcrumb
            },
            beforeEnter: (_to, from, next) => {
              localStorage.removeItem('orderPending')
              if (!from.name) {
                next({ name: 'home' })
              } else {
                next()
              }
            }
          },
          {
            path: 'valoracion-del-servicio',
            name: 'service-assessment',
            component: SurveyForm,
            beforeEnter: (_to, from, next) => {
              const shouldRedirect = ['service-assessment-sended', 'service-assessment-rejected'].includes(from.name)

              if (shouldRedirect) {
                window.location.href = i18n.t(`message.COUNTRY_LINK`)
              } else {
                next()
              }
            }
          },
          {
            path: 'encuesta-enviada',
            name: 'service-assessment-sended',
            component: SurveyResult,
            props: {
              text: i18n.t('message.SECTION_PAYOFF_SERVICE-ASSESSMENT-SENDED'),
              label: i18n.t('message.ASSESSMENTSENDED_BUTTON'),
              contacts: i18n.t(`message.PROVIDER_ERROR_CONTACT`)
            }
          },
          {
            path: 'encuesta-no-permitida',
            name: 'service-assessment-rejected',
            component: SurveyResult,
            props: {
              text: i18n.t('message.SECTION_PAYOFF_SERVICE-ASSESSMENT-REJECTED'),
              label: i18n.t('message.ASSESSMENTREJECTED_BUTTON'),
              contacts: i18n.t(`message.PROVIDER_ERROR_CONTACT`)
            }
          },
          {
            path: 'encuesta-ya-evaluada',
            name: 'service-assessment-already-rated',
            component: SurveyResult,
            props: {
              text: i18n.t('message.SECTION_PAYOFF_SERVICE-ASSESSMENT-ALREADY-RATED'),
              label: i18n.t('message.ASSESSMENTSENDED_BUTTON'),
              contacts: i18n.t(`message.PROVIDER_ERROR_CONTACT`)
            }
          }
        ]
      }
    ]
  },
  { path: '*', redirect: { name: PAGE_NOT_FOUND } }
]
