<template>
  <article class="modal-order-repeat">
    <template v-for="(index,slot) in this.$slots">
      <div
        :key="slot"
        v-html="getContent(slot)"
        class="modal-order-repeat__slot-container">
      </div>
    </template>
    <template v-if="hasResume">
      <div
        class="modal-order-repeat__resume"
        v-for="block in hasResume"
        :key="block.title">
        <h3 class="modal-order-repeat__resume__title">{{block.title}}</h3>
        <table class="modal-order-repeat__resume__data">
          <tr
            class="modal-order-repeat__resume__row"
            v-for="row in block.table"
            :key="row.label">
            <td class="modal-order-repeat__resume__label">{{row.label}}</td>
            <td class="modal-order-repeat__resume__value">{{row.value}}</td>
          </tr>
        </table>
      </div>
    </template>
    <ul class="modal-order-repeat__buttons">
      <li
        v-for="(node, index) in hasButton"
        :key="index">
        <template v-if="node.type === 'button'">
          <ButtonComponent
            data-cy="showRepeatResults"
            :spinner="node.isLoading"
            :isDisabled="node.isLoading"
            @click.native="runMethod(node.action, node.params ? node.params : null )"
            :label="node.label"
            :key="index"
            v-if="!isIE11" />
          <a
            class="link--simple"
            @click="runMethod(node.action)"
            :key="index"
            v-else>{{node.label}}</a>
        </template>
        <template v-if="node.type === 'link'">
          <a
            class="link--simple"
            @click="runMethod(node.action)"
            :key="index"
          >{{node.label}}</a>
        </template>
      </li>
    </ul>
  </article>
</template>
<script>
import { Utilities } from '../../helpers/utilities'
export default {
  data() {
    return {
      isLoading: false
    }
  },

  props: {
    hasResume: { type: Array },
    hasButton: { type: Array }
  },

  computed: {
    isIE11 () {
      return Utilities.isIE11()
    }
  },

  methods: {
    getContent (name) {
      return this.$slots[name][0]['text']
    },

    repeatOrder (result) {
      this.$emit('repeatOrder', result)
    },

    runMethod (method, param) {
      this[method](param)
    }
  }
}
</script>
<style lang="scss" scoped src="./ModalOrderRepeat.scss"></style>
