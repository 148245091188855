<template>
  <aside class="mobile-order-info">
    <ul class="mobile-order-info__display">
      <li
        v-if="this.$slots.address"
        class="mobile-order-info__result">
          <slot name="address" />
      </li>
      <li
        v-if="this.$slots.quantity"
        class="mobile-order-info__result">
          <slot name="quantity" />
      </li>
    </ul>
    <ul class="mobile-order-info__extra">
      <li
        class="mobile-order-info__extra-code"
        v-if="this.$slots.code">
        <slot name="code" />
      </li>

      <li
        class="mobile-order-info__extra-action"
        v-if="this.$slots.action">
        <slot name="action" />
      </li>
    </ul>
  </aside>
</template>
<script>
export default {
  name: 'MobileOrderInfo'
}
</script>
<style lang="scss" scoped src="./MobileOrderInfo.scss"></style>
