const BASE_URL = 'https://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as'

const Activities = {
  home: 1382813,
  'business-services': 1382814,
  summary: 1382815,
  'personal-data': 1382816,
  recap: 1382817,
  'service-assessment': 1382819,
  'service-assessment-sended': 1382820,
  tagOrder: 1382818,
  unregister: 1382821
}

export class SizmekService {
  static tagPage ({
    page,
    postalCode,
    totalPrice,
    unitPrice,
    litersAmount,
    productType
  }) {
    const activityId = Activities[page]
    if (!activityId) {
      return
    }

    const url = SizmekService.composeUrl({
      rnd: SizmekService.getRandom(),
      ActivityID: activityId,
      CodigoPostal: postalCode,
      Revenue: totalPrice,
      'PrecioLitro combustible': unitPrice,
      LitrosCombustible: litersAmount,
      TipoCombustible: productType
    })

    SizmekService.loadScript(url)
  }

  static composeUrl (fields) {
    let url = BASE_URL
    for (const key in fields) {
      if (fields[key]) {
        url += `&${key}=${fields[key]}`
      }
    }
    return url
  }

  static loadScript (url) {
    const script = document.createElement('script')
    script.setAttribute('src', url)
    script.async = true
    document.body.appendChild(script)
  }

  static getRandom () {
    return Math.random() * 1000000
  }
}
