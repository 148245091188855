<template>
  <section
    class="stars-vote"
  >
    <div class="stars-vote__wrap">
      <div class="stars-vote__label">
        <transition
          mode="out-in"
          :name="`change-${animation}`"
        >
          <div
            :key="label"
            class="stars-vote__stripe"
          >
            {{label}}
          </div>
        </transition>
      </div>
      <div class="stars-vote__evaluation">
        <IconComponent
          v-for="node in reverseRates"
          :name="icon.type"
          :size="icon.size"
          :key="node.label"
          :class="[ voted === node.score ? icon.activeClass : '' ]"
          @click.native="vote(node)"
        >
        </IconComponent>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'stars',
  data () {
    return {
      voted: 0,
      label: ''
    }
  },

  computed: {
    reverseRates () {
      return this.ratings.slice().reverse()
    },

    defaultLabel () {
      return this.defaultMessage
    }
  },

  methods: {
    vote (node) {
      this.voted = node.score
      this.label = node.label
      this.$emit('vote', { type: this.hasType, value: this.voted })
    }
  },

  props: {
    ratings: {
      type: Array,
      default: () => []
    },

    hasType: { type: String },

    icon: {
      type: Object,
      default: () => { return { type: 'IconStar', size: 'custom', activeClass: 'active' } }
    },

    defaultMessage: { type: String },

    animation: {
      type: String,
      default: 'move'
    }
  },

  mounted () {
    this.label = this.defaultMessage
  }
}
</script>
<style lang="scss" scoped src="./StarsVote.scss"></style>
