var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{class:[
    _vm.hasProduct.length !== 0 ? 'modal-basic--hasHeader' : null,
    'modal-basic'
  ]},[(_vm.hasProduct.length !== 0)?_c('header',{class:[
      _vm.hasProduct !== '' ? `modal-basic__header__${_vm.hasProduct[0].type}` : null,
      'modal-basic__header'
    ]},[_c('figure',[_c('img',{attrs:{"src":require(`images/${_vm.hasProduct[0].logo}`),"alt":"logo"}})])]):_vm._e(),_vm._l((this.$slots),function(index,slot){return [_c('div',{key:slot,staticClass:"modal-basic__slot-container",domProps:{"innerHTML":_vm._s(_vm.getContent(slot))}})]}),(_vm.hasButton)?_c('ul',{staticClass:"modal-basic__buttons"},_vm._l((_vm.hasButton),function(node,index){return _c('li',{key:index},[(node.type === 'button')?[(!_vm.isIE11)?_c('ButtonComponent',{key:index,attrs:{"hasFullWidth":"","label":node.label,"isSecondary":(node.isSecondary)},nativeOn:{"click":function($event){return _vm.runMethod(node.action, node.params ? node.params : null)}}}):_c('a',{key:index,staticClass:"link--simple",on:{"click":function($event){return _vm.runMethod(node.action, node.params ? node.params : null)}}},[_vm._v(_vm._s(node.label))])]:_vm._e(),(node.type === 'link')?[_c('a',{key:index,staticClass:"link--simple",on:{"click":function($event){return _vm.runMethod(node.action, node.params ? node.params : null)}}},[_vm._v(_vm._s(node.label))])]:_vm._e()],2)}),0):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }