var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[
    _vm.isFocused ? 'input--isFocused' : null,
    _vm.isFailed ? 'input--isFailed' : null,
    _vm.isDisabled ? 'input--isDisabled' : null,
    _vm.hasCustomWidth !== '' ? 'input--hasCustomWidth' : null,
    _vm.$slots['icon'] ? 'input--hasIcon' : null,
    'input'
  ],style:([ _vm.hasCustomWidth !== '' ? {'max-width' : _vm.hasCustomWidth} : null ])},[(_vm.$slots['label'])?_c('label',{staticClass:"input__label",attrs:{"for":_vm.hasID}},[_vm._t("label")],2):_vm._e(),_c('div',{staticClass:"input__wrap"},[_vm._t("icon"),_c('input',{directives:[{name:"numericOnly",rawName:"v-numericOnly",value:(_vm.isNumber),expression:"isNumber"}],staticClass:"input__field",attrs:{"id":_vm.hasID,"type":"text","placeholder":_vm.hasPlaceholder,"readonly":_vm.isDisabled,"maxlength":_vm.hasMax},domProps:{"value":_vm.value},on:{"focus":_vm.focused,"blur":_vm.blurred,"input":function($event){return _vm.sendModel($event)}}})],2),(_vm.$slots['errorMessage'])?_c('span',{staticClass:"input__failed-message"},[_vm._t("errorMessage")],2):_vm._e(),(_vm.$slots['extratext'])?_c('span',{staticClass:"input__extratext"},[_vm._t("extratext")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }