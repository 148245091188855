<template>
    <aside
      :class="[
        'cognitive-message',
        `cognitive-message--${display}`
      ]">
      {{message}}
    </aside>
</template>

<script>
export default {
  name: 'CognitiveMessage',
  props: {
    message: {
      type: String,
      default: ''
    },

    display: {
      type: String,
      default: 'primary'
    }
  }
}
</script>

<style lang="scss" scoped src="./CognitiveMessage.scss"></style>
