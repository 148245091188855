import { http } from './index'
import axios from 'axios'

/**
 * This cancel token lets you cancel request on demand
 * @type {CancelTokenStatic|*}
 */
const CancelToken = axios.CancelToken
let cancelations = []

class Assessment {
  static getRating = async payload => {
    try {
      const res = await http.get(
        '/rating',
        {
          params: payload
        },
        {
          cancelToken: new CancelToken(function executor (c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  static setRating = async payload => {
    try {
      const res = await http.post(
        '/rating', payload,
        {
          cancelToken: new CancelToken(function executor (c) {
            cancelations.push(c)
          })
        }
      )
      return res
    } catch (error) {
      return error.response
    }
  }
}

export { Assessment }
