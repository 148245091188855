import Vue from 'vue'
import VueI18n from 'vue-i18n'
import literalES from './es'
import literalEN from './en'

import * as moment from 'moment/moment'

const messages = {
  es: {
    message: literalES
  },
  en: {
    message: literalEN
  }
}

let lang = localStorage.getItem('lang') || 'es'

moment.locale(lang || process.env.VUE_APP_I18N_FALLBACK_LOCALE)

Vue.use(VueI18n)

export default new VueI18n({
  locale: lang,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages
})
