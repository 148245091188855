import { render, staticRenderFns } from "./Products.vue?vue&type=template&id=6e894cca&scoped=true"
import script from "./Products.vue?vue&type=script&lang=js"
export * from "./Products.vue?vue&type=script&lang=js"
import style0 from "./Products.scss?vue&type=style&index=0&id=6e894cca&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e894cca",
  null
  
)

export default component.exports