<template>
  <div :id="mySwiperId" class="swiper-container swiper">
    <ul class="swiper-wrapper">
      <li v-for="(bullet, idx) in bullets" class="swiper-slide swiper__slide" :key="`swiper-${mySwiperId}-${idx}`">
        <span v-if="bullet.icon" class="swiper__icon"><Icon :name="bullet.icon" size="medium"/></span>
        <component :is="!!bullet.tagName ? bullet.tagName : 'h2'"
                   class="swiper__text">{{ bullet.text }}</component>
      </li>
    </ul>
    <div :class="['swiper-pagination', 'swiper__pagination', this.bullets.length <= this.bulletsOnDesktop ? 'swiper__pagination--hidden' : '']"></div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import Icon from '../icons/Icon'

export default {
  name: 'Swiper',
  components: { Icon },
  data() {
    return {
      mySwiper: null,
      mySwiperId: `swiper-container--${(Math.random() * 10000).toFixed()}`
    }
  },
  props: {
    bullets: {
      type: Array,
      default: () => []
    },
    bulletsOnDesktop: {
      type: Number,
      default: 3
    }
  },
  computed: {
    swipperOptions: function() {
      return {
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: {
          delay: 5000
        },
        // Responsive breakpoints
        breakpointsInverse: true,
        breakpoints: {
          // when window width is >= 768px
          992: {
            slidesPerView: this.bulletsOnDesktop,
            allowSlidePrev: this.bullets.length > this.bulletsOnDesktop,
            allowSlideNext: this.bullets.length > this.bulletsOnDesktop
          }
        }
      }
    }
  },
  mounted() {
    this.mySwiper = new Swiper(`#${this.mySwiperId}`, this.swipperOptions)
  }
}
</script>

<style lang="scss" scoped src="./Swiper.scss"></style>
