import { GtmService } from '../../services/gtm'
import { SizmekService } from '../../services/sizmek'

export const analytics = {
  namespaced: true,

  actions: {
    pushVirtualPage: ({ rootState }, { path, isPrivateArea, pageName }) => {
      GtmService.pushVirtualPage({
        userId: rootState.user.id,
        gigyaServices: rootState.user.gigyaServices,
        path,
        pageName,
        isPrivateArea,
        language: rootState.i18n.locale
      })
    },

    pushEcommerceView: ({ rootState }, { stepNumber }) => {
      const {
        orders: {
          order: { amountInfo }
        }
      } = rootState
      GtmService.pushEnhancedEcommerceView({
        userId: rootState.user.id,
        stepNumber,
        product: {
          name: amountInfo.type,
          id: amountInfo.type,
          price: amountInfo.unitPrice,
          category: `gasoleo/calefaccion/${amountInfo.type.toLowerCase()}`,
          variant: amountInfo.isRecommended ? 'recomendado' : 'normal',
          quantity: amountInfo.quantity
        }
      })
    },

    pushEcommerceOption: ({ rootState }, { stepNumber, option, extraParams }) => {
      GtmService.pushEnhancedEcommerceOption({
        userId: rootState.user.id,
        stepNumber,
        option,
        extraParams
      })
    },

    pushTransaction ({ rootState }, { orderId, providerName }) {
      const {
        orders: {
          order: { amountInfo, timeInfo }
        }
      } = rootState
      GtmService.pushTransaction({
        userId: rootState.user.id,
        orderId,
        providerName,
        totalPrice: amountInfo.totalPrice,
        timeSlot: timeInfo.time === 'MORNING' ? 'manana' : 'tarde',
        product: {
          name: amountInfo.type,
          id: amountInfo.type,
          price: amountInfo.unitPrice,
          category: `gasoleo/calefaccion/${amountInfo.type.toLowerCase()}`,
          variant: amountInfo.isRecommended ? 'recomendado' : 'normal',
          quantity: amountInfo.quantity
        },
        searchType: amountInfo.filter === 'LITERS' ? 'litros' : 'euros'
      })
    },

    pushAvailabilityEvent (
      { rootState },
      { action, label, availability, product, category }
    ) {
      GtmService.pushEvent({
        userId: rootState.user.id,
        category: category || 'disponibilidad',
        action,
        label: label || rootState.orders.order.amountInfo.quantity,
        extraParams: {
          disponibilidad: availability,
          cp: rootState.orders.order.addressInfo.postalCode.value,
          producto: product,
          tipo_busqueda: rootState.orders.order.amountInfo.filter === 'LITERS' ? 'litros' : 'euros'
        }
      })
    },

    pushGigyaEvent ({ rootState }, { category, action, label, extraParams }) {
      GtmService.pushGigyaEvent({
        userId: rootState.user.id,
        category,
        action,
        label,
        extraParams
      })
    },

    tagPage ({ rootState }, { page, extraParams }) {
      SizmekService.tagPage({
        page,
        ...extraParams
      })
    }
  }
}
