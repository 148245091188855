import * as Cookies from 'js-cookie'

const COOKIES_POLICY_KEY = 'cookiesPolicyAccepted'

export const cookies = {
  namespaced: true,

  state: {
    cookiesPolicyAccepted: Cookies.get(COOKIES_POLICY_KEY) === 'true'
  },

  mutations: {
    updateCookiesPolicy: (state, value) => {
      state.cookiesPolicyAccepted = value
    }
  },

  actions: {
    acceptCookiesPolicy: ({ commit }) => {
      const date = new Date()
      date.setFullYear(date.getFullYear() + 1)
      Cookies.set(COOKIES_POLICY_KEY, 'true', { expires: 365, secure: true })
      commit('updateCookiesPolicy', true)
    }
  }
}
