<template>
  <aside
    :style="[background !== '' ? {backgroundImage: `url(${require('images/banners/' + background + '')})`} : null ]"
    :class="[
      'banner',
      isDark ? 'banner--dark' : null ]">
    <div class="banner__wrap">
      <h3
        v-if="title"
        class="banner__title"
        v-html="title">
      </h3>
      <p
        v-if="description"
        class="banner__description"
        v-html="description">
      </p>
      <template v-if="Object.keys(action).length > 0">
        <ButtonComponent
          v-if="action.isButton"
          :label="action.label"
          @click.native="handleEmit"
          class="banner__action">
        </ButtonComponent>
      </template>
    </div>
  </aside>
</template>
<script>
export default {
  name: 'Banner',

  props: {
    background: { type: String },
    title: { type: String },
    description: { type: String },
    isDark: {
      type: Boolean,
      default: false
    },
    action: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    handleEmit(fn) {
      this.$emit('bannerClick')
    }
  }
}
</script>
<style lang="scss" scoped src="./Banner.scss"></style>
