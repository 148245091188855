<template>
    <section class="cta">
        <h1 class="cta__title">{{title}}</h1>
        <h2 class="cta__subtitle">{{subtitle}}</h2>
        <ButtonComponent
            class="cta__button"
            :label="ctaLabel"
            @click.native="onCTATriggered"
        />
    </section>
</template>

<script>
export default {
  name: 'CallToAction',
  methods: {
    onCTATriggered () {
      this.$emit('ctaClick')
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    ctaLabel: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped src="./CallToAction.scss"></style>
