<template>
  <aside class="tabs-switch">
    <header>
      <nav class="tabs-switch__navigation">
        <ul>
          <li
            v-for="(tab, index) in this.hasTabs"
            :class="[
              tab.content === getComponent ? 'selected' : null
            ]"
            :key="index"
            :data-key="tab.content"
            :data-cy="tab.content"
            @click="change(tab.content)"
          >
            {{tab.label}}
          </li>
        </ul>
      </nav>
    </header>
    <section class="tabs-switch__route">
      <component
        :is="getComponent"
        :hasDestination="hasDestination">
      </component>
    </section>
  </aside>
</template>
<script>
export default {
  name: 'TabsSwitch',

  props: {
    hasTabs: {
      type: Array,
      default: () => []
    },

    hasDestination: {
      type: String,
      default: ''
    },

    isComponent: {
      type: String,
      default: ''
    }
  },

  computed: {
    getComponent () {
      return this.isComponent
    }
  },

  methods: {
    change (element) {
      this.$emit('change', element)
    }
  }
}
</script>
<style lang="scss" scoped src="./TabsSwitch.scss"></style>
