<template>
  <div class="locale-wrapper">
    <div class="sl-nav">
      <ul>
        <li class="currentLang">
          <b @click="drawer" :class="drawerState ? 'chevron' : 'chevron bottom'"> {{ $t("message.LANGUAGE") }}</b>
          <ul :class="drawerState ? 'listShow' : 'listHide'">
            <li v-for="locale in locales" :key="locale.id" @click="switchLocale(locale.id)">
              <span>{{ locale.text }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'LocaleSwitcher',
  data() {
    return {
      currentLang: this.$i18n.locale,
      locales: this.languages(),
      drawerState: false
    }
  },
  methods: {
    ...mapActions('i18n', ['changeLocale']),
    ...mapGetters({
      languages: 'i18n/languages'
    }),
    drawer() {
      this.drawerState = !this.drawerState
    },
    switchLocale(locale) {
      this.changeLocale(locale)
      this.$router.go(0)
    }
  },
  computed: {}
}
</script>

<style scoped>
.orangeIcon {
  color: #ff6600;
  filter: invert(71%) sepia(53%) saturate(5986%) hue-rotate(359deg) brightness(100%)
    contrast(101%);
}
.locale-wrapper {
  margin-right: 20px;
}
.chevron::after {
  border-style: solid;
  border-width: 0.16em 0.16em 0 0;
  content: '';
  display: inline-block;
  height: 0.65em;
  left: 0.40em;
  position: relative;
  top: 4px;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.65em;
  color: #ff8200;
}

.chevron.bottom:after {
  top: 0px;
  transform: rotate(135deg);
}
.sl-nav::before {
  vertical-align: -50%;
  margin: 0 0.5rem 0 1rem;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBmaWxsPSIjZmY4MjAwIj48IS0tISBGb250IEF3ZXNvbWUgRnJlZSA2LjIuMCBieSBAZm9udGF3ZXNvbWUgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbSBMaWNlbnNlIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20vbGljZW5zZS9mcmVlIChJY29uczogQ0MgQlkgNC4wLCBGb250czogU0lMIE9GTCAxLjEsIENvZGU6IE1JVCBMaWNlbnNlKSBDb3B5cmlnaHQgMjAyMiBGb250aWNvbnMsIEluYy4gLS0+PHBhdGggZD0iTTM1MiAyNTZjMCAyMi4yLTEuMiA0My42LTMuMyA2NEgxNjMuM2MtMi4yLTIwLjQtMy4zLTQxLjgtMy4zLTY0czEuMi00My42IDMuMy02NEgzNDguN2MyLjIgMjAuNCAzLjMgNDEuOCAzLjMgNjR6bTI4LjgtNjRINTAzLjljNS4zIDIwLjUgOC4xIDQxLjkgOC4xIDY0cy0yLjggNDMuNS04LjEgNjRIMzgwLjhjMi4xLTIwLjYgMy4yLTQyIDMuMi02NHMtMS4xLTQzLjQtMy4yLTY0em0xMTIuNi0zMkgzNzYuN2MtMTAtNjMuOS0yOS44LTExNy40LTU1LjMtMTUxLjZjNzguMyAyMC43IDE0MiA3Ny41IDE3MS45IDE1MS42em0tMTQ5LjEgMEgxNjcuN2M2LjEtMzYuNCAxNS41LTY4LjYgMjctOTQuN2MxMC41LTIzLjYgMjIuMi00MC43IDMzLjUtNTEuNUMyMzkuNCAzLjIgMjQ4LjcgMCAyNTYgMHMxNi42IDMuMiAyNy44IDEzLjhjMTEuMyAxMC44IDIzIDI3LjkgMzMuNSA1MS41YzExLjYgMjYgMjEgNTguMiAyNyA5NC43em0tMjA5IDBIMTguNkM0OC42IDg1LjkgMTEyLjIgMjkuMSAxOTAuNiA4LjRDMTY1LjEgNDIuNiAxNDUuMyA5Ni4xIDEzNS4zIDE2MHpNOC4xIDE5MkgxMzEuMmMtMi4xIDIwLjYtMy4yIDQyLTMuMiA2NHMxLjEgNDMuNCAzLjIgNjRIOC4xQzIuOCAyOTkuNSAwIDI3OC4xIDAgMjU2czIuOC00My41IDguMS02NHpNMTk0LjcgNDQ2LjZjLTExLjYtMjYtMjAuOS01OC4yLTI3LTk0LjZIMzQ0LjNjLTYuMSAzNi40LTE1LjUgNjguNi0yNyA5NC42Yy0xMC41IDIzLjYtMjIuMiA0MC43LTMzLjUgNTEuNUMyNzIuNiA1MDguOCAyNjMuMyA1MTIgMjU2IDUxMnMtMTYuNi0zLjItMjcuOC0xMy44Yy0xMS4zLTEwLjgtMjMtMjcuOS0zMy41LTUxLjV6TTEzNS4zIDM1MmMxMCA2My45IDI5LjggMTE3LjQgNTUuMyAxNTEuNkMxMTIuMiA0ODIuOSA0OC42IDQyNi4xIDE4LjYgMzUySDEzNS4zem0zNTguMSAwYy0zMCA3NC4xLTkzLjYgMTMwLjktMTcxLjkgMTUxLjZjMjUuNS0zNC4yIDQ1LjItODcuNyA1NS4zLTE1MS42SDQ5My40eiIvPjwvc3ZnPg==);
}
.sl-nav {
  display: inline;
  font-family: "repsolRegular", sans-serif;
  font-size: 0.875rem;
}
.sl-nav:hover {
  color: #ff8200;
}
.sl-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
}
.sl-nav li {
  cursor: pointer;
  padding-bottom: 2px;
}
.sl-nav li b:hover {
  color: #ff8200;
}
.listHide {
  display: none !important;
}
.listShow{
  position: absolute !important;
  top: 29px;
  right: -24px;
  display: block;
  background: #fff;
  width: 120px;
  padding-top: 0px;
  z-index: 99;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 65%);
}
.triHide {
  display: none !important;
}
.triShow {
  position: absolute;
  top: 15px;
  right: 16px;
  z-index: 100;
  height: 14px;
  overflow: hidden;
  width: 30px;
  background: transparent;
}
.triShow:after {
  content: "";
  display: block;
  z-index: 100;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius: 2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.sl-nav li ul li {
  position: relative;
  text-align: center;
  background: transparent;
  padding: 12px 16px;
  border-bottom: solid 1px #ddd;
  z-index: 99;
  font-size: 15px;
  color: #3c3c3c;
}
.sl-nav li ul li:last-child {
  border-bottom: 0;
}
.sl-nav li ul li:hover{
  color: #464646;
  background-color: #92d9e2;
  border-radius: 5px;
}
.sl-nav li ul li span.active {
  color: #146c78;
}
</style>
