<template>
  <i :class="[size ? 'icon--' + size : null, extraClass ? extraClass : null, `icon-${name}`, 'icon']">
    <component :is="name"/>
  </i>
</template>

<script>
import * as Icons from './iconsFactory.js'

export default {
  name: 'Icon',

  data() {
    return {}
  },

  components: {
    ...Icons
  },

  props: {
    /**
     * ['IconFacebook', 'IconFlickr', 'IconInstagram', 'IconLinkedin', 'IconTwitter', 'IconYoutube',
     *  'IconInfo', 'IconPump', 'IconPin', 'IconEdit', 'IconStar', 'IconTruck',
     *  'IconUser', 'IconHamburguer', 'IconCaretRight', 'IconEnter', 'IconClose',
     *  'IconCalendar', 'IconSearch', 'IconCaret', 'IconLeave', 'IconSmartphone', 'IconCheck', 'IconStepCheck',
     *  'IconPlus', 'IconMinus' ]
    ]
     */
    name: {
      type: String,
      required: true,
      default: ''
    },
    /**
     * ['big', 'medium', 'small', 'xsmall', 'xxsmall', 'xxxsmall']
     */
    size: {
      type: String,
      default: 'big'
    },

    extraClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style src="./Icon.scss" scoped lang="scss"></style>
