<template>
  <section
    ref="result-list"
    :class="[
      checkPromotionExist ? `result-list--extraTopSpace-${voucherValidation ? 'valid' : 'invalid'}` : null,
      showEuroAlert ? 'result-list--extraEuroMessage' : null,
      'result-list']">

    <!-- START: EURO SEARCH ALERT -->
    <transition
      appear
      v-on:before-enter="setExpandHeight(arguments, {variable: '--expand-alertEuro-height', queryElement: '.result-list__alert__message'})"
      name="expandHeight">
        <aside
          class="result-list__alert"
          v-if="showEuroAlert">
          <div class="result-list__alert__message grid">
            <p>
              {{$t(`message.EURO_SEARCH_ALERT`)}}
              <a
                @click="displayInfo([
                  { 'name': 'titleLiters', 'content': `<h2 class='modal-basic__title'>${$t(`message.LITER_INFO_TITLE`)}</h2>` },
                  { 'name': 'descriptionLiters', 'content': `<div class='modal-basic__text'>${$t(`message.LITER_INFO_TEXT`)}</div>` },
                  { 'name': 'descriptionLiters2', 'content': `<div class='modal-basic__text'>${$t(`message.LITER_INFO_TEXT2`)}</div>` },
                  { 'name': 'titleEuros', 'content': `<h2 class='modal-basic__title'>${$t(`message.EUROS_INFO_TITLE`)}</h2>` },
                  { 'name': 'descriptionEuros', 'content': `<div class='modal-basic__text'>${$t(`message.EUROS_INFO_TEXT`)}</div>` },
                  { 'name': 'descriptionEuros2', 'content': `<div class='modal-basic__text'>${$t(`message.EUROS_INFO_TEXT2`)}</div>` }
                ])"
                class="link--simple">
                {{$t(`message.EURO_SEARCH_ALERT_LINK`)}}
              </a>
            </p>
          </div>
        </aside>
    </transition>
    <!-- END: EURO SEARCH ALERT -->

    <div
      class="result-list__wrap grid"
      v-if="orderList.length > 0">

      <!-- START: PROMOTIONAL CODE VALIDATION STATE -->
      <transition
        appear
        v-on:before-enter="setExpandHeight(arguments, {variable: '--expand-promotionalCode-height', queryElement: '.promotional-message-validation__content'})"
        name="expandHeight">
        <PromotionMessageValidation
          v-if="voucherValidation && windowWidth"
          valid
          :showInfo = "promoLegalBasisUrl"
          @displayInfo = "showPromoInfo"
          data-cy="promotional-code-message">
          <template #message>{{$t(`message.PROMOTIONAL_CODE_EXIST`)}} {{voucherDescription}}</template>
        </PromotionMessageValidation>
      </transition>
      <!-- END: PROMOTIONAL CODE VALIDATION STATE-->

      <transition
        appear
        name="appearInfo"
        mode="out-in">
        <div class="result-list__info">
          <h2>
            {{$t(`message.RESULT_LIST_LABEL_TITLE`)}}
            <LinkButton @click.native="displayInfo([
                { 'name': 'title', 'content': `<h2 class='modal-basic__title'>${$t(`message.MODAL_ORDER_OPTIONS_TITLE`)}</h2>` },
                { 'name': 'description', 'content': `<div class='modal-basic__text'>${$t(`message.MODAL_ORDER_OPTIONS_TEXT`)}</div>` },
                { 'name': 'title2', 'content': `<h2 class='modal-basic__title'>${$t(`message.MODAL_ORDER_OPTIONS_TITLE2`)}</h2>` },
                { 'name': 'description2', 'content': `<div class='modal-basic__text'>${$t(`message.MODAL_ORDER_OPTIONS_TEXT2`)}</div>` }
              ])">
              <template>
                <IconComponent name="IconInfo" size="xxsmall" />
              </template>
            </LinkButton>
          </h2>
        </div>
      </transition>

      <!-- START: COGNITIVE MESSAGE -->
      <CognitiveMessage :message="$t(`message.COGNITIVE_MESSAGE_RESULTS`)"/>
      <!-- END: COGNITIVE MESSAGE -->

      <!-- START: ORDER RESULT LIST -->
      <transition-group
        appear
        @enter="enter"
        @leave="leave"
        enter-active-class="resultsIn"
        leave-active-class="void"
        tag="ul"
        class="result-list__displayed"
        ref="results"
        mode="in-out">
        <template v-for="(day, index) in orderHighlightedProduct">
          <li
            :key="day.date"
            :data-index="index"
            class="result-list__displayed__node">
            <ResultRecord class="result-list__record">
              <template #date>
                <li class="result-record__date-label">{{$t(`message.RESULT_DATE_LABEL`)}}</li>
                <li>
                  <span>{{day.date | formatDate}}</span>
                  <span>{{day.date | formatMonth}}</span>
                </li>
              </template>
              <ResultDetail
                v-for="(prod, index) in day.products"
                :slot="prod.productType"
                :hasType="prod.productType"
                :key="index"
                :totalPrice="$t(`message.RESULT_TOTAL_PRICE`, {totalPrice: isFilterByEuros ? formatPrice(literForEuros(prod.price) * prod.price) : formatPrice(quantity * prod.price)})"
                @showInfo="showProductInfo"
                @showRecommendedInfo="showRecommendedInfo">
                <template
                  v-if="prod.reccomended"
                  #recommended>
                  <IconComponent name="IconStar" size="xxxsmall" />
                  {{$t(`message.RESULT_RECOMMENDED_LABEL`)}}
                </template>
                <template
                  v-if="isFilterByEuros"
                  #euros>
                  {{ literForEuros(prod.price) }} {{$t(`message.ORDER_SEARCH_LITERS_CHOOSED`)}}
                </template>
                <template #unitPrice>
                  <span>{{$t(`message.RESULT_UNIT_PRICE_LABEL`, {price: parseFloat(prod.price).toLocaleString()})}}</span>
                </template>
                <template #action>
                  <ButtonComponent
                    @click.native="sendRequest({
                      id: prod.id,
                      type: prod.productType,
                      unitPrice: prod.price,
                      quantity: isFilterByEuros ? literForEuros(prod.price) : quantity,
                      totalPrice: isFilterByEuros ? (literForEuros(prod.price) * prod.price).toFixed(2) : (quantity * prod.price).toFixed(2),
                      isRecommended: prod.reccomended
                    }, day.date)"
                    hasFullWidth
                    :label="$t(`message.RESULT_BUTTON_LABEL`)" />
                </template>
              </ResultDetail>
            </ResultRecord>
          </li>
        </template>
      </transition-group>
      <!-- END: ORDER RESULT LIST -->
      <p class="result-list__description">{{$t(`message.RESULT_LIST_LABEL_DESCRIPTION`)}}</p>
    </div>
   </section>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { LocalStorage } from '../../services/storage/localStorage'
import { Constants } from '../../constants'

export default {
  name: 'ResultList',

  data () {
    return {
      resultSize: 0,
      resultHeight: 0,
      clonedResults: []
    }
  },

  computed: {
    ...mapState(['orders']),
    ...mapGetters({
      totalPrice: 'orders/getTotalPrice'
    }),

    orderList () {
      return this.orders.response
    },

    orderFilterList () {
      if (Object.keys(this.orderList).length !== 0) {
        return this.orderList
          .map(node => {
            const products = node.products
            node.products = products.filter(product =>
              product.liters >= Number(this.orders.order.amountInfo.quantity)
            )
            return node
          })
          .filter(node => {
            return node.products && node.products.length !== 0
          })
      }
    },

    orderHighlightedProduct () {
      if (this.orderFilterList) {
        const array = this.orderFilterList
          .reduce((acc, val) => {
            acc = acc.concat(val.products)
            return acc
          }, [])
          .filter(product => product.productType === 'BiEnergy')
          .sort((a, b) => a.price - b.price)[0]

        if (array) array.reccomended = true
        if (!this.resultsOut) return this.orderFilterList
      }
    },

    checkPromotionExist () {
      return this.orders.order.voucher.isValid !== ''
    },

    voucherValidation () {
      return this.orders.order.voucher.isValid
    },

    resultsOut () {
      return this.orders.order.resultsOut
    },

    windowWidth () {
      return window.innerWidth > 768
    },

    voucherDescription () {
      return this.orders.order.voucher.description
    },

    isFilterByEuros () {
      return this.orders.order.amountInfo.filter === 'EUROS'
    },

    requiredPrice () {
      return this.orders.order.amountInfo.unitPrice
    },

    requiredAmount () {
      return this.orders.order.amountInfo.filterField
    },

    quantity () {
      return this.orders.order.amountInfo.quantity
    },

    deliveryTime () {
      return this.orders.order.timeInfo.time
    },

    showEuroAlert () {
      return this.isFilterByEuros && this.requiredAmount !== ''
    },

    isAlertDisplay () {
      return this.alertMessageState
    },

    promoLegalBasisUrl () {
      return this.orders.order.voucher.legalBasisUrl !== '' ? this.orders.order.voucher.legalBasisUrl : undefined
    }
  },

  methods: {
    literForEuros (unitPrice) {
      const actualLiters = Math.floor(this.requiredAmount / unitPrice)
      if (actualLiters > Constants.MAX_ACCEPTED) { return Constants.MAX_ACCEPTED } else
      if (actualLiters < Constants.MIN_ACCEPTED) { return Constants.MIN_ACCEPTED } else { return actualLiters }
    },

    ...mapActions('modal', [
      'setModalDisplay',
      'setModalComponent'
    ]),

    ...mapActions('orders', [
      'setAmount',
      'setDate',
      'setResultsOut',
      'setQuantity',
      'setFilterChange'
    ]),

    openBasicModal (values = { props: {}, slots: [] }) {
      const payload = {
        state: true,
        name: 'ModalBasic',
        allowClose: false,
        size: 490,
        props: values.props,
        slots: values.slots
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    displayInfo (slots) {
      const values = {
        slots: slots
      }

      this.openBasicModal(values)
    },

    showProductInfo (type) {
      const values = {
        props: [ { 'hasProduct': [{ 'type': type, 'logo': 'png/logos/' + type + '.png' }] } ],
        slots: [
          { 'name': 'title', 'content': `<h2 class='modal-basic__title'>${this.$t(`message.${'MODAL_PRODUCT_' + type.toUpperCase() + '_TITLE'}`)}</h2>` },
          { 'name': 'description', 'content': `<div class='modal-basic__text'>${this.$t(`message.${'MODAL_PRODUCT_' + type.toUpperCase() + '_TEXT'}`)}</div>` }
        ]
      }

      this.openBasicModal(values)
    },

    sendRequest (amountValues, date) {
      this.setAmount(amountValues)
      this.setDate({ date: this.getDate(date) })
      this.$router.push({ name: 'summary' })

      LocalStorage.update('orderPending', { amountInfo: amountValues })

      LocalStorage.update('orderPending', { timeInfo: {
        date: this.getDate(date),
        time: this.deliveryTime
      } })
    },

    getDate (date) {
      return `${Vue.filter('capitalize')(Vue.filter('formatDate')(date))} ${this.$t(`message.RESUME_DATE_ARTICLE`)} ${Vue.filter('capitalize')(Vue.filter('formatMonth')(date))}`
    },

    setExpandHeight (args, props) {
      this.$nextTick(() => {
        let componentStyle = this.$el.style
        componentStyle.setProperty(props.variable, `${this.$el.querySelector(props.queryElement).offsetHeight}px`)
      })
    },

    enter (el) {
      el.style.animationDelay = `${(Number(el.dataset.index) + 1) * 0.6}s`
      this.resultSize = this.orderHighlightedProduct.length
    },

    leave (el) {
      let node = el.querySelector('.result-record')
      node.style.animationDelay = `${Number(el.dataset.index) * 0.3}s`
      node.classList.add('resultsOut')
      node.addEventListener('animationend', () => {
        if (Number(el.dataset.index) === (this.resultSize - 1)) {
          let wrap = this.$el.querySelector('.result-list__displayed')
          wrap.innerHTML = ''
          this.setResultsOut({ state: false })
        }
      })
    },

    formatPrice (value) {
      const res = value.toFixed(2).toString().split('.')
      const integer = parseFloat(res[0]).toLocaleString()
      const decimal = res[1]
      return `${integer}<span>,${decimal}€</span>`
    },

    showRecommendedInfo () {
      const values = {
        slots: [
          { 'name': 'title', 'content': `<h2 class='modal-basic__title'>${this.$t('message.RECOMMENDED_OPTION_TITLE')}</h2>` },
          { 'name': 'description', 'content': `<div class='modal-basic__text'>${this.$t('message.RECOMMENDED_OPTION_DESCRIPTION')}</div>` }
        ],
        props: {
          hasButton: [
            {
              action: 'close',
              label: this.$t(`message.REQUEST_LIMIT_BUTTON_LABEL`),
              type: 'button'
            }
          ]
        }
      }

      this.openBasicModal(values)
    },

    showPromoInfo () {
      const values = {
        slots: [
          { 'name': 'descriptionLiters', 'content': `<div class='modal-basic__text'>${this.$t(`message.PROMO_LEGAL_BASIS`, { url: `${this.promoLegalBasisUrl}` })}</div>` }
        ]
      }

      this.openBasicModal(values)
    }
  }
}
</script>
<style lang="scss" scoped src="./ResultList.scss"></style>
