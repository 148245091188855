<template>
  <article class="modal-distributor">
    <!-- MODAL TITLE -->
    <header
      v-if="this.$slots['title']"
      class="modal-distributor__header">
      <h2 class="modal-distributor__title"><slot name="title" /></h2>
    </header>

    <!-- PROVIDER INFO:
      1. name
      2. phone
      3. email
    -->
    <ul class="modal-distributor__schedule">
      <li
        v-if="image"
        class="modal-distributor__image">
        <figure class="modal-distributor__image__wrap">
          <img
            :src="require(`../../assets/images/${image.src}`)"
            :alt="image.alt" />
        </figure>
      </li>
      <li class="modal-distributor__data">
        <h3
          v-if="provider && provider.name"
          class="modal-distributor__data__name">
          {{provider.name}}
        </h3>
        <ul
          v-if="provider && provider.details && provider.details.length > 0"
          class="modal-distributor__data__details">
          <li
            v-for="detail in provider.details"
            v-if="detail.data && detail.data !== ''"
            :key="detail.label">
            <span v-html="detail.label"></span>
            <span v-html="detail.data"></span>
          </li>
        </ul>
      </li>
    </ul>

    <!-- MODAL ACTION
      1. button
      2. simple link
     -->
     <ul class="modal-distributor__actions">
       <li
        v-if="hasButton"
        v-for="(node, index) in hasButton"
        :key="index">
        <template v-if="node.type === 'button'">
          <ButtonComponent
            @click.native="runMethod(node.action)"
            :label="node.label"
            :key="index"
            :isSecondary=(node.isSecondary) />
        </template>
        <template v-if="node.type === 'link'">
          <a
            class="link--simple"
            @click="runMethod(node.action)"
            :key="index">{{node.label}}</a>
        </template>
       </li>
     </ul>
  </article>
</template>
<script>
export default {
  name: 'ModalDistributor',

  props: {
    image: { type: Object },
    provider: { type: Object },
    hasButton: { type: Array }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    runMethod (method) {
      this[method]()
    }
  }
}
</script>
<style lang="scss" scoped src="./ModalDistributor.scss"></style>
