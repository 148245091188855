<template>
  <section></section>
</template>

<script>
import Vue from 'vue'
import { GigyaService } from '../../services/gigya'
import { mapActions } from 'vuex'

export default {
  name: 'ConfirmRegistration',

  methods: {
    ...mapActions('orders', [
      'setID',
      'setDate',
      'setAmount',
      'setAddress',
      'setQuantity',
      'setPayment',
      'setTime',
      'setExtraAddress',
      'getOrderDraft',
      'setVoucher',
      'setVoucherValidation',
      'setVoucherDescription',
      'setVoucherType'
    ]),

    async getOrderDraftRequest ({ UID, UIDSignature, signatureTimestamp }) {
      const draft = await this.getOrderDraft({ UID, UIDSignature, signatureTimestamp })

      if (draft.code) {
        if ('extraInfo' in draft) {
          const ID = draft.extraInfo.pendingOrder.offerId
          const order = draft.extraInfo.pendingOrder.order

          this.setDraftData({ ID, ...order })
        } else {
          this.$store.dispatch(`orders/${draft.code}`)
        }
      } else {
        const ID = draft.offerId
        const order = draft.order
        this.setDraftData({ ID, ...order })
      }
    },

    setDraftData ({ ID, additionalAddress, address, amount, deliveryDate, paymentOption, postalCode, productType, timeSlot, unitPrice, voucher }) {
      this.setAddress({ address, postalCode })
      this.setAmount({
        id: ID,
        type: productType,
        unitPrice,
        totalPrice: this.setTotalPrice(unitPrice, amount),
        isRecommended: true
      })
      this.setQuantity({ quantity: amount })
      this.setDate({ date: this.getDate(deliveryDate) })
      this.setTime({ time: timeSlot })
      this.setPayment({ payment: paymentOption })
      this.setExtraAddress({ extraAddress: additionalAddress })
      if (voucher.code) {
        this.setVoucher({ code: voucher.code })
        this.setVoucherDescription({ description: voucher.description })
        this.setVoucherValidation({ isValid: true })
        this.setVoucherType({ type: voucher.type })
      }
      this.$router.push({ name: 'recap' })
    },

    getDate (date) {
      return `${Vue.filter('capitalize')(Vue.filter('formatDate')(date))} ${this.$t(`message.RESUME_DATE_ARTICLE`)} ${Vue.filter('capitalize')(Vue.filter('formatMonth')(date))}`
    },

    setTotalPrice (price, amount) {
      const total = Math.round(price * amount * 100) / 100
      return total.toFixed(2)
    }
  },

  mounted () {
    const { regToken } = this.$route.query
    GigyaService.finalizeRegistration(regToken).then(event => {
      const service =
        event.data &&
        event.data.Service &&
        Object.keys(event.data.Service).join(',')
      this.$store.dispatch('analytics/pushGigyaEvent', {
        category: 'gigya_verificacion',
        action: 'registro_verificacion',
        label: service,
        extraParams: {
          userId: event.UID,
          login: 'logged',
          service
        }
      })
      if (event.user) {
        const {
          user: { UID, loginProvider }
        } = event
        this.$store.dispatch('analytics/pushGigyaEvent', {
          category: 'gigya_login',
          action: loginProvider,
          label: window.location.href,
          extraParams: {
            userId: UID,
            login: 'logged',
            service
          }
        })
      }
    })

    GigyaService.getAccountInfo().then(event => {
      this.getOrderDraftRequest(event)
    })
  }
}
</script>
