<template>
    <aside class="order-declaration">
        <h4
          class="order-declaration__title"
          v-if="$slots['title']"
        ><slot name="title" /></h4>
        <slot
            v-if="$slots['alert']"
            name="alert"
        />
        <div class="order-declaration__wrap">
            <p
                v-if="$slots['info']"
                class="order-declaration__info"
            >
                <slot name="info" />
            </p>
            <div
                v-if="$slots['links']"
                class="order-declaration__links"
            >
                <slot name="links" />
            </div>
            <slot
                v-if="$slots['action']"
                name="action"
            />
        </div>
    </aside>
</template>
<script>
export default {
  name: 'OrderDeclaration'
}
</script>
<style lang="scss" scoped src="./OrderDeclaration.scss"></style>
