<template>
    <component :is="tagName"
               :class="[
                 'form-field',
                 errorMsg || $slots['error'] ? 'form-field--with-error' : '',
                 isFullWidth ? 'form-field--full-width' : ''
                  ]">
        <label class="form-field__label" :for="idName">
            <!-- @slot label -->
            <slot name="label">{{ labelMsg }}</slot>
        </label>
        <div class="form-field__wrap">
            <!-- @slot input -->
            <slot name="input"/>
        </div>
        <transition name="failed-message">
            <span v-if="$slots['error'] || errorMsg" class="form-field__failed-message">
                <!-- @slot error -->
                <slot name="error">{{ errorMsg }}</slot>
            </span>
        </transition>

        <span v-if="$slots['extratext']" class="form-field__extra-text">
          <!-- @slot extratext -->
          <slot name="extratext"/>
        </span>
    </component>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    tagName: {
      type: String,
      default: 'div'
    },
    idName: String,
    labelMsg: String,
    errorMsg: String,
    isFullWidth: Boolean
  }
}
</script>

<style lang="scss" scoped src="./FormFields.scss"></style>
