<template>
  <section class="order-summary">
    <form @submit.prevent="sendRequest" autocomplete="off">
      <div class="order-summary__wrap">
        <ul class="order-summary__list">
          <li>
            <OrderEntry>
              <template #header><h5>{{$t(`message.ORDER_SUMMARY_DELIVERY_TITLE`)}}</h5></template>
              <template #body>
                <RadioButtonComponent
                    hasName="delivery-time"
                    :isChecked="timeChecked"
                    v-model.trim="$v.form.deliveryTime.$model"
                  >
                  <template #MORNING>{{$t(`message.ORDER_SUMMARY_DELIVERY_MORNING`)}} </template>
                  <template #AFTERNOON v-if="afternoonAvailable">{{$t(`message.ORDER_SUMMARY_DELIVERY_AFTERNOON`)}} </template>
                </RadioButtonComponent>
              </template>
            </OrderEntry>
          </li>
          <li>
            <OrderEntry>
              <template #header><h5>{{$t(`message.ORDER_SUMMARY_PAYMENT_TITLE`)}}</h5></template>
              <template #body>
                <p>{{$t(`message.ORDER_SUMMARY_PAYMENT_INFO`)}}</p>
                <RadioButtonComponent
                    hasName="payment-method"
                    :isChecked="paymentChecked"
                    v-model.trim="$v.form.deliveryPayment.$model">
                  <template #CASH>{{$t(`message.ORDER_SUMMARY_PAYMENT_CASH`)}} </template>
                  <template #CARD>{{$t(`message.ORDER_SUMMARY_PAYMENT_CREDIT_CARD`)}} </template>
                  <template #WAYLET v-if="wayletAvailable">{{$t(`message.ORDER_SUMMARY_PAYMENT_WAYLET`)}} </template>
                </RadioButtonComponent>
                <transition
                  v-on:before-enter="setExpandHeight(arguments, {variable: '--expand-payment-card', queryElement: '.payment-card__wrap'})"
                  name="expandHeight">
                  <PaymentCard
                    v-if="paymentChecked == 'CARD'"
                    :cards="['visa', 'master']">
                    <template #text>{{$t(`message.ORDER_SUMMARY_PAYMENT_AVAILABLE_CREDIT_CARD`)}}</template>
                    <template #extra>
                      <CognitiveMessage
                        :message="$t(`message.COGNITIVE_PAYMENT_MESSAGE`)"
                        display="secondary"
                      />
                    </template>
                  </PaymentCard>
                </transition>
              </template>
              <template #footer></template>
            </OrderEntry>
          </li>
        </ul>
        <div class="order-summary__footer">
            <a
              class="link--simple link--simple--hasbackArrow"
              :title="$t(`message.BUTTON_GO_BACK`)"
              @click="goBack()"
            >{{$t(`message.BUTTON_GO_BACK`)}}</a>
          <ButtonComponent
            :label="$t(`message.BUTTON_GO_ON`)"
            type="submit"
          >
          </ButtonComponent>
        </div>
      </div>
  </form>
  </section>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import { GigyaService } from '../../services/gigya'
import { Errors } from '../../errors'
import { Prices } from '../../helpers/prices'
import { LocalStorage } from '../../services/storage/localStorage'
import { Constants } from '../../constants'

export default {
  name: 'OrderSummary',
  data () {
    return {
      backRoute: '',
      form: {
        deliveryTime: '',
        deliveryPayment: ''
      }
    }
  },

  validations: {
    form: {
      deliveryTime: {
        required,
        autoTouch: true
      },
      deliveryPayment: {
        required,
        autoTouch: true
      }
    }
  },

  watch: {
    time () {
      const time = this.$v.form.deliveryTime.$model !== '' ? this.$v.form.deliveryTime.$model : this.timeChecked
      this.setTime({ time })
      LocalStorage.update('orderPending', { timeInfo: { time } })
    },

    payment () {
      const payment = this.$v.form.deliveryPayment.$model !== '' ? this.$v.form.deliveryPayment.$model : this.paymentChecked
      this.setPayment({ payment })
      LocalStorage.update('orderPending', { paymentInfo: { payment } })
    }
  },

  computed: {
    ...mapState(['user', 'orders']),

    time () {
      return this.$v.form.deliveryTime.$model
    },

    payment () {
      return this.$v.form.deliveryPayment.$model
    },

    isLogged () {
      return this.user.isLoggedIn
    },

    timeChecked () {
      return this.orders.order.timeInfo.time
    },

    paymentChecked () {
      return this.orders.order.paymentInfo.payment
    },

    afternoonAvailable () {
      return !this.orders.order.timeInfo.date.includes(Constants.SABADO)
    },

    wayletAvailable () {
      return this.orders.wayletAvailable
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.backRoute = from.name
      vm.pushEcommerceView({ stepNumber: 1 })
    })
  },

  methods: {
    ...mapActions('orders', [
      'setTime',
      'setPayment',
      'getPrices',
      'availableResponse',
      'setWayletAvailable',
      'setVoucherValidation',
      'setButtonState',
      'setVoucherDescription'
    ]),
    ...mapActions('analytics', ['pushEcommerceView', 'pushEcommerceOption']),

    sendRequest () {
      const time = this.$v.form.deliveryTime.$model !== '' ? this.$v.form.deliveryTime.$model : this.timeChecked
      const payment = this.$v.form.deliveryPayment.$model !== '' ? this.$v.form.deliveryPayment.$model : this.paymentChecked

      this.setTime({ time })
      this.setPayment({ payment })

      this.pushEcommerceView({ stepNumber: 2 })
      if (!this.isLogged) {
        this.$router.push({ name: 'personal-data', params: { isComponent: 'UserLogin' } })
      } else {
        GigyaService.getAccountInfo().then(event => {
          this.pushEcommerceOption({ stepNumber: 2, option: `login - ${event.loginProvider}` })
          this.$router.push({ name: 'recap' })
        })
      }

      LocalStorage.update('orderPending', {
        timeInfo: { time },
        paymentInfo: { payment }
      })
    },

    show () {
      const payment = this.$refs.paymentCards
      return `${payment.childNodes[0].clientHeight}px`
    },

    async goBack () {
      const {
        addressInfo: {
          address,
          postalCode
        },
        amountInfo: {
          quantity,
          filter
        },
        voucher
      } = this.orders.order

      const results = await this.getPrices({
        address: address,
        postalCode: postalCode.value,
        amount: quantity,
        voucher: voucher.code,
        type: filter
      })

      if (results) {
        if (results.voucher) {
          this.setVoucherValidation({ isValid: results.voucher.state === 'AVAILABLE' })
          this.setVoucherDescription({ description: results.voucher.description })
        }
        if (!('code' in results)) {
          if (Prices.filterList(results.offers, quantity).length === 0) {
            this.$store.dispatch(`orders/${Errors.RESULTS_ERROR}`)
          } else {
            this.availableResponse(results.offers)
            this.setWayletAvailable(results.wayletAvailable)
            localStorage.removeItem('wayletAvailable')
            localStorage.setItem('wayletAvailable', results.wayletAvailable.toString())
            this.setButtonState({ type: 'search', isDisabled: false })
          }
        } else {
          this.$store.dispatch(`orders/${results.code}`)
        }
      }
    },

    setExpandHeight (args, props) {
      let bodyStyles = document.body.style
      if (args[0]) {
        this.$nextTick(() => {
          bodyStyles.setProperty(props.variable, getComputedStyle(args[0].querySelector(props.queryElement)).height)
        })
      }
    }
  },

  beforeCreate () {
    this.$store.dispatch('orders/refreshData')
  }
}
</script>
<style lang="scss" scoped src="./OrderSummary.scss"></style>
