export const atlas = {
  namespaced: true,

  state: {
    keys: [],
    query: ''
  },

  mutations: {
    setKeys: (state, value) => {
      state.keys = value
    },

    setQuery: (state, value) => {
      state.query = value
    }
  },

  actions: {
    setKeys: ({ commit }, payload) => {
      commit('setKeys', payload)
    },

    setQuery: ({ commit }, payload) => {
      commit('setQuery', payload)
    }
  }
}
