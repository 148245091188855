<template>
  <section class="profile">
    <div class="profile__wrap transition">
      <div id="profile-update-container"></div>
      <ButtonComponent
        @click.native="userLogout"
        :label="$t(`message.SIGN_OFF`)"
        isSecondary
      ></ButtonComponent>
      <LinkButton @click.native="openModal({
        type:'ModalUserForm',
        size: 770,
        slots: [
          { name: 'title', content: 'Darme de baja '},
          { name: 'description', content: '¿Nos cuentas el motivo? Tu experiencia nos ayudará a mejorar.'}
        ],
        props: [
          {
            submit: false,
            isDisabled: reasonID === '',
            submitMessage: $t('message.USER_UNREGISTER_STATE_MESSAGE'),
            hasAlert: {
              icon: 'IconAlerts',
              text: $t(`message.UNREGISTER_ALERT`)
            },

            hasForm: {
              freeText: {
                state: true,
                label: $t('message.USER_UNREGISTER_FREETEXT_INFO'),
                placeholder: $t('message.USER_UNREGISTER_FREETEXT_PLACEHOLDER'),
                isEditable: false,
                hasValue: unregisterExtraText
              },
              radioButtons: [
                {
                  id: 'tooMuchPublicity',
                  label: $t('message.USER_UNREGISTER_FORM_LABEL_TOOMUCHPUBLICITY')
                },
                {
                  id: 'notUseService',
                  label: $t('message.USER_UNREGISTER_FORM_LABEL_NOTUSESERVICE')
                },
                {
                  id: 'otherReason',
                  label: $t('message.USER_UNREGISTER_FORM_LABEL_OTHERREASON')
                }
              ]
            },

            hasButton: [
              {
                action: 'userUnregister',
                label: $t(`message.UNREGISTER`),
                type: 'button'
              },

              {
                action: 'close',
                label: $t(`message.KEEP_PROFILE`),
                type: 'link'
              }
            ]
          }
        ]
      })">
        <template>{{ $t(`message.UNREGISTER`) }}</template>
      </LinkButton>
    </div>
  </section>
</template>

<script>
import { GigyaService } from '../../services/gigya'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UserProfile',

  data() {
    return {
      reasons: {
        tooMuchPublicity: {
          value: this.$t('message.USER_UNREGISTER_FORM_REASON_TOOMUCHPUBLICITY'),
          default: true
        },
        notUseService: {
          value: this.$t('message.USER_UNREGISTER_FORM_REASON_NOTUSESERVICE'),
          default: true
        },
        otherReason: {
          value: this.$t('message.USER_UNREGISTER_FORM_REASON_OTHERREASON'),
          default: false
        }
      }
    }
  },

  watch: {
    modalState () {
      if (!this.modalState) {
        this.setUnregisterReason({
          id: '',
          reason: '',
          defaultChoose: ''
        })

        const { props } = this.modal.component
        props[0].isDisabled = this.reasonID === ''
        this.setModalProps({ props: props })
      }
    },

    reasonID () {
      const { props } = this.modal.component
      if (this.reasonID === 'otherReason') {
        props[0].hasForm.freeText.isEditable = true
      } else {
        props[0].hasForm.freeText.isEditable = false
      }
      this.setModalProps({ props: props })
    }
  },

  computed: {
    ...mapState(['user', 'modal']),

    modalState () {
      return this.modal.status
    },

    reasonID () {
      return this.user.unregister.id
    },

    unregisterState () {
      return this.user.unregister.state
    },

    unregisterExtraText () {
      return this.user.unregister.extraText
    }
  },

  methods: {
    ...mapActions('modal', [
      'setModalDisplay',
      'setModalComponent',
      'setModalProps'
    ]),

    ...mapActions('user', [
      'logout',
      'updateUser',
      'setUnregisterReason',
      'setUnregisterExtraText'
    ]),

    loadContent () {
      const onAfterSubmit = ({ profile: { firstName } }) => {
        this.updateUser({ firstName })
        this.openModal(
          {
            type: 'ModalBasic',
            size: 490,
            slots: [
              { 'name': 'title', 'content': `<h2 class='modal-basic__title'>${this.$t(`message.MODIFIED_PROFILE_TITLE`)}</h2>` },
              { 'name': 'description', 'content': `<div class='modal-basic__text'>${this.$t(`message.MODIFIED_PROFILE_DESCRIPTION`)}</div>` }
            ]
          }
        )
      }

      GigyaService.showProfileUpdate('profile-update-container', {
        onAfterSubmit
      })
    },

    userLogout () {
      this.logout()
    },

    openModal (data = {
      name: 'ModalBasic',
      slots: []
    }) {
      const payload = {
        state: true,
        name: data.type,
        size: data.size,
        slots: data.slots,
        props: data.props
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    selectReason (res) {
      this.setUnregisterReason({
        id: res,
        reason: this.reasons[res].value,
        defaultChoose: this.reasons[res].default
      })

      const { props } = this.modal.component
      props[0].isDisabled = this.reasonID === ''
      this.setModalProps({ props: props })
      this.changeExtraText('')
    },

    changeExtraText (res) {
      this.setUnregisterExtraText({ extraText: res })
    }
  },

  mounted () {
    this.loadContent()
    this.$root.$on('selectReason', this.selectReason)
    this.$root.$on('changeExtraText', this.changeExtraText)
  }
}
</script>

<style lang="scss" scoped src="./UserProfile.scss"></style>
