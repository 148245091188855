<template>
  <ul class="features">
    <li
      v-for="(node, index) in this.$slots"
      :key="index"
    >
      <slot :name="index" />
    </li>
  </ul>
</template>
<script>
export default {
  name: 'FeaturesList'
}
</script>
<style lang="scss" scoped src="./FeaturesList.scss"></style>
