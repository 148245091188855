<template>
  <article class="modal-userForm">
    <transition
      mode="out-in"
      name="change-state">
        <!-- START: FORM -->
        <div
          v-if="!submit"
          key="unregisterForm">
          <header
            v-if="this.$slots['title'] || this.$slots['description']"
            class="modal-userForm__header">
            <h3
              v-if="this.$slots['title']"
              class="modal-userForm__title">
              <slot name="title" />
            </h3>
            <p
              v-if="this.$slots['description']"
              class="modal-userForm__description">
              <slot name="description" />
            </p>
          </header>
          <template v-if="hasForm">
            <form
              class="modal-userForm__form">
              <RadioButtonComponent
                @input="select"
                hasName="test"
                hasDirection="column">
                <template
                  v-for="radio in hasForm.radioButtons"
                  :slot="radio.id">
                  {{radio.label}}
                </template>
              </RadioButtonComponent>
              <FormField
                v-if="hasForm.freeText.state"
                idName="modal-userForm__free-text">
                <template #input>
                  <textarea
                    rows="6"
                    @input="changeText"
                    :placeholder="hasForm.freeText.placeholder"
                    :disabled="!textAreaStatus"
                    :maxlength="hasForm.freeText.maxlength || maxlength"
                    :class="[hasForm.freeText.validationError ? 'validation--isFailed' : null]"
                    :value="hasForm.freeText.hasValue ? hasForm.freeText.hasValue : null"
                    data-cy="free-text"
                    tabindex="1"/>
                  <span class="validation__failed-message">{{hasForm.freeText.validationErrorMessage}}</span>
                  <span class="modal-userForm__free-text__info">{{hasForm.freeText.label}}</span>
                </template>
              </FormField>
            </form>
          </template>
          <AlertsComponent
            v-if="hasAlert">
            <template #icon>
              <IconComponent
                :name="hasAlert.icon"
                size="xsmall">
              </IconComponent>
            </template>
            <template #text>{{hasAlert.text}}</template>
          </AlertsComponent>
          <ul
            v-if="hasButton"
            class="modal-userForm__buttons">
            <li
              v-for="(node, index) in hasButton"
              :key="index">
              <template v-if="node.type === 'button'">
                <ButtonComponent
                  :spinner="hasSpinner"
                  @click.native="runMethod(node.action, node.params ? node.params : null)"
                  :label="node.label"
                  :key="index"
                  :isSecondary=(node.isSecondary)
                  :isDisabled="isDisabled"
                  v-if="!isIE11"
                  tabindex="2"/>
                <a
                  class="link--simple"
                  @click="runMethod(node.action, node.params ? node.params : null)"
                  :key="index"
                  v-else>{{node.label}}</a>
              </template>
              <template v-if="node.type === 'link'">
                <LinkButton
                  :key="index"
                  @click.native="runMethod(node.action, node.params ? node.params : null)"
                  tabindex="3">
                  <template>{{node.label}}</template>
                </LinkButton>
              </template>
            </li>
          </ul>
        </div>
        <!-- END: FORM -->

        <!-- START: MESSAGE -->
        <div
          v-else
          key="submitFormMessage"
          class="modal-userForm__message"
          v-html="submitMessage">
        </div>
        <!-- END: MESSAGE -->
    </transition>
  </article>
</template>
<script>
export default {
  name: 'ModalUserForm',
  data () {
    return {}
  },

  watch: {
    textAreaStatus () {
      if (!this.hasForm.freeText.isEditable) {
        this.$root.$emit('changeExtraText', '')
      }
    }
  },

  props: {
    submit: false,
    hasAlert: { type: Object },
    hasForm: { type: Object },
    hasButton: { type: Array },
    isDisabled: {
      type: Boolean,
      default: false
    },
    hasSpinner: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 500
    },
    submitMessage: { type: String }
  },

  computed: {
    textAreaStatus () {
      return this.hasForm.freeText.isEditable
    },

    isIE11 () {
      return !!window.MSInputMethodContext && !!document.documentMode
    }
  },

  methods: {
    userUnregister () {
      this.$emit('unregister')
    },

    close () {
      this.$emit('close')
    },

    deleteOrder (result) {
      this.$emit('deleteOrder', result)
    },

    select (res) {
      this.$root.$emit('selectReason', res)
    },

    changeText (e) {
      this.$root.$emit('changeExtraText', e.target.value)
    },

    getContent (name) {
      return this.$slots[name][0]['text']
    },

    runMethod (method, param) {
      this[method](param)
    }
  }
}
</script>
<style lang="scss" scoped src="./ModalUserForm.scss"></style>
