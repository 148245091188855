<template>
  <div class="user-form-order__wrapper">
    <form @submit.prevent="validateForm" class="user-form-order" autocomplete="false">
      <aside class="user-form-order__main">
        <div class="user-form-order__main__fields">
          <ul class="user-form-order__fields">
            <!-- START: ADDRESS FIELD -->
            <li :class="[
              'user-form-order__fields--big user-form-order__fields--autocomplete',
              'user-form-order__fields--big',
              $v.form.address.$error ? 'hasError' : null
            ]">
              <label for="address" class="user-form-order__label">{{ $t(`message.ORDER_SEARCH_ADDRESS_LABEL`) }}</label>
              <FormField idName="input-address" class="form-field__address form-field__address--autocomplete">
                <template #input>
                  <IconComponent name="IconPin" size="custom" />
                  <input type="text" id="address" ref="address"
                    :placeholder="$t(`message.LANDING_INPUT_ADDRESS_PLACEHOLDER`)" maxlength="150"
                    v-model.trim="$v.form.address.$model" :aria-label="$t(`message.ARIA_LABEL_DIRECTION`)"
                    @blur="resetAutocomplete" @keyup="autocomplete" autocomplete="new-password" tabindex="1"
                    @input="activeButton" />
                  <transition name="fade">
                    <span v-if="isTyping" class="autocompleted-streets__spinner">
                      <img src="~@/assets/images/png/spinner-22.png" />
                    </span>
                  </transition>
                  <transition name="expand">
                    <div class="autocompleted-streets" v-if="Object.keys(autoCompletedStreets).length > 0"
                      :class="['error' in autoCompletedStreets ? 'autocompleted-streets--error' : null]">
                      <ul>
                        <li :class="[
                          index === autocompleteIndexItem ? 'autocompleted-streets__result--select' : null,
                          'autocompleted-streets__result'
                        ]" v-for="(result, index) in autoCompletedStreets" :id="`result_${index}`" :key="result.id"
                          @click="autoFillInput(result)">
                          <span v-if="'error' in autoCompletedStreets" class="autocompleted-streets__location">
                            {{ $t(`message.AUTOCOMPLETED_UNAVAILABLE_RESULTS`) }}
                          </span>
                          <span v-else class="autocompleted-streets__location">
                            {{ result.address.freeformAddress }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </transition>
                </template>
                <template v-if="$v.form.address.$error" #error>{{ $t(`message.DIRECTION_NEED`) }}</template>
              </FormField>
            </li>
            <!-- END: ADDRESS FIELD -->
            <!-- START: POSTAL CODE -->
            <li :class="['user-form-order__fields--small', postalCodeError ? 'hasError' : null]">
              <label for="postalcode" class="user-form-order__label">
                {{ $t(`message.ORDER_SEARCH_POSTAL_CODE_LABEL`) }}
              </label>
              <FormField idName="input-postalcode" maxlength="5" class="form-field__postalcode" data-cy="postal-code">
                <template #input>
                  <input type="tel" id="postalcode" ref="postalcode"
                    :placeholder="$t(`message.LANDING_INPUT_POSTALCODE_PLACEHOLDER`)" maxlength="5" v-numericOnly="true"
                    v-model.trim="$v.form.postalCode.$model" :aria-label="$t(`message.ARIA_LABEL_POSTALCODE`)"
                    @blur="completeTheCP" autocomplete="new-password" tabindex="2" @input="activeButton" />
                </template>
                <template v-if="postalCodeError" #error>
                  {{ postalCodeError }}
                </template>
              </FormField>
            </li>
            <!-- END: POSTAL CODE -->
            <!-- START: LITERS/EUROS -->
            <li :class="[
              'user-form-order__fields--medium',
              'user-form-order__fields--liters',
              $v.form[tempOrderFilterSetted.toLowerCase()].$error ? 'hasError' : null
            ]">
              <label class="user-form-order__label">
                {{ $t(`message.ORDER_SEARCH_LITERS_LABEL`) }}
                <LinkButton class="user-form-order__showInfo" @click.native="
                  displayInfo({
                    slots: [
                      {
                        name: 'titleLiters',
                        content: `<h2 class='modal-basic__title'>${$t(`message.LITER_INFO_TITLE`)}</h2>`
                      },
                      {
                        name: 'descriptionLiters',
                        content: `<div class='modal-basic__text'>${$t(`message.LITER_INFO_TEXT`)}</div>`
                      },
                      {
                        name: 'descriptionLiters2',
                        content: `<div class='modal-basic__text'>${$t(`message.LITER_INFO_TEXT2`)}</div>`
                      },
                      {
                        name: 'titleEuros',
                        content: `<h2 class='modal-basic__title'>${$t(`message.EUROS_INFO_TITLE`)}</h2>`
                      },
                      {
                        name: 'descriptionEuros',
                        content: `<div class='modal-basic__text'>${$t(`message.EUROS_INFO_TEXT`)}</div>`
                      },
                      {
                        name: 'descriptionEuros2',
                        content: `<div class='modal-basic__text'>${$t(`message.EUROS_INFO_TEXT2`)}</div>`
                      }
                    ]
                  })
                " :aria-label="$t(`message.ARIA_LABEL_SHOW_INFO`)">
                  <template>
                    <IconComponent name="IconInfo" size="xxsmall" />
                  </template>
                </LinkButton>
              </label>
              <FormField idName="input-filter-type" class="form-field__filter">
                <template #input>
                  <select @change="setFilter" tabindex="3">
                    <option v-for="key in filters" :key="key.option" :selected="tempOrderFilterSetted === key.option"
                      :value="key.option">
                      {{ key.label }}
                    </option>
                  </select>
                </template>
              </FormField>
              <transition mode="out-in" name="change-move" @after-enter="inputChange">
                <FormField idName="input-liters" class="form-field__quantity" :key="tempOrderFilterSetted">
                  <template #input>
                    <IconComponent :name="getFilterIcon()" size="custom" />
                    <input type="tel" :id="tempOrderFilterSetted" :ref="tempOrderFilterSetted"
                      :placeholder="$t(`message.LANDING_INPUT_${tempOrderFilterSetted}_PLACEHOLDER`)" maxlength="4"
                      v-numericOnly="true" :aria-label="$t(`message.ARIA_LABEL_QUANTITY`)" autocomplete="new-password"
                      v-model.trim="$v.form[tempOrderFilterSetted.toLowerCase()].$model"
                      @keyup="litersMessage = !$v.form[tempOrderFilterSetted.toLowerCase()].$error && lowerThanMaximun"
                      @blur="litersMessage = false" @input="activeButton" tabindex="4" />
                  </template>
                  <template v-if="$v.form[tempOrderFilterSetted.toLowerCase()].$error" #error>
                    {{
                    $v.form[tempOrderFilterSetted.toLowerCase()].required &&
                    $v.form[tempOrderFilterSetted.toLowerCase()].numeric
                    ? $t(`message.ORDER_${tempOrderFilterSetted}_ERROR_FORMAT`)
                    : $t(`message.ORDER_${tempOrderFilterSetted}_ERROR_REQUIRED`)
                    }}
                  </template>
                </FormField>
              </transition>
              <transition v-on:before-enter="
                setExpandHeight(arguments, {
                  variable: '--expand-cognitive-liters-height',
                  queryElement: '.user-cognitive__message'
                })
              " name="expandHeight">
                <span v-if="litersMessage && tempOrderFilterSetted === 'LITERS'" class="user-cognitive__alert">
                  <span class="user-cognitive__message">{{ $t(`message.COGNITIVE_LITERS_MESSAGE`) }}</span>
                </span>
              </transition>
            </li>
            <!-- END: LITERS/EUROS -->
            <!-- START: EXTRA ADDRESS INFO -->
            <li class="user-form-order__fields--big">
              <label for="extraAddress" :class="[
                'user-form-order__label',
                'user-form-order__label--hasAction',
                'user-form-order__label--hasDropDown',
                extraAddressIsOpen ? 'user-form-order__label--isOpen' : null
              ]">
                <LinkButton @click.native="extraAddressIsOpen = !extraAddressIsOpen" tabindex="5">
                  <template>
                    <div>
                      {{ $t(`message.ORDER_SEARCH_EXTRAADDRESS_LABEL`) }}
                    </div>
                  </template>
                </LinkButton>
              </label>
              <transition v-on:before-enter="
                setExpandHeight(arguments, {
                  variable: '--expand-address-extra-height',
                  queryElement: '.form-field__extra-address'
                })
              " name="expandHeight">
                <aside v-if="extraAddressIsOpen" class="user-form-order__extraAddress-transition">
                  <FormField idName="input-extraAddress" class="form-field__address form-field__extra-address">
                    <template #input>
                      <input type="text" id="extraAddress" ref="extraAddress"
                        :placeholder="$t(`message.LANDING_INPUT_EXTRAADDRESS_PLACEHOLDER`)" maxlength="150"
                        v-model.trim="$v.form.extraAddress.$model" :aria-label="$t(`message.ARIA_LABEL_EXTRAADDRESS`)"
                        autocomplete="new-password" @input="activeButton" />
                    </template>
                  </FormField>
                </aside>
              </transition>
            </li>
            <!-- END: EXTRA ADDRESS INFO -->
            <!-- START: PROMOTIONAL CODE -->
            <li :class="[
              'user-form-order__fields--small',
              !promotionalCodeValidation && promotionalCodeValidation !== '' ? 'hasError' : null
            ]">
              <label for=" promotionalcode" :class="[
                'user-form-order__label',
                'user-form-order__label--hasAction',
                'user-form-order__label--hasDropDown',
                promotionalCodeIsOpen ? 'user-form-order__label--isOpen' : null
              ]">
                <LinkButton class="user-form-order__showInfo" @click.native="
                  displayInfo({
                    slots: [
                      {
                        name: 'description',
                        content: `<div class='modal-basic__text'>${$t(`message.PROMOTIONALCODE_INFO_TEXT`)}</div>`
                      },
                      {
                        name: 'description2',
                        content: `<div class='modal-basic__text'>${$t(`message.PROMOTIONALCODE_INFO_TEXT2`)}</div>`
                      },
                      {
                        name: 'description3',
                        content: `<div class='modal-basic__text'>${$t(`message.PROMOTIONALCODE_INFO_TEXT3`)}</div>`
                      }
                    ]
                  })
                " :aria-label="$t(`message.ARIA_LABEL_SHOW_INFO`)">
                  <template>
                    <IconComponent name="IconInfo" size="xxsmall" />
                  </template>
                </LinkButton>
                <LinkButton data-cy="showPromotionaCodeField"
                  @click.native="promotionalCodeIsOpen = !promotionalCodeIsOpen" tabindex="6">
                  <template>
                    <div>
                      {{ $t(`message.ORDER_SEARCH_PROMOTIONALCODE_LABEL`) }}
                    </div>
                  </template>
                </LinkButton>
              </label>
              <transition v-on:before-enter="
                setExpandHeight(arguments, {
                  variable: '--expand-promotional-code-height',
                  queryElement: '.form-field__promotionalcode'
                })
              " name="expandHeight">
                <aside v-if="promotionalCodeIsOpen" class="user-form-order__promotionalCode-transition">
                  <FormField idName="promotionalcode" class="form-field__promotionalcode" data-cy="prmotional-code">
                    <template #input>
                      <transition v-if="promotionalCodeValidation" name="validate">
                        <IconComponent class="validPromotionaCode" name="IconStepCheck" size="custom" />
                      </transition>
                      <input type="text" id="promotionalcode" ref="promotionalcode" maxlength="15"
                        v-model.trim="$v.form.promotionalcode.$model"
                        :aria-label="$t(`message.ARIA_LABEL_PROMOTIONALCODE`)" @keyup="resetPromotionalCode"
                        autocomplete="off" tabindex="5" @input="activeButton" data-cy="promotionalcode" />
                    </template>
                    <template v-if="!promotionalCodeValidation && promotionalCodeValidation !== ''" #error>
                      {{ promotionalCodeError }}
                    </template>
                  </FormField>
                </aside>
              </transition>
            </li>
            <!-- END: PROMOTIONAL CODE -->
          </ul>
        </div>
        <div class="user-form-order__main__actions">
          <ButtonComponent :spinner="isLoading" :label="
            response.length === 0
              ? $t(`message.ORDER_SEARCH_BUTTON_LABEL`)
              : $t(`message.ORDER_SEARCH_AGAIN_BUTTON_LABEL`)
          " type="submit" id="sendOrder" tabindex="7" :isDisabled="buttonDisabledState || !isAlive">
          </ButtonComponent>

          <transition name="spinner">
            <Spinner v-if="isLoadingLastOrder" :message="$t(`message.WAITING_MESSAGE`)" />
          </transition>

          <ButtonComponent v-if="isLogged && getLastOrder.length > 0"
            :label="$t('message.ORDER_HISTORY_HEADER_HIGHLIGHT_BUTTON_LABEL_HOME')"
            @click.native="repeatOrder(getLastOrder[0].order)" data-cy="repeatOrder">
          </ButtonComponent>
        </div>
      </aside>
      <p class="user-form-order__alert_message">{{ $t(`message.ORDER_BUTTON_MESSAGE`) }}</p>
    </form>
    <div v-if="!isAlive" class="overlay">
      <p>{{ this.$t('message.MAINTENANCE_TITLE')}}</p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { required, between, numeric } from 'vuelidate/lib/validators'
import { Errors } from '../../errors'
import { Prices } from '../../helpers/prices'
import { LocalStorage } from '../../services/storage/localStorage'
import { Autocomplete } from '../../services/http/Autocomplete'
import { Constants } from '../../constants'
import { GigyaService } from '../../services/gigya'

const multipleOf = (value) => value % 10 === 0
const minValue = (value) => value >= Constants.MIN_ACCEPTED

export default {
  name: 'UserFormOrder',
  data() {
    return {
      UID: '',
      route: '',
      extraFieldHeight: '',
      extraFieldFocused: true,
      isLoading: false,
      isLoadingLastOrder: false,
      minAutocompleteLenght: 3,
      timer: 0,
      delay: 2000,
      streets: {},
      isTyping: false,
      whichCode: [37, 38, 39, 40, 13],
      autocompleteIndexItem: 0,
      litersMessage: false,
      filters: [
        {
          label: this.$t('message.ORDER_DROPDOWN_TYPE_LABEL_LITERS'),
          option: 'LITERS',
          icon: 'IconPump'
        },
        {
          label: this.$t('message.ORDER_DROPDOWN_TYPE_LABEL_EUROS'),
          option: 'EUROS',
          icon: 'IconEuro'
        }
      ],
      form: {
        address: '',
        postalCode: '',
        liters: '',
        extraAddress: '',
        promotionalcode: '',
        euros: ''
      },
      tempOrderFilter: 'LITERS',
      extraAddressIsOpen: false,
      promotionalCodeIsOpen: false
    }
  },

  validations() {
    const type = this.formTypeValidation
    return {
      form: {
        address: {
          required
        },
        postalCode: {
          required,
          numeric
        },
        extraAddress: {},
        promotionalcode: {},
        ...type
      }
    }
  },

  watch: {
    $route(to, from) {
      this.isLoading = false
      this.isLoadingLastOrder = false
      this.extraFieldFocused = false
      this.setResultsOut({ state: false })
      this.initForm()
      this.setButtonState({ type: 'search', isDisabled: false })
    },

    additionalInfo() {
      this.setExtraAddress({ extraAddress: this.$v.form.extraAddress.$model })
      LocalStorage.update('orderPending', {
        addressInfo: {
          extraAddress: this.$v.form.extraAddress.$model
        }
      })
    },

    postalCode() {
      this.resetCodeValidation({ type: 'PostalCode', value: true })
    },

    tempOrderFilterSetted() {
      this.form[this.tempOrderFilter === 'LITERS' ? 'liters' : 'euros'] = ''
    },

    isLogged() {
      if (this.user.isLoggedIn && this.getLastOrder.length === 0) {
        this.getUserAccountOrders()
      }
    },

    getLastOrder() {
      this.isLoadingLastOrder = false
    }
  },

  computed: {
    ...mapState(['orders', 'atlas', 'user']),

    ...mapGetters({
      getHistory: 'orders/getHistoryHome'
    }),

    isLogged() {
      return this.user.isLoggedIn
    },

    getLastOrder() {
      return this.getHistory() || []
    },

    additionalInfo() {
      return this.form.extraAddress
    },

    autoCompletedStreets() {
      return this.streets
    },

    postalCode() {
      return this.form.postalCode
    },

    promotionalCode() {
      return this.form.promotionalcode
    },

    postalCodeValidation() {
      return this.orders.order.addressInfo.postalCode.isValid
    },

    postalCodeError() {
      if (this.$v.form.postalCode.$error) {
        return this.$t(`message.ORDER_POSTALCODE_ERROR_REQUIRED`)
      }
      if (!this.postalCodeValidation && this.postalCodeValidation !== '') {
        return this.$t(`message.ORDER_INVALID_POSTALCODE_ERROR`)
      }
      return ''
    },

    voucher() {
      return this.orders.order.voucher
    },

    promotionalCodeValidation() {
      return this.orders.order.voucher.isValid
    },

    promotionalCodeError() {
      const voucherError = {
        MINIMUM_REQUIRED: {
          label: `${this.$t('message.ORDER_PROMOTIONAL_ERROR_MINIMUM', {
            quantity: `${this.voucher.error.value}`
          })} ${this.$t(`message.ORDER_SEARCH_TYPE_${this.orderFilter}`)}`
        }
      }

      return this.orders.order.voucher.error.type
        ? voucherError[this.orders.order.voucher.error.type].label
        : this.$t(`message.ORDER_PROMOTIONAL_ERROR_INCORRECT`)
    },

    response() {
      return this.orders.response
    },

    buttonDisabledState() {
      return this.orders.order.buttonState.search.isDisabled
    },

    lowerThanMaximun() {
      return this.$v.form[this.tempOrderFilterSetted.toLowerCase()].$model < Constants.MAX_ACCEPTED
    },

    orderFilter() {
      return this.orders.order.amountInfo.filter
    },

    filterType() {
      return this.tempOrderFilterSetted === 'LITERS' ? 'liters' : 'euros'
    },

    formTypeValidation() {
      return this.tempOrderFilterSetted === 'LITERS'
        ? {
          liters: { required, between: between(Constants.MIN_ACCEPTED, Constants.MAX_ACCEPTED), multipleOf, numeric }
        }
        : { euros: { required, multipleOf, minValue, numeric } }
    },

    isFilterChange() {
      return this.orders.order.isFilterChange
    },

    tempOrderFilterSetted() {
      return this.tempOrderFilter
    },

    promoByUrl() {
      return this.$route.query.promo
    },

    isAlive() {
      return this.$store.state.status.isAlive
    }
  },

  methods: {
    ...mapActions('orders', [
      'getPrices',
      'getOrderHistoryHome',
      'setHistoryHome',
      'setExtraAddress',
      'resetCodeValidation',
      'availableResponse',
      'setWayletAvailable',
      'setVoucherValidation',
      'setLockState',
      'setResultsOut',
      'setButtonState',
      'setVoucherDescription',
      'setVoucher',
      'setOrderFilter',
      'setQuantity',
      'setResponse',
      'setFilterField',
      'setFilterChange',
      'setVoucherError',
      'setVoucherType',
      'setLegalBasisUrl'
    ]),
    ...mapActions('modal', ['setModalDisplay', 'setModalComponent']),
    ...mapActions('atlas', ['setQuery']),

    initForm() {
      const {
        addressInfo: { address, postalCode, extraAddress },
        amountInfo: { filterField, filter },
        voucher
      } = this.orders.order

      this.$nextTick(() => {
        this.form.address = address
        this.form.postalCode = postalCode.value
        this.form.extraAddress = extraAddress
        this.form.promotionalcode = voucher.code
        this.tempOrderFilter = filter
        const type = filter === 'LITERS' ? 'liters' : 'euros'
        this.form[type] = filterField
      })
    },

    async validateForm() {
      this.$emit('submit')
      await GigyaService.getAccountInfo().then((event) => {
        this.UID = event.UID
      })
      if (!this.buttonDisabledState) {
        this.$v.form.$touch()
        const invalid = this.$v.form.$invalid
        if (!invalid) {
          this.isLoading = true
          const voucher = this.promotionalCode ? { voucher: this.promotionalCode } : null
          const results = await this.getPrices({
            address: this.$v.form.address.$model,
            postalCode: this.$options.filters['completeCP']({ value: this.$v.form.postalCode.$model, size: 5 }),
            amount: this.$v.form[this.filterType].$model,
            type: this.tempOrderFilterSetted,
            UID: this.UID,
            ...voucher
          })

          this.isLoading = false
          this.setLockState({ state: false })
          this.setFilterField({ filterField: this.$v.form[this.filterType].$model })

          if (results) {
            if (results.voucher) {
              const description = results.voucher.description ? results.voucher.description : undefined
              const error = {
                type: results.voucher.error ? results.voucher.error : undefined,
                value: results.voucher.minimum ? results.voucher.minimum : undefined
              }
              const type = results.voucher.type ? results.voucher.type : undefined

              this.setVoucherResult({
                state: results.voucher.state === 'AVAILABLE',
                description,
                code: this.$v.form.promotionalcode.$model,
                type,
                error,
                legalBasisUrl: results.voucher.legalBasisUrl
              })
            } else {
              this.setVoucherResult({
                state: '',
                description: '',
                code: '',
                type: '',
                error: {
                  type: '',
                  value: ''
                },
                legalBasisUrl: ''
              })
            }
            if (!('code' in results)) {
              if (Prices.filterList(results.offers, this.$v.form[this.filterType].$model).length === 0) {
                this.$store.dispatch(`orders/${Errors.RESULTS_ERROR}`)
              } else {
                if (this.response.length !== 0 && !this.isFilterChange) this.setResultsOut({ state: true })
                this.setFilterChange({ state: false })
                this.setOrderFilter({ value: this.tempOrderFilterSetted })
                this.availableResponse(results.offers)
                this.setWayletAvailable(results.wayletAvailable)
                localStorage.removeItem('wayletAvailable')
                localStorage.setItem('wayletAvailable', results.wayletAvailable.toString())
                LocalStorage.update('orderPending', {
                  addressInfo: {
                    address: this.$v.form.address.$model,
                    postalCode: this.$v.form.postalCode.$model,
                    extraAddress: this.$v.form.extraAddress.$model
                  },
                  amountInfo: {
                    quantity: this.correctAmountByEuros(this.$v.form[this.filterType].$model),
                    filter: this.orderFilter,
                    filterField: this.$v.form[this.filterType].$model
                  }
                })

                this.setButtonState({ type: 'search', isDisabled: true })
              }
            } else {
              this.setOrderFilter({ value: this.tempOrderFilterSetted })
              this.$store.dispatch(`orders/${results.code}`)
              this.resetCodeValidation({ type: 'Voucher', value: '' })
              if (results.code && (results.code === 1001 || results.code === 1002)) {
                window.open(Constants.URL_REDIR_NO_LITERS, '_blank')
              }
            }
          }
        }
      }
    },

    focused() {
      this.extraFieldFocused = !this.extraFieldFocused
    },

    displayInfo(params = { props: {}, slots: [] }) {
      const payload = {
        state: true,
        name: 'ModalBasic',
        allowClose: false,
        size: 490,
        slots: params.slots,
        props: {
          hasButton: params.buttons
        }
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    autocomplete() {
      let keyInclude = this.whichCode.includes(event.which)
      this.form.postalCode = ''
      clearTimeout(this.timer)
      if (this.form.address.length > this.minAutocompleteLenght && this.atlas.keys.length > 0) {
        if (!keyInclude) {
          this.isTyping = true
          this.timer = setTimeout(() => {
            this.setQuery(encodeURIComponent(this.form.address))
            Autocomplete.getResults().then((result) => {
              if (result.data) {
                this.streets = result.data.results.map((node) => node).filter((node) => node.type !== 'Geography')
                if (this.streets.length === 0) {
                  this.streets = Object.assign({}, this.streets, { error: true })
                }
              } else {
                this.streets = Object.assign({}, this.streets, { error: true })
              }
              this.isTyping = false
            })
          }, this.delay)
        } else {
          this.moveAutocompleteItem(event.which)
        }
      } else {
        this.isTyping = false
        this.streets = {}
      }
    },

    autoFillInput(result) {
      this.$nextTick(() => {
        if (typeof result === 'object') {
          const postalCode = result.address.postalCode
          this.form.address = `${result.address.streetName} ${result.address.streetNumber || ''}, ${
            result.address.municipality
          }, ${result.address.countrySecondarySubdivision}`
          if (postalCode) {
            if (postalCode.split(',').length === 1) this.form.postalCode = result.address.postalCode
          }

          this.resetAutocomplete()
        }
      })
    },

    resetAutocomplete() {
      clearTimeout(this.timer)
      this.streets = {}
      this.isTyping = false
    },

    moveAutocompleteItem(key) {
      switch (key) {
        case 40:
          this.autocompleteIndexItem = this.autocompleteIndexItem + 1
          break
        case 38:
          this.autocompleteIndexItem = this.autocompleteIndexItem - 1
          break
        case 13:
          this.autoFillInput(this.autoCompletedStreets[this.autocompleteIndexItem])
      }

      if (
        this.autocompleteIndexItem < 0 ||
        this.autocompleteIndexItem > Object.keys(this.autoCompletedStreets).length
      ) {
        this.autocompleteIndexItem = 0
      }
    },

    completeTheCP(event) {
      const params = {
        value: this.$v.form.postalCode.$model,
        size: 5
      }
      if (event.target.value) this.form.postalCode = this.$options.filters['completeCP'](params)
    },

    resetPromotionalCode() {
      this.resetCodeValidation({ type: 'Voucher', value: '' })
    },

    activeButton() {
      this.setButtonState({ type: 'search', isDisabled: false })
    },

    setVoucherResult(voucher) {
      this.setVoucherValidation({ isValid: voucher.state })
      if (voucher.description) this.setVoucherDescription({ description: voucher.description })
      if (voucher.code && voucher.state) this.setVoucher({ code: voucher.code })
      if (voucher.type) this.setVoucherType({ type: voucher.type })
      const type = voucher.error.type ? voucher.error.type : undefined
      const value = voucher.error.value ? voucher.error.value : undefined
      this.setVoucherError({
        type,
        value
      })
      voucher.legalBasisUrl
        ? this.setLegalBasisUrl({ legalBasisUrl: voucher.legalBasisUrl })
        : this.setLegalBasisUrl({ legalBasisUrl: '' })
      LocalStorage.update('orderPending', {
        voucher: {
          code: voucher.code,
          isValid: voucher.state,
          description: voucher.description,
          type: voucher.type,
          error: {
            type: voucher.type,
            value: voucher.value
          },
          legalBasisUrl: voucher.legalBasisUrl
        }
      })
    },

    setExpandHeight(args, props) {
      if (args[0]) {
        this.$nextTick(() => {
          let componentStyle = this.$el.style
          componentStyle.setProperty(props.variable, `${this.$el.querySelector(props.queryElement).offsetHeight}px`)
        })
      }
    },

    setFilter(e) {
      this.$nextTick(() => {
        this.tempOrderFilter = e.target.value
        this.activeButton()
      })
    },

    getFilterIcon() {
      return this.filters.filter((node) => node.option === this.tempOrderFilterSetted)[0].icon
    },

    correctAmountByEuros(value) {
      if (value < Constants.MIN_ACCEPTED) {
        return Constants.MIN_ACCEPTED
      } else if (value > Constants.MAX_ACCEPTED) {
        return Constants.MAX_ACCEPTED
      } else {
        return value
      }
    },

    inputChange() {
      const {
        amountInfo: { filterField, filter }
      } = this.orders.order

      const type = filter === 'LITERS' ? 'liters' : 'euros'
      this.form[type] = filterField
    },

    async getOrderHistoryRequest({ UID, UIDSignature, signatureTimestamp }) {
      const order = await this.getOrderHistoryHome({ UID, UIDSignature, signatureTimestamp })

      this.setHistoryHome(order)
    },

    getUserAccountOrders() {
      this.isLoadingLastOrder = true

      GigyaService.getAccountInfo().then((event) => {
        this.getOrderHistoryRequest(event)
      })
    },

    repeatOrder(result) {
      const payload = {
        state: true,
        name: 'ModalOrderRepeat',
        allowClose: false,
        size: 630,
        slots: [
          {
            name: 'title',
            content: `<h2 class='modal-order-repeat__title'>${this.$t(`message.ORDER_HISTORY_REPEAT_TITLE`)}</h2>`
          },
          {
            name: 'description',
            content: `<div class='modal-order-repeat__description'>${this.$t(
              `message.ORDER_HISTORY_REPEAT_DESCRIPTION`
            )}</div>`
          }
        ],
        props: {
          hasResume: [
            {
              title: this.$t('message.ORDER_HISTORY_REPEAT_DIRECTION_TITLE'),
              table: [
                {
                  label: this.$t('message.RESUME_STORE_ADDRESS'),
                  value: result.address
                },
                {
                  label: this.$t('message.RESUME_STORE_POSTALCODE'),
                  value: result.postalCode
                }
              ]
            },
            {
              title: this.$t('message.ORDER_HISTORY_REPEAT_AMOUNT_TITLE'),
              table: [
                {
                  label: this.$t('message.ORDER_HISTORY_REPEAT_AMOUNT_LABEL'),
                  value: this.$t('message.RESUME_STORE_QUANTITY_TYPE', { ltr: result.amount })
                }
              ]
            }
          ],
          hasButton: [
            {
              action: 'repeatOrder',
              label: this.$t(`message.ORDER_HISTORY_REPEAT_ACTION`),
              type: 'button',
              params: result,
              isLoading: false
            }
          ]
        }
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.user.isLoggedIn && this.getLastOrder.length === 0) {
        this.getUserAccountOrders()
      }
      this.initForm()
      this.setButtonState({ type: 'search', isDisabled: false })
      if (this.promoByUrl) {
        this.promotionalCodeIsOpen = true
        this.setVoucher({ code: this.promoByUrl })
      }

      if (this.voucher.code) {
        this.promotionalCodeIsOpen = true
      }
    })
  }
}
</script>
<style lang="scss" scoped src="./UserFormOrder.scss"></style>
