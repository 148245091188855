<template>
  <nav class="navigation-menu" ref="nav">
    <div class="navigation-menu__hamburguer" ref="hamburger">
      <button
        v-on:click="toggleVisibility"
        class="navigation-menu__btn-show"
        v-show="!isVisible"
        :aria-label="AriaLabelShowMenu"
      >
        <Icon name="IconHamburguer" size="xsmall"/>
      </button>
      <button
        v-on:click="toggleVisibility"
        class="navigation-menu__btn-hide"
        v-show="isVisible"
        :aria-label="AriaLabelHideMenu"
      >
        <Icon name="IconClose" size="xsmall"/>
      </button>
    </div>
    <ul :class="{'visible': isVisible}"
        :style="topPosition">
      <li
        v-for="(navigationLink, i) in navigationLinks"
        :key="i"
      >
        <router-link
          class="navigation-menu__link link--simple"
          :to="navigationLink.url"
          :rel="navigationLink.rel"
          exact-active-class="active"
          v-on:click.native="removeVisibleClass()"
        >
          <span>
            {{navigationLink.text}}
            <Icon name="IconCaretRight" size="xxxsmall"/>
          </span>
        </router-link>
      </li>
      <li class="navigation-menu__login-user-area" v-if="userIsLogged">
        <LinkButton class="navigation-menu__link navigation-menu__login-user-area__link"
                @click.native="onCloseSession">
          <Icon name="IconEnter" size="xxsmall"/>{{labelCloseSession}}
        </LinkButton>
      </li>
      <li class="navigation-menu__login-user-area" v-else>
        <LinkButton class="navigation-menu__link navigation-menu__login-user-area__link"
                @click.native="onUserLogin">
          <Icon name="IconEnter" size="xxsmall"/>{{labelUserArea}}
        </LinkButton>
      </li>
    </ul>
    <div class="navigation-menu__user-area" v-if="userIsLogged">
      <router-link :to="urlUserArea">
        <Icon name="IconUser" size="xxsmall"/> {{userWelcomeMessage}}
      </router-link>
    </div>
    <div class="navigation-menu__user-area" v-else>
      <button
        @click="onUserLogin"
        :aria-label="AriaLabelUserLogin"
      >
        <Icon name="IconUser" size="xxsmall"/>
      </button>
    </div>
  </nav>
</template>
<script>
import Icon from '../icons/Icon.vue'
import LinkButton from '../link-button/LinkButton'

export default {
  name: 'NavigationMenu',
  components: {
    Icon,
    LinkButton
  },
  data: function() {
    return {
      isVisible: false,
      mq: null,
      topPosition: {},
      viewportHeight: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    }
  },

  methods: {
    removeVisibleClass: function() {
      const body = document.querySelector('body')
      body.classList.remove('lock')
      this.isVisible = false
      this.topPosition = {}
      this.$root.$emit('handleUserMenu', false)
      this.$emit('toggleMobileMenu', false)
    },

    toggleVisibility: function() {
      const body = document.querySelector('body')
      this.isVisible = !this.isVisible
      this.topPosition = {}
      if (this.isVisible) {
        this.setMenuHeight()
        window.addEventListener('orientationchange', () => setTimeout(() => this.setMenuHeight(), 600), false)
      } else {
        window.removeEventListener('orientationchange', () => this.setMenuHeight())
      }

      this.$emit('toggleMobileMenu', this.isVisible)
      this.isVisible ? body.classList.add('lock') : body.classList.remove('lock')
      this.$root.$emit('handleUserMenu', this.isVisible)
    },

    updateActive: function(linkId) {
      this.$emit('update-active-link', linkId)
    },

    onUserLogin: function() {
      this.removeVisibleClass()
      this.$emit('userLogin')
    },

    onCloseSession: function() {
      this.removeVisibleClass()
      this.$emit('closeSession')
    },

    setMenuHeight () {
      const nav = this.$refs.nav
      const navRect = nav.getBoundingClientRect()
      const navHeight = nav.clientHeight
      const viewPort = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      const pos = viewPort - (navRect.top + navHeight)
      this.topPosition = { height: `${pos}px` }
    }
  },
  props: {
    navigationLinks: {
      type: Array,
      default: function() {
        return []
      }
    },
    headerHeight: {
      type: Number,
      default: 0
    },
    userIsLogged: {
      type: Boolean,
      default: false
    },
    labelUserArea: {
      type: String,
      default: 'Área Usuario'
    },
    urlUserArea: {
      type: [String, Object]
    },
    labelCloseSession: {
      type: String,
      default: 'Cerrar Sessión'
    },
    userWelcomeMessage: {
      type: String,
      default: ''
    },
    AriaLabelShowMenu: {
      type: String,
      default: 'Abrir menú'
    },
    AriaLabelHideMenu: {
      type: String,
      default: 'Cierrar menú'
    },
    AriaLabelUserLogin: {
      type: String,
      default: 'Mi perfil'
    }
  },
  watch: {
    headerHeight: function(newVal) {
      const pos = this.viewportHeight - newVal
      this.topPosition = this.isVisible ? { height: `${pos}px` } : {}
    }
  },

  mounted() {
    if (matchMedia) {
      this.mq = window.matchMedia('(min-width: 768px)')
      this.mq.addListener(this.removeVisibleClass)
      this.removeVisibleClass(this.mq)
    }
  }
}
</script>
<style lang="scss" scoped src="./NavigationMenu.scss"></style>
