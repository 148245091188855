<template>
  <aside class="alerts">
    <!-- @slot icon -->
    <slot v-if="this.$slots['icon']" name="icon"/>
    <p class="alerts__text">
      <!-- @slot text -->
      <slot name="text"/>
    </p>
  </aside>
</template>
<script>
export default {
  name: 'Alerts'
}
</script>
<style lang="scss" scoped src="./Alerts.scss"></style>
