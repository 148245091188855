<template>
  <aside class="order-prices">
    <h4
      class="order-prices__title"
      v-if="$slots['title']">
      <slot name="title" />
    </h4>
    <slot
      v-if="$slots['cognitive']"
      name="cognitive" />
    <slot
      v-if="$slots['alert']"
      name="alert" />
    <div class="order-prices__types">
      <!-- START: ORDER TYPE -->
      <div class="order-prices__type">
        <ul class="order-prices__row">
          <li><slot name="labelType" /></li>
          <li>
            <figure><slot name="imageType" /></figure>
          </li>
        </ul>
      </div>
      <!-- END: ORDER TYPE -->
      <div class="order-prices__type">
        <ul class="order-prices__row">
          <li><slot name="labelQuantity" /></li>
          <li class="bold"><slot name="amountQuantity" /></li>
        </ul>
      </div>
      <!-- START: ORDER PRICES -->
      <div class="order-prices__type order-prices__type--last">
        <ul class="order-prices__row">
          <li><slot name="labelTotalPrice" /></li>
          <li>
            <span class="regular"><slot name="amountUnitPrice" /></span>
            <span class="price" v-html="totalPrice"></span>
            <span class="iva_label">(<slot name="labelIva" />)</span>
          </li>
        </ul>
      </div>
      <!-- END: ORDER PRICES -->

      <!-- START: PROMOTIONAL VALIDATION -->
      <transition
        appear
        v-on:before-enter="setExpandHeight(arguments, {variable: '--expand-promotionalCode-height', queryElement: '.promotional-message-validation__content'})"
        name="expandHeight">
        <slot
          v-if="this.$slots['promotionalCode']"
          name="promotionalCode" />
      </transition>
      <!-- END: PROMOTIONAL VALIDATION -->
    </div>
  </aside>
</template>
<script>
export default {
  name: 'OrderPricesInfo',

  props: {
    totalPrice: {
      type: String,
      default: ''
    }
  },

  methods: {
    setExpandHeight (args, props) {
      let bodyStyles = document.body.style
      this.$nextTick(() => {
        bodyStyles.setProperty(props.variable, `${args[0].querySelector(props.queryElement).offsetHeight}px`)
      })
    }
  }
}
</script>
<style lang="scss" scoped src="./OrderPricesInfo.scss"></style>
