import { User } from '../../services/http/User'
import { GigyaService } from '../../services/gigya'
import router from '../../router'

const EMPTY_USER = {
  isLoggedIn: false,
  id: '',
  firstName: '',
  gigyaServices: []
}

export const user = {
  namespaced: true,

  state: {
    ...EMPTY_USER,

    unregister: {
      state: false,
      id: '',
      reason: '',
      defaultChoose: '',
      extraText: ''
    }
  },

  mutations: {
    setUser: (state, { isLoggedIn, id, firstName, gigyaServices }) => {
      Object.assign(state, {
        isLoggedIn,
        id,
        firstName,
        gigyaServices
      })
    },
    clearUser: state => {
      Object.assign(state, EMPTY_USER)
    },

    setUnregisterReason: (state, payload) => {
      state.unregister.id = payload.id
      state.unregister.reason = payload.reason
      state.unregister.defaultChoose = payload.defaultChoose
    },

    setUnregisterExtraText: (state, payload) => {
      state.unregister.extraText = payload.extraText
    }
  },

  actions: {
    unregisterReason: ({ commit, rootState }, payload) => {
      return User.unregister(payload)
        .then(res => {
        }).catch(res => res)
    },

    updateUser: ({ commit }, payload) => {
      commit('setUser', { isLoggedIn: GigyaService.isLoggedIn(), ...payload })
    },

    logout: ({ state, commit, dispatch }, payload) => {
      if (!state.isLoggedIn) {
        return
      }
      GigyaService.logout().then(() => {
        commit('clearUser', payload)
        dispatch('orders/clearOrder', null, { root: true })
        if (router.currentRoute.name !== 'home') {
          router.push({ name: 'home' })
        }
      })
    },

    unregister: ({ commit, dispatch }, payload) => {
      GigyaService.unregisterAccount().then(() => {
        dispatch('unregisterReason', payload)
        dispatch('logout')
        commit('setUnregisterReason', {
          id: '',
          reason: '',
          default: ''
        })
        dispatch('modal/setModalSubmit', { state: true }, { root: true })
      })
    },

    setUnregisterReason: ({ commit }, payload) => {
      commit('setUnregisterReason', payload)
    },

    setUnregisterExtraText: ({ commit }, payload) => {
      commit('setUnregisterExtraText', payload)
    }
  }
}
