import { render, staticRenderFns } from "./CognitiveMessage.vue?vue&type=template&id=5bfd5614&scoped=true"
import script from "./CognitiveMessage.vue?vue&type=script&lang=js"
export * from "./CognitiveMessage.vue?vue&type=script&lang=js"
import style0 from "./CognitiveMessage.scss?vue&type=style&index=0&id=5bfd5614&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bfd5614",
  null
  
)

export default component.exports