export const BASE_URL = `${process.env.VUE_APP_GIGYA_SDK_URL}?apikey=${
  process.env.VUE_APP_GIGYA_API_KEY
}`

export const SESSION_COOKIE = `glt_${process.env.VUE_APP_GIGYA_API_KEY}`

export const LOGIN_SCREENSET = 'VVDD-GasoleoCalefaccion-RegistrationLogin'
export const LOGIN_START_SCREEN = 'gigya-login-screen'
export const REGISTER_START_SCREEN = 'gigya-register-screen'
export const RESET_START_SCREEN = 'gigya-reset-password-screen'

export const UPDATE_SCREENSET = 'VVDD-GasoleoCalefaccion-ProfileUpdate'
export const UPDATE_START_SCREEN = 'gigya-update-profile-screen'

export const UNREGISTER_DATA = {
  data: { 'Service.VVDDGasoleoCalefaccion': null },
  preferences: {
    'Terms.VVDDGasoleoCalefaccion.isConsentGranted': false,
    'Consent.VVDDGasoleoCalefaccion.PERFILADO.isConsentGranted': false,
    'Consent.VVDDGasoleoCalefaccion.CESION.isConsentGranted': false,
    'Consent.VVDDGasoleoCalefaccion.COMERCIAL.isConsentGranted': false
  }
}

export const SESSION_CHECK_INTERVAL = 300
export const SESSION_INACTIVE_TIMEOUT = 10 * 60 * 1000
