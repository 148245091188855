<template>
  <section
    :class="[
      `search__mode--${route}`,
      'search'
    ]">
    <!-- START: ONLY MOBILE USER INFO DISPLAY -->
    <MobileOrderInfo v-if="mediaTest && (route === 'results' || route === 'results-error')">
      <template #address>
        <IconComponent name="IconPin" size="custom" />
        <h4 class="mobile-order-info__choosed">
          <span> {{orders.order.addressInfo.address}} </span>
          <span> {{orders.order.addressInfo.postalCode.value}} </span>
        </h4>
      </template>
      <template #quantity>
        <IconComponent name="IconPump" size="custom" />
        <h4 class="mobile-order-info__choosed">
          <span> {{orders.order.amountInfo.quantity}} {{$t('message.ORDER_SEARCH_LITERS_CHOOSED')}}</span>
        </h4>
      </template>
      <template #action>
        <a
          class="link--simple"
          :title="$t(`message.ORDER_SEARCH_CHANGE_CRITERIA_LABEL`)"
          @click="setFixed">
          <IconComponent
            name="IconEdit"
            size="custom"/>
          {{$t(`message.ORDER_SEARCH_CHANGE_CRITERIA_LABEL`)}}
        </a>
      </template>
      <template
        #code
        v-if="voucherValidation !== ''">
        <p>
          {{$t(`message.ORDER_SEARCH_PROMOTIONALCODE_MOBILE_LABEL`)}} {{voucherValue}}
          <IconComponent
            v-if="voucherValidation"
            name="IconStepCheck"
            size="custom"
            class="validPromotionaCode"/>
        </p>
        <PromotionMessageValidation
          v-if="voucherValidation"
          valid
          :showInfo = "promoLegalBasisUrl"
          @displayInfo = "showPromoInfo">
          <template #message>{{$t(`message.PROMOTIONAL_CODE_EXIST`)}} {{voucherDescription}}</template>
        </PromotionMessageValidation>
        <span
          class="error-message"
          v-else>{{promotionalCodeError}}</span>
      </template>
    </MobileOrderInfo>
    <!-- END: ONLY MOBILE USER INFO DISPLAY -->

    <!-- START: HERO TITLE -->
    <transition
      name="expandHeight"
      v-on:before-enter="setExpandHeight(arguments, {variable: '--expand-hero-height', queryElement: '.hero'})">
      <div
        v-if="route === 'home'"
        class="app__hero">
        <Hero
          :swipperBullets="swipperBullets"
          :title="this.$t(`message.ORDER_PAYOFF_TITLE`)"
          class="search__header__title"
          ref="hero"/>
      </div>
    </transition>
    <!-- END: HERO TITLE -->

    <!-- START: FORM WRAP CONTENT -->
    <article
      :class="[
          isFixed ? 'search__modal--isFixed' : null,
          lockState && openSearchForm ? 'search__modal--isOpen' : null,
          'search__modal']"
      :style="[
        lockState && openSearchForm ? {'height' : `calc(100% - ${formPosition}px)`} : null,
        isFixed ? {'top' : `${formPosition}px`} : null
      ]">
        <ul class="search__list">
          <li class="search__list__title">
            <h2>{{$t(`message.ORDER_SEARCH_TITLE_RESULT`)}}</h2>
          </li>
          <li>
            <nav class="search__navigation">
              <ul>
                <li class="search__navigation__tab">
                  <h3 class="search__navigation__label">{{$t(`message.ORDER_SEARCH_TITLE_START`)}}</h3>
                </li>
              </ul>
              <button
                v-if="mediaTest && route !== 'home'"
                class="search__navigation__close"
                @click="removeFixed">
              </button>
            </nav>
          </li>
        </ul>
      <UserFormOrder @submit="removeFixed" />
    </article>
  </section>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { Errors } from '../../errors'
import { Autocomplete } from '../../services/http/Autocomplete'

export default {
  name: 'SearchForm',
  data () {
    return {
      route: '',
      mediaTest: false,
      isFixed: false,
      openSearchForm: false,
      clientHeaderHeight: 0,
      clientDraggerScroll: 0,
      swipperBullets: [{
        icon: 'IconCheck',
        text: this.$t(`message.ORDER_BULLET_1`)
      }, {
        icon: 'IconCalendar',
        text: this.$t(`message.ORDER_BULLET_2`)
      }, {
        icon: 'IconSmartphone',
        text: this.$t(`message.ORDER_BULLET_3`)
      }]
    }
  },

  watch: {
    $route (to, from) {
      this.route = to.name
      if ((this.route !== 'results') && (this.route !== 'results-error')) {
        this.isFixed = false
      }
    }
  },

  computed: {
    ...mapState(['orders']),

    formPosition () {
      return this.clientHeaderHeight
    },

    lockState () {
      return this.orders.order.lockState
    },

    voucherValidation () {
      return this.orders.order.voucher.isValid
    },

    voucherValue () {
      return this.orders.order.voucher.code
    },

    voucherDescription () {
      return this.orders.order.voucher.description
    },

    orderFilter () {
      return this.orders.order.amountInfo.filter
    },

    voucher () {
      return this.orders.order.voucher
    },

    promotionalCodeError () {
      const voucherError = {
        MINIMUM_REQUIRED: { 'label': `${this.$t('message.ORDER_PROMOTIONAL_ERROR_MINIMUM', { quantity: `${this.voucher.error.value}` })} ${this.$t(`message.ORDER_SEARCH_TYPE_${this.orderFilter}`)}` }
      }

      return this.orders.order.voucher.error.type ? voucherError[this.orders.order.voucher.error.type].label : this.$t(`message.ORDER_PROMOTIONAL_ERROR_INCORRECT`)
    },

    promoLegalBasisUrl () {
      return this.orders.order.voucher.legalBasisUrl !== '' ? this.orders.order.voucher.legalBasisUrl : undefined
    }
  },

  methods: {
    ...mapActions('orders', [
      'setLockState',
      'setButtonState'
    ]),

    ...mapActions('modal', [
      'setModalDisplay',
      'setModalComponent'
    ]),

    screenTest () {
      const mediaQuery = window.matchMedia('(max-width: 767px)')
      this.mediaTest = mediaQuery.matches
    },

    setFixed () {
      this.isFixed = true
      this.openSearchForm = true
      this.setLockState({ state: true })
      this.setButtonState({ type: 'search', isDisabled: false })
    },

    removeFixed () {
      this.openSearchForm = false
      this.setLockState({ state: false })
    },

    detectAnimationEnd (e) {
      const animationName = e.animationName
      const check = animationName.includes('top--out')

      if (check) this.isFixed = false
    },

    redirectToSearch () {
      const response = this.orders.response
      if (typeof response.length === 'undefined') {
        this.$store.dispatch(`orders/${Errors.NON_ACTIVE_USER}`)
      }
    },

    setExpandHeight (args, props) {
      let bodyStyles = document.body.style
      if (args[0]) {
        this.$nextTick(() => {
          bodyStyles.setProperty(props.variable, getComputedStyle(args[0].querySelector(props.queryElement)).height)
        })
      }
    },

    setHeroHeight (element) {
      let bodyStyles = document.body.style
      if (element) {
        this.$nextTick(() => {
          bodyStyles.setProperty('--expand-hero-height', getComputedStyle(element.querySelector('.hero')).height)
        })
      }
    },

    openBasicModal (values = { props: {}, slots: [] }) {
      const payload = {
        state: true,
        name: 'ModalBasic',
        allowClose: false,
        size: 490,
        props: values.props,
        slots: values.slots
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    showPromoInfo () {
      const values = {
        slots: [
          { 'name': 'descriptionLiters', 'content': `<div class='modal-basic__text'>${this.$t(`message.PROMO_LEGAL_BASIS`, { url: `${this.promoLegalBasisUrl}` })}</div>` }
        ]
      }

      this.openBasicModal(values)
    }
  },

  beforeMount () {
    this.redirectToSearch()
  },

  mounted () {
    this.screenTest()
    const mediaQuery = window.matchMedia('(max-width: 767px)')
    mediaQuery.addListener(this.screenTest)

    this.route = this.$route.name

    this.$root.$emit('redirect')

    this.$root.$on('handleUserMenu', (value) => {
      if (value) this.setLockState({ state: false })
    })

    const wrap = document.querySelector('.search__modal')
    wrap.addEventListener('animationend', (e) => this.detectAnimationEnd(e))

    this.$nextTick(() => {
      const hero = this.$el.querySelector('.app__hero')
      window.addEventListener('orientationchange', () => {
        if (hero) setTimeout(() => this.setHeroHeight(hero), 100)
      }, false)

      window.addEventListener('resize', () => {
        if (hero) setTimeout(() => this.setHeroHeight(hero), 100)
      }, false)
    })

    Autocomplete.getKeys()
  },

  beforeDestroy () {
    window.removeEventListener('orientationchange', () => this.setHeroHeight())
    window.removeEventListener('resize', () => this.setHeroHeight())
  }
}
</script>
<style lang="scss" scoped src="./SearchForm.scss"></style>
