<template>
  <ul class="breadcrumb">
    <li
      v-for="(node, index) in steps"
      :key="index"
      :class="[
        node.active ? 'breadcrumb__action--active' : null,
        node.closed ? 'breadcrumb__action--closed' : null,
      ]"
    >
      <ul class="breadcrumb__wrap">
        <li class="breadcrumb__action">
          <div
            class="breadcrumb__step"
          >
            <button
              :disabled="node.disabled"
              :data-index="index + 1"
              ></button>
          </div>
        </li>
        <li class="breadcrumb__label">{{node.label}}</li>
      </ul>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'BreadcrumbComponent',

  props: {
    steps: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped src="./Breadcrumb.scss"></style>
